import * as React from "react";
import { connect } from "react-redux";
import WarningIcon from "../../components/icons/NewShotIcon";
import { IfMobile, IfNotMobile } from "../../components/responsive/responsiveUtil";
import { ApplicationState } from "../../reducers";
import commonStyles from "../styles/style.module.scss";
import styles from "./style.module.scss";
import { filterRelevantGunshot } from "../../util/gunshotUtil";

interface State {
  addAnimateClass: boolean;
  newShotsCount: number;
}

class NotificationOverlay extends React.Component<Props, State> {
  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): State | null {
    // Return null to indicate no change to state.
    if (prevState.newShotsCount < nextProps.newShots) {
      return {
        addAnimateClass: true,
        newShotsCount: nextProps.newShots,
      };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      addAnimateClass: true,
      newShotsCount: props.newShots,
    };
  }

  public render() {
    const { newShots } = this.props;
    const { addAnimateClass } = this.state;

    if (addAnimateClass) {
      setTimeout(() => {
        this.setState((prevState) => ({
          ...prevState,
          addAnimateClass: false,
        }));
      }, 3000);
    }

    const contentClasses = [
      styles.summaryNotificationOverlay,
      addAnimateClass ? styles.notificationBlink : "",
      newShots === 1 ? styles.notificationExpand : "",
    ].join(" ");

    return (
      <div className={contentClasses}>
        <div className={styles.newShotIcon}><WarningIcon width={18} height={15} backgroundColor={"#fb5c5c"} /></div>
        <p
          className={[styles.notificationText, commonStyles.noMargin].join(" ")}
        >
          <IfNotMobile><>{newShots > 1 ? `${newShots} new shots!`: "New shot!"}</></IfNotMobile>
          <IfMobile><>{newShots > 1 ? `${newShots} shots!`: "Shot!"}</></IfMobile>
        </p>
      </div>
    );
  }
}

interface Props {
  newShots: number;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
  newShots: filterRelevantGunshot(state.triangulation.newShots, undefined, new Date().getTime() - 5_000, state.triangulation.relativeRange, state.triangulation.twinToDisplay, state.areaFilter.list).length,
}};

export default connect<Props, {}, {}, ApplicationState>(mapStateToProps)(NotificationOverlay);
