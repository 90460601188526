import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import LockedIcon from "../../components/icons/LockedIcon";
import ItemLabel from "../../components/label/ItemLabel";
import RoundLabel from "../../components/label/RoundLabel";
import DropdownItem from "../../components/overlay/DropdownItem";
import ScoutBattery from "../../components/scout/ScoutBattery";
import { Focus, FocusType } from "../../models/map";
import { Scout, ScoutState } from "../../models/scouts";
import { stateChangeToMessage } from "../../util/scoutUtil";
import { getScoutMarkerDetails } from "../../util/util";
import { setFocusAction } from "../commander/commanderReducer";
import ListeningOrMuted from "./ListeningOrMuted";
import styles from "./style.module.scss";
import ScoutDetails from "./ScoutDetails";
import { ApplicationState } from "../../reducers";

export const getDisplayName = (scout: Scout) => {
  return scout.status.displayName
    ? scout.status.displayName
    : scout.status.deviceId.substr(0, 4);
};

const LabelAndTitle = ({ scout }: { scout: Scout }) => {
  return (
    <div className={[styles.simpleRow, styles.centered].join(" ")}>
      <div className={styles.itemInfo}>
        <p className={[styles.displayName].join(" ")}>
          {getDisplayName(scout)}
        </p>
        <ListeningOrMuted scout={scout} />
      </div>
    </div>
  );
};

const getTimeAgoInSeconds = (time: Date) => {
  return Math.floor((new Date().getTime() - time.getTime()) / 60000);
};

const StatusLabel = ({
  minutesAgo,
  state,
}: {
  minutesAgo: number;
  state: ScoutState;
}) => (
  <div className={styles.row}>
    <ItemLabel message={stateChangeToMessage(minutesAgo, state)} />
  </div>
);

const ScoutItem = ({ focused, scout, setFocus }: Props) => {
  const focusedScout = focused !== undefined && focused.id === scout.deviceId;
  const setFocusOnScout = () => {
    if (!focusedScout) {
      setFocus({ id: scout.deviceId, type: FocusType.Scouts });
    }
  };
  const minutesSinceStateChange = scout.lastChangedStatus
    ? getTimeAgoInSeconds(scout.lastChangedStatus)
    : 999;
  const markerDetails = getScoutMarkerDetails(scout, false);
  return (
    <DropdownItem
      id={scout.deviceId}
      focused={focusedScout}
      setFocus={setFocusOnScout}
    >
      <div>
        {minutesSinceStateChange < 5 && (
          <StatusLabel
            minutesAgo={minutesSinceStateChange}
            state={scout.state}
          />
        )}
        <div>
          <div className={[styles.row, styles.start].join(" ")}>
            <div
              style={{
                position: "relative",
                marginTop: focusedScout ? "0.4rem" : "0",
              }}
            >
              <RoundLabel
                letter={`${scout.config.markerLetter}` || ""}
                color={markerDetails.background}
              />
              {scout.fixedLocation && (
                <LockedIcon
                  backgroundColor={"#203864"}
                  width={"161"}
                  height={"21"}
                  cssStyle={{
                    marginLeft: "20px",
                    marginTop: "-16px",
                    position: "absolute",
                  }}
                />
              )}
            </div>
            <div className={styles.scoutItemDetails}>
              <div className={styles.spacedRow}>
                <LabelAndTitle scout={scout} />

                <div style={{ display: "flex", alignItems: "center" }}>
                  {scout.state !== ScoutState.Offline && scout.status.batteryPercent > 0 && (
                    <ScoutBattery status={scout.status} />
                  )}
                </div>
              </div>
              {focusedScout && <ScoutDetails deviceId={scout.deviceId} />}
            </div>
          </div>
        </div>
      </div>
    </DropdownItem>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  scout: Scout;
  focused: Focus | undefined;
}

interface DispatchToProps {
  setFocus: (_: Focus | undefined) => void;
}

interface OwnProps {
  deviceId: string;
}

const mapStateToProps = (state: ApplicationState, own: any) => ({
  focused: state.commander.focused,
  scout: state.scout.list.find((s) => s.deviceId === own.deviceId)!,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFocus: setFocusAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(ScoutItem);
