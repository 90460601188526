import * as React from "react"
const PlanningPage = React.lazy(() => import("./PlanningPage"));

const LazyPlanningPage = () => {
    return (
        <React.Suspense fallback={<></>}>
            <PlanningPage />
          </React.Suspense>
    )
}

export default LazyPlanningPage