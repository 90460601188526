import { db, store } from "../../ConfiguredApp";
import {
  addUnhandledPotentialGunshot,
  removeUnhandledPotentialGunshot,
} from "../../containers/scout/scoutReducer";
import { PotentialGunshot } from "../../models/detections";
import { UserData } from "../../models/user";

let ref: () => void;
export const listenForDetections = (user: UserData) => {
  if (user && !ref && user.organisation) {
    ref = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("detections")
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change: any) => {
          const data = { ...change.doc.data() } as PotentialGunshot;
          if (change.type === "added") {
            store.dispatch(addUnhandledPotentialGunshot(data.deviceId));
          }
          if (change.type === "removed") {
            store.dispatch(removeUnhandledPotentialGunshot(data.deviceId));
          }
        });
      });
  }
};
