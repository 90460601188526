import {db, store} from "../../ConfiguredApp";
import {addPlanAction, removePlanAction} from "../../containers/planning/planningReducer";
import { Plan } from "../../models/plan";
import {UserData} from "../../models/user";

let ref: () => void;


export const listenForPlans = (user: UserData) => {
  if (user && !ref && user.organisation) {
    ref = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("plans")
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change: any) => {
          if (change.type === "added" || change.type === "modified") {
            const plan = {...change.doc.data(), id: change.doc.id, updated: new Date(change.doc.data().updated), labels: change.doc.data().labels === undefined ? [] : change.doc.data().labels} as Plan;
            store.dispatch(addPlanAction(plan));
          }

          if (change.type === "removed") {
            store.dispatch(removePlanAction(change.doc.id));
          }
        });
      });
  }
};
