import * as React from "react";
import { useState, useEffect } from "react";
import { getLocationFor } from "../../services/geoLocationService";
import { LatLon } from "../../models/location";
import { LngLat } from "mapbox-gl";
import commonStyles from "../../containers/styles/style.module.scss";
import styles from "./style.module.scss";
import { MgrsMode } from "../../models/map";
import mgrsUtil from "../../util/mgrsUtil";


interface Props {
  latLon: LatLon;
  mgrsMode: MgrsMode;
}

const Location = ({ latLon: { latitude, longitude } }: Props) => {
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [location, setLocation] = useState("");
  useEffect(() => {
    if (currentLatitude !== latitude && location === "") {
      setLocation("");
      setCurrentLatitude(latitude);
      
      getLocationFor(new LngLat(longitude, latitude), (l) => setLocation(l));
    }
  }, [currentLatitude, latitude, location, longitude]);

  return (
    <p
      id={`scout_location`}
      className={commonStyles.infoText}
    >
      {`${location}`}
    </p>
  );
};

const LocationDetails = ({ latLon, mgrsMode }: Props) => {
  return (
    <div className={styles.row}>
      <div>
      <div className={commonStyles.subHeader}>
        Location
      </div>
        <Location latLon={latLon} mgrsMode={mgrsMode} />
        <p className={commonStyles.infoText}>{`${latLon.latitude.toFixed(5)}, ${latLon.longitude.toFixed(5)}`}</p>
        {mgrsMode !== MgrsMode.Off && <p className={commonStyles.infoText}>MGRS: {mgrsUtil([latLon.longitude,latLon.latitude], mgrsMode)}</p>}
      </div>
    </div>
  );
};

export default LocationDetails;
