import { Action, Reducer } from "redux";

import { Geofence } from "../../models/geofence";
import { ThunkResult } from "../../reducers";

const CREATE_NEW_GEOFENCE = "CREATE_NEW_GEOFENCE";
const SHOW_GEOFENCE = "SHOW_GEOFENCE";
const ADD_OR_UPDATE_GEOFENCE = "ADD_OR_UPDATE_GEOFENCE";
const DELETE_GEOFENCE = "DELETE_GEOFENCE";
const SELECTED_GEOFENCE = "SELECTED_GEOFENCE";

export interface GeofenceReducerState {
  createNew: boolean;
  show: boolean;
  list: Geofence[];
  selected: string | undefined;
}

export const geofenceInitialState: GeofenceReducerState = {
  createNew: false,
  show: false,
  list: [],
  selected: undefined,
};

export const addOrUpdateGeofenceAction = (
  geofence: Geofence
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: ADD_OR_UPDATE_GEOFENCE, geofence });
  };
};

export const deleteGeofenceAction = (geofenceId: string): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: DELETE_GEOFENCE, id: geofenceId });
  };
};

export const showGeofenceAction = (show: boolean): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: SHOW_GEOFENCE, show });
  };
};

export const createGeofenceAction = (): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_GEOFENCE });
  };
};

export const selectedGeofenceAction = (
  selected: string | undefined
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: SELECTED_GEOFENCE, selected });
  };
};

const reducer: Reducer<GeofenceReducerState> = (
  state: GeofenceReducerState = geofenceInitialState,
  action
) => {
  switch ((action as Action).type) {
    case SHOW_GEOFENCE: {
      return { ...state, show: action.show };
    }
    case CREATE_NEW_GEOFENCE: {
      return { ...state, createNew: true };
    }
    case ADD_OR_UPDATE_GEOFENCE: {
      if (state.list.find((s) => s.id === action.geofence.id)) {
        return {
          ...state,
          list: state.list.map((s) => {
            if (s.id === action.geofence.id) {
              return action.geofence;
            } else {
              return s;
            }
          }),
          createNew: false,
        };
      } else {
        return {
          ...state,
          list: [...state.list, action.geofence],
          createNew: false,
        };
      }
    }
    case DELETE_GEOFENCE: {
      return {
        ...state,
        list: state.list.filter((s) => s.id !== action.id),
        selected: undefined,
      };
    }
    case SELECTED_GEOFENCE: {
      return { ...state, selected: action.selected };
    }
    default:
      return state;
  }
};

export default reducer;
