import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { LatLon } from "../../../models/location";
import { MapViewType } from "../../../models/MapViewType";
import { ApplicationState } from "../../../reducers";
import { addMarkerIcons } from "../mapUtil";
import { getLocationSearchSvg } from "../svgUtils";

export interface MapboxProps {
  pendingFixedLocation: { [deviceId: string]: LatLon | undefined };
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

export function addDragLayers(map: Map) {
  dragIconAndLineLayer(map);

  addMarkerIcons(
    [
      {
        name: "pending_drag_icon",
        imageWidth: 50,
        imageHeight: 50,
        svg: getLocationSearchSvg(),
      },
    ],
    map
  );
  map.addLayer({
    id: "pending-drag-icon",
    layout: {
      "icon-allow-overlap": true,
      "icon-anchor": "center",
      "icon-image": "pending_drag_icon",
      "text-allow-overlap": true,
      "text-anchor": "bottom",
      "text-field": "Updating position...",
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 18,
      "text-offset": [0, -2],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "pending-drag-icon",
    type: "symbol",
  });

  
}

export function dragIconAndLineLayer(map: Map) {
  addMarkerIcons(
    [
      {
        name: "drag_icon",
        imageWidth: 100,
        imageHeight: 100,
        svg: getLocationSearchSvg(),
      },
    ],
    map
  );

  map.addLayer({
    id: "drag-line",
    paint: {
      "line-color": "yellow",
      "line-width": 5,
      "line-opacity": 1,
    },
    source: "drag-line",
    type: "line",
  });
  map.addLayer({
    id: "drag-icon",
    layout: {
      "icon-allow-overlap": true,
      "icon-anchor": "center",
      "icon-image": "drag_icon",
      "text-allow-overlap": true,
      "text-anchor": "bottom",
      "text-field": ["get", "description"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 18,
      "text-offset": [0, -3],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "drag-icon",
    type: "symbol",
  });
}

function addSource(map: Map, pendingFixedLocation: {
  [deviceId: string]: LatLon | undefined;
}) {
  map.addSource("drag-icon", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  map.addSource("drag-line", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  map.addSource("pending-drag-icon", {
    data: createPendingFixedLocationGeoJson(pendingFixedLocation),
    type: "geojson",
  });
}

function updateSource(
  map: Map,
  pendingFixedLocation: {
    [deviceId: string]: LatLon | undefined;
  }
) {
  map
    .getSource("pending-drag-icon")
    // @ts-ignore
    .setData(createPendingFixedLocationGeoJson(pendingFixedLocation));
}

const createPendingFixedLocationGeoJson = (pendingFixedLocation: {
  [deviceId: string]: LatLon | undefined;
}): GeoJSON.FeatureCollection => {
  return {
    features: 
      Object.values(pendingFixedLocation).filter((l) => l !== undefined).map((latLon) => ({
        geometry: {
          coordinates: [latLon!.longitude, latLon!.latitude],
          type: "Point",
        } as GeoJSON.Point,
        properties: {},
        type: "Feature",
      } as GeoJSON.Feature)),
    type: "FeatureCollection",
  };
};

const DragLayer = ({ map, loaded, pendingFixedLocation }: Props) => {
  if (map && loaded) {
    if (!map.getSource("drag-icon")) {
      addSource(map, pendingFixedLocation);
      addDragLayers(map);
    }
    else {
      updateSource(map, pendingFixedLocation);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    pendingFixedLocation: state.scout.pendingFixedLocation,
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(DragLayer);
