import {LngLatBounds, Map} from "mapbox-gl";
import * as React from "react";
import {connect} from "react-redux";
import {GunshotLayerType, TwinDisplayMode} from "../../../models/map";
import {MapViewType} from "../../../models/MapViewType";
import {Gunshot} from "../../../models/gunshots";
import { ApplicationState } from "../../../reducers";
import {triangulationPoints} from "../mapUtil";
import {getDiffInSeconds} from "../util";
import { filterRelevantGunshot } from "../../../util/gunshotUtil";
import { RelativeRange } from "../../range/rangeUtil";

export interface MapboxProps {
    gunshots: Gunshot[];
    timeOfLatestGunshot: Date;
    relativeLatestTriangulation: number | undefined;
    gunshotLayer: GunshotLayerType;
}

export interface OwnProps {
    map: Map;
    loaded: boolean;
    currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {

}

export interface MapboxState {
    interval: number;
    blinkOpacity: number;
    bounds: LngLatBounds | undefined;
}

function addBlinkingTriangulationLayer(map: Map) {
    map.addLayer({
        id: "point",
        paint: {
            "circle-color": "red",
            "circle-color-transition": {
                duration: 1000,
            },
            "circle-opacity": 0,
            "circle-opacity-transition": {
                duration: 1000,
            },
            "circle-radius": 22,
            "circle-radius-transition": {
                duration: 1000,
            },
        },
        source: "newtriangulations",
        type: "circle",
    });
}

function addBlinkingGunshotsSource(newTriangulations: Gunshot[], timeOfLatestGunshot: Date, rangeEnd: number | undefined, map: Map) {
    map.addSource("newtriangulations", {
        data: triangulationPoints(newTriangulations, timeOfLatestGunshot, rangeEnd),
        type: "geojson",
    });
}

function updateBlinkingTriangulationSource(newTriangulations: Gunshot[], timeOfLatestGunshot: Date, rangeEnd: number | undefined, map: Map) {
    // @ts-ignore
    map.getSource("newtriangulations").setData(triangulationPoints(newTriangulations, timeOfLatestGunshot, rangeEnd));
}

class TriangulationBlinkingLayer extends React.Component<Props, MapboxState> {

    private interval: any;
    private blink: any;

    constructor(props: Props) {
        super(props);
        this.state = {blinkOpacity: 0, interval: 0, bounds: undefined};
        this.intervalHandler = this.intervalHandler.bind(this);
        this.blinkHandler = this.blinkHandler.bind(this);
    }

    public shouldComponentUpdate(nextProps: Props, nextState: MapboxState) {

        // If no triangulations to blink
        return !(nextProps.gunshots.length === 0 && this.props.gunshots.length === 0);


    }

    public componentDidMount() {
        this.interval = setInterval(this.intervalHandler, 400);
        this.blink = setInterval(this.blinkHandler, 1000);
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.blink);
    }

    public blinkHandler() {
        const {map, gunshots} = this.props;
        if (map && this.props.loaded && gunshots.length > 0) {
            map.setPaintProperty("point", "circle-opacity", this.state.blinkOpacity === 0 ? 0.5 : 1);
            map.setPaintProperty("point", "circle-radius", this.state.blinkOpacity === 0 ? 15 : 20);
            map.setPaintProperty("point", "circle-color", this.state.blinkOpacity === 0 ? "white" : "red");
            this.setState((prevState) => ({...prevState, blinkOpacity: prevState.blinkOpacity === 0 ? 1 : 0}));
        }
    }

    public intervalHandler() {
        const {map, gunshots} = this.props;
        if (map && this.props.loaded && gunshots.length > 0) {
            this.setState((prevState) => ({...prevState, interval: prevState.interval + 1}));
        }
    }

    public render() {
        const {gunshots, timeOfLatestGunshot, map, loaded, relativeLatestTriangulation} = this.props;
        if (map && loaded) {

            if (!map.getSource("newtriangulations")) {
                addBlinkingGunshotsSource(gunshots, timeOfLatestGunshot, relativeLatestTriangulation, map);
                addBlinkingTriangulationLayer(map);
            } else {
                if (this.props.gunshotLayer === GunshotLayerType.Heatmap) {
                    updateBlinkingTriangulationSource([], timeOfLatestGunshot, relativeLatestTriangulation, map);
                } else {
                    updateBlinkingTriangulationSource(gunshots, timeOfLatestGunshot, relativeLatestTriangulation, map);
                }
            }
        }

        return null;
    }
}

const mapStateToProps = (state: ApplicationState) => {

    const relevantGunshots = filterRelevantGunshot(state.triangulation.newShots, undefined, Date.now() - 20_000, state.triangulation.relativeRange, state.triangulation.twinToDisplay, state.areaFilter.list);

    return ({
        gunshotLayer: state.commander.gunshotLayerType,
        relativeLatestTriangulation: state.triangulation.relativeEndDate,
        timeOfLatestGunshot: state.triangulation.timeOfLatestGunshot,
        gunshots: relevantGunshots
    });
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(TriangulationBlinkingLayer);
