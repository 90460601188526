import { store } from "../ConfiguredApp";
import { Scout, ScoutState } from "../models/scouts";
import { Status, StatusMessage } from "../models/warning";

export const movingWhenFixedPositionRule = (
  scout: Scout
): StatusMessage | undefined => {
  const id = `${scout.deviceId}_moving`;
  // If fixed position and moving
  if (
    scout.status.fixedLocation &&
    scout.status.moving &&
    scout.state !== ScoutState.Offline &&
    scout.state !== ScoutState.OfflineIgnore
  ) {
    return {
      id,
      message: `${
        scout.status.displayName
          ? scout.status.displayName
          : scout.status.deviceId.substr(0, 4)
      } has a fixed position, but the device moved`,
      component: "Scout",
      status: Status.Warning,
      useInPopup: true,
    };
  } else if (store.getState().status.list[id]) {
    return {
      id,
      message: `Ok`,
      component: "Scout",
      status: Status.Ok,
      useInPopup: false,
    };
  }
};

export const detectionRule = (
  scout: Scout
): StatusMessage | undefined => {
  const id = `${scout.deviceId}_detection_unavailable`;
  if (
    scout.state === ScoutState.DetectionUnavailable
  ) {
    return {
      id,
      message: `${
        scout.status.displayName
          ? scout.status.displayName
          : scout.status.deviceId.substr(0, 4)
      } Gunshot detector unavailable`,
      component: "Scout",
      status: Status.Warning,
      useInPopup: false,
    };
  } else if (store.getState().status.list[id]) {
    return {
      id,
      message: `Ok`,
      component: "Scout",
      status: Status.Ok,
      useInPopup: false,
    };
  }
};

export const antiTheftOnRule = (scout: Scout): StatusMessage | undefined => {
  const id = `${scout.deviceId}_anti_theft`;
  // If fixed position and moving
  if (
    scout.status.moving &&
    store.getState().authentication.userData?.organisation?.antiTheft && 
    scout.state !== ScoutState.Offline &&
    scout.state !== ScoutState.OfflineIgnore
  ) {
    return {
      id,
      message: `Scout ${
        scout.status.displayName
          ? scout.status.displayName
          : scout.status.deviceId.substr(0, 4)
      } moved`,
      component: "Scout",
      status: Status.Warning,
      useInPopup: true,
    };
  } else if (store.getState().status.list[id]) {
    return {
      id,
      message: `Ok`,
      component: "Scout",
      status: Status.Ok,
      useInPopup: false,
    };
  }
};

export const batteryRule = (scout: Scout): StatusMessage | undefined => {
  const id = `${scout.deviceId}_low_battery`;
  if (
    scout.status.batteryPercent < 30 &&
    scout.state !== ScoutState.Offline &&
    scout.state !== ScoutState.OfflineIgnore
  ) {
    return {
      id,
      message: `Scout ${
        scout.status.displayName
          ? scout.status.displayName
          : scout.status.deviceId.substr(0, 4)
      } has ${scout.status.batteryPercent}% battery left`,
      component: "Scout",
      status: Status.Warning,
      useInPopup: true,
    };
  } else if (store.getState().status.list[id]) {
    return {
      id,
      message: `Ok`,
      component: "Scout",
      status: Status.Ok,
    };
  }
};

export const listeningShouldHaveAudioRule = (scout: Scout): StatusMessage | undefined => {
  const id = `${scout.deviceId}_listening_no_audio`;
  if (
    store.getState().commander.admin &&
    scout.state === ScoutState.Listening &&
    scout.status.audioStatistics?.sampleAverages[0]?.sampleAverage === -96
  ) {
    return {
      id,
      message: `Scout ${
        scout.status.displayName
          ? scout.status.displayName
          : scout.status.deviceId.substr(0, 4)
      } has no audio stream`,
      component: "Scout",
      status: Status.Warning,
      useInPopup: true,
    };
  } else if (store.getState().status.list[id]) {
    return {
      id,
      message: `Ok`,
      component: "Scout",
      status: Status.Ok,
    };
  }
};


export const clockIsHealthyRule = (scout: Scout): StatusMessage | undefined => {
  const id = `${scout.deviceId}_clock_health`;
  const details = scout.status.timeService?.details ?? ""
  if (
    store.getState().commander.admin &&
    scout.state === ScoutState.Listening &&
    details.includes("healty: false")
  ) {
    const regex = /Health(.*?)\)/;

    const match = details.match(regex);
    const reason = (match) ? match[1] : "Unknown reason"
    return {
      id,
      message: `Scout ${
        scout.status.displayName
          ? scout.status.displayName
          : scout.status.deviceId.substr(0, 4)
      }: Unhealthy clock - ${reason}`,
      component: "Scout",
      status: Status.Warning,
      useInPopup: true,
    };
  } else if (store.getState().status.list[id]) {
    return {
      id,
      message: `Ok`,
      component: "Scout",
      status: Status.Ok,
    };
  }
};
