import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { MapViewType } from "../../../models/MapViewType";
import { orderLayers } from "../layerUtils";
import { ApplicationState } from "../../../reducers";
import { CoverageData } from "../../../util/coverage";
import React from "react";
import common from "../../../containers/styles/style.module.scss";

export interface MapboxProps {
  mapType: MapViewType;
  coverage: CoverageData;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}



function addCoverageLayer(map: Map) {
    map.addLayer({
      id: "coverage-heatmap",
      maxzoom: 24,
      source: "coverage-data",
      type: "heatmap",
      filter: ['<=', 'resolution', 200],
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['get', 'score'],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          1,
          10,
          1,
          13,
          0.7,
          14,
          0.6,
          15,
          0.4,
          20,
          0.4,
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(33,102,172,0)",
          0.1,
          "#67cf99",
          0.2,
          "rgba(132, 204, 22, 0.7)",//"rgba(56, 217, 88, 1)", //rgb(209,229,240)
          0.8,
          "rgba(132, 204, 22, 1)",
        ],
        "heatmap-radius": {
            "base": 2,
            "stops": [
              [
                10,
                2*7
              ],
              [
                19,
                512*7
              ]
            ]
          },
        "heatmap-opacity": 0.5,
      },
    });

    map.addLayer({
      id: "rifle-coverage-heatmap",
      maxzoom: 24,
      source: "coverage-data",
      type: "heatmap",
      filter: ['<=', 'resolution', 200],
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['get', 'rifleScore'],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          1,
          10,
          1,
          13,
          0.7,
          14,
          0.6,
          15,
          0.4,
          20,
          0.4,
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
         0,
          "rgba(33,102,172,0)",
          0.1,
          "#67c1cf",
          0.2,
          "rgba(22, 149, 204, 0.7)",//"rgba(56, 217, 88, 1)", //rgb(209,229,240)
          0.8,
          "#168ccc",
        ],
        "heatmap-radius": {
            "base": 2,
            "stops": [
              [
                10,
                2*7
              ],
              [
                19,
                512*7
              ]
            ]
          },
        "heatmap-opacity": 0.5,
      },
    })
    map.addLayer({
      id: "coverage-heatmap-mid-res",
      maxzoom: 24,
      source: "coverage-data",
      type: "heatmap",
      filter: ['>', 'resolution', 200],
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['get', 'score'],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          1,
          10,
          1,
          13,
          0.7,
          14,
          0.6,
          15,
          0.6,
          20,
          0.6,
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(33,102,172,0)",
          0.1,
          "#67cf99",
          0.2,
          "rgba(132, 204, 22, 0.7)",//"rgba(56, 217, 88, 1)", //rgb(209,229,240)
          0.8,
          "rgba(132, 204, 22, 1)",
        ],
        "heatmap-radius": {
            "base": 2,
            "stops": [
              [
                10,
                2*20
              ],
              [
                19,
                512*20
              ]
            ]
          },
        "heatmap-opacity": 0.5,
      },
    });
    map.addLayer({
      id: "rifle-coverage-heatmap-mid-res",
      maxzoom: 24,
      source: "coverage-data",
      type: "heatmap",
      filter: ['>', 'resolution', 200],
      paint: {
        // Increase the heatmap weight based on frequency and property magnitude
        'heatmap-weight': ['get', 'rifleScore'],
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          1,
          10,
          1,
          13,
          0.7,
          14,
          0.6,
          15,
          0.6,
          20,
          0.6,
        ],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(33,102,172,0)",
          0.1,
          "#67c1cf",
          0.2,
          "rgba(40, 172, 229, 0.7)",//"rgba(56, 217, 88, 1)", //rgb(209,229,240)
          0.8,
          "#168ccc",
        ],
        "heatmap-radius": {
            "base": 2,
            "stops": [
              [
                10,
                2*20
              ],
              [
                19,
                512*20
              ]
            ]
          },
        "heatmap-opacity": 0.5,
      },
    })
  }
  


  const createFeaturesForCoverage = (
    cover:CoverageData
  ): any => {
    const resolution = Math.max(cover.resolution?.xResolution ?? 0, cover.resolution?.yResolution ?? 0)
    return cover.points.map((coverPoint) => {
      return {
        geometry: {
          coordinates: [coverPoint.longitude, coverPoint.latitude],
          type: "Point",
        },
        id: `${coverPoint.latitude}-${coverPoint.longitude}`,
        properties: {
          score: coverPoint.score/3,
          rifleScore: coverPoint.rifleScore/3,
          resolution 
        },
        type: "Feature",
      };
    });
  };
  const createCoverageGeoJson = (
    coverage: CoverageData
  ): GeoJSON.FeatureCollection => {
    const points = createFeaturesForCoverage(coverage)
    return {
      features: points,
      type: "FeatureCollection",
    };
  };

const addScoutSource = (
  coverage: CoverageData,
  map: Map,
) => {
    
  map.addSource("coverage-data", {
    data: createCoverageGeoJson(coverage),
    type: "geojson",
  });
};

function updateScoutSource(coverage: CoverageData, map: Map) {
  // @ts-ignore
  map.getSource("coverage-data").setData(createCoverageGeoJson(coverage));
}

const CoverageHeatmapLayer = ({
  map,
  loaded,
  coverage
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("coverage-data")) {
      addScoutSource(coverage, map);
      addCoverageLayer(map);
      orderLayers(map);
    } else {
        updateScoutSource(coverage, map);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  
  return {
    mapType: state.commander.mapType,
    coverage: state.commander.coverage ? state.scout.coverages ?? {area: 0, points: []} : {area: 0, points: []},
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(CoverageHeatmapLayer);


const planMapStateToProps = (state: ApplicationState) => {
  return {
    mapType: state.commander.mapType,
    coverage: state.planning.coverages ?? {area: 0, points: []},
  };
};

export const PlanCoverage = connect<MapboxProps, {}, {}, ApplicationState>(planMapStateToProps)(CoverageHeatmapLayer);


const ColorSample = ({color}: {color: string}) => (
  <div style={{width: "1rem", height: "1rem", borderRadius: "20px",marginRight: "0.5rem", background: color}}/>
)


export const CoverageExplanation = () => {
  return (
    <div  className={[common.verCenteredRow, common.paddingVertical].join(" ")}>
      <div className={common.verCenteredRow} style={{marginRight: "1rem"}}>
        
      <ColorSample color="#67cf99"/>
      <p className={common.infoText}>Pistol</p>
      </div>
      <div className={common.verCenteredRow}>
      <ColorSample color="#67c1cf"/>
      <p className={common.infoText}>Rifle</p>
      </div>

    </div>
  )
}