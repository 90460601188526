import * as React from "react";
import styles from "../../containers/overview/style.module.scss";
import commonStyles from "../../containers/styles/style.module.scss";
import {FocusType} from "../../models/map";

interface Props {
    focusType: FocusType;
    inFocus: boolean;
    setFocus: () => void;
    children: JSX.Element;
}

const SummaryItem = ({focusType, inFocus, setFocus, children}: Props) => (
    <div
        id={`summary_${focusType}_button`}
        className={[inFocus ? styles.focus : styles.noFocus, styles.padding, commonStyles.clickable].join(" ")}
        onClick={setFocus}
    >
        {children}
    </div>
);

export default SummaryItem;
