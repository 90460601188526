import { Gunshot } from "../../models/gunshots";

export enum RelativeRange {
  ONE_MINUTE = "Last minute",
  TEN_MINUTES = "Last 10 minutes",
  LAST_HOUR = "Last hour",
  TWENTYFOUR_HOURS = "Last 24 hours",
  TODAY="Today",
  YESTERDAY="Yesterday",
  ALL = "All",
}

export const shouldBeDisplayed = (
  gunshot: Gunshot,
  relativeRangeDate: RelativeRangeDate | undefined
) => {
  if (!relativeRangeDate) {
    return true;
  }

  if (
    (!relativeRangeDate.start || relativeRangeDate.start < gunshot.timestamp) &&
    (!relativeRangeDate.end || relativeRangeDate.end > gunshot.timestamp)
  ) {
    return true;
  } else {
    return false;
  }
};

export interface RelativeRangeDate {
  start: Date | undefined;
  end: Date | undefined;
}

export const relativeRangeToDate = (
  relativeRange: RelativeRange
): RelativeRangeDate => {
  switch (relativeRange) {
    case RelativeRange.TEN_MINUTES:
      return { start: new Date(Date.now() - 1000 * 60 * 10), end: undefined };
    case RelativeRange.ONE_MINUTE:
      return { start: new Date(Date.now() - 1000 * 60), end: undefined };
    case RelativeRange.LAST_HOUR:
      return { start: new Date(Date.now() - 1000 * 60 * 60), end: undefined };
    case RelativeRange.TODAY:
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      return { start: startOfDay, end: undefined };
    case RelativeRange.YESTERDAY:
      const startOfYesterday = getYesterday();
      startOfYesterday.setHours(0, 0, 0, 0);
      const endOfYesterday = getYesterday();
      endOfYesterday.setHours(23, 59, 59, 999);
      return { start: startOfYesterday, end: endOfYesterday };
    case RelativeRange.TWENTYFOUR_HOURS:
      return {
        start: new Date(Date.now() - 1000 * 60 * 60 * 24),
        end: undefined,
      };
    default:
      return { start: undefined, end: undefined };
  }
};
function getYesterday() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
}
