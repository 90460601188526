import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { store } from "../../ConfiguredApp";
import { addFeaturesSwitchFeaturesAction } from "../../containers/feature/featureReducer";
import { FeatureState } from "../../models/featureSwitch";
import { UserData } from "../../models/user";

let ref: () => void;
export const listenForFeatureSwitchConfig = (user: UserData) => {
  if (user && !ref && user.organisation) {
    ref = firebase
      .firestore()
      .collection("env")
      .doc("prod")
      .collection("app")
      .doc("featureSwitch")
      .onSnapshot((snapshot) => {
        const features = snapshot.data() as { [id: string]: FeatureState };
        store.dispatch(addFeaturesSwitchFeaturesAction(features));
      });

    return ref;
  }
};
