import * as React from "react";
import commonStyles from "../../containers/styles/style.module.scss";
import { Focus, FocusType } from "../../models/map";
import { getFocusElementId, getFocusType } from "../../util/util";
import styles from "./style.module.scss";

interface Props {
  focused: Focus | undefined;
  children: JSX.Element[];
  focusType: FocusType;
}

interface State {
  show: boolean;
}

class GenericDropdownOverlay extends React.Component<Props, State> {
  public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const showList = getFocusType(nextProps.focused) === nextProps.focusType;
    if (showList) {
      return { show: true };
    } else {
      return prevState;
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = { show: false };
    this.transitionEnd = this.transitionEnd.bind(this);
  }

  public transitionEnd() {
    const open = getFocusType(this.props.focused) === this.props.focusType;
    if (!open) {
      this.setState(() => ({
        show: false,
      }));
    }
  }

  public render() {
    const { focused, children, focusType } = this.props;

    const focusedElement = getFocusElementId(focused);
    const open = getFocusType(focused) === focusType;

    const className = [
      styles.genericDropdown,
      open ? styles.open : styles.closed,
      focused && focused.type === FocusType.Scouts
        ? styles.dropdownScout
        : styles.dropdown,
      focusedElement ? "" : styles.shadow,
      open ? commonStyles.openAnimation : commonStyles.closeAnimation,
    ];

    if (this.state.show) {
      const elem = (
        <div
          data-testid={`${focusType}_dropdown`}
          className={className.join(" ")}
          onAnimationEnd={this.transitionEnd}
        >
          <div className={commonStyles.itemAnimation}>{children}</div>
        </div>
      );
      return elem;
    } else {
      return (
        <div
          id={`closed_dropdown_${focusType.valueOf()}`}
          className={styles.dropdownPosition}
        />
      );
    }
  }
}

export default GenericDropdownOverlay;
