import {Geometry, LineString} from "geojson";
import {Map} from "mapbox-gl";

export enum FocusType {
    Shots = 0,
    Scouts = 1,
    Status = 2,
    Coverage = 3,
    Menu = 4,
    Geofence = 5,
}

export enum TwinDisplayMode {
    All = 0,
    Inner = 1,
    Outer = 2,
}

export enum MgrsMode {
    Off = 0,
    hundred_meter_accuracy = 3,
    ten_meter_accuracy = 4,
    one_meter_accuracy = 5,
}

export enum GunshotLayerType {
    Circle = 0,
    Heatmap = 1,
}

export interface Focus {
    id?: string;
    type: FocusType;
    scrolled?: boolean;
}

export interface FocusItem {
    id: string;
    type: FocusType;
}

export enum MapCategory {
    Main = 0,
    Focus = 1,
    Both = 2,
}

export enum EventType {
    ROTATE = 0,
    CENTER = 1,
}

export interface MapEvent {
    type: EventType;
    mapCategory: MapCategory;
    toExecute: (_: Map) => void;
}

export interface Coverage {
    area: number;
    scouts: number;
    geojson: Geometry;
}

export interface Hyperbolas {
    hyperbolas: LineString[];
}