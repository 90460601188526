import * as React from "react";
import ProgressDots from "./ProgressDots";
import styles from "./style.module.css";

const LoadingScreen = ({ message }: { message: string }) => {
  return (
    <div className={styles.loadingScreen}>
      <h1 id={"loading_screen"} className={styles.loadingMessage}>
        {message}
      </h1>
      <ProgressDots color={"#203864"} />
    </div>
  );
};

export default LoadingScreen;
