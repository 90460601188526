import axios from "axios";
import { createHeaders, getHostname } from "./serviceUtil";


export const muteAllScouts = (jwtToken: string) => {
  axios.put(`${getHostname()}/alpha/mute-devices`, "", createHeaders(jwtToken)).then(response => {
    // tslint:disable-next-line: no-empty
    if (response.status === 200) {
      // tslint:disable-next-line: no-empty
    } else {
    }
  });
};

export const changeOperatingMode = (jwtToken: string, mode: string) => {
  axios.put(`${getHostname()}/alpha/configuration/mode/${mode}`, "", createHeaders(jwtToken)).then(response => {
    // tslint:disable-next-line: no-empty
    if (response.status === 200) {
      // tslint:disable-next-line: no-empty
    } else {
    }
  });
};

export const setScoutsToListening = (jwtToken: string) => {
  axios.put(`${getHostname()}/alpha/set-devices-to-listen`, "", createHeaders(jwtToken)).then(response => {
    // tslint:disable-next-line: no-empty
    if (response.status === 200) {
      // tslint:disable-next-line: no-empty
    } else {
    }
  });
};

export const muteScout = (deviceId: string, jwtToken: string) => {
  axios
    .put(
      `${getHostname()}/alpha/scouts/${deviceId}/mute`,
      "",
      createHeaders(jwtToken)
    )
    .then(response => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};


export const enableGpsLocationForScout = (deviceId: string, jwtToken: string) => {
    axios
        .put(
            `${getHostname()}/alpha/scouts/${deviceId}/useGpsLocation`,
            "",
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                // tslint:disable-next-line: no-empty
            } else {
            }
        });
};

export const useFixedLocationForScout = (deviceId: string, jwtToken: string) => {
    axios
        .put(
            `${getHostname()}/alpha/scouts/${deviceId}/useFixedLocation`,
            "",
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                // tslint:disable-next-line: no-empty
            } else {
            }
        });
};

export const setScoutToListening = (deviceId: string, jwtToken: string) => {
  axios
    .put(
      `${getHostname()}/alpha/scouts/${deviceId}/listen`,
      "",
      createHeaders(jwtToken)
    )
    .then(response => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

interface FixedLocationRequest {
    latitude: number;
    longitude: number;
}

export const setFixedLocationScout = (deviceId: string, latitude: number, longitude: number, jwtToken: string) => {

    const request: FixedLocationRequest = {latitude, longitude};
    axios
        .put(
            `${getHostname()}/alpha/scouts/${deviceId}/fixed-location`,
            request,
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                // tslint:disable-next-line: no-empty
            } else {
            }
        });
};



