import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import LoadingDemo from "../../components/loading/LoadingDemo";
import { UserData } from "../../models/user";
import { ApplicationState } from "../../reducers";
import NoAccessPage, { NoAccessReason } from "../authentication/NoAccessPage";
import OverviewPage from "../overview/OverviewPage";

/**
 * Validates that you are eligible for demo
 * @returns
 */
const DemoPage = ({ userData, noAccess }: Props) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get("id") || !urlParams.get("token")) {
        return <NoAccessPage reason={NoAccessReason.NoTokens}/>   
    }
    if (noAccess) {
     return <NoAccessPage />
    }
    if (!userData) {
        return <LoadingDemo />
    }
  return <OverviewPage />;
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  userData?: UserData;
  noAccess: boolean;
}

interface DispatchToProps {}

const mapStateToProps = (state: ApplicationState) => ({
  userData: state.authentication.userData,
  noAccess: state.authentication.noAccess,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(DemoPage);
