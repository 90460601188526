import distance from "@turf/distance";
import { Geofence, LonLat } from "../models/geofence";


export const isValidPointInAreaFilter = (point: LonLat, geofences: Geofence[]): boolean => {

    if ( geofences.length === 0 ) {
        return true
    }

    let includes = 0
    let excludes = 0

    geofences.forEach(( g) => {
        const insideRadius = isPointWithinRadius(point, g.center, g.radius)

        // Point is inside excluded area, this should make the point omitted
    if (!g.listening && insideRadius) {
        excludes++
    }
    // Point is inside included area and therefore should be included
    if (g.listening && insideRadius) {
        includes++
    }
    })
    // If no active areas, include if no excludes
    if (geofences.filter((g) => g.listening).length === 0) {
        return excludes === 0
    }
    return includes > 0 && excludes === 0
}


const isPointWithinRadius = (point: LonLat, center: LonLat, radius: number): boolean => {
    const distanceBetween = distance(
        point,
        center,
        { units: "meters"}
      );
      return distanceBetween <= radius;
}