import * as React from "react";
import { Scout } from "../../models/scouts";
import { Gunshot } from "../../models/gunshots";
import { notEmpty } from "../../util/functionUtils";
import commonStyles from "../styles/style.module.scss";

interface Props {
  gunshot: Gunshot;
  scouts: Scout[];
  suffix: string;
}

const Device = ({ scout }: { scout: string }) => {
  return (  
    <div className={commonStyles.item}>
      {scout}
    </div>
  );
};

const DetectedBy = ({ gunshot, scouts, suffix }: Props) => {
  const scoutsForTriangulations = (gunshot.observations || [])
    .map((scout) => scout.sensor.id)
    .map((deviceId) => {
      
      const scout = scouts.find((scout) => scout.deviceId === deviceId)
      if (scout) {
        return scout.status.displayName
        ? scout.status.displayName
        : scout.deviceId.substr(0, 5)
      } else {
        return deviceId.substr(0,5)
      }
    })
    .filter(notEmpty);
  return scoutsForTriangulations.length ? (
    <div key={`detected_by_${gunshot.id}`}>
      <p className={[commonStyles.subHeader, commonStyles.secondarySm].join(" ")}>
        Detected by
      </p>
      <div className={commonStyles.flexStartRow}>
        {scoutsForTriangulations.map((s, i) => (
          <Device key={`device_${s}_${i}`} scout={s} />
        ))}
      </div>
    </div>
  ) : (
    <p />
  );
};

export default DetectedBy;
