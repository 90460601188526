
export enum FeatureState {
    Public = "public",
    Sales = "sales",
    Admin = "admin",
    Off = "off",
}


export enum Feature {
    WeaponType = "weaponType",
    WeaponTypeVerbose = "weaponTypeVerbose",
    WeakClassifications = "weakClassifications",
    Direction = "direction",
    Demo = "demo",
    Geofence = "geofence",
    OperatingMode = "operatingMode",
    AreaFilter = "areaFilter",
}