import {Action, Reducer} from "redux";
import { Sector } from "../../models/direction";
import { Observation } from "../../models/gunshots";
import { LatLon } from "../../models/location";
import {ThunkResult} from "../../reducers";

const PLAYING_SCOUT = "PLAYING_SCOUT";
const NEED_OF_NEW_LOGIN = "NEED_OF_NEW_LOGIN";
const SET_FAST_TRACK_DEVICES = "SET_FAST_TRACK_DEVICES";
const SET_ALPHA_DIRECTION = "SET_ALPHA_DIRECTION";
const SET_WIDE_PANEL = "SET_WIDE_PANEL";


export const setPlayingScoutAction = (playingScout: Observation | undefined): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: PLAYING_SCOUT, playingScout});
    };
};


export const setNeedOfNewLogin = (needOfNewLogin: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: NEED_OF_NEW_LOGIN, needOfNewLogin});
    };
};

export const setFastTrackDevices = (devices: string[]): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_FAST_TRACK_DEVICES, devices});
    };
};

export const setAlphaDirectionAction = (direction: DirectionAndLocation | undefined): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_ALPHA_DIRECTION, direction});
    };
};

export const setWidePanelAction = (widePanel: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_WIDE_PANEL, widePanel});
    };
};

export interface DirectionAndLocation {
    latLon: LatLon,
    direction: Sector[],
    frequencyAdjusted: Boolean,
}


export interface AdminState {
    playingScout: Observation | undefined;
    needOfNewLogin: boolean;
    fastTrackDevices: string[];
    direction: DirectionAndLocation | undefined;
    widePanel: boolean;
}

const initialState: AdminState = {
    needOfNewLogin: false,
    playingScout: undefined,
    fastTrackDevices: [],
    direction: undefined,
    widePanel: false
};

const reducer: Reducer<AdminState> = (
    state: AdminState = initialState,
    action
) => {
    switch ((action as Action).type) {
        case PLAYING_SCOUT: {
            return {
                ...state,
                playingScout: action.playingScout,
            };
        }
        case NEED_OF_NEW_LOGIN: {
            return {
                ...state,
                needOfNewLogin: action.needOfNewLogin,
            }
        }
        case SET_FAST_TRACK_DEVICES: {
            return {
                ...state,
                fastTrackDevices: action.devices
            }
        }
        case SET_ALPHA_DIRECTION: {
            return {
                ...state,
                direction: action.direction
            }
        }
        case SET_WIDE_PANEL: {
            return {
                ...state,
                widePanel: action.widePanel
            }
        }
        default:
            return state;
    }
};

export default reducer;
