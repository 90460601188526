import * as React from "react";
import style from "./style.module.scss";

interface Props {
  id: string;
  icon: string;
  text: string;
  onClick: () => void;
}

export const IconButton = ({ id, text, onClick, icon }: Props) => (
  <div id={id} className={style.iconButton} onClick={onClick}>
    <img
      style={{ height: "16px", marginRight: "0.5rem" }}
      src={icon}
      alt={icon}
    />
    <span className={style.iconButtonText}>{text}</span>
  </div>
);

export default IconButton;
