import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ScoutLocationAndRange, ScoutState } from "../../models/scouts";
import { StatusMessage } from "../../models/warning";
import { addCoveragesAction } from "../scout/scoutReducer";
import { deleteWarningsAction, replaceWarningsAction } from "../status/statusReducer";
import { useEffect, useState } from "react";
import { ApplicationState } from "../../reducers";
import { getRangeFactor } from "../../util/util";
import { CoverageData, generateCoverage, scoutToScoutLocationAndRange } from "../../util/coverage";
import { updateCoverage } from "../../topics/firebase/scouts";

type Props = StateToProps & DispatchFromProps;

interface StateToProps {
  scouts: ScoutLocationAndRange[];
  rangeFactor: number;
  coverageOn: boolean;
}

interface DispatchFromProps {
  setCoverage: (_: CoverageData | undefined) => void;
  replaceWarnings: (component: string, statusMessage: StatusMessage[]) => void;
  deleteWarnings: (component: String) => void;
}

const CoverageComponent = ({
  scouts,
  coverageOn,
  rangeFactor,
  setCoverage,
}: Props) => {
  
  // Used to trigger when state for coverage settings change.
  // We now have the update running in scout listener file
  useEffect(() => {
    updateCoverage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageOn, rangeFactor]);
  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const rangeFactor = getRangeFactor(state.commander.coverageSettings);
  return {
  coverageOn: state.commander.coverage,
  rangeFactor,
  scouts: state.scout.list.filter(s => s.state === ScoutState.Listening || (s.state === ScoutState.Disabled && s.status.detector?.disabled?.metric === "Silent environment")).map(scoutToScoutLocationAndRange),
}};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      replaceWarnings: replaceWarningsAction,
      deleteWarnings: deleteWarningsAction,
      setCoverage: addCoveragesAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(CoverageComponent);
