import React from "react";
import styles from "./style.module.scss";

interface Props {
  children?: JSX.Element[] | JSX.Element;
}
const ButtonGroup = ({ children }: Props) => {
  return <div className={styles.buttonGroup}>{children}</div>;
};

export default ButtonGroup;
