import { LngLatBounds, Map } from "mapbox-gl";
import { release } from "os";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { MapViewType } from "../../../models/MapViewType";
import { Gunshot } from "../../../models/gunshots";
import { GunshotLayerType, TwinDisplayMode } from "../../../models/map";
import { ApplicationState } from "../../../reducers";
import { filterRelevantGunshot } from "../../../util/gunshotUtil";
import { RelativeRange } from "../../range/rangeUtil";
import NewGunshotLayer from "./NewGunshotLayer";

export interface MapboxProps {
  timeOfLatestGunshot: Date;
  relativeLatestTriangulation: number | undefined;
  gunshotLayer: GunshotLayerType;
  twinsDisplayMode: TwinDisplayMode;
  newestGunshot: Gunshot | undefined;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

export interface MapboxState {
  bounds: LngLatBounds | undefined;
  animatedGunshots: Gunshot[];
}

const NewGunshotManager = ({
  map,
  loaded,
  currentMapType,
  twinsDisplayMode,
  newestGunshot,
}: Props) => {
  const [animatedGunshots, setAnimatedGunshots] = useState<Gunshot[]>([]);
  const [latestAnimatedGunshot, setLatestAnimatedGunshot] = useState<
    Gunshot | undefined
  >(newestGunshot);
  const [timeout, addTimeout] = useState<NodeJS.Timer | undefined>(undefined);

  // public shouldComponentUpdate(nextProps: Props, nextState: MapboxState) {
  //   // If no triangulations to blink
  //   return nextProps.newestGunshot !== this.props.newestGunshot;
  // }

  useEffect(() => {
    if (
      newestGunshot &&
      newestGunshot.id !== latestAnimatedGunshot?.id &&
      animatedGunshots.find((s) => newestGunshot?.id === s.id) === undefined
    ) {
      setAnimatedGunshots([...animatedGunshots, newestGunshot]);
      if (timeout) {
        clearTimeout(timeout);
      }
      setLatestAnimatedGunshot(newestGunshot);
      addTimeout(
        setTimeout(() => {
          setAnimatedGunshots([]);
          addTimeout(undefined);
        }, 2000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedGunshots, newestGunshot, timeout]);
  return (
    <div>
      {animatedGunshots.map((g: Gunshot) => (
        <NewGunshotLayer
          key={g.id}
          map={map}
          loaded={loaded}
          currentMapType={currentMapType}
          gunshot={g}
          twinsDisplayMode={twinsDisplayMode}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  var newestGunshots =
    state.commander.initialLoadingDone && state.triangulation.newShots.length
      ? filterRelevantGunshot(state.triangulation.newShots, undefined, new Date().getTime() - 30_000, state.triangulation.relativeRange, state.triangulation.twinToDisplay, state.areaFilter.list)
      : [];
  
  return {
    gunshotLayer: state.commander.gunshotLayerType,
    relativeLatestTriangulation: state.triangulation.relativeEndDate,
    timeOfLatestGunshot: state.triangulation.timeOfLatestGunshot,
    newestGunshot: newestGunshots.length > 0 ? newestGunshots[newestGunshots.length-1] : undefined,
    twinsDisplayMode: state.triangulation.twinToDisplay,
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  NewGunshotManager
);
