import * as React from "react";
import SummaryNotificationOverlay from "../../containers/notification/SummaryNotificationOverlay";
import styles from "../../containers/overview/style.module.scss";
import summaryStyles from "./style.module.scss";
import commonStyles from "../../containers/styles/style.module.scss";
import { Focus, FocusType, TwinDisplayMode } from "../../models/map";
import { Gunshot } from "../../models/gunshots";
import SummaryItem from "./SummaryItem";
import { RelativeRange } from "../range/rangeUtil";
import { useEffect, useState } from "react";
import { filterRelevantGunshot } from "../../util/gunshotUtil";
import { Geofence } from "../../models/geofence";

interface Props {
  inFocus: boolean;
  newShots: Gunshot[];
  setFocus: (_: Focus | undefined) => void;
  markShotsAsSeen: () => void;
  gunshots: { [id: string]: Gunshot };
  rangeStart: number | undefined;
  rangeEnd: number | undefined;
  relativeRange: RelativeRange;
  areaFilter: Geofence[];
  twinsDisplayMode: TwinDisplayMode;
}

const NoShot = ({ shotsLast24Hours }: { shotsLast24Hours: number }) => (
  <p className={[styles.summaryDescription, commonStyles.noMargin].join(" ")}>
    {shotsLast24Hours} the last 24 hours
  </p>
);

const ShotsInSelectedTimeline = ({ gunshots }: { gunshots: number }) => (
  <p className={[commonStyles.noMargin].join(" ")}>
    {gunshots} in selected timeline
  </p>
);

// TODO add correct calculations for last hour and last 24 hours
const Shots = ({
  inFocus,
  setFocus,
  newShots,
  markShotsAsSeen,
  gunshots,
  rangeEnd,
  rangeStart,
  relativeRange,
  areaFilter,
  twinsDisplayMode
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [time, setTime] = useState(Date.now());
  const [relevantGunshots, setRelevantGunshots] = useState<Gunshot[]>([]);
  const [shotsLast24Hours, setShotsLast24Hours] = useState<Gunshot[]>([]);
  const [relevantNewShots, setRelevantNewShots] = useState<Gunshot[]>([]);
  
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    const relativeRangeToUse = relativeRange === RelativeRange.ALL ? RelativeRange.LAST_HOUR : relativeRange;
    setRelevantGunshots(filterRelevantGunshot(gunshots, rangeEnd, rangeStart, relativeRangeToUse, twinsDisplayMode, areaFilter));

    setShotsLast24Hours(filterRelevantGunshot(gunshots, rangeEnd, rangeStart, RelativeRange.TWENTYFOUR_HOURS, twinsDisplayMode, areaFilter));

    setRelevantNewShots(filterRelevantGunshot(newShots, rangeEnd, rangeStart, RelativeRange.TWENTYFOUR_HOURS, twinsDisplayMode, areaFilter));
  }, [gunshots, rangeEnd, rangeStart, relativeRange, twinsDisplayMode, areaFilter, newShots])

  const setFocusOnClick = () => {
    setFocus({ type: FocusType.Shots });
    setTimeout(markShotsAsSeen, 3000);
  };
  
  const shotDescription =
    relevantNewShots.length > 0 ? (
      <SummaryNotificationOverlay />
    ) : rangeEnd || rangeStart ? (
      <ShotsInSelectedTimeline gunshots={relevantGunshots.length} />
    ) : (
      <NoShot shotsLast24Hours={shotsLast24Hours.length} />
    );

    const timeRangeUsed = relativeRange === RelativeRange.ALL ? "last hour" : relativeRange.toLowerCase();

  return (
    <SummaryItem
      focusType={FocusType.Shots}
      inFocus={inFocus}
      setFocus={setFocusOnClick}
    >
      <div
        className={[
          styles.shotSummary,
          styles.summaryText,
          commonStyles.centerText,
        ].join(" ")}
      >
        <p className={summaryStyles.summaryLabel}>Shots {timeRangeUsed}</p>
        <p id={"shots_last_hour"} className={summaryStyles.summaryValue}>
          {relevantGunshots.length}
        </p>
        {shotDescription}
      </div>
    </SummaryItem>
  );
};

export default Shots;


