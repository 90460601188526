import { db, store } from "../../ConfiguredApp";
import {
  addCoveragesAction,
  refreshScoutState,
  removeScout,
  setScout,
} from "../../containers/scout/scoutReducer";
import {
  addWarningsAction,
  deleteStatusByIdAction,
} from "../../containers/status/statusReducer";
import { Organisation } from "../../models/organisation";
import {
  MinimalScout,
  MinimalScoutStatus,
  Scout,
  ScoutState,
} from "../../models/scouts";
import { UserData } from "../../models/user";
import { Status } from "../../models/warning";
import {
  antiTheftOnRule,
  batteryRule,
  clockIsHealthyRule,
  detectionRule,
  listeningShouldHaveAudioRule,
  movingWhenFixedPositionRule,
} from "../../rules/scout";
import {
  generateCoverage,
  scoutStatusToScoutLocationAndRange,
  scoutToScoutLocationAndRange,
} from "../../util/coverage";
import { getMarkerLetter, getStateFor } from "../../util/scoutUtil";
import { debugLog, getRangeFactor } from "../../util/util";

let ref: () => void;

const timeouts: { [s: string]: NodeJS.Timer } = {};

function updateAutoOfflineTimeout(scout: Scout) {
  if (timeouts[scout.deviceId]) {
    clearTimeout(timeouts[scout.deviceId]);
  }
  timeouts[scout.deviceId] = setTimeout(() => {
    store.dispatch(refreshScoutState(scout.deviceId));
    updateAutoOfflineTimeout(scout);
  }, 60000);
}

const createScoutHandler = (
  organisation: Organisation,
  simulation: boolean = false
) => {
  if (organisation.scale) {
    return createScaleScoutHandler();
  } else {
    return createStandardScoutHandler(simulation);
  }
};
const scouts: { [s: string]: MinimalScoutStatus } = {};
const createStandardScoutHandler = (simulation: Boolean) => {
  debugLog("Creating standard scout handler");
  return (snapshot) => {
    createCoverageInterval(3_000);
    snapshot.docChanges().forEach((change: any) => {
      if (change.type === "added" || change.type === "modified") {
        const data = change.doc.data() as MinimalScout;
        if (data.status === undefined) {
          // Ignoring scouts without status
          return;
        }
        // If no config -- create it
        if (data.config === undefined) {
          data.config = { markerLetter: "", hidden: false };
        }
        const state = simulation
          ? ScoutState.Listening
          : getStateFor(data, change.type === "modified");

        const scout: Scout = {
          deviceId: change.doc.id,
          ...data,
          state,
          unhandledPotentialGunshots: 0,
        };
        scout.status.state = state;
        // We need to set the range to 500m for UI, but the backend will use the 30_000m
        if (scout.status.detector?.range === 30000) {
          scout.status.detector!.range = 500;
        }
        scout.config.markerLetter = getMarkerLetter(scout);
        store.dispatch(setScout(scout));

        createWarningsIfNeeded(scout);

        // Putting in map to use for coverage
        scouts[change.doc.id] = scout.status;

        if (!store.getState().featureSwitch.demo) {
          updateAutoOfflineTimeout(scout);
        }
      }

      if (change.type === "removed") {
        store.dispatch(removeScout(change.doc.id));
        store.dispatch(deleteStatusByIdAction(`${change.doc.id}_moving`));
      }
    });
  };
};

const createScaleScoutHandler = () => {
  createCoverageInterval(10_000);

  return (snapshot) => {
    snapshot.docChanges().forEach((change: any) => {
      if (change.type === "added" || change.type === "modified") {
        const data = change.doc.data().status as MinimalScoutStatus;
        data.state = ScoutState.Listening;
        // simulation
        //   ? ScoutState.Listening
        //   : getStateFor(data, change.type === "modified");
        if (store.getState().commander.showScoutsAtScaleMode) {
          const scout: Scout = {
            deviceId: change.doc.id,
            //@ts-ignore
            status: data,
            state: data.state,
            unhandledPotentialGunshots: 0,
          };
          store.dispatch(setScout(scout));
        }

        scouts[change.doc.id] = data;
      }

      if (change.type === "removed") {
        delete scouts[change.doc.id];
      }
    });
  };
};

export const listenForDemoScouts = (user: UserData, planId: string) => {
  if (user && !ref && user.organisation) {
    ref = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("simulations")
      .doc(planId)
      .collection("scouts")
      .onSnapshot(createScoutHandler(user.organisation, true));
  }
};

export const listenForScouts = (user: UserData) => {
  if (user && !ref && user.organisation) {
    ref = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("scouts")
      .onSnapshot(createScoutHandler(user.organisation));
  }
};
function createCoverageInterval(interval: number = 10000) {
  setInterval(() => {
    updateCoverage();
  }, 10000);
}

export function updateCoverage() {
  const start = Date.now();

  const rangeFactor = getRangeFactor(
    store.getState().commander.coverageSettings
  );
  const filtered = Object.values(scouts)
    .filter(
      (s) => s.state === ScoutState.Listening ||
        (s.state === ScoutState.Disabled &&
          s.detector?.disabled?.metric === "Silent environment")
    )
    .map(scoutStatusToScoutLocationAndRange);
  const coverage = generateCoverage(filtered, rangeFactor);
  store.dispatch(addCoveragesAction(coverage));
  debugLog(`Coverage generated in ${Date.now() - start}ms`);
}

function createWarningsIfNeeded(scout: Scout) {
  const verdicts = [
    detectionRule(scout),
    movingWhenFixedPositionRule(scout),
    batteryRule(scout),
    antiTheftOnRule(scout),
    listeningShouldHaveAudioRule(scout),
    clockIsHealthyRule(scout)
  ];

  verdicts
    .filter((verdict) => verdict?.status !== Status.Ok)
    .forEach((s) => {
      if (s) {
        store.dispatch(addWarningsAction(s));
      }
    });

  verdicts
    .filter((verdict) => verdict?.status === Status.Ok)
    .forEach((s) => {
      if (s) {
        store.dispatch(deleteStatusByIdAction(s.id));
      }
    });
}
