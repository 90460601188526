import { LineString } from "geojson";
import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { FocusType } from "../../../models/map";
import { MapViewType } from "../../../models/MapViewType";
import { ApplicationState } from "../../../reducers";
import { orderLayers } from "../layerUtils";
import { hyperbolasLines } from "../mapUtil";

export interface MapboxProps {
  hyperbolas: LineString[];
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

function addHyperbolasLayer(map: Map) {
  map.addLayer({
    id: "hyperbolas",
    minzoom: 12,
    paint: {
      "line-color": ["get", "color"],
      "line-width": 3,
    },
    source: "hyperbolas",
    type: "line",
  });
}

function addHyperbolaSource(hyperbolas: LineString[], map: Map) {
  map.addSource("hyperbolas", {
    data: hyperbolasLines(hyperbolas),
    type: "geojson",
  });
}

function updateHyperbolaSource(hyperbolas: LineString[], map: Map) {
  map
    .getSource("hyperbolas")
    // @ts-ignore
    .setData(hyperbolasLines(hyperbolas));
}

const HyperbolasLayer = ({ loaded, map, hyperbolas }: Props) => {
  if (map && loaded) {
    if (!map.getSource("hyperbolas")) {
      addHyperbolaSource(hyperbolas, map);
      addHyperbolasLayer(map);
      orderLayers(map);
    } else {
      updateHyperbolaSource(hyperbolas, map);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const focused = state.commander.focused;

  if (
    !focused ||
    focused.type !== FocusType.Shots ||
    !focused.id ||
    !state.commander.showHyperbolas
  ) {
    return {
      hyperbolas: [],
    };
  }

  const gunshot = state.triangulation.gunshots[focused.id];
  const hyperbolas = gunshot ? gunshot.hyperbolas : undefined;

  return {
    hyperbolas: hyperbolas ? hyperbolas.hyperbolas : [],
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(HyperbolasLayer);
