import React, { CSSProperties } from "react";
import styles from "./style.module.scss";

interface Props {
    text: string;
    onClick: () => void;
    style?: CSSProperties;
}

const ActionButton = ({text, onClick, style}: Props) => {
    return (
        <div style={style ? style : {}}className={styles.actionButton} onClick={onClick}>
            {text}
        </div>
    )
}

export default ActionButton;