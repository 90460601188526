import { db, store } from "../../ConfiguredApp";
import { RelativeRange } from "../../components/range/rangeUtil";
import { relativeRangeToDate } from "../../components/range/rangeUtil";
import { initialLoadingDone } from "../../containers/commander/commanderReducer";
import { addTriangulationBatch as addGunshotBatch } from "../../containers/triangulations/triangulationReducer";
import { Gunshot } from "../../models/gunshots";
import { UserData } from "../../models/user";
import { debugLog } from "../../util/util";
import {
  onGunshotAdded,
  onGunshotDelete,
  onGunshotModified,
} from "../common/gunshotEventHandlers";

let ref: () => void;

const TWO_WEEKS_AGO = 604800000 * 2;

function createGunshot(doc: any): Gunshot {
  const data = doc.data();
  mapTimestampsAndGeoJson(data);
  const gunshot = data as Gunshot;
  if (gunshot.weapon == null || !gunshot.weapon["caliber"]) {
    gunshot.weapon = undefined;
  }
  return gunshot;
}

function addBatch(gunshots) {
  

  store.dispatch(addGunshotBatch(gunshots));
}

let loadedOldTriangulationsInBatch = false;

let staringTime = Date.now();

export const listenForDemoGunshots = (user: UserData, planId: string) => {
  if (user && !ref && user.organisation) {
    const collection = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("simulations")
      .doc(planId)
      .collection("gunshots");

    collection
      .limit(1)
      .get()
      .then((query) => {
        if (query.size === 0) {
          store.dispatch(initialLoadingDone());
        }
      });
    staringTime = Date.now();
    ref = collection.onSnapshot((snapshot) => {
      const docChanges = snapshot.docChanges();
      store.dispatch(initialLoadingDone());
      docChanges.forEach((change: any) => {
        if (change.type === "added") {
          onGunshotAdded(createGunshot(change.doc));
        }

        if (change.type === "modified") {
          onGunshotModified(createGunshot(change.doc));
        }
      });
      loadedOldTriangulationsInBatch = true;
    });

    /**
     * Subscription used for "removed" events.
     * We need a standalone "remove" subscription because the time
     * range must cover older gunshots as well.
     */
    collection.onSnapshot((snapshot) => {
      const docChanges = snapshot.docChanges();
      docChanges.forEach((change: any) => {
        if (change.type === "removed") {
          onGunshotDelete(createGunshot(change.doc));
        }
      });
    });
  }
};

var lastDocumentUsed: any = undefined;
var oldestFetchedTimestamp: Date | undefined = undefined;

export const listenForGunshots = (user: UserData) => {
  const twoWeeksAgo = new Date(Date.now() - TWO_WEEKS_AGO);
  if (user && !ref && user.organisation) {
    const collection = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("gunshots");
    collection
      .where("timestamp", ">", twoWeeksAgo)
      .limit(1)
      .get()
      .then((query) => {
        if (query.size === 0) {
          store.dispatch(initialLoadingDone());
        }
      });
    staringTime = Date.now();
    ref = collection
      .where("timestamp", ">", new Date())
      .orderBy("timestamp", "desc")
      // .limit(30)
      .onSnapshot((snapshot) => {
        const docChanges = snapshot.docChanges();
        docChanges.forEach((change: any) => {
          if (change.type === "added") {
            onGunshotAdded(createGunshot(change.doc));
          }
          if (change.type === "modified") {
            onGunshotModified(createGunshot(change.doc));
          }

          if (change.type === "removed") {
            onGunshotDelete(createGunshot(change.doc));
          }
        });
        if (!loadedOldTriangulationsInBatch) {
          lastDocumentUsed = snapshot.docs.length
            ? snapshot.docs[snapshot.docs.length - 1]
            : undefined;
          fetchOldTriangulations(user, RelativeRange.TWENTYFOUR_HOURS);
          loadedOldTriangulationsInBatch = true;
        }
      });
  }
};

const mapTimestampsAndGeoJson = (gunshot: any) => {
  gunshot.timestamp = gunshot.timestamp.toDate();
  gunshot.location.geoJson = JSON.parse(gunshot.location.geoJson);
  gunshot.observations.forEach((o) => {
    o.timestamp = o.timestamp.toDate();
    o.location.geoJson = JSON.parse(o.location.geoJson);
  });
  return gunshot;
};

function isMobileDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}

var gunshotsLoaded = 0;
export const fetchOldTriangulations = (user: UserData, from: RelativeRange, endDate:Date | undefined=undefined) => {
  debugLog(isMobileDevice() ? "Mobile" : "Desktop");
  if (user && user.organisation) {
    const startTime =
      relativeRangeToDate(from).start ?? new Date(Date.now() - TWO_WEEKS_AGO);
      const endTime = endDate ?? oldestFetchedTimestamp ?? new Date()
    const triangulationCollection = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("gunshots");
    console.log("[GUNSHOT]",
      "Fetching older gunshots in the background",
      `TIME[${startTime.toISOString()}ms-${endTime.toISOString()}]`
    );
    let query = triangulationCollection
      .where("timestamp", ">", startTime)
      .where("timestamp", "<", endTime)
      .orderBy("timestamp", "desc")
      .limit(500);

    // Saving oldest fetched gunshots so we don't load them twice if time range changes
    oldestFetchedTimestamp =
      oldestFetchedTimestamp && oldestFetchedTimestamp < startTime
        ? oldestFetchedTimestamp
        : startTime;

    if (lastDocumentUsed) {
      query.startAfter(lastDocumentUsed);
    }

    query.get().then((snapshot) => {
      console.log("[GUNSHOT]",
        `Received remaining gunshots (${
          snapshot.docChanges().length
        } gunshots)`,
        `TIME[${Date.now() - staringTime}ms]`
      );
      const mappedDocuments = snapshot
        .docChanges()
        .filter((change) => change.type === "added")
        .map((change: any) => {
          return change.doc;
        });
      if (mappedDocuments.length) {
        const gunshots = mappedDocuments.map((d) => createGunshot(d));
        gunshotsLoaded += gunshots.length;
        addBatch(gunshots);
        lastDocumentUsed = snapshot.docs.length
          ? snapshot.docs[snapshot.docs.length - 1]
          : undefined;
          if (!isMobileDevice() || gunshotsLoaded < 500) {
            fetchOldTriangulations(user, from, gunshots[gunshots.length-1].timestamp);
          }
      } else {
        debugLog("[GUNSHOT]",
        `Finished loading`
      );
        store.dispatch(initialLoadingDone());
      }
      
    });
  }
};
