import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as React from "react";
// @ts-ignore
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import  CommanderLogo from "../../components/logo/CommanderLogo";
import styles from "./style.module.css";

const uiConfig = {
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
    // Need to use popup because of this:
    // https://github.com/firebase/firebase-js-sdk/issues/6716
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            disableSignUp: { status: true },
          },
    ],
};

const LoginPage = () => (
    <>
        <div>
            <div className={styles.container}>
                <CommanderLogo/>
                <div className={styles.loginForm}>
                    <h2 className={styles.title}>Log in</h2>
                    <div className={styles.authContainer}>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default LoginPage;
