import axios from "axios";
import { store } from "../../ConfiguredApp";
import { LatLon } from "../../models/location";
import { Scout, ScoutState } from "../../models/scouts";
import { UserData } from "../../models/user";
import { createHeaders } from "../../services/serviceUtil";

export const startSimulation = (shooter: LatLon, target: LatLon) => {
    const request = {
      location: { lat: shooter.latitude, lon: shooter.longitude },
      target: { lat: target.latitude, lon: target.longitude },
      scouts: store.getState().scout.list.filter(
        (s: Scout) => s.state === ScoutState.Listening
      ).map((s: Scout) => ({lat: s.status.latitude, lon: s.status.longitude, deviceId: s.status.deviceId})),
    };
    axios
      .post(
        `https://api.triangula.no/audio/simulation/gunshot`,
        request,
        createHeaders(store.getState().commander.jwtToken)
      )
      .then((response) => {
        if (response.status === 200) {
          // tslint:disable-next-line: no-empty
        } else {
        }
      });
  };


  export const addScoutsForSimulation = () => {
    axios
      .get(
        `https://api.triangula.no/audio/simulation/gunshot`,
        createHeaders(store.getState().commander.jwtToken)
      )
      .then((response) => {
        if (response.status === 200) {
          // tslint:disable-next-line: no-empty
        } else {
        }
      });
  };

  export const resetSimulation = () => {
    axios
      .delete(
        `https://api.triangula.no/audio/simulation/gunshot`,
        createHeaders(store.getState().commander.jwtToken)
      )
      .then((response) => {
        if (response.status === 200) {
          // tslint:disable-next-line: no-empty
        } else {
        }
      });
  };


  export const applyDemo = (user: UserData | undefined) => {
    return user !== undefined && user.email.endsWith("sales@triangula.com");
  }