import * as React from "react";
import { SmallLogo } from "../svg/SmallLogo";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

const HeaderOnlyLogo = () => {
  return (
    <div className={styles.menu}>
      <div className={styles.logo}>
        <Link id={"back_to_commander_btn"} to={"/"}>
          <SmallLogo fontSizeRem={1.625} />
        </Link>
      </div>
    </div>
  );
};

export default HeaderOnlyLogo;
