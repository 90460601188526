import * as React from "react";
import styles from "./style.module.scss";
import DemoLogoPng from "./triangula-demo.png";

const DemoLogo = () => {
  return (
      <img className={styles.logo} src={DemoLogoPng} alt={"Triangula Commander"} />
  );
};

export default DemoLogo;
