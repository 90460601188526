import * as React from "react";
import {connect} from "react-redux";
import styles from "./style.module.scss";
import { ReactComponent as Logo } from './account_circle.svg';
import { ApplicationState } from "../../reducers";

interface Props {
    name?: string;
}


const UserWidget = ({name}: Props) => {
    return (<div className={styles.userWidget}>
        <Logo />
        <div className={styles.name}>{name}</div>
        </div>);
};

const mapStateToProps = (state: ApplicationState) => {
    const {userData} = state.authentication;
    if (userData && userData.organisation) {
        return {name: userData.organisation.name};
    } else {
        return {name: undefined}
    }
};

export default connect<Props, {}, {}, ApplicationState>(mapStateToProps)(UserWidget);