import React from "react";

import FeatureSwitch from "../../components/featureSwitch/FeatureSwitch";
import commonStyles from "../../containers/styles/style.module.scss";
import { Feature } from "../../models/featureSwitch";
import { Gunshot, Weapon } from "../../models/gunshots";
import { weaponTypeToPrettyText } from "../../util/util";
import styles from "./style.module.scss";

interface Props {
  gunshot: Gunshot;
}

enum ClassificationScore {
  Good = "good match",
  Weak = "weak match",
  Ok = "ok match",
}

const getClassificationScore = (probability: number): ClassificationScore => {
  return probability > 80
    ? ClassificationScore.Good
    : probability > 50
    ? ClassificationScore.Ok
    : ClassificationScore.Weak;
};

const RawClassification = ({
  label,
  score,
  extra,
}: {
  label: string;
  extra?: string[];
  score: ClassificationScore;
}) => {
  return (
    <div className={[commonStyles.item, styles.classification].join(" ")}>
      <p>{label}</p>
      {extra !== undefined && extra.length > 0 && (
        <p style={{ marginLeft: "0.2rem" }}>{`• ${extra.join(" • ")}`}</p>
      )}
      <p
        className={
          score === ClassificationScore.Good
            ? styles.good
            : score === ClassificationScore.Ok
            ? styles.ok
            : styles.weak
        }
      >
        {score}
      </p>
    </div>
  );
};

export const Classification = ({
  label,
  probability,
  extra,
}: {
  label: string;
  probability: number;
  extra?: string[];
}) => {
  const score = getClassificationScore(probability);

  if (score === ClassificationScore.Weak) {
    return (
      <>
        <FeatureSwitch feature={Feature.WeakClassifications}>
          <RawClassification label={label} score={score} extra={extra} />
        </FeatureSwitch>
      </>
    );
  }

  return <RawClassification label={label} score={score} extra={extra} />;
};

const WeaponType = ({ gunshot }: Props) => {
  if (!gunshot.weapon) {
    return <></>;
  }
  const weaponProbability = gunshot.weapon.type.joinedProbability
    ? gunshot.weapon.type.joinedProbability
    : gunshot.weapon.type.probability;
  
  return (
    <div className={styles.weaponTypeContainer}>
      <div className={commonStyles.subHeader}>Weapon details</div>
      <div className={commonStyles.flexStartRow}>
        <Classification
          label={`${gunshot.weapon.caliber.size}${
            gunshot.weapon.caliber.size === 12 ? "ga" : "mm"
          }`}
          probability={
            weaponProbability > gunshot.weapon.caliber.probability
              ? gunshot.weapon.caliber.probability
              : weaponProbability
          }
          extra={getExamples(gunshot.weapon)}
        />
        <Classification
          label={weaponTypeToPrettyText(gunshot.weapon.type.name)}
          probability={weaponProbability}
        />
        <Classification
          label={gunshot.weapon.silencer.attached ? "Silencer" : "No silencer"}
          probability={gunshot.weapon.silencer.probability}
        />
        <FeatureSwitch feature={Feature.WeaponTypeVerbose}>
          <>
            {gunshot.weapon._extra !== undefined && (
              <Classification
                label={`[v] ${weaponTypeToPrettyText(
                  gunshot.weapon._extra.type.name
                )}`}
                probability={gunshot.weapon._extra.type.probability}
              />
            )}
          </>
        </FeatureSwitch>
      </div>
    </div>
  );
};

export const familyProbability = (gunshot: Gunshot): number => {
  let internal = gunshot.internal;
  if (internal) {
    let firingRates = internal.observations.map((t) =>
      t.firing_rate ? t.firing_rate : 0
    );
    let validFiringRates = internal.observations
      .filter((t) => t.firing_rate != null)
      .map((t) => t.firing_rate!);
    let max = Math.max(...firingRates);
    let min = Math.min(...firingRates);
    if (max === 0 && min === 0) {
      return 0;
    } else if (
      validFiringRates.length === firingRates.length &&
      Math.abs(max - min) < 50
    ) {
      return 100;
    } else if (
      validFiringRates.length >= 2 &&
      Math.abs(Math.max(...validFiringRates) - Math.min(...validFiringRates)) <
        50
    ) {
      return 70;
    }
  }
  return 0;
};

export default WeaponType;

function getExamples(weapon: Weapon): string[] {
  if (weapon.type.name === "rifle" && weapon.caliber.size === 5.56) {
    return [".22″"];
  } else if (weapon.type.name === "rifle" && weapon.caliber.size === 7.62) {
    return [".308″"];
  } else if (weapon.type.name === "rifle" && weapon.caliber.size === 8.6) {
    return [".338″"];
  } else if (weapon.type.name === "pistol" && weapon.caliber.size === 9) {
    return [".357″", "0.38″"];
  } else if (weapon.type.name === "rifle" && weapon.caliber.size === 10.6) {
    return [".416″"];
  } else if (weapon.type.name === "pistol" && weapon.caliber.size === 10.9) {
    return [".44″", ".45″"];
  }

  return [];
}
