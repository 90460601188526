import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import RoundButton from "../../components/buttons/RoundButton";
import { ApplicationState } from "../../reducers";
import {toggleCoverageAction} from "../commander/commanderReducer";

const CoverageToggle = ({toggleCoverage, coverageOn}: Props) => {
    return (
        <div
            id={"coverage_toggle"}
        >
            <RoundButton
                id={"coverage_button"}
                cssProperties={{
                    backgroundColor: coverageOn ? "red" : "#26CD7A",
                    color: "white",
                }}
                text={coverageOn ? "Disable coverage" : "Display coverage"}
                onClick={toggleCoverage}
            />
        </div>
    );
};



type Props = DispatchFromProps & StateToProps;

interface StateToProps {
    coverageOn: boolean;
}

interface DispatchFromProps {
    toggleCoverage: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
    coverageOn: state.commander.coverage,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            toggleCoverage: toggleCoverageAction,
        },
        dispatch
    );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
)(CoverageToggle);