import * as React from "react";
import common from "../../containers/styles/style.module.scss";

const LightBold = ({ id, text }: { id?: string; text: string }) => {
  if (id) {
    return (
      <p id={id} className={common.secondarySmBold}>
        {text}
      </p>
    );
  } else {
    return <p className={common.secondarySmBold}>{text}</p>;
  }
};

export default LightBold;
