import * as React from "react";
import {connect} from "react-redux";
import { ApplicationState } from "../../reducers";
import styles from "./style.module.css";

interface Props {
    name?: string;
}

const OrganisationName = ({name}: Props) => {
    const classes = [
        styles.content,
        name ? styles.name : ""
    ].join(" ");
    return <div className={classes}>{name || "Welcome"}</div>;
};

const mapStateToProps = (state: ApplicationState) => {
    const {userData} = state.authentication;
    if (userData && userData.organisation) {
        return {name: userData.organisation.name};
    } else {
        return {name: undefined}
    }
};

export default connect<Props, {}, {}, ApplicationState>(mapStateToProps)(OrganisationName);