import { Action, Reducer } from "redux";
import { StatusMessage } from "../../models/warning";

const ADD_WARNINGS = "ADD_WARNINGS";
const REPLACE_WARNINGS = "REPLACE_WARNINGS";
const DELETE_WARNINGS = "DELETE_WARNINGS";
const DELETE_STATUS_BY_ID = "DELETE_STATUS_BY_ID";
const CLEAR_POPUP_QUEUE = "CLEAR_POPUP_QUEUE";
const ADD_POPUP = "ADD_POPUP";

export interface AddWarnings extends Action {
  type: "ADD_WARNINGS";
  statusMessage: StatusMessage;
}

export const addWarningsAction = (
  statusMessage: StatusMessage
): AddWarnings => {
  return {
    type: ADD_WARNINGS,
    statusMessage,
  };
};

export interface ReplaceWarnings extends Action {
  type: "REPLACE_WARNINGS";
  component: String;
  statusMessages: StatusMessage[];
}

export const replaceWarningsAction = (
  component: String,
  statusMessages: StatusMessage[]
): ReplaceWarnings => {
  return {
    type: REPLACE_WARNINGS,
    component,
    statusMessages,
  };
};

export interface DeleteWarnings extends Action {
  type: "DELETE_WARNINGS";
  component: String;
}

export const deleteWarningsAction = (component: String): DeleteWarnings => {
  return {
    type: DELETE_WARNINGS,
    component,
  };
};

export interface DeleteStatusById extends Action {
  type: "DELETE_STATUS_BY_ID";
  id: String;
}

export const deleteStatusByIdAction = (id: String): DeleteStatusById => {
  return {
    type: DELETE_STATUS_BY_ID,
    id,
  };
};

export interface ClearPopupQueue extends Action {
  type: "CLEAR_POPUP_QUEUE";
}

export const clearPopupQueueAction = (): ClearPopupQueue => {
  return {
    type: CLEAR_POPUP_QUEUE
  };
};

export interface AddPopup extends Action {
  type: "ADD_POPUP";
  statusMessage: StatusMessage;
}

export const addPopupAction = (statusMessage: StatusMessage): AddPopup => {
  return {
    type: ADD_POPUP,
    statusMessage,
  };
};

export interface StatusReducerState {
  list: { [id: string]: StatusMessage };
  popupQueue: StatusMessage[];
}

export const statusInitialState: StatusReducerState = {
  popupQueue: [],
  list: {
    // "test": {id:"test", component: "Internet connection", message: "Can't connect to internet", status: Status.Critical},
    // "test1": {id:"test1", component: "TestC", message: "Something is wrong!", status: Status.Critical},
  },
};

const reducer: Reducer<StatusReducerState> = (
  state: StatusReducerState = statusInitialState,
  action
) => {
  switch ((action as Action).type) {
    case ADD_WARNINGS: {
      return {
        ...state,
        list: {
          ...state.list,
          [action.statusMessage.id]: action.statusMessage,
        },
        popupQueue:
          (!state.list[action.statusMessage.id] || state.list[action.statusMessage.id].status !== action.statusMessage.status) &&
          action.statusMessage.useInPopup &&
          !state.popupQueue.find((s) => s.id === action.statusMessage.id)
            ? [...state.popupQueue, action.statusMessage]
            : state.popupQueue,
      };
    }
    case REPLACE_WARNINGS: {
      const newStatusList = {};
      Object.keys(state.list)
        .filter((e) => state.list[e].component !== action.component)
        .forEach((e) => (newStatusList[e] = state.list[e]));
      action.statusMessages.forEach((e) => (newStatusList[e.id] = e));

      return {
        ...state,
        list: newStatusList,
      };
    }
    case ADD_POPUP: {
      return {
        ...state,
        popupQueue: [...state.popupQueue, action.statusMessage],
      };
    }
    case DELETE_WARNINGS: {
      const newList = {};
      Object.keys(state.list).forEach((x) => {
        if (state.list[x].component !== action.component) {
          newList[x] = state.list[x];
        }
      });
      return {
        ...state,
        list: newList,
        popupQueue: state.popupQueue.filter(
          (s) => s.component !== action.component
        ),
      };
    }

    case DELETE_STATUS_BY_ID: {
      if (!state.list[action.id]) {
        return state;
      }

      const newList = {};
      Object.keys(state.list).forEach((x) => {
        if (state.list[x].id !== action.id) {
          newList[x] = state.list[x];
        }
      });
      return {
        ...state,
        list: newList,
        popupQueue: state.popupQueue.filter((s) => s.id !== action.id),
      };
    }
    case CLEAR_POPUP_QUEUE: {
      return {
        ...state,
        popupQueue: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
