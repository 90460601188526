import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import * as React from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import {
  setNoAccessAction,
  setUserAction,
  setUserDataAction,
} from "./containers/authentication/authenticationReducer";
import { setJwtToken } from "./containers/commander/commanderReducer";
import { addWarningsAction } from "./containers/status/statusReducer";
import {
  initialLocalState,
  subscribeForLocalStateChanges,
} from "./localStorage";
import {
  createDeviceOfflineMessage,
  createOnlineMessage,
} from "./models/warning";
import reducers, { ApplicationState } from "./reducers";
import { loginUsingToken } from "./services/loginService";
import { config } from "./util/firebaseUtil";
// @ts-ignore
import("./global_style/style.css");

export let store: any;

export const setupCommanderStore = () => {
  const composeEnhancer =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    reducers,
    composeEnhancer(
      applyMiddleware(thunk as ThunkMiddleware<ApplicationState, any>)
    )
  );
};

export let db: firebase.firestore.DocumentReference;

function loginUsingQueryParamTokens(firebaseLogin: string, jwtToken: string) {
  firebase.auth().signInWithCustomToken(firebaseLogin);
  store.dispatch(setJwtToken(jwtToken));
}

export const setupFirestoreConnection = () => {
  firebase.initializeApp(config);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  if (token) {
    Promise.allSettled([
      firebase
        .auth()
        .signOut()
        .then(() => store.dispatch(setUserAction(undefined)))
        .then(() => store.dispatch(setUserDataAction(undefined))),
    ]).then(() => {
      loginUsingToken(token)
        .then((firebaseToken) => {
          loginUsingQueryParamTokens(firebaseToken, token);
        })
        .catch((e) => {
          store.dispatch(setNoAccessAction())
        });
    });
  } 

  db = firebase
    .firestore()
    .collection("env")
    .doc("prod")
    .collection("app")
    .doc("commander");
};

export const setupLocalState = () => {
  initialLocalState();
  subscribeForLocalStateChanges();
};
export default ({
  children,
  callback,
}: {
  children?: JSX.Element[] | JSX.Element;
  callback?: (store: any) => void;
}) => {
  useEffect(() => {
    if (window.navigator.onLine) {
      handleOnline();
    } else {
      handleOffline();
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  });

  const handleOnline = () => {
    store.dispatch(addWarningsAction(createOnlineMessage()));
  };
  const handleOffline = () => {
    store.dispatch(addWarningsAction(createDeviceOfflineMessage()));
  };
  if (callback) {
    callback(store);
  }

  return (
    <Provider store={store}>
      <>{children}</>
    </Provider>
  );
};
