import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { MapViewType } from "../../../models/MapViewType";
import { Scout, ScoutState } from "../../../models/scouts";
import { orderLayers } from "../layerUtils";
import { ApplicationState } from "../../../reducers";
import circle from "@turf/circle";
import { getRangeFactor } from "../../../util/util";

export interface MapboxProps {
  mapType: MapViewType;
  scouts: Scout[];
  rangeFactor: number;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

const addScoutLayer = (map: Map) => {
  map.addLayer({
    id: "scout-dynamic-range-line",
    paint: {
      "line-color": "#22c55e",
      "line-opacity": 0.8,
      "line-width": 2,
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    source: "dynamic-range",
    type: "line",
  });
  map.addLayer({
    id: "scout-dynamic-range",
    paint: {
      "fill-color": "#22c55e",
      "fill-opacity": 0.2,
    },
    source: "dynamic-range",
    type: "fill",
  });
};

const addScoutSource = (
  scouts: Scout[],
  rangeFactor: number,
  map: Map
) => {
  map.addSource("dynamic-range", {
    data: createFeatures(scouts, rangeFactor),
    type: "geojson",
  });
};

function updateScoutSource(scouts: Scout[], rangeFactor: number, map: Map) {
  // @ts-ignore
  map.getSource("dynamic-range").setData(createFeatures(scouts, rangeFactor));
}

const ScoutRangeLayer = ({
  scouts,
  map,
  rangeFactor,
  loaded,
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("dynamic-range")) {
      addScoutSource(scouts, rangeFactor, map);
      addScoutLayer(map);
      orderLayers(map);
    } else {
      updateScoutSource(scouts, rangeFactor, map);
    }
  }

  return null;
};

export const createFeatures = (
  scouts: Scout[],
  rangeFactor: number
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesForScouts(scouts, rangeFactor),
    type: "FeatureCollection",
  };
};

const createFeaturesForScouts = (
  scouts: Scout[],
  rangeFactor: number
): any => {
  return scouts.map((scout) => {
    var center = [scout.status.scoutLocation?.longitude ?? 0, scout.status.scoutLocation?.latitude ?? 0];
var radius = (scout.status.detector?.range ?? 0)* rangeFactor;
var options = {steps: 50, units: 'meters', properties: {id: scout.status.deviceId}};
//@ts-ignore
return circle(center, radius, options);
    
  });
};


const mapStateToProps = (state: ApplicationState) => {

  if (!state.commander.showScoutRange) {
    return {
      mapType: state.commander.mapType,
      scouts:[],
      rangeFactor: getRangeFactor(state.commander.coverageSettings)
    };
  }
  
  return {
    mapType: state.commander.mapType,
    scouts: state.scout.list
    .filter(s => s.state !== ScoutState.OfflineIgnore)
    .filter(s => s.state !== ScoutState.Offline),
    rangeFactor: getRangeFactor(state.commander.coverageSettings)
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(ScoutRangeLayer);
