import {Action, Reducer} from "redux";
import {CoverageSettings} from "../../models/coverage";
import { Plan, PlanScout } from "../../models/plan";
import {ThunkResult} from "../../reducers";
import { CoverageData } from "../../util/coverage";

const SET_PLANNING_COVERAGE_SETTINGS = "SET_PLANNING_COVERAGE_SETTINGS";
const ADD_PLANNING_COVERAGE = "ADD_PLANNING_COVERAGE";


export const setCoverageSettingsAction = (
    coverageSettings: CoverageSettings
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_PLANNING_COVERAGE_SETTINGS, coverageSettings});
    };
};

const ADD_PLAN = "ADD_PLAN";

export const addPlanAction = (plan: Plan): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: ADD_PLAN, plan});
    };
};

const REMOVE_PLAN = "REMOVE_PLAN";

export const removePlanAction = (planId: string): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: REMOVE_PLAN, planId} );
    };
};

const SELECT_PLAN = "SELECT_PLAN";

export const selectPlanAction = (planId: string): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SELECT_PLAN, planId} );
    };
};

const SELECT_PLAN_SCOUT = "SELECT_PLAN_SCOUT";

export const selectPlanScoutAction = (scoutId: string | undefined): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SELECT_PLAN_SCOUT, scoutId} );
    };
};


export const addPlanningCoveragesAction = (
    coverages: CoverageData | undefined
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: ADD_PLANNING_COVERAGE, coverages});
    };
};

const RESET_PLANNING_STATE = "RESET_PLANNING_STATE";
export const resetPlanningStateAction = (): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: RESET_PLANNING_STATE} );
    };
};

export interface PlanningState {
    coverageSettings: CoverageSettings;
    coverages: CoverageData | undefined;
    selectedPlan: Plan | undefined;
    selectedScout: string | undefined;
    plans: { [id: string]: Plan };
}

export const initialPlanState: PlanningState = {
    coverageSettings: {landscape: 2, weapon: 3, listeningScouts: false},
    coverages: undefined,
    plans: {},
    selectedPlan: undefined,
    selectedScout: undefined,
};

const reducer: Reducer<PlanningState> = (
    state: PlanningState = initialPlanState,
    action
) => {
    switch ((action as Action).type) {
        case SET_PLANNING_COVERAGE_SETTINGS: {
            return {...state, coverageSettings: action.coverageSettings};
        }
        case ADD_PLAN: {
            return {
                ...state,
                plans: {...state.plans, [action.plan.id]: action.plan},
                selectedPlan: state.selectedPlan && action.plan.id === state.selectedPlan.id ? action.plan : state.selectedPlan,
                selectedScout: state.selectedPlan && state.selectedScout
                && action.plan.id === state.selectedPlan.id
                && action.plan.scouts.find((s: PlanScout) => s.id ===state.selectedScout) === undefined ?  undefined : state.selectedScout,
            };
        }
        case REMOVE_PLAN: {
            const filteredPlans = {...state.plans};
            delete filteredPlans[action.planId];
            return {
                ...state,
                plans: filteredPlans,
                selectedPlan: state.selectedPlan && action.planId === state.selectedPlan.id ? undefined : state.selectedPlan,
            };

        }
        case ADD_PLANNING_COVERAGE: {
            return {...state, coverages: action.coverages};
        }
        case SELECT_PLAN: {
            return {...state, selectedPlan: state.plans[action.planId]}
        }
        case SELECT_PLAN_SCOUT: {
        
            return {...state, selectedScout: action.scoutId === state.selectedScout ? undefined : action.scoutId}
        }
        case RESET_PLANNING_STATE: {
            return {...state, selectedPlan: undefined, selectedScout: undefined};
        }
        default:
            return state;
    }
};

export default reducer;
