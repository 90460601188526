import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styles from "./style.module.scss";
import OverlayButton from "../../components/overlay/OverlayButton";
import Spinner from "../../components/common/Spinner";
import { ApplicationState } from "../../reducers";

const LoadingContent = ({ initialLoadingDone }: Props) => {
  const classes = [styles.loadingOverlay].join(" ");
  if (initialLoadingDone) {
    return <></>;
  } else {
    return (
      <OverlayButton cssProperties={{ right: "20px", top: "80px" }}>
        <div className={styles.loadingContent}>
          <div className={styles.loadingSpinner}>
            <Spinner size={1.5} />
          </div>
          <div className={classes}>Loading gunshots in the background</div>
        </div>
      </OverlayButton>
    );
  }
};

interface StateToProps {
  initialLoadingDone: boolean;
}

interface Props extends StateToProps, DispatchToProps {}

interface DispatchToProps {}

const mapStateToProps = (state: ApplicationState) => ({
  initialLoadingDone: state.commander.initialLoadingDone,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(LoadingContent);
