import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import OverlayButton from "../../components/overlay/OverlayButton";
import { Focus } from "../../models/map";
import { ApplicationState } from "../../reducers";
import { mapInFocus } from "../commander/commanderReducer";
import { createGeofenceAction } from "../geofence/geofenceReducer";
import styles from "./style.module.scss";

const AddGeofenceButton = ({
  createGeofence,
}: Props) => {
  
  const classes = [styles.darkButton, styles.buttonText].join(" ");
  return (
    <OverlayButton>
      <div className={classes} onClick={createGeofence}>
        Add active zone
      </div>
    </OverlayButton>
  );
};

interface StateToProps {
  
}

interface Props extends StateToProps, DispatchToProps {}

interface DispatchToProps {
  createGeofence: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  automaticFocus: state.commander.mapInFocus,
  focused: state.commander.focused,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createGeofence: createGeofenceAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(AddGeofenceButton);
