import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import { db } from "../../ConfiguredApp";
import { Organisation, OrganisationName, Units } from "../../models/organisation";
import { UserData, UserDataWithOrganisationId } from "../../models/user";
import { MessageType } from "./messageType";

function getOrganisationInfo(
  id: string,
  setOrganisationAction: (_: Organisation) => void
) {
  db.collection("organisations")
    .doc(id)
    .onSnapshot(doc => {
      if (doc.exists) {
        const organisation = doc.data() as OrganisationName;
        if (organisation["unit"] === null) {
          organisation["unit"] = Units.Metric
        }
        setOrganisationAction({ ...organisation, id });
        
      }
    });
}

export const listenForUserInfo = (
  user: firebase.User,
  userDataAction: (_: UserData | undefined) => void,
  setOrganisationAction: (_: Organisation) => void,
  initialLoad: () => void
) => {
  db.collection("users")
    .doc(user.uid)
    .onSnapshot(doc => {
      if (doc.exists) {
        const userDataWithOrgId = doc.data() as UserDataWithOrganisationId;
        const userData: UserData = {
          displayName: userDataWithOrgId.displayName,
          email: userDataWithOrgId.email,
          organisationId: userDataWithOrgId.organisation,
        };

        userDataAction(userData);
        if (userDataWithOrgId.organisation) {
          getOrganisationInfo(
            userDataWithOrgId.organisation,
            setOrganisationAction
          );
        }
        initialLoad();
      } else {
        initialLoad();
      }
    });
};

const createRegisterMessage = (userId: string, organisationName: string) => ({
  content: {
    organisationName,
    userId,
  },
  messageType: MessageType.Register,
});

const createOneTimeCodeMessage = (userId: string) => ({
  content: {
    userId,
  },
  messageType: MessageType.GenerateOneTimeCode,
});

function getInboxRef(user: firebase.User) {
  return db
    .collection("users")
    .doc(user.uid)
    .collection("inbox");
}

export const addOrganisationForUser = (
  organisationName: string,
  user: firebase.User
): Promise<firebase.firestore.DocumentReference> => {
  return getInboxRef(user).add(
    createRegisterMessage(user.uid, organisationName)
  );
};

export const generateOneTimeCode = (
  user: firebase.User
): Promise<firebase.firestore.DocumentReference> => {
  return getInboxRef(user).add(createOneTimeCodeMessage(user.uid));
};
