import * as React from "react";
import { connect } from "react-redux";
import GenericDropdownOverlay from "../../components/overlay/GenericDropdownOverlay";
import { IfMobile, IfSm } from "../../components/responsive/responsiveUtil";
import { Focus, FocusType } from "../../models/map";
import { ApplicationState } from "../../reducers";
import ScoutList from "./ScoutList";
import styles from "./style.module.scss";

const DesktopOverlay = () => (
  <IfSm>
    <div className={styles.overview}>
      {/* <ScoutOverview /> */}
      <ScoutList />
    </div>
  </IfSm>
);

const MobileOverlay = () => (
  <IfMobile>
    <div className={styles.overview}>
      <ScoutList />
    </div>
  </IfMobile>
);

const ScoutsView = ({ focused }: Props) => {
  return (
    <GenericDropdownOverlay focused={focused} focusType={FocusType.Scouts}>
      <DesktopOverlay/>
      <MobileOverlay />
    </GenericDropdownOverlay>
  );
};

interface Props {
  focused: Focus | undefined;
}

const mapStateToProps = (state: ApplicationState) => ({
  focused: state.commander.focused,
});

export default connect<Props, {}, {}, ApplicationState>(mapStateToProps)(ScoutsView);
