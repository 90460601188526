import { Action, Reducer } from "redux";

import { RelativeRange } from "../../components/range/rangeUtil";
import { Gunshot } from "../../models/gunshots";
import { Hyperbolas, TwinDisplayMode } from "../../models/map";
import { ThunkResult } from "../../reducers";
import { INITIAL_LOADING_DONE, SET_RANGE } from "../commander/commanderReducer";

const ADD_GUNSHOT = "ADD_GUNSHOT";
const ADD_GUNSHOT_BATCH = "ADD_GUNSHOT_BATCH";
const UPDATE_GUNSHOT = "UPDATE_GUNSHOT";
const SET_HYPERBOLAS = "SET_HYPERBOLAS";
const MARK_NEW_SHOTS_AS_SEEN = "MARK_NEW_SHOTS_AS_SEEN";
const MARK_NEW_SHOT_AS_SEEN = "MARK_NEW_SHOT_AS_SEEN";
const REMOVE_GUNSHOT = "REMOVE_GUNSHOT";
const TWIN_TO_DISPLAY = "TWIN_TO_DISPLAY";
const SET_RELATIVE_RANGE = "SET_RELATIVE_RANGE";

export const addGunshotActionCreator = (gunshot: Gunshot) => {
  return { type: ADD_GUNSHOT, gunshot };
};

export const addTriangulation = (gunshot: Gunshot): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(addGunshotActionCreator(gunshot));
  };
};

export const addGunshotBatchActionCreator = (gunshots: Gunshot[]) => {
  return { type: ADD_GUNSHOT_BATCH, gunshots: gunshots };
};

export const addTriangulationBatch = (
  gunshots: Gunshot[]
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(addGunshotBatchActionCreator(gunshots));
  };
};

export const updateTriangulationActionCreator = (gunshot: Gunshot) => {
  return { type: UPDATE_GUNSHOT, gunshot };
};

export const updateGunshot = (gunshot: Gunshot): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(updateTriangulationActionCreator(gunshot));
  };
};

export const setHyperbolasActionCreator = (
  gunshotId: string,
  hyperbolas: Hyperbolas
) => {
  return { type: SET_HYPERBOLAS, gunshotId: gunshotId, hyperbolas };
};

export const setHyperbolasAction = (
  gunshotId: string,
  hyperbolas: Hyperbolas
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(setHyperbolasActionCreator(gunshotId, hyperbolas));
  };
};

export const removeTriangulationAction = (
  gunshot: Gunshot
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: REMOVE_GUNSHOT, gunshot });
  };
};

export const markNewShotsAsSeen = (): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: MARK_NEW_SHOTS_AS_SEEN });
  };
};

export const twinToDisplayAction = (
  twinToDisplay: TwinDisplayMode
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: TWIN_TO_DISPLAY, twinToDisplay });
  };
};

export const setRelativeRangeAction = (
  relativeRange: RelativeRange
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: SET_RELATIVE_RANGE, relativeRange });
  };
};

export const markNewShotAsSeen = (id: string): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: MARK_NEW_SHOT_AS_SEEN, id });
  };
};

export interface GunshotState {
  initLoadingDone: boolean;
  newShots: Gunshot[];
  relativeEndDate: number | undefined;
  gunshots: { [id: string]: Gunshot };
  timeOfLatestGunshot: Date;
  twinToDisplay: TwinDisplayMode;
  relativeRange: RelativeRange;
}

export const gunshotInitialState: GunshotState = {
  initLoadingDone: false,
  newShots: [],
  relativeEndDate: undefined,
  timeOfLatestGunshot: new Date("1970-01-01"),
  gunshots: {},
  twinToDisplay: TwinDisplayMode.All,
  relativeRange: RelativeRange.TODAY,
};

const getGunshot = (
  id: string,
  gunshots: { [id: string]: Gunshot }
): Gunshot | undefined => {
  return gunshots[id];
};

const removeGunshotFromList = (
  id: string,
  gunshots: { [id: string]: Gunshot }
): { [id: string]: Gunshot } => {
  const newList = { ...gunshots };
  delete newList[id];

  return newList;
};

const reducer: Reducer<GunshotState> = (
  state: GunshotState = gunshotInitialState,
  action
) => {
  switch ((action as Action).type) {
    case ADD_GUNSHOT: {
      const gunshot: Gunshot = action.gunshot;
      return {
        ...state,
        newShots:
          state.initLoadingDone && gunshot
            ? [...state.newShots, gunshot]
            : state.newShots,
        timeOfLatestGunshot:
          state.timeOfLatestGunshot > gunshot.timestamp
            ? state.timeOfLatestGunshot
            : gunshot.timestamp,
        gunshots: {
          ...state.gunshots,
          [gunshot.id]: gunshot,
        },
      };
    }

    case ADD_GUNSHOT_BATCH: {
      const gunshotsInBatch: Gunshot[] = action.gunshots;
      // If we already got the triangulation -- treat new one as a new revision
      let gunshots = {
        ...state.gunshots,
      };

      let newestGunshot;

      gunshotsInBatch.forEach((t) => {
        gunshots[t.id] = t;
        newestGunshot =
          newestGunshot && newestGunshot.timestamp > t.timestamp
            ? newestGunshot
            : t;
      });

      return {
        ...state,
        gunshots: gunshots,
        timeOfLatestGunshot:
          newestGunshot && state.timeOfLatestGunshot < newestGunshot.timestamp
            ? newestGunshot.timestamp
            : state.timeOfLatestGunshot,
      };
    }
    case SET_RELATIVE_RANGE: {
      return {
        ...state,
        relativeRange: action.relativeRange,
      };
    }

    case UPDATE_GUNSHOT: {
      const gunshot: Gunshot = action.gunshot;
      return {
        ...state,
        newShots:
          state.initLoadingDone &&
          gunshot
            ? [...state.newShots.filter((g) => g.id !== gunshot.id), gunshot]
            : state.newShots,
        timeOfLatestGunshot:
          state.timeOfLatestGunshot > gunshot.timestamp
            ? state.timeOfLatestGunshot
            : gunshot.timestamp,
        gunshots: {
          ...state.gunshots,
          [gunshot.id]: gunshot,
        },
      };
    }

    case SET_HYPERBOLAS: {
      const existingRevision = getGunshot(action.gunshotId, state.gunshots);

      if (!existingRevision) {
        return state;
      }

      return {
        ...state,
        gunshots: {
          ...state.gunshots,
          [action.gunshotId]: {
            ...existingRevision,
            hyperbolas: action.hyperbolas,
          },
        },
      };
    }
    case SET_RANGE: {
      if (action.rangeEnd === undefined) {
        return { ...state, relativeEndDate: undefined };
      }
      const sortedGunshots = Object.keys(state.gunshots)
        .map((t) => state.gunshots[t])
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
        .filter((t) => t.timestamp.getTime() < action.rangeEnd);

      if (sortedGunshots.length === 0) {
        return { ...state, relativeEndDate: undefined };
      }

      return {
        ...state,
        relativeEndDate: sortedGunshots[0].timestamp.getTime(),
      };
    }

    case REMOVE_GUNSHOT: {
      const gunshots = removeGunshotFromList(action.gunshot.id, state.gunshots);
      return {
        ...state,
        newShots: state.newShots.filter((s) => s.id !== action.gunshot.id),
        gunshots: gunshots,
      };
    }

    case MARK_NEW_SHOTS_AS_SEEN: {
      return { ...state, newShots: [] };
    }
    case TWIN_TO_DISPLAY: {
      return { ...state, twinToDisplay: action.twinToDisplay };
    }
    case MARK_NEW_SHOT_AS_SEEN: {
      return {
        ...state,
        newShots: state.newShots.filter((t) => t !== action.id),
      };
    }
    case INITIAL_LOADING_DONE: {
      return { ...state, initLoadingDone: true };
    }
    default:
      return state;
  }
};

export default reducer;
