import * as React from "react";
import LightInfo from "../../components/text/LightInfo";
import { Scout } from "../../models/scouts";
import {getChargingDescription, getScoutStateDescription} from "../../util/util";

const ListeningOrMuted = ({ scout }: { scout: Scout }) => {
  return (
    <LightInfo id={"scout_state"} text={`${getScoutStateDescription(scout)} ${scout.status.batteryPercent === 1 ? " • Empty battery" : ""}`} />
  );
};

export const ListeningOrMutedWithChargingState = ({ scout }: { scout: Scout }) => {
  return (
      <LightInfo id={"scout_state"} text={`${getScoutStateDescription(scout)} ᛫ ${getChargingDescription(scout)}`} />
  );
};

export default ListeningOrMuted;
