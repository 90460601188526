import * as React from "react";
import { connect } from "react-redux";
import { ScoutState } from "../../models/scouts";
import { ApplicationState } from "../../reducers";
import ScoutItem from "./ScoutItem";
import styles from "./style.module.scss";

const ScoutItems = ({ scouts }: ScoutItemsProps) => {
  const ScoutElements = scouts.map((s, i) => {
    return <ScoutItem key={"scout_" + s} deviceId={s} />;
  });
  return <>{ScoutElements}</>;
};

interface ScoutItemsProps {
  scouts: string[];
}


const mapStateToProps = (state: ApplicationState) => {
  const offlineScouts = state.scout.list
  .filter(s => s.state === ScoutState.Offline)
  .sort((a, b) => {
    return (a.status.displayName || a.status.deviceId) < (b.status.displayName || b.status.deviceId) ? -1 : 1;
  })
  const onlineScouts = state.scout.list
  .filter(s => s.state !== ScoutState.OfflineIgnore && s.state !== ScoutState.Offline)
  .sort((a, b) => {
    return (a.status.displayName || a.status.deviceId) < (b.status.displayName || b.status.deviceId) ? -1 : 1;
  })
  return {scouts: [...onlineScouts, ...offlineScouts].map(s => s.deviceId)}
};

const ConnectedScoutItems = connect<ScoutItemsProps, {}, {}, ApplicationState>(mapStateToProps)(
  ScoutItems
);

const ScoutList = () => {
  return (
    <div id={"scout_list"} className={[styles.scoutList].join(" ")}>
      <ConnectedScoutItems />
    </div>
  );
};

export default ScoutList;
