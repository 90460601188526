import * as React from "react";
import MediaQuery from "react-responsive";

export const IfMobile = ({ children }: { children: JSX.Element }) => (
  <MediaQuery query="(max-device-width: 599px)">{children}</MediaQuery>
);

export const IfNotMobile = ({ children }: { children: JSX.Element }) => (
  <MediaQuery query="(min-width: 599px)">{children}</MediaQuery>
);

export const IfSm = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => <MediaQuery query="(min-width: 700px)">{children}</MediaQuery>;

export const IfMd = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => <MediaQuery query="(min-width: 960px)">{children}</MediaQuery>;

export const IfSmallerThanMd = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => <MediaQuery query="(max-width: 960px)">{children}</MediaQuery>;
