import { db, store } from "../../ConfiguredApp";
import {
  addOrUpdateGeofenceAction,
  deleteGeofenceAction,
} from "../../containers/geofence/geofenceReducer";
import { addWarningsAction } from "../../containers/status/statusReducer";
import { Geofence } from "../../models/geofence";
import { UserData } from "../../models/user";
import { Status } from "../../models/warning";

let ref: () => void;
var notified = false;
export const listenForGeofenceUpdates = (user: UserData) => {
  if (user && !ref && user.organisation) {
    ref = db
      .collection("organisations")
      .doc(user.organisation.id)
      .collection("geofences")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change: any) => {
          if (change.type === "removed") {
            store.dispatch(deleteGeofenceAction(change.doc.id));
          } else {
            const data = {
              id: change.doc.id,
              center: [
                change.doc.data().shape.center.longitude,
                change.doc.data().shape.center.latitude,
              ],
              radius: change.doc.data().shape.radius,
              listening: change.doc.data().listening,
            } as Geofence;
            store.dispatch(addOrUpdateGeofenceAction(data));
            notifyThatZonesAreDefined();
          }
        });
      });
  }
};

const notifyThatZonesAreDefined = () => {
  if (!notified) {
    notified = true;
    store.dispatch(
      addWarningsAction({
        message: "You got Zones defined. Areas outside the defined zones are inactive.",
        component: "Zone",
        id: "zone",
        status: Status.Ok,
        selfDiagnose: true,
        displayOnCommanderStatusPage: false,
        useInPopup: true,
      })
    );
  }
};
