import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import { map } from "../../components/mapbox/MainMap";
import { IfSm } from "../../components/responsive/responsiveUtil";
import UserWidget from "../../components/user/UserWidget";
import { MapViewType } from "../../models/MapViewType";
import { ApplicationState } from "../../reducers";
import { setUserAction } from "../authentication/authenticationReducer";
import { FeatureSwitchState } from "../feature/featureReducer";
import { MapInitState, setMapInitStateAction } from "../map/mapReducer";
import MenuWithLogo, { Page } from "./MenuWithLogo";
import styles from "./style.module.scss";

const UserItem = ({page}: {page: Page}) => (
  <div className={[styles.noFocus, styles.user].join(" ")}>
    <MenuWithLogo page={page} />
  </div>
);

const MenuBar = ({
  setMapInitState,
  loggedIn,
  logout,
  featureSwitch,
  demoMode,
}: Props) => (
  <div id={"summary"} className={[styles.content].join(" ")}>
    <UserItem page={demoMode ? Page.demo : Page.overview}/>
    <div className={styles.menuItems}>
      <ul>
        <IfSm>
          <>
            {!demoMode && featureSwitch.planning && (
              <li>
                <Link
                  id={"planning_btn"}
                  to={"/planning"}
                  className={styles.link}
                  onClick={() => {
                    setMapInitState({
                      center: map.getCenter(),
                      mapType: MapViewType.HYBRID,
                      zoom: map.getZoom(),
                    });
                  }}
                >
                  Planning mode
                </Link>
              </li>
            )}
            {!demoMode && (
              <li>
                <Link id={"setup_btn"} to={"/setup"} className={styles.link}>
                  Add more scouts
                </Link>
              </li>
            )}
          </>
        </IfSm>
        <UserWidget />
      </ul>
    </div>
  </div>
);

const signOut = (setUser: (_: firebase.User | undefined) => void) =>
  firebase
    .auth()
    .signOut()
    .then(() => setUser(undefined));

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  mapType: MapViewType;
  loggedIn: boolean;
  featureSwitch: FeatureSwitchState;
  demoMode: boolean;
}

interface DispatchToProps {
  setMapInitState: (_: MapInitState | undefined) => void;
  logout: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  mapType: state.commander.mapType,
  loggedIn: state.authentication.userData !== undefined,
  featureSwitch: state.featureSwitch,
  demoMode: state.featureSwitch.demo,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setMapInitState: setMapInitStateAction,
      logout: () => signOut(setUserAction),
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(MenuBar);
