import * as React from "react";

const CheckedIcon = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="Sketching" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Menu" transform="translate(-389.000000, -298.000000)">
                <g id="check" transform="translate(388.000000, 297.000000)">
                    <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="22" height="22"/>
                    <circle id="Background" fill="#5CB47D" cx="11" cy="11" r="10"/>
                    <polyline id="Check" stroke="#FFFFFF" points="5 10.9375 8.34782609 14 16 7"/>
                </g>
            </g>
        </g>
    </svg>
);

export default CheckedIcon;
