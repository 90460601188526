import * as React from "react";
import { Replay } from "../../models/gunshots";
import ProgressBarInformation from "./ProgressBarInformation";
import styles from "./style.module.scss";

const ProgressBar = ({ replay }: { replay: Replay }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ProgressBarInformation />
      <div className={styles.progressContainer}>
        <div className={[styles.progress2, styles.progressMoved].join(" ")}>
          <div
            style={replay.paused ? { animationPlayState: "paused" } : {}}
            className={styles.progressBar2}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
