import * as React from "react";

const SmallLeftArrow = () => (
  <svg width="6px" height="9px" viewBox="0 0 6 9" version="1.1">
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Navigation"
        transform="translate(-484.000000, -102.000000)"
        fill="#6f6f6f"
      >
        <g id="ic_chevron_left" transform="translate(-46.000000, 86.000000)">
          <g id="Icon-24px">
            <polygon
              id="Shape"
              points="535.5575 17.0575 534.5 16 530 20.5 534.5 25 535.5575 23.9425 532.1225 20.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SmallLeftArrow;
