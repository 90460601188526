import * as React from "react";
import commonStyles from "../../containers/styles/style.module.scss";
import styles from "./style.module.scss";

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const FocusedItem = ({ children }: Props) => (
  <div className={[styles.focusedItem].join(" ")}>
    <div className={commonStyles.itemAnimation}>{children}</div>
  </div>
);

export default FocusedItem;
