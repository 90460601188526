import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import WideDropdownOverlay from "../../components/overlay/WideDropdownOverlay";
import { CoverageSettings } from "../../models/coverage";
import { Geofence } from "../../models/geofence";
import { Focus, FocusType } from "../../models/map";
import { ApplicationState } from "../../reducers";
import { deleteAllZones, deleteZone } from "../../services/zoneService";
import { getFocusType } from "../../util/util";
import Slider from "../slider/Slider";
import common from "../styles/style.module.scss";
import CoverageToggleButton from "./CoverageToggleButton";
import styles from "./style.module.scss";

const DisabledText = () => (
  <>
    <p className={common.secondary}>
      Displaying coverage in map is disabled
    </p>
  </>
);

const EnabledText = () => (
    <p className={common.secondary}>Coverage areas are displayed in the map</p>
);

const SliderSection = () => (
  <>
    <hr className={styles.hrStyle} />
    <Slider />
  </>
);

interface CoverageForActiveScoutsOnlyProps {
  setCoverageSettings: (_: CoverageSettings) => void;
  coverageSettings: CoverageSettings;
}

interface CoverageControllerProps {
  coverageOn: boolean;
  geofences: Geofence[];
  jwtToken: string | undefined;
}

const ZoneController = ({geofences, jwtToken}:{geofences: Geofence[], jwtToken: string | undefined}) => {
  const activeGeofences = geofences.filter((g) => g.listening)
  return (
  <>
      <hr className={styles.hrStyle} />
      <h4>Triangula Zones</h4>
      {activeGeofences.length === 0 &&  <p style={{textAlign: "center"}}>All zones are inactive, which means that no scouts are listening for gunshots. To make scouts listen for gunshots, either activate the existing zones or delete them altogether.</p>}
      {activeGeofences.length > 0 && <p style={{textAlign: "center"}}>{activeGeofences.length} zone{activeGeofences.length > 1 && "s"} {activeGeofences.length > 1 ? "are" : "is"} active</p>}
      {jwtToken !== undefined && <div onClick={() => deleteAllZones(jwtToken!, () => {}, () => {})} className={common.option}>DELETE ALL ZONES</div>}
  </>
)}

const CoverageController = ({
  coverageOn,
  geofences,
  jwtToken
}: CoverageControllerProps) => {
  return (
    <div id={"coverage_overlay"} className={styles.coverageController}>
      {coverageOn ? <EnabledText /> : <DisabledText />}
      <CoverageToggleButton />
      {geofences.length > 0 && <ZoneController geofences={geofences} jwtToken={jwtToken} />}
      {coverageOn && <SliderSection />}
    </div>
  );
};

const CoverageOverlay = ({
  focused,
  coverageOn,
  geofences,
  jwtToken
}: Props) => {
  const showList = getFocusType(focused) === FocusType.Coverage;

  return (
    <div className={styles.coverageOverlay}>
      <WideDropdownOverlay open={showList}>
        <CoverageController
          coverageOn={coverageOn}
          geofences={geofences}
          jwtToken={jwtToken}
        />
      </WideDropdownOverlay>
    </div>
  );
};

type Props = DispatchFromProps & StateToProps;

interface StateToProps {
  focused: Focus | undefined;
  coverageOn: boolean;
  geofences: Geofence[];
  jwtToken: string | undefined;
}

interface DispatchFromProps {
}

const mapStateToProps = (state: ApplicationState) => ({
  coverageOn: state.commander.coverage,
  focused: state.commander.focused,
  geofences: state.geofence.list,
  jwtToken: state.commander.jwtToken
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(CoverageOverlay);
