// @ts-ignore
import distance from "@turf/distance";
// @ts-ignore
import midpoint from "@turf/midpoint";
import bearing from "@turf/bearing";
import { LineString } from "geojson";
import { LngLatBounds, Map } from "mapbox-gl";
import * as mapboxgl from "mapbox-gl";
import { LocalLocation } from "../../containers/location/locationReducer";
import { Coverage, Focus, FocusType, TwinDisplayMode } from "../../models/map";
import { MapViewType } from "../../models/MapViewType";
import { PotentialIncident } from "../../models/potentialIncident";
import { Scout, ScoutPosition, ScoutState } from "../../models/scouts";
import { Gunshot, GunshotPositionFeature } from "../../models/gunshots";
import {
  createBoundsWithPadding,
  filterLocationsIfNeeded,
  getRepresentativePoint,
  gunshotHeatMapColor,
} from "../../util/mapUtil";
import { getMarkerLetter } from "../../util/scoutUtil";
import { LngLatLine } from "./layers/TwinLinesLayer";
import { Plan, PlanScout } from "../../models/plan";
import { MapInitState } from "../../containers/map/mapReducer";
import { Units } from "../../models/organisation";


import buffer from '@turf/buffer';
import {point} from '@turf/helpers'
import { Geofence } from "../../models/geofence";
import { isValidPointInAreaFilter } from "../../util/areaFilterUtil";

export function getCirclePolygon(centerLngLat: number[], radius: number): GeoJSON.Feature {
  const center = point(centerLngLat);
  const options = { units: 'kilometers' };
  // @ts-ignore
  const circle = buffer(center, radius, options);
  return circle;
}

export const scoutPoints = (
  scouts: Scout[],
  potentialIncidents: PotentialIncident[] = []
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesForScouts(scouts, potentialIncidents),
    type: "FeatureCollection",
  };
};

export const fixedScoutPoints = (
  scouts: Scout[]
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesForFixedScouts(scouts),
    type: "FeatureCollection",
  };
};

export const planScoutPoints = (
  scouts: PlanScout[],
  plan: Plan | undefined,
  selectedPlanScout?: string
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesForPlanScouts(scouts, plan, selectedPlanScout),
    type: "FeatureCollection",
  };
};

const createCoverageFeatures = (coverages: Coverage[]): any => {
  return coverages.map((coverage) => {
    const color = coverage.scouts === 4 ? "#238800" : "#a5df26";
    return {
      geometry: coverage.geojson,
      properties: {
        area: coverage.area,
        color,
        nrOfScouts: coverage.scouts,
      },
      type: "Feature",
    };
  });
};

export const coveragePoints = (
  coverages: Coverage[]
): GeoJSON.FeatureCollection => {
  return {
    features: createCoverageFeatures(coverages),
    type: "FeatureCollection",
  };
};

export const triangulationPoints = (
  gunshots: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesFor(
      gunshots,
      timeOfLatestGunshot,
      rangeEnd
    ),
    type: "FeatureCollection",
  };
};

export const gunshotShape = (
  gunshots: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined,
  focused: Focus | undefined
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatureShapeFor(
      gunshots,
      timeOfLatestGunshot,
      rangeEnd,
      focused
    ),
    type: "FeatureCollection",
  };
};

export const localLocationsPoints = (
  locations: LocalLocation[]
): GeoJSON.FeatureCollection => {
  return {
    features: createLocalLocationsFeaturesFor(locations),
    type: "FeatureCollection",
  };
};

export const hyperbolasLines = (
  hyperbolas: LineString[]
): GeoJSON.FeatureCollection => {
  return {
    // @ts-ignore
    features: hyperbolas.map((h) => ({
      geometry: h,
      properties: {
        color: "#ffa500", // hyperbolasColor[i],
      },
      type: "Feature",
    })),
    type: "FeatureCollection",
  };
};

export const distancePoints = (
  gunshot: Gunshot | undefined,
  bearingInMils: boolean,
  twinsDisplayMode: TwinDisplayMode,
  unit: Units
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesForDistance(
      gunshot,
      bearingInMils,
      twinsDisplayMode,
      unit
    ),
    type: "FeatureCollection",
  };
};

export const focusedTriangulationLines = (
  gunshot: Gunshot | undefined,
  twinsDisplayMode: TwinDisplayMode
): GeoJSON.FeatureCollection => {
  return {
    features: createFocusedFeaturesFor(gunshot, twinsDisplayMode),
    type: "FeatureCollection",
  };
};

export const focusedScoutLines = (
  scoutPositions: ScoutPosition[]
): GeoJSON.FeatureCollection => {
  return {
    features: createPositionFeaturesFor(scoutPositions),
    type: "FeatureCollection",
  };
};

export const twinLines = (lines: LngLatLine[]): GeoJSON.FeatureCollection => {
  return {
    features: createTwinLineFeaturesFor(lines),
    type: "FeatureCollection",
  };
};

const createTwinLineFeaturesFor = (lines: LngLatLine[]): any => {
  return lines.map((l) => {
    return {
      geometry: {
        coordinates: [
          [l.point1.lng, l.point1.lat],
          [l.point2.lng, l.point2.lat],
        ],
        type: "LineString",
      },
      id: `${l.point1.lng}${l.point1.lat}${l.point2.lng}${l.point2.lat}`,
      properties: {
        id: `${l.point1.lng}${l.point1.lat}${l.point2.lng}${l.point2.lat}`,
        opacity: l.focused ? 0.7 : 0.2
      },
      type: "Feature",
    };
  });
};

const createFocusedFeaturesFor = (
  gunshot: Gunshot | undefined,
  twinsDisplayMode: TwinDisplayMode
): any => {
  if (!gunshot) {
    return [];
  }
  return filterLocationsIfNeeded(gunshot, twinsDisplayMode)
    .map((g) => {
      const gunshotLocation = getRepresentativePoint(g);
      return gunshot.observations!.map((o) => {
        return {
          geometry: {
            coordinates: [
              [gunshotLocation.longitude, gunshotLocation.latitude],
              o.location.geoJson.geometry.coordinates,
            ],
            type: "LineString",
          },
          id: g.id,
          properties: {
            id: g.id,
          },
          type: "Feature",
        };
      });
    })
    .reduce((acc, v) => [...acc, ...v], []);
};

const createPositionFeaturesFor = (scoutPositions: ScoutPosition[]): any => {
  return scoutPositions.map((s) => ({
    geometry: {
      coordinates: [s.position.lng, s.position.lat],
      type: "Point",
    },
    properties: {
      color: gunshotHeatMapColor(s.time, new Date().getTime()),
    },
    type: "Feature",
  }));
};

const createLocalLocationsFeaturesFor = (
  localLocations: LocalLocation[]
): any => {
  return localLocations.map((location) => {
    return {
      geometry: {
        coordinates: [location.lng, location.lat],
        type: "Point",
      },
      properties: {
        id: `${location.lat}${location.lng}`,
      },
      type: "Feature",
    };
  });
};
const createFeatureShapeFor = (
  gunshots: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined,
  focused: Focus | undefined
): any => {
  const feature = gunshots
    .map((gunshot) => {
      return gunshot.location.geoJson.features
      .map((p) => {
        const nrOfCoords = countCoordinates(p);
        const focusedShot =
          focused !== undefined &&
          focused.type === FocusType.Shots &&
          focused.id === gunshot.id;
        const gunshotTimestamp = gunshot.timestamp.getTime();
        const lastTimestamp = rangeEnd
          ? rangeEnd
          : timeOfLatestGunshot.getTime();
        const color = gunshotHeatMapColor(gunshotTimestamp, lastTimestamp);
        const focusedShotExistsButNotThisOne = focused && focused.type === FocusType.Shots && focused.id;
        return {
          geometry: p.geometry,
          id: gunshot.id,
          properties: {
            color,
            fillOpacity: focusedShot
              ? 0.5 : (focusedShotExistsButNotThisOne) ? 0.3
              : nrOfCoords < 15
              ? 0.5
              : nrOfCoords < 20
              ? 0.25
              : nrOfCoords < 25
              ? 0.2
              : 0.1,
            id: gunshot.id,
            lineColor: focusedShot ? "white" : color,
            lineOpacity: focusedShot ? 0.9 : focusedShotExistsButNotThisOne ? 0.2 : 0.45,
            lineWidth: focusedShot ? 3 : 1,
            nrOfCoordinates: nrOfCoords,
            timestamp: gunshot.timestamp.getTime(),
          },
          type: "Feature",
        };
      });
    })
    .reduce((accumulator, value) => accumulator.concat(value), []);
  return feature;
};

const createFeaturesFor = (
  gunshots: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined
): any => {
  return gunshots
    .map((gunshot) => {
      return gunshot.location.geoJson.features
      .map((p) => {
        return {
          geometry: {
            coordinates: [
              p.properties.representativePoint.longitude,
              p.properties.representativePoint.latitude,
            ],
            type: "Point",
          },
          id: gunshot.id,
          properties: {
            color: gunshotHeatMapColor(
              gunshot.timestamp.getTime(),
              rangeEnd ? rangeEnd : timeOfLatestGunshot.getTime()
            ),
            id: gunshot.id,
            timestamp: gunshot.timestamp.getTime(),
          },
          type: "Feature",
        };
      });
    })
    .reduce((accumulator, value) => accumulator.concat(value), []);
};

const createFeaturesForDistance = (
  gunshot: Gunshot | undefined,
  bearingInMils: boolean,
  twinsDisplayMode: TwinDisplayMode,
  unit: Units
): any => {
  if (gunshot) {
    const toReturn: any[][] = gunshot.observations!.map((observation) => {
      return filterLocationsIfNeeded(gunshot, twinsDisplayMode).map((t) => {
        const gunshotLocation = [
          getRepresentativePoint(t).longitude,
          getRepresentativePoint(t).latitude,
        ];
        const observationLocation =
          observation.location.geoJson.geometry.coordinates;
        const calculatedDistance = distance(
          gunshotLocation,
          observation.location.geoJson.geometry.coordinates,
          { units: unit === Units.Imperial ? "yards" : "meters"}
        );

        const distanceWithUnit = unit === Units.Imperial ? `${+calculatedDistance!.toFixed(0)}yd` : `${+calculatedDistance!.toFixed(0)}m`
        const midpointLine = midpoint(gunshotLocation, observationLocation);

        
        const degrees = bearing(observationLocation, gunshotLocation);
        const degreesNormalized = degrees < 0 ? Math.abs(degrees) : 180 + (180-degrees)
        const mils = degreesNormalized * 17.777778;


        return {
          geometry: midpointLine.geometry,
          id: observation.id,
          properties: {
            distance: `${distanceWithUnit} at ${
              bearingInMils ? mils.toFixed(1) + "mil" : degreesNormalized.toFixed(1) + "°"
            }`,
            id: observation.id,
          },
          type: "Feature",
        };
      });
    });

    return toReturn.reduce((prev, curr) => {
      return prev.concat(curr);
    });
  }
  return [];
};

const createFeaturesForScouts = (
  scouts: Scout[],
  potentialIncidents: PotentialIncident[]
): any => {
  return scouts.map((scout) => {
    return {
      geometry: {
        coordinates: [scout.status.longitude, scout.status.latitude],
        type: "Point",
      },
      id: scout.status.deviceId,
      properties: {
        iconAnchor: scout.fixedLocation ? [-6, -5] : [-7.5, -7.5],
        id: scout.deviceId,
        letter: `${getMarkerLetter(scout)}`,
        device_name: scout.status.displayName
          ? scout.status.displayName.substr(0, 10)
          : "",
        moving: scout.status.moving ? "true" : "false",
        listening: scout.state === ScoutState.Listening ? "true" : "false",
        state: `${
          potentialIncidents.find((p) => p.deviceId === scout.deviceId)
            ? scout.state === ScoutState.Disabled ? "potential_incident_disabled" : "potential_incident"
            : scout.state.toString()
        }${
          scout.fixedLocation
            ? "_fixed"
            : scout.status.moving && scout.state === ScoutState.Listening
            ? "_moving"
            : ""
        }`,
      },
      type: "Feature",
    };
  });
};

const createFeaturesForFixedScouts = (scouts: Scout[]): any => {
  return scouts.map((scout) => {
    return {
      geometry: {
        coordinates: [scout.longitude, scout.latitude],
        type: "Point",
      },
      id: scout.status.deviceId,
      properties: {
        id: scout.deviceId,
      },
      type: "Feature",
    };
  });
};

const createFeaturesForPlanScouts = (
  scouts: PlanScout[],
  plan: Plan | undefined,
  selectedPlanScout?: string
): any => {
  return scouts.map((scout) => {
    return {
      geometry: {
        coordinates: [scout.longitude, scout.latitude],
        type: "Point",
      },
      id: scout.id,
      properties: {
        device_name: scout.name
          ? scout.name
          : "",
        claimed:
          scout.claimedBy !== undefined && scout.claimedBy !== null ? 3 : 0,
        color: `${(scout.labelId && plan
          ? plan.labels.find((l) => scout.labelId === l.id)!.color
          : "#3887be"
        ).toUpperCase()}${scout.id === selectedPlanScout ? "_selected" : "_selected"}`,
        id: scout.id,
      },
      type: "Feature",
    };
  });
};

export const getSatelliteMap = () => {
  if (
    process.env.REACT_APP_MAP_HOST &&
    process.env.REACT_APP_MAP_HOST.length > 0
  ) {
    return `${process.env.REACT_APP_MAP_HOST}/styles/satellite/style.json`;
  } else {
    return "mapbox://styles/mapbox/satellite-streets-v11";
  }
};

export const getRoadMap = () => {
  if (
    process.env.REACT_APP_MAP_HOST &&
    process.env.REACT_APP_MAP_HOST.length > 0
  ) {
    return `${process.env.REACT_APP_MAP_HOST}/styles/map/style.json`;
  } else {
    return "https://api.maptiler.com/maps/streets/style.json?key=7NBCsLfJrAt6F93vbbFV";
  }
};

//http://localhost:8088/data/20210126_Rygge_AOI_Mbtiles_IMAGERY.json

export const getMapStyling = (mapType: MapViewType) => {
  if (mapType === MapViewType.SATELLITE) {
    return getSatelliteMap();
  } else {
    return getRoadMap();
  }
};

export const setDefaultMap = (initState?: MapInitState) => {
  if (initState) {
    return {
      lat: initState.center.lat,
      lng: initState.center.lng,
      zoom: initState.zoom,
    };
  }

  // If using custom map, get init boundaries from map server
  if (process.env.REACT_APP_MAP_HOST) {
    return undefined;
  }

  return { lat: 59.916814, lng: 10.752228, zoom: 10 };
};

export const changeBounds = (
  mapInFocus: boolean,
  bounds: LngLatBounds,
  map: Map | undefined,
) => {
  // If user has started dragging map around -- don't update the bounds of map
  if (!mapInFocus || !map) {
    return false;
  }

  // If bounds are empty -- no rerender needed
  if (bounds.isEmpty()) {
    return false;
  }

  // Calculate if new bounds are needed
    const biggerBounds = createBoundsWithPadding(map.getBounds());
    return (
      biggerBounds.getNorth() < bounds.getNorth() ||
      biggerBounds.getEast() < bounds.getEast() ||
      biggerBounds.getWest() > bounds.getWest() ||
      biggerBounds.getSouth() > bounds.getSouth()
    );
};

export const mapOnClick = (
  map: Map,
  layer: string,
  onClick: (id: string | undefined) => void
) => {
  map.on("click", (e) => {
    const features = map.queryRenderedFeatures(
      [
        [e.point.x, e.point.y],
        [e.point.x, e.point.y],
      ],
      { layers: [layer] }
    );
    if (features.length > 0) {
      // @ts-ignore
      onClick(features[0].id ?? features[0].properties.id);
    } else if (layer === "plan_scout") {
      onClick(undefined);
    } else if (layer === "geofence") {
      onClick(undefined);

    }
  });
};

export const countCoordinates = (feature: GunshotPositionFeature) => {
  return feature.geometry
    ? feature.geometry.type === "MultiPolygon"
      ? feature.geometry.coordinates.reduce(
          (acc1, v1) => acc1 + v1.reduce((acc2, v2) => acc2 + v2.length, 0),
          0
        )
      : 1
    : 0;
};


export interface SvgMarker {
  name: string;
  svg: string;
  imageHeight?: number;
  imageWidth?: number;
}

export const addMarkerIcons = (markers: SvgMarker[], map: mapboxgl.Map) => {
  markers.forEach((marker) => {
    const img = new Image(
      marker.imageWidth ? marker.imageWidth : 42,
      marker.imageHeight ? marker.imageHeight : 42
    );
    img.onload = () => {
      if (map && map.getStyle()) {
        if (!map.hasImage(marker.name)) {
          map.addImage(marker.name, img);
        }
      }
    };
    img.src = marker.svg;
  });
};
