import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import OverlayButton from "../../components/overlay/OverlayButton";
import { Focus } from "../../models/map";
import { ApplicationState } from "../../reducers";
import { mapInFocus } from "../commander/commanderReducer";
import styles from "./style.module.scss";

const InFocusButton = ({ automaticFocus, changeMapFocus, focused }: Props) => {
  const changeToAutomaticMapFocus = () => changeMapFocus(true);
  const classes = [styles.darkButton, styles.buttonText].join(" ");
  if (!focused && !automaticFocus) {
    return (
      <OverlayButton>
        <div className={classes} onClick={changeToAutomaticMapFocus}>
          Focus
        </div>
      </OverlayButton>
    );
  } else {
    return <></>;
  }
};

interface StateToProps {
  automaticFocus: boolean;
  focused: Focus | undefined;
}

interface Props extends StateToProps, DispatchToProps {}

interface DispatchToProps {
  changeMapFocus: (_: boolean) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  automaticFocus: state.commander.mapInFocus,
  focused: state.commander.focused,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeMapFocus: mapInFocus,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(InFocusButton);
