// This import loads the firebase namespace along with all its type information.
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import * as React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import LoginPage from "../authentication/LoginPage";
import CriticalPage from "../critical/CriticalPage";
import LazyOverviewPage from "../overview/LazyOverviewPage";
import LazyPlanningPage from "../planning/LazyPlanningPage";
import LazySetupPage from "../setup/LazySetupPage";
import OrganisationPage from "../setup/OrganisationPage";

interface Props {
  criticalIssues: boolean;
  loading: boolean;
  userNotSet: boolean;
  loadingUserData: boolean;
  userDataNotSet: boolean;
  initialLoadingDone: boolean;
  notEnoughScouts: boolean;
}

const RouterComponent = ({
  loadingUserData,
  criticalIssues,
  loading,
  userNotSet,
  userDataNotSet,
  initialLoadingDone,
  notEnoughScouts,
}: Props) => (
  <Router>
    <div>
      <Switch>
        {criticalIssues && <Route path="/" component={CriticalPage} />}
        {loading && <Route path="/" component={Loading} />}
        {userNotSet && <Route path="/" component={LoginPage} />}
        {loadingUserData && <Loading />}
        {userDataNotSet && <Route path="/" component={OrganisationPage} />}
        <Route path="/planning" component={LazyPlanningPage} />
        <Route path="/setup" component={LazySetupPage} />
        {initialLoadingDone && notEnoughScouts && (
          <Route path="/" component={LazySetupPage} />
        )}
        <Route exact={true} path="/" component={LazyOverviewPage} />
      </Switch>
    </div>
  </Router>
);

export default RouterComponent;
