import {LngLat, Map} from "mapbox-gl";
import {EventType, MapCategory, MapEvent} from "../../models/map";

export const rotateNorth: () => MapEvent = (): MapEvent => ({
    mapCategory: MapCategory.Main,
    toExecute: (map: Map) => map.rotateTo(0),
    type: EventType.ROTATE,
});

export const centerMainMap: (_: LngLat) => MapEvent = (latLng: LngLat): MapEvent => ({
    mapCategory: MapCategory.Main,
    toExecute: (map: Map) => map.panTo(latLng),
    type: EventType.CENTER,
});
