import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import { CoverageExplanation } from "../../components/mapbox/layers/CoverageHeatmapLayer";
import {CoverageSettings} from "../../models/coverage";
import { ApplicationState } from "../../reducers";
import {setCoverageSettingsAction} from "../commander/commanderReducer";
import common from "../styles/style.module.scss";
import styles from "./style.module.scss";


const Environment = ({setCoverageSettings, coverageSettings}: AllProps) => (
    <div className={styles.sliderItem}>
        <h4>Landscape</h4>
        <div className={styles.sliderRow}>
            <p className={[styles.sliderDescription, common.noMargin].join(" ")}>City</p>
            <input
                className={styles.slider}
                type="range"
                min="1"
                max="3"
                value={coverageSettings.landscape}
                onChange={(e) => setCoverageSettings({...coverageSettings, landscape: +e.target.value})}
            />
            <p className={[styles.sliderDescription, common.noMargin].join(" ")}>Open</p>
        </div>
    </div>
);

export const Slider = (props: AllProps) => {
    return (
        <div className={styles.container}>
            <Environment {...props}/>
            <CoverageExplanation/>
        </div>
    );
};

interface DispatchFromProps {
    setCoverageSettings: (_: CoverageSettings) => void;
}

interface StateToProps {
    coverageSettings: CoverageSettings;
}

interface AllProps extends StateToProps, DispatchFromProps {
}

const mapStateToProps = (state: ApplicationState) => ({
    coverageSettings: state.commander.coverageSettings,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setCoverageSettings: setCoverageSettingsAction,
}, dispatch);

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(mapStateToProps, mapDispatchToProps)(Slider);
