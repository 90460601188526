import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { MapViewType } from "../../../models/MapViewType";
import { Scout } from "../../../models/scouts";
import { ApplicationState } from "../../../reducers";
import { orderLayers } from "../layerUtils";
import { fixedScoutPoints } from "../mapUtil";

export interface MapboxProps {
  hideScouts: boolean;
  mapType: MapViewType;
  scouts: Scout[];
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

const addScoutLayer = (map: Map) => {
  map.addLayer({
    id: "fixed_scouts",
    // "interactive": false,
    // "layout": {
    //     "icon-image": "scout_target",
    //     "icon-size": 1,
    // },
    paint: {
      "circle-color": "#3887be",
      "circle-opacity": 0.0,
      "circle-radius": 25,
    },
    source: "fixed_scouts",
    type: "circle",
  });
};

const addScoutSource = (scouts: Scout[], map: Map) => {
  map.addSource("fixed_scouts", {
    data: fixedScoutPoints(scouts),
    type: "geojson",
  });
};

function updateScoutSource(scouts: Scout[], map: Map) {
  // @ts-ignore
  map.getSource("fixed_scouts").setData(fixedScoutPoints(scouts));
}

const FixedScoutLayer = ({ scouts, map, loaded, hideScouts }: Props) => {
  if (map && loaded) {
    if (!map.getSource("fixed_scouts")) {
      addScoutSource(scouts, map);
      addScoutLayer(map);
      orderLayers(map);
    } else {
      updateScoutSource(scouts, map);
    }

    if (
      (map.getLayer("fixed_scouts") &&
        map.getLayoutProperty("fixed_scouts", "visibility") === "visible" &&
        hideScouts) ||
      (map.getLayoutProperty("fixed_scouts", "visibility") !== "visible" &&
        !hideScouts)
    ) {
      map.setLayoutProperty(
        "fixed_scouts",
        "visibility",
        hideScouts ? "none" : "visible"
      );
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    hideScouts: state.scout.hideScouts,
    mapType: state.commander.mapType,
    scouts: state.scout.list.filter(
      s =>
        s.fixedLocation !== undefined &&
        s.fixedLocation &&
        s.latitude &&
        s.longitude
    ),
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(FixedScoutLayer);
