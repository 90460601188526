import {Action, AnyAction, Reducer} from "redux";
import {CoverageSettings} from "../../models/coverage";
import {Focus, GunshotLayerType, MgrsMode} from "../../models/map";
import {MapViewType} from "../../models/MapViewType";
import {Replay} from "../../models/gunshots";
import {ThunkResult} from "../../reducers";

const FOCUSED = "FOCUSED";
const SCROLLED = "SCROLLED";
const MAP_IN_FOCUS = "MAP_IN_FOCUS";
const CHANGE_MAP_TYPE = "CHANGE_MAP_TYPE";
const CHANGE_GUNSHOT_LAYER = "CHANGE_GUNSHOT_LAYER";
const RESET_MAP_ANGLE = "RESET_MAP_ANGLE";
const SET_ADMIN = "SET_ADMIN";
const SET_JWT_TOKEN = "SET_JWT_TOKEN";
const TOGGLE_COVERAGE = "TOGGLE_COVERAGE";
const COVERAGE_ON = "COVERAGE_ON";
const SET_COVERAGE_SETTINGS = "SET_COVERAGE_SETTINGS";
const SET_CLEANUP_TIMESTAMP = "SET_CLEANUP_TIMESTAMP";
export const SET_RANGE = "SET_RANGE";
const SHOW_ONLY_FOCUSED_SHOT = "SHOW_ONLY_FOCUSED_SHOT";
const DEGREES_IN_MILS = "DEGREES_IN_MILS";
const SHOW_SCOUT_RANGE = "SHOW_SCOUT_RANGE";
const SHOW_HYPERBOLAS = "SHOW_HYPERBOLAS";
const SHOW_SCOUTS_AT_SCALE_MODE = "SHOW_SCOUTS_AT_SCALE_MODE";
const DRAG_SCOUTS_MODE = "DRAG_SCOUTS_MODE";
const SET_REPLAY = "SET_REPLAY";
const MGRS_ACCURACY = "MGRS_ACCURACY";
const TOGGLE_SHOW_LAST_TEN = "TOGGLE_SHOW_LAST_TEN";

export const INITIAL_LOADING_DONE = "INITIAL_LOADING_DONE";

export interface FocusedAction extends Action {
    type: "FOCUSED";
    focused: Focus | undefined;
    clickedInMap: boolean;
}

export const setFocusAction = (focus: Focus | undefined, clickedInMap: boolean = false): FocusedAction => {
    return {type: FOCUSED, focused: focus, clickedInMap};
};

export const toggleShowLastTenAction = (): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: TOGGLE_SHOW_LAST_TEN});
    };
};

export const setAdmin = (): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_ADMIN});
    };
};

export const initialLoadingDone = (): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: INITIAL_LOADING_DONE});
    };
};

export const setScrolledAction = (scrolled: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SCROLLED, scrolled});
    };
};

export const setJwtToken = (
    jwtToken: string | undefined
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_JWT_TOKEN, jwtToken});
    };
};

export const toggleCoverageAction = (): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: TOGGLE_COVERAGE});
    };
};


export const coverageOnAction = (on: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: COVERAGE_ON, on});
    };
};

export const mapInFocus = (inFocus: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: MAP_IN_FOCUS, mapInFocus: inFocus});
    };
};

export const changeMapTypeCreator = (mapType: MapViewType): AnyAction => {
    return {type: CHANGE_MAP_TYPE, mapType};
};

export const changeMapType = (mapType: MapViewType): ThunkResult<void> => {
    return dispatch => {
        dispatch(changeMapTypeCreator(mapType));
    };
};

export const changeGunshotLayerTypeAction = (gunshotLayerType: GunshotLayerType): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: CHANGE_GUNSHOT_LAYER, gunshotLayerType});
    };
};

export const resetMapAngleAction = (reset: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: RESET_MAP_ANGLE, reset});
    };
};

export const setCoverageSettingsAction = (
    coverageSettings: CoverageSettings
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_COVERAGE_SETTINGS, coverageSettings});
    };
};

export const setCleanupTimestampAction = (
    cleanupTimestamp: number
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_CLEANUP_TIMESTAMP, cleanupTimestamp});
    };
};

export const setRangeAction = (
    rangeStart: number | undefined,
    rangeEnd: number | undefined,
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_RANGE, rangeStart, rangeEnd});
    };
};

export const setShowOnlyFocusedShotAction = (
    showOnlyFocusedShot: boolean
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SHOW_ONLY_FOCUSED_SHOT, showOnlyFocusedShot});
    };
};

export const bearingInMilsAction = (
    bearingInMils: boolean
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: DEGREES_IN_MILS, bearingInMils});
    };
};

export const showHyperbolasAction = (
    showHyperbolas: boolean
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SHOW_HYPERBOLAS, showHyperbolas});
    };
};

export const showScoutsAtScaleModeAction = (
    showScoutsAtScaleMode: boolean
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SHOW_SCOUTS_AT_SCALE_MODE, showScoutsAtScaleMode});
    };
};


export const dragScoutsModeAction = (
    dragScoutsMode: boolean
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: DRAG_SCOUTS_MODE, dragScoutsMode});
    };
};

export const setReplayAction = (
    replay: Replay | undefined
): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_REPLAY, replay});
    };
};

export const setMgrsModeAction = (mgrsMode: MgrsMode): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: MGRS_ACCURACY, mgrsMode});
    };
};

export const setShowScoutRangeAction = (showScoutRange: boolean): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SHOW_SCOUT_RANGE, showScoutRange});
    };
};

export interface CommanderState {
    focused: Focus | undefined;
    initialLoadingDone: boolean;
    jwtToken: string | undefined;
    mapInFocus: boolean;
    scrolled: boolean;
    mapType: MapViewType;
    resetMapAngle: boolean;
    coverage: boolean;
    showScoutsAtScaleMode: boolean;
    coverageSettings: CoverageSettings;
    cleanupTimestamp: number;
    rangeStart: number | undefined;
    rangeEnd: number | undefined;
    showOnlyFocusedShot: boolean;
    bearingInMils: boolean;
    showHyperbolas: boolean;
    dragScoutsMode: boolean;
    clickedInMap: boolean;
    gunshotLayerType: GunshotLayerType;
    replay: Replay | undefined;
    mgrsMode: MgrsMode;
    showLastTen: boolean;
    showScoutRange: boolean;
    admin: boolean;
}

export const commanderInitialState: CommanderState = {
    bearingInMils: false,
    cleanupTimestamp: 0,
    clickedInMap: false,
    coverage: false,
    showScoutsAtScaleMode: false,
    coverageSettings: {landscape: 2, weapon: 3, listeningScouts: false},
    dragScoutsMode: false,
    focused: undefined,
    gunshotLayerType: GunshotLayerType.Circle,
    initialLoadingDone: false,
    jwtToken: undefined,
    mapInFocus: true,
    mapType: MapViewType.SATELLITE,
    mgrsMode: MgrsMode.one_meter_accuracy,
    rangeEnd: undefined,
    rangeStart: undefined,
    replay: undefined,
    resetMapAngle: true,
    scrolled: false,
    showHyperbolas: false,
    showLastTen: false,
    showOnlyFocusedShot: false,
    showScoutRange: false,
    admin: false,
};

// This occurs when summary buttons are clicked
function ifDifferentTypeOrIdIsSet(prevFocus: Focus, newFocus: Focus): boolean {
    return prevFocus.type !== newFocus.type || newFocus.id !== undefined;
}

const reducer: Reducer<CommanderState> = (
    state: CommanderState = commanderInitialState,
    action
) => {
    switch ((action as Action).type) {
        case FOCUSED: {
            if (
                state.focused &&
                state.focused.id &&
                action.focused &&
                action.focused.id &&
                state.focused.id === action.focused.id &&
                state.focused.type === action.focused.type
            ) {
                return {
                    ...state,
                    clickedInMap: false,
                    focused: {type: state.focused.type},
                    scrolled: false,
                };
            } else if (
                action.focused &&
                (!state.focused ||
                    ifDifferentTypeOrIdIsSet(state.focused, action.focused))
            ) {
                return {...state, focused: action.focused, scrolled: false, clickedInMap: (action.clickedInMap !== undefined && action.clickedInMap)};
            } else {
                return {...state, focused: undefined, scrolled: false, clickedInMap: false};
            }
        }
        case INITIAL_LOADING_DONE: {
            return {...state, initialLoadingDone: true};
        }
        case TOGGLE_SHOW_LAST_TEN: {
            return {...state, showLastTen: state.showLastTen};
        }
        case MAP_IN_FOCUS: {
            return {...state, mapInFocus: action.mapInFocus};
        }
        case SHOW_SCOUTS_AT_SCALE_MODE: {
            return {...state, showScoutsAtScaleMode: action.showScoutsAtScaleMode}
        }
        case SET_RANGE: {
            return {...state, rangeStart: action.rangeStart, rangeEnd: action.rangeEnd};
        }
        case SET_CLEANUP_TIMESTAMP: {
            return {...state, cleanupTimestamp: action.cleanupTimestamp};
        }
        case SCROLLED: {
            return {...state, scrolled: action.scrolled};
        }
        case CHANGE_MAP_TYPE: {
            return {...state, mapType: action.mapType};
        }
        case CHANGE_GUNSHOT_LAYER: {
            return {...state, gunshotLayerType: action.gunshotLayerType};
        }
        case RESET_MAP_ANGLE: {
            return {...state, resetMapAngle: action.reset};
        }
        case SET_ADMIN: {
            return {...state, admin: true};
        }
        case SET_JWT_TOKEN: {
            return {...state, jwtToken: action.jwtToken};
        }
        case TOGGLE_COVERAGE: {
            return {...state, coverage: !state.coverage};
        }
        case SET_COVERAGE_SETTINGS: {
            return {...state, coverageSettings: action.coverageSettings};
        }
        case COVERAGE_ON: {
            return {...state, coverage: action.on};
        }
        case SHOW_ONLY_FOCUSED_SHOT: {
            return {...state, showOnlyFocusedShot: action.showOnlyFocusedShot};
        }
        case DEGREES_IN_MILS: {
            return {...state, bearingInMils: action.bearingInMils};
        }
        case SHOW_HYPERBOLAS: {
            return {...state, showHyperbolas: action.showHyperbolas};
        }
        case DRAG_SCOUTS_MODE: {
            return {...state, dragScoutsMode: action.dragScoutsMode};
        }
        case SET_REPLAY: {
            return {...state, replay: action.replay};
        }
        case MGRS_ACCURACY: {
            return {...state, mgrsMode: action.mgrsMode};
        }
        case SHOW_SCOUT_RANGE: {
            return {...state, showScoutRange: action.showScoutRange};
        }
        default:
            return state;
    }
};

export default reducer;
