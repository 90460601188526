import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import OkIcon from "../../components/icons/OkIcon";
import WarningIcon from "../../components/icons/WarningIcon";
import { Status, StatusMessage } from "../../models/warning";
import { ApplicationState } from "../../reducers";
import { clearPopupQueueAction } from "./statusReducer";
import styles from "./style.module.scss";

const WarningMsg = ({ status }: { status: StatusMessage }) => {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 3500);
  }, []);
  return (
    <div
      className={[
        styles.warningMsgWrapper,
        hide ? styles.hide : styles.show,
      ].join(" ")}
    >
      <div className={[styles.warningMsg].join(" ")}>
        {status.status === Status.Ok ? (
          <OkIcon width={30} height={30} backgroundColor={"#029959"} />
        ) : (
          <WarningIcon width={30} height={30} backgroundColor={"#f59e0b"} />
        )}{" "}
        <p>{status.message}</p>
      </div>
    </div>
  );
};

const NotificationContainer = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  return <div className={styles.fadeInOverlay}>{children}</div>;
};

const NotificationOverlay = ({ popupQueue, clearPopupQueue }: Props) => {
  const [fadeTimeout, setFadeTimeout] = useState<NodeJS.Timer | undefined>();

  useEffect(() => {
    if (fadeTimeout) {
      clearTimeout(fadeTimeout);
    }
    if (popupQueue.length) {
      setFadeTimeout(
        setTimeout(() => {
          clearPopupQueue();
          setFadeTimeout(undefined);
        }, 3000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearPopupQueue, popupQueue]);
  return (
    <NotificationContainer>
      {[...popupQueue].map((s) => (
        <WarningMsg key={s.id} status={s} />
      ))}
    </NotificationContainer>
  );
};

type Props = DispatchFromProps & StateToProps;

interface StateToProps {
  popupQueue: StatusMessage[];
}

interface DispatchFromProps {
  clearPopupQueue: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  popupQueue: state.status.popupQueue,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearPopupQueue: clearPopupQueueAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationOverlay);
