import axios from "axios";
import { LatLon } from "../../models/location";
import { Plan } from "../../models/plan";
import { Scout } from "../../models/scouts";
import { UserData } from "../../models/user";
import { createHeaders } from "../../services/serviceUtil";

export interface Simulation {
  name: string;
  scouts: SimulationScout[];
}

export interface GunshotSimulation {
  location: LatLon;
  target: LatLon;
  scouts: SimulationScout[];
}

export interface SimulationScout {
  "id": string;
  "latitude": number;
  "longitude": number;
  "name": string;
}

export const simulateGunshot = (planId: string, shooter: LatLon, target: LatLon, scouts: Scout[], jwtToken: string) => {
    const request = {
      location: shooter,
      target: target,
      scouts: scouts.map((s) => {
        return {
          "id": s.deviceId,
          "latitude": s.status.latitude,
          "longitude": s.status.longitude,
          "name": s.status.displayName || "Unknown",
        } as SimulationScout
      }),
    };
    axios
      .post(
        `https://api.triangula.no/simulations/${planId}/shoot`,
        request,
        createHeaders(jwtToken)
      )
      .then((response) => {
        if (response.status === 200) {
          // tslint:disable-next-line: no-empty
        } else {
        }
      });
  };


  export const createSimulation = (plan: Plan, jwtToken: string): Promise<string> => {
    return axios
      .post(
        `https://api.triangula.no/simulations/${plan.id}`,
        {
          name: plan.name!,
          scouts: plan.scouts.map((s) => {
            return {
            "id": s.id,
            "latitude": s.latitude,
            "longitude": s.longitude,
            "name": s.name!,
          } as SimulationScout})
        } as Simulation,
        createHeaders(jwtToken)
      )
      .then((response) => {
        if (response.status === 200) {
          return "Success"
          // tslint:disable-next-line: no-empty
        } else {
          return "Fail"
        }
      });
  };

  export const applyDemo = (user: UserData | undefined) => {
    return user !== undefined && user.email.endsWith("sales@triangula.com");
  }