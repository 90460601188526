import * as React from "react"
const OverviewPage = React.lazy(() => import("./OverviewPage"))

const LazyOverviewPage = () => {
    return (
        <React.Suspense fallback={<></>}>
            <OverviewPage />
          </React.Suspense>
    )
}

export default LazyOverviewPage