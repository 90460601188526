import * as React from "react";

const SmallRightArrow = () => (
  <svg width="7px" height="9px" viewBox="0 0 7 9" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Navigation"
        transform="translate(-492.000000, -102.000000)"
        fill="#6f6f6f"
      >
        <g id="ic_chevron_right" transform="translate(144.000000, 96.000000)">
          <g id="Icon-24px">
            <polygon
              id="Shape"
              points="349.6475 6 348.59 7.0575 352.025 10.5 348.59 13.9425 349.6475 15 354.1475 10.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SmallRightArrow;
