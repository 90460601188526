import {Map} from "mapbox-gl";
import {connect} from "react-redux";
import {FocusType, TwinDisplayMode} from "../../../models/map";
import {MapViewType} from "../../../models/MapViewType";
import {Gunshot} from "../../../models/gunshots";
import {orderLayers} from "../layerUtils";
import {focusedTriangulationLines} from "../mapUtil";
import { ApplicationState } from "../../../reducers";

export interface MapboxProps {
    gunshot: Gunshot | undefined;
    hideScouts: boolean;
    twinsDisplayMode: TwinDisplayMode;
}

export interface OwnProps {
    map: Map;
    loaded: boolean;
    currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {
}

function addTriangulationFocusLinesLayer(map: Map) {
    map.addLayer({
        id: "triangulation_lines",
        minzoom: 12,
        paint: {
            "line-color": "#adadad",
            "line-width": 3,
        },
        source: "triangulation_lines",
        type: "line",
    });
}

function addTriangulationLinesSource(
    gunshot: Gunshot | undefined,
    map: Map,
    twinsDisplayMode: TwinDisplayMode
) {
    map.addSource("triangulation_lines", {
        data: focusedTriangulationLines(gunshot, twinsDisplayMode),
        type: "geojson",
    });
}

function updateTriangulationLinesSource(
    gunshot: Gunshot | undefined,
    map: Map,
    twinsDisplayMode: TwinDisplayMode
) {
    map
        .getSource("triangulation_lines")
        // @ts-ignore
        .setData(focusedTriangulationLines(gunshot, twinsDisplayMode));
}

const TriangulationLinesLayer = ({loaded, map, gunshot, hideScouts, twinsDisplayMode}: Props) => {
    if (map && loaded) {
        if (!map.getSource("triangulation_lines")) {
            addTriangulationLinesSource(gunshot, map, twinsDisplayMode);
            addTriangulationFocusLinesLayer(map);
            orderLayers(map);
        } else {
            updateTriangulationLinesSource(gunshot, map, twinsDisplayMode);
        }

        if ((map.getLayer("triangulation_lines") && map.getLayer("triangulation_lines") && map.getLayoutProperty("triangulation_lines", 'visibility') === "visible" && hideScouts) ||
            (map.getLayer("triangulation_lines") && map.getLayoutProperty("triangulation_lines", 'visibility') !== "visible" && !hideScouts)) {
            map.setLayoutProperty("triangulation_lines", 'visibility', hideScouts ? "none" : "visible");
        }
    }

    return null;
};

const mapStateToProps = (state: ApplicationState) => {
    const focused = state.commander.focused;

    if (!focused || focused.type !== FocusType.Shots || !focused.id) {
        return {
            hideScouts: state.scout.hideScouts,
            gunshot: undefined,
            twinsDisplayMode: state.triangulation.twinToDisplay,
        };
    }

    const focusedGunshot = state.triangulation.gunshots[focused.id];

    if (state.commander.clickedInMap) {
        return{
            hideScouts: state.scout.hideScouts,
            gunshot: focusedGunshot,
            twinsDisplayMode: state.triangulation.twinToDisplay,
        };
    } else {

        return {
            hideScouts: state.scout.hideScouts,
            gunshot: focusedGunshot,
            twinsDisplayMode: state.triangulation.twinToDisplay,
        };
    }
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
    TriangulationLinesLayer
);
