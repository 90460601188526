import * as React from "react";
import commonStyles from "../../containers/styles/style.module.scss";
import styles from "./style.module.scss";

interface Props {
  children: JSX.Element;
  open: boolean;
}

interface State {
  show: boolean;
}

class WideDropdownOverlay extends React.Component<Props, State> {
  public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.open) {
      return { show: true };
    } else {
      return prevState;
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = { show: false };
    this.transitionEnd = this.transitionEnd.bind(this);
  }

  public transitionEnd() {
    if (!this.props.open) {
      this.setState(() => ({
        show: false,
      }));
    }
  }

  public render() {
    const { children, open } = this.props;

    const className = [
      styles.warningBox,
      open ? styles.open : styles.closed,
      open ? commonStyles.openAnimation : commonStyles.closeAnimation,
    ];

    if (this.state.show) {
      const elem = (
        <div
          className={className.join(" ")}
          onAnimationEnd={this.transitionEnd}
        >
          <div className={commonStyles.itemAnimation}>{children}</div>
        </div>
      );
      return elem;
    } else {
      return <div />;
    }
  }
}

export default WideDropdownOverlay;
