// @ts-ignore
import axios, { AxiosPromise } from "axios";
import { Sector } from "../models/direction";
import { Gunshot } from "../models/gunshots";
import { LatLon } from "../models/location";
import { Hyperbolas } from "../models/map";
import { dateToTut } from "../util/util";
import { createHeaders } from "./serviceUtil";


interface ThresholdRequest {
  threshold: number;
}

interface ModelRequest {
  model: string;
}

interface DetectorRequest {
  id: string;
}

export const setThresholdForScout = (
  deviceId: string,
  threshold: string,
  jwtToken: string
) => {
  const request: ThresholdRequest = { threshold: +threshold };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/threshold`,
      request,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setPredictionThresholdForScout = (
  deviceId: string,
  threshold: string,
  jwtToken: string
) => {
  const request: ThresholdRequest = { threshold: +threshold };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/predictionThreshold`,
      request,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const uploadAudioEvents = (
  deviceId: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/upload`,
      {},
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const stopUploadAudioEvents = (
  deviceId: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/stopUpload`,
      {},
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setGainForScout = (
  deviceId: string,
  gain: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/gain`,
      { gain: +gain },
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setTimeServiceForScout = (
  deviceId: string,
  timeServiceName: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/timeService`,
      { timeServiceName: timeServiceName },
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};


interface HyperbolaObservation {
  lat: number;
  lng: number;
  tut: number;
  temperature: number;
}

interface HyperbolaRequest {
  "audio-events": HyperbolaObservation[];
}

export const getHyperbola = (
  gunshot: Gunshot,
  jwtToken: string
): Promise<Hyperbolas> => {
  const request: HyperbolaRequest = {
    "audio-events": gunshot.observations.map((o) => ({
      lat: o.location.geoJson.geometry.coordinates[1],
      lng: o.location.geoJson.geometry.coordinates[0],
      temperature: gunshot.weather.temperature,
      tut: dateToTut(o.timestamp),
    })),
  };
  return axios
    .post(
      `https://api.triangula.no/backoffice/gunshot/hyperbola`,
      request,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data as Hyperbolas;
      } else {
        // tslint:disable-next-line:no-console
        return { hyperbolas: [] };
      }
    });
};

export interface WeaponAndCaliber {
  weapon: string;
  silencer: boolean;
  caliber: number;
}

export const getWeaponAndCaliber = (
  body: any,
  jwtToken: string,
): Promise<any | undefined> => {
  return axios
    .post(
      `https://api.triangula.no/classifiers/weapon`,
      body,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return undefined;
      }
    });
};

export const requestAlphaGunshotDetection = (
  body: any,
  jwtToken: string,
): Promise<any | undefined> => {
  if (body.device?.manufacturer === "triangula") {
    body["device"]["manufacturer"] = "motorola"
    body["device"]["model"] = "moto g(60)"
  }

  return axios
    .post(
      `https://api.triangula.no/classifiers/gunshot`,
      body,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw Error("Didn't get a gunshot detection");
      }
    });
};

export const requestPeakStatistics = (
  body: any,
  jwtToken: string,
): Promise<any | undefined> => {
  if (body.device?.manufacturer === "triangula") {
    body["device"]["manufacturer"] = "motorola"
    body["device"]["model"] = "moto g(60)"
  }

  return axios
    .post(
      `https://api.triangula.no/backoffice/audio/peaks`,
      body,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw Error("Didn't get a gunshot detection");
      }
    });
};

export interface DirectionObservation {
  start: string;
  end: string;
  prediction: number;
  incident: string;
}
export interface DirectionDetails {
  result: number[];
  grouped: Sector[];
  observations: DirectionObservation[];
  frequencyAdjusted: Boolean;
}

export const getDirectionPrediction = (
  body: any,
  jwtToken: string,
): Promise<DirectionDetails> => {
  return axios
    .post(
      `https://api.triangula.no/classifiers/direction`,
      body,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        let details = response.data as DirectionDetails;
        return details;
        // tslint:disable-next-line: no-empty
      } else {
        return undefined!
      }
    });
};

export const setModelForScout = (
  deviceId: string,
  model: string,
  jwtToken: string
) => {
  const request: ModelRequest = { model };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/model`,
      request,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const resetProperty = (
  deviceId: string,
  key: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/${key}/reset`,
      {},
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setDetectorForScout = (
  deviceId: string,
  detectorId: string,
  jwtToken: string
) => {
  const request: DetectorRequest = { id: detectorId };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/detector`,
      request,
      createHeaders(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

interface MarkAsGunshotRequest {
  deviceId: string;
  incidentId: string;
}

export const markAsNotGunshot = (
  deviceId: string,
  incidentId: string,
  jwtToken: string
): AxiosPromise => {
  const request: MarkAsGunshotRequest = { deviceId, incidentId };
  return axios.post(
    `https://api.triangula.no/audio/not-gunshot`,
    request,
    createHeaders(jwtToken)
  );
};

interface DeleteTriangulationRequest {
  gunshotId: string;
}

export const deleteGunshot = (
  gunshot: Gunshot,
  jwtToken: string
): AxiosPromise => {
  const request: DeleteTriangulationRequest = { gunshotId: gunshot.id };
  return axios.post(
    `https://api.triangula.no/backoffice/gunshots/delete`,
    request,
    createHeaders(jwtToken)
  );
};


export const getSpectrogram = (
  deviceId: string,
  incidentId: string,
  jwtToken: string,
  callback: (image: string) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .post(
      `https://api.triangula.no/audio/spectrogram?deviceId=${deviceId}&incidentId=${incidentId}`,
      {deviceId, incidentId},
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      if (response.status === 200) {
        callback(arrayBufferToBase64(response.data));
        // tslint:disable-next-line: no-empty
      } else {
        if (onError) {
          onError("Failed to load spectrogram");
        }
      }
    })
    .catch((e) => {
      if (onError) {
        console.error(e);
        onError("Failed to load spectrogram");
      }
    });
};

export interface TimingResult {
  position: number;
  score: number;
}
export interface TimingAlternatives {
  aModel: TimingResult;
  bModel: TimingResult;
}

export const getTimingAlternatives = (
  deviceId: string,
  incidentId: string,
  jwtToken: string,
  callback: (image: TimingAlternatives) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .post(
      `https://api.triangula.no/classifiers/timing/abtest`,
      {
        "audio-file": {
            "bucket-name": "triangula-scout-audio",
            "filename": `inbox/scout/${deviceId}/${incidentId}.wav`
        }},
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data as TimingAlternatives);
        // tslint:disable-next-line: no-empty
      } else {
        if (onError) {
          onError("Failed to load alternative timings");
        }
      }
    })
    .catch((e) => {
      if (onError) {
        console.error(e);
        onError("Failed to load spectrogram");
      }
    });
};

export const getFastTrackDevices = (
  jwtToken: string,
  callback: (scouts: string[]) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .get(`https://api.triangula.no/backoffice/fast-track/scouts`, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    })
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.scouts);
      } else {
        if (onError) {
          onError("Failed to fetch overview fast tack");
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError("Failed to fetch overview fast tack");
      }
    });
};

function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export interface Timezone {
  timezone: string;
  localTime?: string; // In ISO
  offset?: string;
  offsetSeconds?: number;
}

export const getTimezoneFor = (
  jwtToken: string,
  location: LatLon,
  tut: number
): Promise<Timezone> => {
  return axios
    .get(`https://api.triangula.no/timezone`, {
      headers: { Authorization: `Bearer ${jwtToken}` },
      params: { lat: location.latitude, lon: location.longitude },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data as Timezone;
      } else {
        throw Error("No timezone fetched");
      }
    });
};

export const addToFastTrack = (
  jwtToken: string,
  deviceId: string,
  callback: (scouts: string[]) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .put(
      `https://api.triangula.no/backoffice/fast-track/scouts/${deviceId}`,
      "",
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.scouts);
      } else {
        if (onError) {
          onError("Failed to fetch overview fast tack");
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError("Failed to fetch overview fast tack");
      }
    });
};

export const removeFromFastTrack = (
  jwtToken: string,
  deviceId: string,
  callback: (scouts: string[]) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .delete(
      `https://api.triangula.no/backoffice/fast-track/scouts/${deviceId}`,
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.scouts);
      } else {
        if (onError) {
          onError("Failed to fetch overview fast tack");
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError("Failed to fetch overview fast tack");
      }
    });
};