import { Map } from "mapbox-gl";
import * as React from "react";
import { TwinDisplayMode } from "../../../models/map";
import { MapViewType } from "../../../models/MapViewType";
import { Gunshot } from "../../../models/gunshots";
import { filterLocationsIfNeeded } from "../../../util/mapUtil";

export interface MapboxProps {}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
  gunshot: Gunshot;
  twinsDisplayMode: TwinDisplayMode;
}

export interface Props extends OwnProps, MapboxProps {}

export interface MapboxState {}

function addBlinkingTriangulationLayerAndSource(
  map: Map,
  gunshot: Gunshot,
  twinMode: TwinDisplayMode
) {
  map.addSource(`new-gunshot-animation-${gunshot.id}`, {
    data: {
      features: filterLocationsIfNeeded(gunshot, twinMode).map((g) => ({
        geometry: {
          coordinates: [
            g.properties.representativePoint.longitude,
            g.properties.representativePoint.latitude,
          ],
          type: "Point",
        },
        properties: {},
        type: "Feature",
      })),
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  map.addLayer({
    id: `new-gunshot-animation-${gunshot.id}`,
    paint: {
      "circle-color": "white",
      "circle-opacity": 0.1,
      "circle-radius": 1000,
      "circle-stroke-color": "#fff",
      "circle-stroke-width": 3,
      "circle-radius-transition": {
        duration: 2000,
      },
      "circle-stroke-width-transition": {
        duration: 3000,
      },
    },
    source: `new-gunshot-animation-${gunshot.id}`,
    type: "circle",
  });
  setTimeout(() => {
    const layer = `new-gunshot-animation-${gunshot.id}`;
    if (map.getLayer(layer)) {
      map.setPaintProperty(layer, "circle-radius", 0);
      map.setPaintProperty(layer, "circle-stroke-width", 0);
    }
  }, 300);
}

class NewGunshotLayer extends React.Component<Props, MapboxState> {
  public componentWillUnmount() {
    const layer = `new-gunshot-animation-${this.props.gunshot.id}`;
    if (this.props.map) {
      if (this.props.map.getLayer(layer)) {
        this.props.map.removeLayer(layer);
      }
      if (this.props.map.getSource(layer)) {
        this.props.map.removeSource(layer);
      }
    }
  }

  public render() {
    const { map, loaded, twinsDisplayMode, gunshot } = this.props;

    if (map && loaded) {
      if (!map.getSource(`new-gunshot-animation-${gunshot.id}`)) {
        addBlinkingTriangulationLayerAndSource(map, gunshot, twinsDisplayMode);
      }
    }

    return null;
  }
}

export default NewGunshotLayer;
