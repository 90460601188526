import React from "react";
import styles from "./style.module.css";


const Spinner = () => {
    return (
      <div className={styles.bigSpinner}>
        <div style={{ position: "absolute" }} className={styles.bigLoader}></div>
      </div>
    );
  };
  export default Spinner;