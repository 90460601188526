import {Map} from "mapbox-gl";
import * as React from "react";
import {connect} from "react-redux";
import { Observation } from "../../../models/gunshots";
import { ApplicationState } from "../../../reducers";
import {orderLayers} from "../layerUtils";
import {addMarkerIcons} from "../mapUtil";
import { getPlayObservationAudio } from "../svgUtils";

export interface MapboxProps {
    observation: Observation | undefined;
}

export interface OwnProps {
    map: Map;
    loaded: boolean;
}

export interface MapboxState {
}

export interface Props extends OwnProps, MapboxProps {}

function addTriangulationLayer(map: Map) {
    addMarkerIcons([{ name: "play_observation", svg: getPlayObservationAudio() }], map);
    map.addLayer({
        id: "playingscout",
        layout: {
          "icon-allow-overlap": true,
          "icon-anchor": "center",
          "icon-image": "play_observation",
        },
        source: "playingscout",
        type: "symbol",
    });
}

const addScoutSource = (observations: Observation[], map: Map) => {
    map.addSource("playingscout", {
        data: createGeoJson(observations),
        type: "geojson",
    });
};

const createGeoJson = (observations: Observation[]): GeoJSON.FeatureCollection => {
    return {
        // @ts-ignore
        features: observations.map((observation) => {
            return observation.location.geoJson;
          }),
        type: "FeatureCollection",
      }
}

function updateScoutSource(observations: Observation[], map: Map) {
    // @ts-ignore
    map.getSource("playingscout").setData(createGeoJson(observations));
}

class FocusedTriangulationsLayer extends React.Component<Props, MapboxState> {


    public render() {
        const { observation, map, loaded } = this.props;
        const scouts = observation ? [observation] : [];
        if (map && loaded) {
            if (!map.getSource("playingscout")) {
                addScoutSource(scouts, map);
                addTriangulationLayer(map);
                orderLayers(map);
            } else {
                updateScoutSource(scouts, map);
            }
        }

        return null;
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        observation: state.admin.playingScout,
    };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
    FocusedTriangulationsLayer
);
