import { AxiosRequestConfig } from "axios";
import { jwtIsValid } from "./jwtUtil";

export const getHostname = () => {
  return process.env.REACT_APP_BOX ? "/api" : "https://api.triangula.no";
};

export const createHeaders = (jwtToken: string | undefined): AxiosRequestConfig  => {
    const headers = {}
    if (jwtIsValid(jwtToken)) {
      headers["Authorization"] = `Bearer ${jwtToken}`
    }
    return {
      headers
    };
}