import React from "react";
import ActionButton from "../../components/buttons/ActionButton";
import styles from "../overview/style.module.scss";
import { addScoutsForSimulation, resetSimulation } from "./demoService";

const DemoButtons = () => (
  <div>
    <p>Run a simulation of a shooting.</p>{" "}
    <p>
      First place scouts in map by pressing "ADD SCOUTS". Right-click in map to
      place shooter, then right-click again to choose direction. The second
      click will fire å gunshot.
    </p>
    <div style={{ display: "flex" }}>
      <ActionButton
        text={"ADD SCOUTS"}
        onClick={() => {
          addScoutsForSimulation();
        }}
        style={{paddingLeft: 0}}
      />
      <ActionButton
        text={"RESET DEMO"}
        onClick={() => {
          resetSimulation();
        }}
      />
    </div>
  </div>
);

export const DemoSettings = () => {
  return (
    <section>
      <h4 className={styles.subsection}>Demo</h4>
      <DemoButtons />
    </section>
  );
};
