import { Action, Reducer } from "redux";
import { FeatureState } from "../../models/featureSwitch";
import { ThunkResult } from "../../reducers";

const ADD_FEATURES = "ADD_FEATURES";

export const addFeaturesSwitchFeaturesAction = (features: {
  [id: string]: FeatureState;
}): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: ADD_FEATURES, features });
  };
};


const SET_DEMO_MODE = "SET_DEMO_MODE";

export const setDemoModeAction = (): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: SET_DEMO_MODE });
  };
};

export interface FeatureSwitchState {
  planning: boolean;
  features: { [id: string]: FeatureState };
  demo: boolean;
}

export const featureInitialState: FeatureSwitchState = {
  planning: true,
  features: {},
  demo: false,
};

const reducer: Reducer<FeatureSwitchState> = (
  state: FeatureSwitchState = featureInitialState,
  action
) => {
  switch ((action as Action).type) {
    case ADD_FEATURES: {
      return { ...state, features: action.features };
    }
    case SET_DEMO_MODE: {
      return { ...state, demo: true };
    }
    default:
      return state;
  }
};

export default reducer;
