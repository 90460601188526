import axios from "axios";
import { createHeaders, getHostname } from "./serviceUtil";

export const loginUsingToken = (jwtToken: string): Promise<string> => {
    return axios
        .post(
            `${getHostname()}/login`,
            "",
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                return response.data.token;
            } else {
                throw Error("Failed login using token")
            }
        });
};