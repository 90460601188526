import { LngLat } from "mapbox-gl";
import { Action, Reducer } from "redux";
import { Focus, FocusType, MapEvent } from "../../models/map";
import { MapViewType } from "../../models/MapViewType";
import { Scout } from "../../models/scouts";
import { Gunshot } from "../../models/gunshots";
import { ThunkResult } from "../../reducers";

const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";

const SET_SCOUT_FOCUS = "SET_SCOUT_FOCUS";

export interface SetScoutFocusAction extends Action {
  type: "SET_SCOUT_FOCUS";
  scout: Scout | undefined;
}

export const setScoutFocusAction = (
  scout: Scout | undefined
): SetScoutFocusAction => {
  return { type: SET_SCOUT_FOCUS, scout };
};

const SET_TRIANGULATION_FOCUS = "SET_TRIANGULATION_FOCUS";

export const setTriangulationFocusAction = (
  gunshot: Gunshot | undefined
): ThunkResult<void> => {
  return dispatch => {
    dispatch({ type: SET_TRIANGULATION_FOCUS, gunshot });
  };
};

const ADD_MAP_EVENT = "ADD_MAP_EVENT";

export const addMapEventAction = (event: MapEvent): ThunkResult<void> => {
  return dispatch => {
    dispatch({ type: ADD_MAP_EVENT, event });
  };
};

const CLEAR_EVENTS = "CLEAR_EVENTS";

export const clearEventsAction = (): ThunkResult<void> => {
  return dispatch => {
    dispatch({ type: CLEAR_EVENTS });
  };
};

const MAP_INIT_STATE = "MAP_INIT_STATE";

export const setMapInitStateAction = (
  mapInitState: MapInitState | undefined
): ThunkResult<void> => {
  return dispatch => {
    dispatch({ type: MAP_INIT_STATE, mapInitState });
  };
};

// State that enables transfer of map settings between pages (Commander to planning mode)
export interface MapInitState {
  zoom: number;
  center: LngLat;
  mapType: MapViewType;
}

export interface MapState {
  inFocus: Focus | undefined;
  events: MapEvent[];
  mapInitState: MapInitState | undefined;
}

const initialState: MapState = {
  events: [],
  inFocus: undefined,
  mapInitState: undefined,
};

const reducer: Reducer<MapState> = (state: MapState = initialState, action) => {
  switch ((action as Action).type) {
    case SET_CURRENT_LOCATION: {
      return { ...state, currentLocation: action.location };
    }
    case SET_SCOUT_FOCUS: {
      const scout: Scout = action.scout;
      return {
        ...state,
        inFocus: { id: scout.deviceId, type: FocusType.Scouts },
      };
    }
    case SET_TRIANGULATION_FOCUS: {
      const gunshot: Gunshot = action.gunshot;
      return {
        ...state,
        inFocus: { id: gunshot.id, type: FocusType.Shots },
      };
    }
    case MAP_INIT_STATE: {
      return {
        ...state,
        mapInitState: action.mapInitState,
      };
    }
    case ADD_MAP_EVENT: {
      return { ...state, events: [...state.events, action.event] };
    }
    case CLEAR_EVENTS: {
      return { ...state, events: [] };
    }
    default:
      return state;
  }
};

export default reducer;
