// This import loads the firebase namespace along with all its type information.
import axios from "axios";
import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { store } from "./ConfiguredApp";
import {
  setOrganisationAction,
  setUserAction,
  setUserDataAction,
} from "./containers/authentication/authenticationReducer";
import { setJwtToken } from "./containers/commander/commanderReducer";
import { listenForDetections } from "./topics/firebase/detection";
import { listenForPlans } from "./topics/firebase/planning";
import { listenForPotentialIncidents } from "./topics/firebase/potentialIncidents";
import { listenForUserInfo } from "./topics/firebase/user";
import { Organisation } from "./models/organisation";
import { UserData } from "./models/user";
import RouterComponent from "./containers/router/Router";
import { listenForScouts } from "./topics/firebase/scouts";
import { listenForGunshots } from "./topics/firebase/gunshots";
import { StatusMessage, statusMessageIsCritical } from "./models/warning";
import { ApplicationState } from "./reducers";
import { listenForFeatureSwitchConfig } from "./topics/firebase/featureSwitch";
import { listenForGeofenceUpdates } from "./topics/firebase/geofence";
import { listenForAreaFilterUpdates } from "./topics/firebase/areaFilter";
import { parseJwt } from "./util/util";



const createJwtToken = (user: firebase.User) => {
  user.getIdToken(true).then((token) => {
    axios
      .post("https://api.triangula.no/auth/generateToken", { token })
      .then((response) => {
        if (response.status === 200) {
          if (loginWithQueryParam()) {
            return;
          }
          const jwtToken = response.data.token;
          const parsedToken = parseJwt(jwtToken);
          setTimeout(
            () => createJwtToken(user),
            parsedToken.exp * 1000 - Date.now() - 60000
          );
          //@ts-ignore
          store.dispatch(setJwtToken(jwtToken));
        } else {
          //@ts-ignore
          store.dispatch(setJwtToken(undefined));
        }
      });
  });
};

const setupListeners = (userData: UserData | undefined) => {
  if (userData && userData.organisation) {
    listenForPotentialIncidents(userData);
    listenForDetections(userData);
    listenForGeofenceUpdates(userData);
    listenForAreaFilterUpdates(userData);
    listenForPlans(userData);
    listenForScouts(userData);
    listenForGunshots(userData);
    listenForFeatureSwitchConfig(userData);
  }
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      loadingUserData: true,
      unregisterAuthObserver: undefined,
    };
  }

  // Listen to the Firebase Auth state and set the local state.
  public componentDidMount() {
    if (process.env.TOKEN) {
      firebase.auth().signInWithCustomToken(process.env.TOKEN);
    }
    this.setState({
      unregisterAuthObserver: firebase.auth().onAuthStateChanged((user) => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (user != null) {
          if (token) {
            if (parseJwt(token).sub !== user.uid) {
              return 
            }
          }
          this.props.setUser(user);
          if (!this.props.jwtToken) {
            createJwtToken(user);
          }
          listenForUserInfo(
            user,
            this.props.setUserData,
            this.props.setOrganisation,
            () =>
              this.setState((prevState) => ({
                ...prevState,
                loadingUserData: false,
              }))
          );
        } else {
        }
        this.setState((prevState) => ({ ...prevState, loading: false }));
      }),
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  public componentWillUnmount() {
    if (this.state.unregisterAuthObserver) {
      this.state.unregisterAuthObserver();
    }
  }

  public render() {
    setupListeners(this.props.userData);
    return (

      <RouterComponent
        criticalIssues={this.props.criticalIssues.length > 0}
        loading={this.state.loading}
        userNotSet={!this.props.user}
        loadingUserData={this.state.loadingUserData}
        userDataNotSet={
          !this.props.userData || !this.props.userData.organisationId
        }
        initialLoadingDone={this.props.initialLoadingDone}
        notEnoughScouts={this.props.notEnoughScouts}
        />
    );
  }
}

interface State {
  loading: boolean;
  loadingUserData: boolean;
  unregisterAuthObserver: firebase.Unsubscribe | undefined;
}

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
  notEnoughScouts: boolean;
  initialLoadingDone: boolean;
  minimalLoadingDone: boolean;
  criticalIssues: StatusMessage[];
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
  setOrganisation: (_: Organisation) => void;
}

type Props = StateToProps & DispatchFromProps;

const mapStateToProps = (state: ApplicationState) => {
  return {
    initialLoadingDone: state.commander.initialLoadingDone,
    jwtToken: state.commander.jwtToken,
    notEnoughScouts: state.scout.list.length < 3 && !state.authentication.userData?.organisation?.scale,
    minimalLoadingDone:
      Object.keys(state.triangulation.gunshots).length > 10,
    user: state.authentication.user,
    userData: state.authentication.userData,
    criticalIssues: Object.values(state.status.list).filter((x) =>
      statusMessageIsCritical(x as StatusMessage)
    ) as StatusMessage[],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOrganisation: setOrganisationAction,
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(App);

function loginWithQueryParam() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("token") !== null && urlParams.get("token") !== undefined;
}

