import firebase from "firebase/compat/app";
import * as React from "react";
import { BigRoundButton } from "../buttons/RoundButton";
import styles from "./style.module.scss";

class CreateOrganisationForm extends React.Component<
  CreateOrganisationFormProps,
  CreateOrganisationFormState
> {
  public state: CreateOrganisationFormState = {
    organisation: "",
  };

  constructor(props: CreateOrganisationFormProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public render() {
    const style = {
      backgroundColor: "#029959",
      color: "white",
      marginBottom: "10px",
      marginTop: "30px",
      paddingLeft: "50px",
      paddingRight: "50px",
    };

    return (
      <div className={styles.formContainer}>
        <h1 id={"name_your_organisation"} className={styles.thin}>
          Name your organisation
        </h1>
        <input
          className={styles.formTextField}
          value={this.state.organisation}
          onChange={(e) => this.handleChange(e.target.value)}
          placeholder={"Organisation name"}
        />
        <BigRoundButton
          id={"to_overview_btn"}
          cssProperties={style}
          text={"Next"}
          onClick={this.handleSubmit}
        />
      </div>
    );
  }

  private handleSubmit() {
    this.props.createHandler(this.state.organisation);
  }

  private handleChange = (organisation: string) =>
    this.setState({ organisation });
}

interface CreateOrganisationFormProps {
  createHandler: (_: string) => void;
  user: firebase.User;
}

interface CreateOrganisationFormState {
  organisation: string;
}

export default CreateOrganisationForm;
