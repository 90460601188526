import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import OverlayButton from "../../components/overlay/OverlayButton";
import {
  IfMd,
  IfMobile,
  IfSm,
  IfSmallerThanMd,
} from "../../components/responsive/responsiveUtil";
import { Focus, FocusType } from "../../models/map";
import { MapViewType } from "../../models/MapViewType";
import { ApplicationState } from "../../reducers";
import { changeMapType } from "../commander/commanderReducer";
import styles from "./style.module.scss";

const SwitchMapButton = ({ mapType, changeMapTypeAction, focused }: Props) => {
  const classes = [styles.darkButton, styles.buttonText].join(" ");

  const isSatellite = mapType === MapViewType.SATELLITE;
  const changeMap = () =>
    changeMapTypeAction(
      isSatellite ? MapViewType.ROADMAP : MapViewType.SATELLITE
    );

  if (focused && focused.type === FocusType.Menu) {
    return <></>;
  }
  return (
    <>
      <IfSm>
        <OverlayButton>
          <div className={classes} onClick={changeMap}>
            Switch to {isSatellite ? "map" : "satellite"}
          </div>
        </OverlayButton>
      </IfSm>
      <IfMobile>
        <></>
      </IfMobile>
    </>
  );
};

const SwitchMapButtonResponsive = (props: Props) => {
  return (
    <>
      <IfMd>
        <SwitchMapButton {...props} />
      </IfMd>
      <IfSmallerThanMd>
        <>{props.focused === undefined && <SwitchMapButton {...props} />}</>
      </IfSmallerThanMd>
    </>
  );
};

interface StateToProps {
  mapType: MapViewType;
  focused: Focus | undefined;
}

interface OwnProps {
  planning?: boolean
}

interface Props extends StateToProps, DispatchToProps, OwnProps {}

interface DispatchToProps {
  changeMapTypeAction: (_: MapViewType) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  focused: state.commander.focused,
  mapType: state.commander.mapType,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeMapTypeAction: changeMapType,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(SwitchMapButtonResponsive);
