import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { MapViewType } from "../../../models/MapViewType";
import { Scout, ScoutState } from "../../../models/scouts";
import { orderLayers } from "../layerUtils";
import { addMarkerIcons, scoutPoints, SvgMarker } from "../mapUtil";
import { getLockedScoutSvg2, getScoutSvg } from "../svgUtils";
import { PotentialIncident } from "../../../models/potentialIncident";
import { ApplicationState } from "../../../reducers";

export interface MapboxProps {
  hideScouts: boolean;
  mapType: MapViewType;
  scouts: Scout[];
  potentialIncidents: PotentialIncident[];
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

const MARKERS: SvgMarker[] = [
  { name: "ready_scout_marker", svg: getScoutSvg("#0142FF") },
  { name: "offline_scout_marker", svg: getScoutSvg("#C5C5C5") },
  { name: "init_scout_marker", svg: getScoutSvg("#CC6618") },
  {
    imageHeight: 50,
    imageWidth: 50,
    name: "ready_scout_marker_fixed",
    svg: getLockedScoutSvg2("#0142FF"),
  },
  {
    imageHeight: 50,
    imageWidth: 50,
    name: "offline_scout_marker_fixed",
    svg: getLockedScoutSvg2("#C5C5C5"),
  },
  {
    imageHeight: 50,
    imageWidth: 50,
    name: "init_scout_marker_fixed",
    svg: getLockedScoutSvg2("#CC6618"),
  },
];

const DISABLED_COLOR = "#0ea5e9"

const LISTENING_MARKERS = [
  { name: "lscout_marker", svg: getScoutSvg("#26CD7A") },
  { name: "loffline_scout_marker", svg: getScoutSvg("#C5C5C5") },
  { name: "potential_incident_marker", svg: getScoutSvg("#26CD7A", "#D0021B") },
  { name: "lscout_marker_moving", svg: getScoutSvg("#F7B500") },
  { name: "potential_incident_marker_moving", svg: getScoutSvg("#F7B500", "#D0021B") },
  { name: "potential_incident_disabled_marker_moving", svg: getScoutSvg(DISABLED_COLOR, "#D0021B") },
  { name: "lscout_marker_disabled", svg: getScoutSvg(DISABLED_COLOR) },
  {
    name: "lscout_marker_fixed",
    svg: getLockedScoutSvg2("#26CD7A"),
    imageWidth: 50,
    imageHeight: 50,
  },
  {
    name: "lscout_marker_disabled_fixed",
    svg: getLockedScoutSvg2(DISABLED_COLOR),
    imageWidth: 50,
    imageHeight: 50,
  },
  {
    name: "potential_incident_marker_fixed",
    svg: getLockedScoutSvg2("#26CD7A", "#D0021B"),
    imageWidth: 50,
    imageHeight: 50,
  },
  {
    name: "potential_incident_disabled_marker_fixed",
    svg: getLockedScoutSvg2(DISABLED_COLOR, "#D0021B"),
    imageWidth: 50,
    imageHeight: 50,
  },
];

const addScoutLayer = (map: Map) => {
  addMarkerIcons(MARKERS, map);
  map.addLayer({
    filter: ["==", ["get", "listening"], "false"],
    id: "scouts",
    interactive: false,
    layout: {
      "icon-allow-overlap": true,
      "icon-anchor": "center",
      "icon-image": [
        "match",
        ["get", "state"],
        "1",
        "offline_scout_marker",
        "3",
        "init_scout_marker",
        "4",
        "ready_scout_marker",
        "6",
        "scout_marker",
        "7",
        "init_scout_marker",
        "9",
        "init_scout_marker",
        "10",
        "init_scout_marker",
        "11",
        "lscout_marker_disabled",
        
        "1_fixed",
        "offline_scout_marker_fixed",
        "3_fixed",
        "init_scout_marker_fixed",
        "4_fixed",
        "ready_scout_marker_fixed",
        "6_fixed",
        "scout_marker_fixed",
        "7_fixed",
        "init_scout_marker_fixed",
        "9_fixed",
        "init_scout_marker_fixed",
        "10_fixed",
        "init_scout_marker_fixed",
        "11_moving",
        "lscout_marker_disabled",
        "11_fixed",
        "lscout_marker_disabled_fixed",
        /* other */ "offline_scout_marker",
      ],
      "icon-offset": ["get", "iconAnchor"],
      "text-allow-overlap": true,
      "text-anchor": "center",
      "text-field": ["get", "letter"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 18,
    },
    paint: {
      "text-color": "#FFFFFF",
    },
    source: "scouts",
    type: "symbol",
  });

  addMarkerIcons(LISTENING_MARKERS, map);
  map.addLayer({
    filter: ["==", ["get", "listening"], "true"],
    id: "listening_scouts",
    interactive: false,
    layout: {
      "icon-allow-overlap": true,
      "icon-anchor": "center",
      "icon-image": [
        "match",
        ["get", "state"],
        "6",
        "lscout_marker",
        "6_fixed",
        "lscout_marker_fixed",
        "6_moving",
        "lscout_marker_moving",
        "11",
        "lscout_marker_disabled",
        "11_moving",
        "lscout_marker_disabled",
        "11_fixed",
        "lscout_marker_disabled_fixed",
        "potential_incident_disabled",
        "potential_incident_marker_disabled",
        "potential_incident_moving",
        "potential_incident_marker_moving",
        "potential_incident",
        "potential_incident_marker",
        "potential_incident_fixed",
        "potential_incident_marker_fixed",
        /* other */ "loffline_scout_marker",
      ],
      "icon-offset": ["get", "iconAnchor"],
      "text-allow-overlap": true,
      "text-anchor": "center",
      "text-field": ["get", "letter"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 18,
    },
    paint: {
      "text-color": "#FFFFFF",
    },
    source: "scouts",
    type: "symbol",
  });

  map.addLayer({
    id: "scout_text",
    interactive: false,
    minzoom: 15,
    layout: {
      "text-allow-overlap": true,
      "text-anchor": "center",
      "text-field": ["get", "device_name"],
      "text-offset": [0, 2],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "scouts",
    type: "symbol",
  });
};

const addScoutSource = (
  scouts: Scout[],
  potentialIncidents: PotentialIncident[],
  map: Map
) => {
  map.addSource("scouts", {
    data: scoutPoints(scouts, potentialIncidents),
    type: "geojson",
  });
};

function updateScoutSource(scouts: Scout[], potentialIncidents: PotentialIncident[], map: Map) {
  // @ts-ignore
  map.getSource("scouts").setData(scoutPoints(scouts, potentialIncidents));
}

const ScoutLayer = ({
  scouts,
  map,
  loaded,
  hideScouts,
  potentialIncidents,
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("scouts")) {
      addScoutSource(scouts, potentialIncidents, map);
      addScoutLayer(map);
      orderLayers(map);
    } else {
      updateScoutSource(scouts, potentialIncidents, map);
    }

    if (
      (map.getLayer("scouts") &&
        map.getLayer("scouts") &&
        map.getLayoutProperty("scouts", "visibility") === "visible" &&
        hideScouts) ||
      (map.getLayer("scouts") &&
        map.getLayoutProperty("scouts", "visibility") !== "visible" &&
        !hideScouts)
    ) {
      map.setLayoutProperty(
        "scouts",
        "visibility",
        hideScouts ? "none" : "visible"
      );
      map.setLayoutProperty(
        "listening_scouts",
        "visibility",
        hideScouts ? "none" : "visible"
      );
      map.setLayoutProperty(
        "scout_text",
        "visibility",
        hideScouts ? "none" : "visible"
      );
      
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  
  return {
    hideScouts: state.scout.hideScouts,
    mapType: state.commander.mapType,
    potentialIncidents: state.scout.potentialIncidents,
    scouts: (!state.authentication.userData?.organisation?.scale || state.commander.showScoutsAtScaleMode) ? state.scout.list.filter(s => s.state !== ScoutState.OfflineIgnore) : [],
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(ScoutLayer);
