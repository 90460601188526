import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { rotateNorth } from "../../components/map/mapEventCreator";
import { IfSm } from "../../components/responsive/responsiveUtil";
import { MapEvent } from "../../models/map";
import { resetMapAngleAction } from "../commander/commanderReducer";
import { addMapEventAction } from "./mapReducer";
import styles from "./style.module.scss";
import OverlayButton from "../../components/overlay/OverlayButton";
import { ApplicationState } from "../../reducers";

const ResetPitchAndBearingButton = ({
  resetMapAngle,
  resetMapAngleCallback,
  addEvent,
  planning
}: Props) => {
  const classes = [styles.darkButton, styles.buttonText].join(" ");

  const onClickAction = () => {
    resetMapAngleCallback(true);
    addEvent(rotateNorth());
  };

  if (!resetMapAngle) {
    return (
      <IfSm>
        <OverlayButton cssProperties={{ left: "15px", top: planning ? "11rem" : "10rem" }}>
          <div className={classes} onClick={onClickAction}>
            Rotate north
          </div>
        </OverlayButton>
      </IfSm>
    );
  } else {
    return <></>;
  }
};

interface StateToProps {
  resetMapAngle: boolean;
}

interface OwnProps {
  planning?: boolean
}

interface Props extends StateToProps, DispatchToProps, OwnProps {}

interface DispatchToProps {
  resetMapAngleCallback: (_: boolean) => void;
  addEvent: (_: MapEvent) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  resetMapAngle: state.commander.resetMapAngle,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addEvent: addMapEventAction,
      resetMapAngleCallback: resetMapAngleAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPitchAndBearingButton);
