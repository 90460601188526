import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { MapViewType } from "../../../models/MapViewType";
import { Scout, ScoutState } from "../../../models/scouts";
import { orderLayers } from "../layerUtils";
import { ApplicationState } from "../../../reducers";
import circle from "@turf/circle";

export interface MapboxProps {
  mapType: MapViewType;
  scouts: Scout[];
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

const addScoutLayer = (map: Map) => {
  map.addLayer({
    id: "scout-location-accuracy-line",
    paint: {
      "line-color": "#60a5fa",
      "line-opacity": 0.8,
      "line-width": 2,
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    source: "gps-accuracy",
    type: "line",
  });
  map.addLayer({
    id: "scout-location-accuracy",
    paint: {
      "fill-color": "#60a5fa",
      "fill-opacity": 0.2,
    },
    source: "gps-accuracy",
    type: "fill",
  });
};

const addScoutSource = (
  scouts: Scout[],
  map: Map
) => {
  map.addSource("gps-accuracy", {
    data: createFeatures(scouts),
    type: "geojson",
  });
};

function updateScoutSource(scouts: Scout[], map: Map) {
  // @ts-ignore
  map.getSource("gps-accuracy").setData(createFeatures(scouts));
}

const GpsAccuracyLayer = ({
  scouts,
  map,
  loaded,
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("gps-accuracy")) {
      addScoutSource(scouts, map);
      addScoutLayer(map);
      orderLayers(map);
    } else {
      updateScoutSource(scouts, map);
    }
  }

  return null;
};

export const createFeatures = (
  scouts: Scout[],
): GeoJSON.FeatureCollection => {
  return {
    features: createFeaturesForScouts(scouts),
    type: "FeatureCollection",
  };
};

const createFeaturesForScouts = (
  scouts: Scout[],
): any => {
  return scouts.map((scout) => {
    var center = [scout.status.scoutLocation?.longitude ?? 0, scout.status.scoutLocation?.latitude ?? 0];
var radius = (scout.status.scoutLocation?.accuracy?? 0);
var options = {steps: 50, units: 'meters', properties: {id: scout.status.deviceId}};
//@ts-ignore
return circle(center, radius, options);
    
  });
};


const mapStateToProps = (state: ApplicationState) => {
  
  return {
    mapType: state.commander.mapType,
    scouts: state.scout.list
    .filter(s => s.state !== ScoutState.OfflineIgnore)
    .filter(s => s.state !== ScoutState.Offline),
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(GpsAccuracyLayer);
