import axios from "axios";
import { Geofence } from "../models/geofence";
import { createHeaders, getHostname } from "./serviceUtil";

const geofenceToJson = (geofence: Geofence) => {
    return {
        shape: {
            center: {
                longitude: geofence.center[0],
                latitude: geofence.center[1]
            },
            radius: geofence.radius,
            type: "circle"
        },
        listening: geofence.listening
    };
}

export const createZone = (geofence: Geofence, jwtToken: string, callback: (id: string) => void, onError: () => void) => {
    axios
        .post(
            `${getHostname()}/zones`,
            geofenceToJson(geofence),
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                callback(response.data.id);
                // tslint:disable-next-line: no-empty
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        });
};

export const muteZone = (geofenceId: string, jwtToken: string, callback: () => void, onError: () => void) => {
    axios
        .put(
            `${getHostname()}/zones/${geofenceId}/mute`,
            {},
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                callback();
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        });
};

export const activateZone = (geofenceId: string, jwtToken: string, callback: () => void, onError: () => void) => {
    axios
        .put(
            `${getHostname()}/zones/${geofenceId}/activate`,
            {},
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                callback();
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        });
};

export const deleteZone = (geofenceId: string, jwtToken: string, callback: () => void, onError: () => void) => {
    axios
        .delete(
            `${getHostname()}/zones/${geofenceId}`,
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                callback();
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        });
};

export const deleteAllZones = (jwtToken: string, callback: () => void, onError: () => void) => {
    axios
        .delete(
            `${getHostname()}/zones`,
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                callback();
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        });
};