import { Map } from "mapbox-gl";

const order = (map: Map) => {
  // If for some reason map or the styles are not set -- don't try to order layers
  if (map === undefined || map.getStyle() === undefined) {
    return;
  }
  if (map.getLayer("scouts") && map.getLayer("scout_lines")) {
    map.moveLayer("scout_lines", "scouts");
  }
  if (map.getLayer("listening_scouts") && map.getLayer("scouts")) {
    map.moveLayer("scouts", "listening_scouts");
  }
  if (map.getLayer("scout_lines") && map.getLayer("gunshot-line")) {
    map.moveLayer("gunshot-line", "scout_lines");
  }
  if (map.getLayer("scouts") && map.getLayer("gunshot-circle")) {
    map.moveLayer("gunshot-circle", "scouts");
  }
  if (map.getLayer("scouts") && map.getLayer("gunshot-line")) {
    map.moveLayer("scouts", "gunshot-line");
  }
  if (map.getLayer("listening_scouts") && map.getLayer("gunshot-circle")) {
    map.moveLayer("listening_scouts", "gunshot-circle");
  }
  if (map.getLayer("listening_scouts") && map.getLayer("gunshot-line")) {
    map.moveLayer("listening_scouts", "gunshot-line");
  }
  if (map.getLayer("scout_lines") && map.getLayer("gunshot-circle")) {
    map.moveLayer("scout_lines", "gunshot-circle");
  }
  if (map.getLayer("gunshot") && map.getLayer("gunshot-line")) {
    map.moveLayer("gunshot", "gunshot-line");
  }
  if (map.getLayer("focusedTriangulations") && map.getLayer("triangulations")) {
    map.moveLayer("triangulations", "focusedTriangulations");
  }
  if (map.getLayer("gunshot_direction") && map.getLayer("focusedTriangulations")) {
    map.moveLayer("focusedTriangulations", "gunshot_direction");
  }
  if (
    map.getLayer("staticFocusedTriangulations") &&
    map.getLayer("focusedTriangulations")
  ) {
    map.moveLayer("focusedTriangulations", "staticFocusedTriangulations");
  }
  if (map.getLayer("hyperbolas") && map.getLayer("focusedTriangulations")) {
    map.moveLayer("hyperbolas", "focusedTriangulations");
  }
  if (map.getLayer("triangulations") && map.getLayer("point")) {
    map.moveLayer("triangulations", "point");
  }

  if (map.getLayer("scouts") && map.getLayer("triangulations")) {
    map.moveLayer("scouts", "triangulations");
  }

  if (map.getLayer("twin_lines") && map.getLayer("scouts")) {
    map.moveLayer("twin_lines", "scouts");
  }

  if (map.getLayer("triangulation_lines") && map.getLayer("scouts")) {
    map.moveLayer("triangulation_lines", "scouts");
  }

  if (map.getLayer("scouts") && map.getLayer("coverage")) {
    map.moveLayer("coverage", "scouts");
  }

  if (map.getLayer("triangulation_lines") && map.getLayer("coverage")) {
    map.moveLayer("coverage", "triangulation_lines");
  }
  if (map.getLayer("listening_scouts") && map.getLayer("scout-location-accuracy-line")) {
    map.moveLayer("scout-location-accuracy-line", "listening_scouts");
  }

  if (map.getLayer("listening_scouts") && map.getLayer("scout-location-accuracy")) {
    map.moveLayer("scout-location-accuracy", "listening_scouts");
  }
  if (map.getLayer("scouts") && map.getLayer("scout-location-accuracy-line")) {
    map.moveLayer("scout-location-accuracy-line", "scouts");
  }

  if (map.getLayer("scouts") && map.getLayer("scout-location-accuracy")) {
    map.moveLayer("scout-location-accuracy", "scouts");
  }

  if (map.getLayer("listening_scouts") && map.getLayer("scout-dynamic-range-line")) {
    map.moveLayer("scout-dynamic-range-line", "listening_scouts");
  }

  if (map.getLayer("listening_scouts") && map.getLayer("scout-dynamic-range")) {
    map.moveLayer("scout-dynamic-range", "listening_scouts");
  }
  if (map.getLayer("scouts") && map.getLayer("scout-dynamic-range-line")) {
    map.moveLayer("scout-dynamic-range-line", "scouts");
  }

  if (map.getLayer("scouts") && map.getLayer("scout-dynamic-range")) {
    map.moveLayer("scout-dynamic-range", "scouts");
  }
  if (map.getLayer("scouts") && map.getLayer("coverage-heatmap")) {
    map.moveLayer("coverage-heatmap", "scouts");
  }

  if (map.getLayer("scouts") && map.getLayer("coverage-heatmap-mid-res")) {
    map.moveLayer("coverage-heatmap-mid-res", "scouts");
  }
  if (map.getLayer("plan_scout") && map.getLayer("coverage-heatmap")) {
    map.moveLayer("coverage-heatmap", "plan_scout");
  }

  if (map.getLayer("plan_scout") && map.getLayer("coverage-heatmap-mid-res")) {
    map.moveLayer("coverage-heatmap-mid-res", "plan_scout");
  }

  if (map.getLayer("coverage-heatmap") && map.getLayer("rifle-coverage-heatmap")) {
    map.moveLayer("rifle-coverage-heatmap", "coverage-heatmap");
  }

  if (map.getLayer("coverage-heatmap-mid-res") && map.getLayer("rifle-coverage-heatmap-mid-res")) {
    map.moveLayer("rifle-coverage-heatmap-mid-res", "coverage-heatmap-mid-res");
  }
  if (map.getLayer("coverage-heatmap") && map.getLayer("rifle-coverage-heatmap")) {
    map.moveLayer("rifle-coverage-heatmap", "coverage-heatmap");
  }

  if (map.getLayer("coverage-heatmap-mid-res") && map.getLayer("rifle-coverage-heatmap-mid-res")) {
    map.moveLayer("rifle-coverage-heatmap-mid-res", "coverage-heatmap-mid-res");
  }

  if (map.getLayer("triangulation_lines") && map.getLayer("creating_geofence_moving_circle")) {
    map.moveLayer("creating_geofence_moving_circle", "triangulation_lines");
    map.moveLayer("geofence", "triangulation_lines");
  }
  
  
};

const planningOrder = (map: Map) => {
  if (map.getLayer("listening_scouts") && map.getLayer("scouts")) {
    map.moveLayer("scouts", "listening_scouts");
  }

  if (map.getLayer("listening_scouts") && map.getLayer("scouts")) {
    map.moveLayer("plan_scout", "scouts");
  }

  if (map.getLayer("scouts") && map.getLayer("coverage")) {
    map.moveLayer("coverage", "plan_scout");
  }

};

export const orderPlanningLayers = (map: Map) => {
  setTimeout(() => planningOrder(map), 200);
  setTimeout(() => planningOrder(map), 500);
  setTimeout(() => planningOrder(map), 2000);
};

export const orderLayers = (map: Map) => {
  setTimeout(() => order(map), 200);
  setTimeout(() => order(map), 500);
  setTimeout(() => order(map), 2000);
};
