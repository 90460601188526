import firebase from "firebase/compat/app";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import LoadingScreen from "../../components/loading/LoadingScreen";
import { Logo } from "../../components/svg/Logo";
import { UserData } from "../../models/user";
import { ApplicationState } from "../../reducers";
import { setUserAction } from "../authentication/authenticationReducer";
import CreateOrganisation from "./CreateOrganisation";
import styles from "./style.module.scss";

const OrganisationPage = ({ userData, user }: AllProps) => {
  return (
    <>
      <div className={styles.organisationPage + " container"}>
        <Logo />
        {userData && user && <CreateOrganisation user={user} />}
        {(!userData || !user) && <LoadingScreen message={"Logging in"} />}
      </div>
    </>
  );
};

interface AllProps extends StateToPros, DispatchFromProps {}

interface StateToPros {
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.authentication.user,
  userData: state.authentication.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
    },
    dispatch
  );

export default connect<StateToPros, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationPage);
