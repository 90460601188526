import { LngLat, LngLatBounds, Map } from "mapbox-gl";
import * as React from "react";
import { CSSProperties } from "react";
import { connect } from "react-redux";
import { MapInitState } from "../../containers/map/mapReducer";
import { ScoutState } from "../../models/scouts";
import { changeBounds } from "./mapUtil";
import { FocusType } from "../../models/map";
import { ApplicationState } from "../../reducers";
import { filterLocationsIfNeeded, getRepresentativePoint } from "../../util/mapUtil";

export interface MapboxProps {
  bounds: LngLatBounds;
  mapInFocus: boolean;
  mapInitState: MapInitState | undefined;
  autoFocus: boolean;
  focusedId: string;
  initialLoadingDone: boolean;
}

export interface OwnProps {
  map: Map | undefined;
  mapRef: React.RefObject<HTMLDivElement>;
  styling: CSSProperties;
  focused: LngLat | undefined;
  loaded: boolean;
}

export type Props = OwnProps & MapboxProps;

export interface MapboxState {
  bounds: LngLatBounds | undefined;
}

function center(lngLat: LngLat, map: Map) {
  addPaddingForScreenSize(map);
  map.setCenter(lngLat);
}

// By adding padding, we can make sure that center is not under overlays
const addPaddingForScreenSize = (map: Map) => {
  if (window.innerWidth < 1400 && window.innerWidth > 700) {
    map.setPadding({ right: 400, left: 0, top: 0, bottom: 0 });
  } else if (window.innerWidth < 700) {
    map.setPadding({ right: 0, left: 0, top: 0, bottom: 400 });
  } else {
    map.setPadding({ right: 0, left: 0, top: 0, bottom: 0 });
  }
};

function fitMapToBound(
  bounds: LngLatBounds,
  map: Map,
  initialLoadingDone: boolean,
  animate: boolean = true,
) {
  if (!initialLoadingDone) {
    map.setCenter(bounds.getCenter());
  }
  map.fitBounds(
    [
      [bounds.getSouthWest().lng - 0.005, bounds.getSouthWest().lat - 0.005],
      [bounds.getNorthEast().lng + 0.005, bounds.getNorthEast().lat + 0.005],
    ],
    animate ? { linear: true} : { linear: true, duration: 0 }
  );
}

const MapboxContainer = ({
  bounds,
  map,
  styling,
  mapRef,
  focused,
  loaded,
  mapInitState,
  initialLoadingDone,
}: Props) => {
  if (map && loaded) {
    if (map.getSource("gunshot")) {
      if (focused) {
        center(focused, map);
      } else if (!mapInitState && !bounds.isEmpty()) {
        fitMapToBound(bounds, map, initialLoadingDone);
      } else if (!mapInitState && bounds.isEmpty()) {
        navigator.geolocation.getCurrentPosition((location) => {
          const bounds = new LngLatBounds();
          bounds.extend(
            new LngLat(location.coords.longitude, location.coords.latitude)
          );
          fitMapToBound(bounds, map, initialLoadingDone, false);
        });
      }
    }
  }
  return (
    <div
      className={
        styling.position && styling.position === "inherit"
          ? "map_inherit"
          : "map_absolute"
      }
      style={styling}
      ref={mapRef}
    />
  );
};

const MemoMapboxContainer = React.memo(MapboxContainer, (p, n) => {
  // If map is not loaded, don't request re-renders
  if (!n.loaded && !n.initialLoadingDone) {
    return true;
  }

  const screenSizeChange =
    p.styling.height !== n.styling.height ||
    p.styling.width !== n.styling.width;

  const switchedToLoaded = n.loaded && p.loaded !== n.loaded;

  const changedFocusedItem = p.focusedId !== n.focusedId;

  if (screenSizeChange || switchedToLoaded || (n.focusedId && changedFocusedItem)) {
    return false;
  }

  const changeBound = changeBounds(n.mapInFocus, n.bounds, n.map);
  return !changeBound;
});

const mapStateToProps = (state: ApplicationState) => {
  const bounds = new LngLatBounds();

  const triangulations = state.triangulation.gunshots;
  const twinToDisplay = state.triangulation.twinToDisplay;

  const triangulationKeys = Object.keys(triangulations);
  triangulationKeys
    .map((key) => triangulations[key])
    .forEach((t) => {
      filterLocationsIfNeeded(t, twinToDisplay).forEach((l) => {
        bounds.extend(new LngLat(l.properties.representativePoint.longitude, l.properties.representativePoint.latitude));
      })
    });
    
    const coverageMaxLat = state.scout.coverages?.boundary?.maxLatitude
    const coverageMaxLon = state.scout.coverages?.boundary?.maxLongitude
    const coverageMinLat = state.scout.coverages?.boundary?.minLatitude
    const coverageMinLon = state.scout.coverages?.boundary?.minLongitude
    if (coverageMaxLat && coverageMaxLon && coverageMinLat && coverageMinLon) {
      bounds.extend(new LngLat(coverageMaxLon, coverageMaxLat));
      bounds.extend(new LngLat(coverageMinLon, coverageMinLat));
    }
  state.scout.list
    .filter((s) => s.state !== ScoutState.Offline && s.state !== ScoutState.OfflineIgnore && s.status.latitude !== 0 && s.status.longitude !== 0)
    .forEach((s) => {
      bounds.extend(new LngLat(s.status.longitude, s.status.latitude));
    });

  const focused = state.commander.focused;
  const focusedId = focused && focused.id;

  let focusLngLat: LngLat | undefined = undefined;
  if (focused && focused.id && focused.type === FocusType.Shots) {
    const triangulation = triangulations[focused.id];
    if (triangulation) {
      const repPoint = getRepresentativePoint(
        triangulation.location.geoJson.features[0]
      );
      focusLngLat = new LngLat(repPoint.longitude, repPoint.latitude);
    }
  }

  if (focused && focused.id && focused.type === FocusType.Scouts) {
    const scout = state.scout.list.find((s) => s.deviceId === focused.id);
    focusLngLat = scout
      ? new LngLat(scout.status.longitude, scout.status.latitude)
      : undefined;
  }
  return {
    bounds,
    mapInFocus: state.commander.mapInFocus,
    mapInitState: state.map.mapInitState,
    autoFocus: state.commander.mapInFocus,
    focusedId: focusedId,
    focused: focusLngLat,
    initialLoadingDone: state.commander.initialLoadingDone,
    batchNumber: state.commander.initialLoadingDone
      ? -1
      : Math.floor(triangulationKeys.length / 30),
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(
  MemoMapboxContainer
);
