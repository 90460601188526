import * as React from "react";
import styles from "./style.module.scss";

export const SmallLogo = ({
    fontSizeRem,
  }: {
    fontSizeRem: number;
  }) => (
  <h1 className={styles.smallLogo} style={{ fontSize: `${fontSizeRem}rem` }}>
    Triangula
  </h1>
);

export const SmallLogoWithPlanning = ({
  fontSizeRem,
}: {
  fontSizeRem: number;
}) => (
  <div className={styles.logoContent}>
    <p className={styles.smallLogo} style={{ fontSize: `${fontSizeRem}rem` }}>
      Triangula
    </p>
    <p
      className={[styles.smallLogo, styles.planningStyle].join(" ")}
      style={{ fontSize: `${fontSizeRem * 0.7}rem` }}
    >
      Planning
    </p>
  </div>
);

export const SmallLogoWithDemo = ({
  fontSizeRem,
}: {
  fontSizeRem: number;
}) => (
  <div className={styles.logoContent}>
    <p className={styles.smallLogo} style={{ fontSize: `${fontSizeRem}rem` }}>
      Triangula
    </p>
    <p
      className={[styles.smallLogo, styles.planningStyle].join(" ")}
      style={{ fontSize: `${fontSizeRem * 0.7}rem` }}
    >
      Demo
    </p>
  </div>
);

