import {db, store} from "../../ConfiguredApp";
import {addPotentialIncident, removePotentialIncident,} from "../../containers/scout/scoutReducer";
import {PotentialIncident} from "../../models/potentialIncident";
import {UserData} from "../../models/user";
import {tutToDate} from "../../util/util";

let ref: () => void;

export const handlePotentialIncidents = (snapshot) => {
    snapshot.docChanges().forEach((change: any) => {
        if (change.type === "added") {
            const data = change.doc.data() as PotentialIncident;
            if (tutToDate(data.tut).getTime() > (Date.now() - 10000)) {
                store.dispatch(addPotentialIncident(data));
                setTimeout(() => store.dispatch(removePotentialIncident(data)), 1000);
            }
        }
    })
}   

export const listenForDemoIncidents = (user: UserData, planId: string) => {
    if (user && !ref && user.organisation) {
        ref = db
            .collection("organisations")
            .doc(user.organisation.id)
            .collection("simulations")
            .doc(planId)
            .collection("potentialIncidents")
            .onSnapshot(handlePotentialIncidents);
    }
};


export const listenForPotentialIncidents = (user: UserData) => {
    if (user && !ref && user.organisation) {
        ref = db
            .collection("organisations")
            .doc(user.organisation.id)
            .collection("potentialIncidents")
            .onSnapshot(handlePotentialIncidents);
    }
};
