import * as React from "react";
import ReactDOM from "react-dom";
import ConnectedDemoApp from "./connected/ConnectedDemoApp";



export const renderDemo = () => {
  ReactDOM.render(
      <ConnectedDemoApp />,
    document.getElementById("root")
  );
};
