// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import * as React from "react";
import { SmallLogo, SmallLogoWithDemo, SmallLogoWithPlanning } from "../../components/svg/SmallLogo";
import styles from "./style.module.scss";
import TriangulaMenu from "./TriangulaMenu";
import { Link } from "react-router-dom";

export enum Page {
  overview,
  planning,
  demo,
}

const MenuWithLogo = ({ page }: { page: Page }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className={styles.menuIcon}>
        <TriangulaMenu page={page} />
      </div>
      <Link id={"back_to_commander_btn"} to={"/"}>
        <div className={styles.logoAndText}>
          {page === Page.overview ?  <SmallLogo fontSizeRem={1.625} /> : page === Page.demo ? <SmallLogoWithDemo fontSizeRem={1.625} /> : <SmallLogoWithPlanning fontSizeRem={1.625} />}
        </div>
      </Link>
    </div>
  );
};

export default MenuWithLogo;
