import * as React from "react";
import styles from "../../containers/overview/style.module.scss";
import summaryStyles from "./style.module.scss";
import commonStyles from "../../containers/styles/style.module.scss";
import { Focus, FocusType } from "../../models/map";
import { Status, StatusMessage } from "../../models/warning";
import SummaryItem from "./SummaryItem";
import { IfMd } from "../responsive/responsiveUtil";
import { criticalColor, okColor, warnColor } from "../../util/util";

interface Props {
  inFocus: boolean;
  setFocus: (_: Focus | undefined) => void;
  status: { [id: string]: StatusMessage };
}



const StatusComponent = ({ inFocus, setFocus, status }: Props) => {
  const setFocusOnClick = () => {
    setFocus({ type: FocusType.Status });
  };

  const classes = [styles.summaryText, commonStyles.centerText, styles.status];

  const statusList = Object.keys(status).map((s) => status[s]);
  const warnings = statusList.filter((x) => x.status === Status.Warning);
  const criticals = statusList.filter((x) => x.status === Status.Critical || x.status === Status.Down);

  const color = criticals.length ? criticalColor : warnings.length ? warnColor :  okColor;
  const statusMsg = criticals.length ? "Critical" : warnings.length ? "Warning" :  "Ok";
  const statusDescription = criticals.length ? `${criticals.length + warnings.length} issues` : warnings.length ? `${warnings.length} possible issues` :  "No issues";

  return (
    <SummaryItem
      focusType={FocusType.Status}
      inFocus={inFocus}
      setFocus={setFocusOnClick}
    >
      <div className={classes.join(" ")}>
        <p className={summaryStyles.summaryLabel}>Status</p>
        <p className={summaryStyles.summaryValue}>
          <IfMd>
            <span
              style={{ backgroundColor: color }}
              className={styles.statusDot}
            />
          </IfMd>
          {statusMsg}
        </p>
        <p className={styles.summaryDescription + " " + commonStyles.noMargin}>
          {statusDescription}
        </p>
      </div>
    </SummaryItem>
  );
};

export default StatusComponent;
