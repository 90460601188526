export interface OrganisationName {
  name: string;
}

export enum Units {
  Metric = "metric",
  Imperial = "imperial",
}

export interface Organisation {
  id: string;
  name: string;
  accessCode?: string;
  sales?: boolean;
  units?: Units;
  activePlan?: string;
  mode?: string;
  scale?: boolean;
  antiTheft?: boolean;
}
