import {Map} from "mapbox-gl";
import {connect} from "react-redux";
import {FocusType, TwinDisplayMode} from "../../../models/map";
import {MapViewType} from "../../../models/MapViewType";
import {Gunshot} from "../../../models/gunshots";
import { ApplicationState } from "../../../reducers";
import {orderLayers} from "../layerUtils";
import {triangulationPoints} from "../mapUtil";
import { filterRelevantGunshot } from "../../../util/gunshotUtil";
import { RelativeRange } from "../../range/rangeUtil";
import { useEffect } from "react";

export interface MapboxProps {
    gunshot?: Gunshot;
    timeOfLatestGunshot: Date;
    relativeLatestTriangulation: number | undefined;
    twinsDisplayMode: TwinDisplayMode;
}

export interface OwnProps {
    map: Map;
    loaded: boolean;
    currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {
}

function addTriangulationLayer(map: Map) {
    map.addLayer({
        id: "staticFocusedTriangulations",
        paint: {
            "circle-color": ["get", "color"],
            "circle-opacity": 1,
            "circle-radius": 12,
            "circle-stroke-color": "#fff",
            "circle-stroke-width": 1,
        },
        source: "staticFocusedTriangulations",
        type: "circle",
    });
}

function addTriangulationSource(
    gunshots: Gunshot[],
    timeOfLatestGunshot: Date,
    rangeEnd: number | undefined,
    map: Map
) {
    
    map.addSource("staticFocusedTriangulations", {
        data: triangulationPoints(gunshots, timeOfLatestGunshot, rangeEnd),
        type: "geojson",
    });
}

function updateTriangulationSource(
    gunshots: Gunshot[],
    timeOfLatestGunshot: Date,
    rangeEnd: number | undefined,
    map: Map,
) {
    map
        .getSource("staticFocusedTriangulations")
        // @ts-ignore
        .setData(triangulationPoints(gunshots, timeOfLatestGunshot, rangeEnd));
}

const FocusStaticTriangulationLayer = ({
                                           gunshot,
                                           timeOfLatestGunshot,
                                           map,
                                           loaded,
                                           relativeLatestTriangulation,
                                           twinsDisplayMode
                                       }: Props) => {
    if (map && loaded) {
        const relevantGunshots = gunshot ? filterRelevantGunshot({"focused": gunshot}, undefined, undefined, RelativeRange.ALL, twinsDisplayMode, []) : [];
        if (!map.getSource("staticFocusedTriangulations")) {
            addTriangulationSource(relevantGunshots, timeOfLatestGunshot, relativeLatestTriangulation, map);
            addTriangulationLayer(map);
            orderLayers(map);
        } else {
            updateTriangulationSource(relevantGunshots, timeOfLatestGunshot, relativeLatestTriangulation, map);
        }
    }

    return null;
};

const mapStateToProps = (state: ApplicationState) => {
    const focused = state.commander.focused;

    if (!focused || focused.type !== FocusType.Shots || !focused.id) {
        return {
            relativeLatestTriangulation: state.triangulation.relativeEndDate,
            timeOfLatestGunshot: state.triangulation.timeOfLatestGunshot,
            gunshot: undefined,
            twinsDisplayMode: state.triangulation.twinToDisplay,
        };
    }

    const focusedTriangulation = state.triangulation.gunshots[focused.id];

    return {
        relativeLatestTriangulation: state.triangulation.relativeEndDate,
        timeOfLatestGunshot: state.triangulation.timeOfLatestGunshot,
        gunshot: focusedTriangulation ? focusedTriangulation : undefined,
        twinsDisplayMode: state.triangulation.twinToDisplay,
    };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(
    FocusStaticTriangulationLayer
);
