import * as React from "react";

import DemoLogo from "../../components/logo/DemoLogo";
import styles from "./style.module.css";

export enum NoAccessReason {
    NoTokens = "no tokens"
}

class NoAccessPage extends React.PureComponent<{reason?: NoAccessReason}, {}> {
    public render() {
      const reason = this.props.reason && this.props.reason === NoAccessReason.NoTokens ? "No access - please navigate from planning mode" : "No access"
    return (
      <div className={styles.container}>
        <div>
          <DemoLogo />
        </div>
        <h2 className={styles.errorDesc}>{reason}</h2>
      </div>
    );
  }
}

export default NoAccessPage;
