import * as React from "react";
import { ScoutStatus } from "../../models/scouts";
import { cssColorFromBasedOnPercentage } from "../../util/util";
import { BatteryIcon } from "../svg/BatteryIcon";
import { ChargingBatteryIcon } from "../svg/ChargingBatteryIcon";
import style from "./style.module.scss";

const ScoutBattery = ({ status }: { status: ScoutStatus }) => {
  const color = cssColorFromBasedOnPercentage(status.batteryPercent);

  return (
    <div>
      <h3 className={style.battery} style={{ color }}>
        {status.batteryPercent}%
        <span className={style.icon}>
          {status.usbCharge || status.acCharge ? (
            <ChargingBatteryIcon color={color} />
          ) : (
            <BatteryIcon color={color} />
          )}
        </span>
      </h3>
    </div>
  );
};

export default ScoutBattery;
