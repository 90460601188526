import firebase from "firebase/compat/app";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import CoverageSummary from "../../components/summary/CoverageSummary";
import Scouts from "../../components/summary/Scouts";
import Shots from "../../components/summary/Shots";
import Status from "../../components/summary/Status";
import { Focus, FocusType, TwinDisplayMode } from "../../models/map";
import { Scout, ScoutState } from "../../models/scouts";
import { Gunshot } from "../../models/gunshots";
import { StatusMessage } from "../../models/warning";
import { getFocusType } from "../../util/util";
import { setUserAction } from "../authentication/authenticationReducer";
import {
  setFocusAction,
  toggleCoverageAction,
} from "../commander/commanderReducer";
import { setScoutFocusAction } from "../map/mapReducer";
import { markNewShotsAsSeen } from "../triangulations/triangulationReducer";
import styles from "./newstyle.module.scss";
import { RelativeRange } from "../../components/range/rangeUtil";
import { ApplicationState } from "../../reducers";
import { CoverageData } from "../../util/coverage";
import { Units } from "../../models/organisation";
import ZoneSummary from "../../components/summary/GeofenceSummary";
import { Geofence } from "../../models/geofence";


const NewSummary = ({
  setFocus,
  scouts,
  newShots,
  markShotsAsSeen,
  gunshots,
  focused,
  coverage,
  status,
  toggleCoverage,
  rangeEnd,
  rangeStart,
  relativeRange,
  unit,
  geofences,
  areaFilter,
  twinsDisplayMode
}: AllProps) => (
  <div id={"summary"} className={[styles.content].join(" ")}>
    <div className={[styles.summary].join(" ")}>
      <Status
        inFocus={getFocusType(focused) === FocusType.Status}
        setFocus={setFocus}
        status={status}
      />
      {geofences.length === 0 ? (

        <CoverageSummary
        inFocus={getFocusType(focused) === FocusType.Coverage}
        setFocus={setFocus}
        coverages={coverage}
        toggleCoverage={toggleCoverage}
        unit={unit}
        />
        ) : (

          <ZoneSummary
          inFocus={getFocusType(focused) === FocusType.Coverage}
          setFocus={setFocus}
          coverages={coverage}
          toggleCoverage={toggleCoverage}
          unit={unit}
          geofences={geofences}
          />
          )
        }
      <Shots
        inFocus={getFocusType(focused) === FocusType.Shots}
        setFocus={setFocus}
        newShots={newShots}
        markShotsAsSeen={markShotsAsSeen}
        gunshots={gunshots}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
        relativeRange={relativeRange}
        areaFilter={areaFilter}
        twinsDisplayMode={twinsDisplayMode}
      />
      <Scouts
        inFocus={getFocusType(focused) === FocusType.Scouts}
        setFocus={setFocus}
        scouts={scouts}
      />
    </div>
  </div>
);

interface AllProps extends DispatchFromProps, StateToProps {}

interface StateToProps {
  newShots: Gunshot[];
  coverage: CoverageData | undefined;
  scouts: Scout[];
  focused: Focus | undefined;
  gunshots: { [id: string]: Gunshot };
  status: { [id: string]: StatusMessage };
  rangeStart: number | undefined;
  rangeEnd: number | undefined;
  relativeRange: RelativeRange;
  unit: Units;
  geofences: Geofence[];
  areaFilter: Geofence[];
  twinsDisplayMode: TwinDisplayMode;
}

interface DispatchFromProps {
  setScoutFocus: (_: Scout | undefined) => void;
  setUser: (_: firebase.User | undefined) => void;
  setFocus: (_: Focus | undefined) => void;
  markShotsAsSeen: () => void;
  toggleCoverage: () => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    coverage: state.scout.coverages,
    focused: state.commander.focused,
    newShots: state.triangulation.newShots,
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
    relativeRange: state.triangulation.relativeRange,
    scouts: state.scout.list.filter(
      (s) => s.state !== ScoutState.OfflineIgnore
    ),
    status: state.status.list,
    gunshots: state.triangulation.gunshots,
    unit: state.authentication.userData?.organisation?.units || Units.Metric,
    geofences: state.geofence.list,
    areaFilter: state.areaFilter.list,
    twinsDisplayMode: state.triangulation.twinToDisplay
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      markShotsAsSeen: markNewShotsAsSeen,
      setFocus: setFocusAction,
      setScoutFocus: setScoutFocusAction,
      setUser: setUserAction,
      toggleCoverage: toggleCoverageAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(NewSummary);
