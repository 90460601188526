import axios from "axios";
import { LngLat } from "mapbox-gl";

interface Location {
  display_name: string;
  name_suffix: string;
}

interface LocationResponse {
  count: number;
  results: Location[];
}

export const getLocationFor = (
  lngLat: LngLat,
  callback: (_: string) => void
) => {
  axios
    .get(
      `https://geocoder.tilehosting.com/q/${lngLat.lng},${
        lngLat.lat
      }.js?key=7NBCsLfJrAt6F93vbbFV`
    )
    .then(response => {
      // tslint:disable-next-line: no-empty
      if (response.status === 200) {
        const mappedResponse = response.data as LocationResponse;
        // tslint:disable-next-line: no-empty
        if (mappedResponse.count > 0) {
          callback(mappedResponse.results[0].display_name);
        }
      }
    });
};
