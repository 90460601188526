import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { Feature, FeatureState } from "../../models/featureSwitch";
import { UserData } from "../../models/user";
import { ApplicationState } from "../../reducers";

type Props = DispatchFromProps & OwnProps & StateToProps;

/**
 * HOC for feature switching. If feature not found in feature switch, it'll
 * default to PUBLIC
 */
const FeatureSwitch = ({
  userData,
  children,
  featureSwitch,
  feature,
}: Props) => {
  const roleForFeature = featureSwitch[feature] || FeatureState.Public;
  if (roleForFeature === FeatureState.Public) {
    return children;
  } else if (
    roleForFeature === FeatureState.Admin &&
    process.env.REACT_APP_ADMIN
  ) {
    return children;
  } else if (
    roleForFeature === FeatureState.Sales &&
    ((userData && userData.organisation && userData.organisation.sales) || process.env.REACT_APP_ADMIN)
  ) {
    return children;
  } else {
    return <></>;
  }
};

interface DispatchFromProps {}

interface OwnProps {
  children: JSX.Element;
  feature: Feature;
}

interface StateToProps {
  featureSwitch: { [id: string]: FeatureState };
  userData?: UserData;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    featureSwitch: state.featureSwitch.features,
    userData: state.authentication.userData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<
  StateToProps,
  DispatchFromProps,
  OwnProps,
  ApplicationState
>(
  mapStateToProps,
  mapDispatchToProps
)(FeatureSwitch);
