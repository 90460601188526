import { Action, Reducer } from "redux";
import { ThunkResult } from "../../reducers";

const TOGGLE_LOCAL_LOCATION = "TOGGLE_LOCAL_LOCATION";

export const toggleLocalLocationAction = (): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: TOGGLE_LOCAL_LOCATION});
    };
};

const SET_LOCAL_LOCATION = "SET_LOCAL_LOCATION";

export const setLocalLocationAction = (localLocation?: LocalLocation): ThunkResult<void> => {
    return dispatch => {
        dispatch({type: SET_LOCAL_LOCATION, localLocation});
    };
};


export interface LocationState {
    showLocalLocation: boolean;
    localLocation?: LocalLocation;
}

export interface LocalLocation {
    lat: number;
    lng: number;
}

const initialState: LocationState = {
    showLocalLocation: false,
};

const reducer: Reducer<LocationState> = (
    state: LocationState = initialState,
    action
) => {
    switch ((action as Action).type) {
        case TOGGLE_LOCAL_LOCATION: {
            return {...state, showLocalLocation: !state.showLocalLocation};
        }
        case SET_LOCAL_LOCATION: {
            return {...state, localLocation: action.localLocation};
        }
        default:
            return state;
    }
};

export default reducer;
