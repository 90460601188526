import { Action, combineReducers, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import adminReducer, { AdminState } from "./admin/container/adminReducer";
import authenticationReducer, {
  AuthenticationState,
} from "./containers/authentication/authenticationReducer";
import commanderReducer, {
  CommanderState,
} from "./containers/commander/commanderReducer";
import locationReducer, {
  LocationState,
} from "./containers/location/locationReducer";
import mapReducer, { MapState } from "./containers/map/mapReducer";
import planningReducer, {
  PlanningState,
} from "./containers/planning/planningReducer";
import scoutReducer, {
  ScoutReducerState,
} from "./containers/scout/scoutReducer";
import statusReducer, {
  StatusReducerState,
} from "./containers/status/statusReducer";
import featureReducer, {
  FeatureSwitchState,
} from "./containers/feature/featureReducer";
import triangulationReducer, {
  GunshotState,
} from "./containers/triangulations/triangulationReducer";
import demoReducer, {
  DemoState,
} from "./containers/demo/demoReducer";
import geofenceReducer, { GeofenceReducerState } from "./containers/geofence/geofenceReducer";
import areaFilterReducer, { AreaFilterReducerState } from "./containers/map/areaFilterReducer";

export const CLEAR_DATA = "CLEAR_DATA";

export const clearDataAction = (): ThunkResult<void> => {
  return dispatch => {
    dispatch({ type: CLEAR_DATA });
  };
};

export interface ApplicationState {
  admin: AdminState;
  authentication: AuthenticationState;
  scout: ScoutReducerState;
  map: MapState;
  commander: CommanderState;
  triangulation: GunshotState;
  status: StatusReducerState;
  planning: PlanningState;
  location: LocationState;
  featureSwitch: FeatureSwitchState;
  demo: DemoState;
  geofence: GeofenceReducerState;
  areaFilter: AreaFilterReducerState;
}

export type ThunkResult<R> = ThunkAction<
  R,
  ApplicationState,
  undefined,
  Action
>;

const combine: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  admin: adminReducer,
  authentication: authenticationReducer,
  commander: commanderReducer,
  location: locationReducer,
  map: mapReducer,
  planning: planningReducer,
  scout: scoutReducer,
  status: statusReducer,
  triangulation: triangulationReducer,
  featureSwitch: featureReducer,
  demo: demoReducer,
  geofence: geofenceReducer,
  areaFilter: areaFilterReducer
});

export default combine;
