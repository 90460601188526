import { Action, Reducer } from "redux";

import { Geofence } from "../../models/geofence";
import { ThunkResult } from "../../reducers";

const CREATE_NEW_AREA_FILTER = "CREATE_NEW_AREA_FILTER";
const SHOW_AREA_FILTER = "SHOW_AREA_FILTER";
const ADD_OR_UPDATE_AREA_FILTER = "ADD_OR_UPDATE_AREA_FILTER";
const DELETE_AREA_FILTER = "DELETE_AREA_FILTER";
const SELECTED_AREA_FILTER = "SELECTED_AREA_FILTER";

export interface AreaFilterReducerState {
  createNew: boolean;
  exclude: boolean;
  show: boolean;
  list: Geofence[];
  selected: string | undefined;
}

export const areaFilterInitialState: AreaFilterReducerState = {
  createNew: false,
  show: false,
  exclude: false,
  list: [],
  selected: undefined,
};

export const addOrUpdateAreaFilterAction = (
  areaFilter: Geofence
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: ADD_OR_UPDATE_AREA_FILTER, areaFilter });
  };
};

export const deleteAreaFilterAction = (areaFilterId: string): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: DELETE_AREA_FILTER, id: areaFilterId });
  };
};

export const showAreaFilterAction = (show: boolean): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: SHOW_AREA_FILTER, show });
  };
};

export const createAreaFilterAction = (exclude: boolean): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_AREA_FILTER, exclude });
  };
};

export const selectedAreaFilterAction = (
  selected: string | undefined
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: SELECTED_AREA_FILTER, selected });
  };
};

const reducer: Reducer<AreaFilterReducerState> = (
  state: AreaFilterReducerState = areaFilterInitialState,
  action
) => {
  switch ((action as Action).type) {
    case SHOW_AREA_FILTER: {
      return { ...state, show: action.show };
    }
    case CREATE_NEW_AREA_FILTER: {
      return { ...state, createNew: true, exclude: action.exclude };
    }
    case ADD_OR_UPDATE_AREA_FILTER: {
      if (state.list.find((s) => s.id === action.areaFilter.id)) {
        return {
          ...state,
          list: state.list.map((s) => {
            if (s.id === action.areaFilter.id) {
              return action.areaFilter;
            } else {
              return s;
            }
          }),
          createNew: false,
        };
      } else {
        return {
          ...state,
          list: [...state.list, action.areaFilter],
          createNew: false,
        };
      }
    }
    case DELETE_AREA_FILTER: {
      return {
        ...state,
        list: state.list.filter((s) => s.id !== action.id),
        selected: undefined,
      };
    }
    case SELECTED_AREA_FILTER: {
      return { ...state, selected: action.selected };
    }
    default:
      return state;
  }
};

export default reducer;
