import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { FocusType, TwinDisplayMode } from "../../../models/map";
import { MapViewType } from "../../../models/MapViewType";
import { Gunshot, GunshotPositionFeature } from "../../../models/gunshots";
import { ApplicationState } from "../../../reducers";
import { coordLengthBearingToCoord, filterLocationsIfNeeded } from "../../../util/mapUtil";

export interface MapboxProps {
  gunshot: Gunshot | undefined;
  twinsDisplayMode: TwinDisplayMode;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

function addLayer(map: Map) {
  map.addLayer({
    id: "gunshot-direction",
    paint: {
      "line-color": ["get", "color"],
      // "line-dasharray": [4, 1],
      "line-width": 5,
      "line-opacity": 0.3
      
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    source: "gunshot-direction-lines",
    type: "line",
  });

 

  map.addLayer({
    id: "gunshot-shadow",
    paint: {
      "line-color": "#000000",
      "line-width": 20,
      "line-blur": 20,
      "line-opacity": 0.2,
    },
    layout: {
      "line-join": "bevel",
      "line-cap": "round",
    },
    source: "gunshot-direction-lines",
    type: "line",
  });

  map.addLayer({
    id: "gunshot-fill",
    paint: {
      "fill-color": ["get", "color"],
      "fill-opacity": 0.2,
    },
    layout: {},
    source: "gunshot-direction-lines",
    type: "fill",
  });
}

function addSource(map: Map, gunshot: Gunshot | undefined, twinsToDisplay: TwinDisplayMode) {
  map.addSource("gunshot-direction-lines", {
    data: lines(gunshot, twinsToDisplay),
    type: "geojson",
  });
}

export const lines = (
  gunshot: Gunshot | undefined,
  twinDisplayMode: TwinDisplayMode
): GeoJSON.FeatureCollection => {
  return {
    features: gunshot ? createFeatures(filterLocationsIfNeeded(gunshot, twinDisplayMode)) : [],
    type: "FeatureCollection",
  };
};

const createFeatures = (
  features: GunshotPositionFeature[]
): GeoJSON.Feature[] => {
  return features
    .map((f) => {
      // In KM
      const MAX_DIRECTION_LENGTH = 0.1;

      return f.properties.direction.sectors.map((sector) => {
        const lengthOfThisSector = MAX_DIRECTION_LENGTH * Math.pow(sector.score, 6);
        const coord1 = coordLengthBearingToCoord(
          f.properties.representativePoint,
          sector.center + sector.size / 2,
          lengthOfThisSector
        );
        const coord2 = coordLengthBearingToCoord(
          f.properties.representativePoint,
          sector.center - sector.size / 2,
          lengthOfThisSector
        );


        return {
          properties: {
            color: sector.score === 1 ? "#FCFF00" : "orange",
          },
          geometry: {
            coordinates: [
              [
                [
                  f.properties.representativePoint.longitude,
                  f.properties.representativePoint.latitude,
                ],
                [coord1.lon, coord1.lat],
                [coord2.lon, coord2.lat],
                [
                  f.properties.representativePoint.longitude,
                  f.properties.representativePoint.latitude,
                ],
              ],
            ],
            type: "Polygon",
          },
        } as any;
      });
    })
    .flat();
};

function updateSource(map: Map, gunshot: Gunshot | undefined, twinsToDisplay: TwinDisplayMode) {
  const geoJson = lines(gunshot, twinsToDisplay);
  map
    .getSource("gunshot-direction-lines")
    // @ts-ignore
    .setData(geoJson);
}

const GunshotDirectionlayer = ({ map, loaded, gunshot, twinsDisplayMode }: Props) => {
  if (map && loaded) {
    if (!map.getSource("gunshot-direction-lines")) {
      addSource(map, gunshot, twinsDisplayMode);
      addLayer(map);
    } else {
      updateSource(map, gunshot, twinsDisplayMode);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const focused = state.commander.focused;

  if (!focused || focused.type !== FocusType.Shots || !focused.id) {
    return {
      gunshot: undefined,
      twinsDisplayMode: state.triangulation.twinToDisplay,
    };
  }

  const gunshot = state.triangulation.gunshots[focused.id]!;
  return {
    gunshot: gunshot,
    twinsDisplayMode: state.triangulation.twinToDisplay,
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  GunshotDirectionlayer
);
