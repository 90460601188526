import * as React from "react";
import styles from "./style.module.css";

const ProgressDots = ({color}: { color?: string }) => {

    const style = {backgroundColor: color ? color : "#fff"};
    return (
        <div>
            <div key={"first-loading-dot"} style={style} className={[styles.dot].join(" ")}/>
            <div key={"second-loading-dot"} style={style} className={[styles.dot, styles.first].join(" ")}/>
            <div key={"third-loading-dot"} style={style} className={[styles.dot, styles.second].join(" ")}/>
        </div>
    );
};

export default ProgressDots;
