import * as React from "react";
import OrganisationName from "../../containers/loading/OrganisationName";
import  CommanderLogo from "../logo/CommanderLogo";
import styles from "./style.module.css";

class Loading extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <div id={"loading_screen"} className={styles.centerContent}>
        <div className={styles.logoWrapper}>
        <CommanderLogo />
        </div>
        <OrganisationName />
      </div>
    );
  }
}

export default Loading;
