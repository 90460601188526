import * as React from "react";

import { Geofence } from "../../models/geofence";
import planStyles from "../planning/style.module.scss";
import common from "../styles/style.module.scss";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../reducers";
import { getCircleArea, squareKmToSquareMi } from "../../util/util";
import { Units } from "../../models/organisation";
import Vr from "../../components/hr/Vr";
import { activateZone, deleteZone, muteZone } from "../../services/zoneService";

const GeofenceView = ({ geofence, unit, jwtToken }: Props) => {
  if (!geofence || jwtToken === undefined ) {
    return <></>;
  }
  let areaInKm = getCircleArea(geofence.radius/1000)
  let areaText = unit === Units.Metric ? `${areaInKm.toFixed(1)} km²` : `${squareKmToSquareMi(areaInKm).toFixed(1)} mi²`
  
  return (
    <div className={common.overlay}>
      <div
      style={{paddingBottom: "0"}}
        className={[
          common.overlayAnimation,
          common.planningOverview,
          common.oneItemPadding,
        ].join(" ")}
      >
        <p
          className={[planStyles.summaryItemTitle, common.flexCenter].join(" ")}
        >
          Zone - {areaText}
        </p>
        {geofence.listening ? (

          <p className={[common.secondary, common.flexCenter].join(" ")}>
          Selected zone is a listening area. This means all scouts in this area will be actively listening for gunshots.
        </p>
          ) : (
            <p className={[common.secondary, common.flexCenter].join(" ")}>
          Selected zone is not active. This means neither scouts outside the zone or scouts inside the zone will listen for gunshots.
        </p>
          )}
        <div className={common.flexSpaceBetween}>
          {

            geofence.listening ? 
            (<div onClick={() => muteZone(geofence.id, jwtToken, () => {}, () => {})} className={common.option}>MUTE</div>):
            (<div onClick={() => activateZone(geofence.id, jwtToken, () => {}, () => {})} className={common.option}>ACTIVATE</div>)
          }
          <Vr/>
          <div onClick={() => deleteZone(geofence.id, jwtToken, () => {}, () => {})} className={common.option}>DELETE</div>
        </div>
      </div>
    </div>
  );
};

interface Props extends DispatchFromProps, StateToProps {}

interface StateToProps {
    geofence: Geofence | undefined;
    unit: Units;
    jwtToken: string | undefined;
}

interface DispatchFromProps {
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    geofence: state.geofence.selected ? state.geofence.list.find(g => g.id === state.geofence.selected) : undefined,
    unit: state.authentication.userData?.organisation?.units || Units.Metric,
    jwtToken: state.commander.jwtToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceView);
