import * as mapbox from "mapbox-gl";
import { Map, ScaleControl } from "mapbox-gl";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { store } from "../../ConfiguredApp";
import {
  mapInFocus,
  resetMapAngleAction,
  setFocusAction,
} from "../../containers/commander/commanderReducer";
import { addDemoEvents, addSimulationEvents } from "../../containers/demo/demoPlayer";
import { applyDemo } from "../../containers/demo/demoService";
import {
  clearEventsAction,
  setMapInitStateAction,
} from "../../containers/map/mapReducer";
import { EventType, Focus, FocusType, MapEvent } from "../../models/map";
import { MapViewType } from "../../models/MapViewType";
import Mapbox from "./Mapbox";
import {
  getMapStyling,
  mapOnClick,
  setDefaultMap,
} from "./mapUtil";

import {
  scoutOnPress,
} from "./dragAndDropUtil";
import { ApplicationState } from "../../reducers";
import { geofenceOnPress } from "./geofenceMapUtil";
import { selectedGeofenceAction } from "../../containers/geofence/geofenceReducer";

export interface MainMapState {
  loaded: boolean;
  currentMapType: MapViewType;
  movingEventInProgress: boolean;
  height: number;
  width: number;
}

type Props = MainMapProps & DispatchFromProps;

interface MainMapProps {
  autoFocus: boolean;
  mapType: MapViewType;
  events: MapEvent[];
  jwtToken: string | undefined;
  dragScoutsMode: boolean;
}

export let map: Map;

class MainMap extends React.Component<Props, MainMapState> {
  private myRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      currentMapType: MapViewType.ROADMAP,
      height: window.innerHeight,
      loaded: false,
      movingEventInProgress: false,
      width: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensionsOnOrientationChange =
      this.updateWindowDimensionsOnOrientationChange.bind(this);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    //@ts-ignore
    window.removeEventListener(
      "orientationchange",
      this.updateWindowDimensionsOnOrientationChange
    );
  }

  public updateWindowDimensions() {
    this.setState((state) => ({
      ...state,
      height: window.innerHeight,
      width: window.innerWidth,
    }));
  }

  public updateWindowDimensionsOnOrientationChange() {
    setTimeout(() => {
      this.setState((state) => ({
        ...state,
        height: window.innerHeight,
        width: window.innerWidth,
      }));
      map.getCanvas().style.width = `${window.innerWidth}px`;
      map.getCanvas().style.height = `${window.innerHeight}px`;
      setTimeout(() => {
        map.resize();
      }, 300);
    }, 200);
  }

  public componentDidMount() {
    const initMapState = store.getState().map.mapInitState;
    const initialCenterAndZoom = setDefaultMap(initMapState);

    if (!initMapState) {
      store.dispatch(mapInFocus(true));
    }

    setTimeout(() => store.dispatch(setMapInitStateAction(undefined)), 2000);

    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener(
      "orientationchange",
      //@ts-ignore
      this.updateWindowDimensionsOnOrientationChange
    );

    // @ts-ignore
    mapbox.accessToken =
      "pk.eyJ1IjoibWFyaXVzaGVycmluZyIsImEiOiJjanI2YnpvaWMyNnBhNDRxcWg4d3g4bmo3In0.TmJdk1oZ0ceEFx1ih0-1Og";
    if (this.myRef.current) {
      const mapConfig = {
        container: this.myRef.current,
        style: getMapStyling(this.props.mapType),
      };

      if (initialCenterAndZoom) {
        mapConfig["center"] = [
          initialCenterAndZoom.lng,
          initialCenterAndZoom.lat,
        ];
        mapConfig["zoom"] = initialCenterAndZoom.zoom;
      }

      map = new Map(mapConfig);

      const scale = new ScaleControl({
        maxWidth: 400,
        unit: "metric",
      });
      map.addControl(scale);

      map.on("load", () => {
        this.setState({ loaded: true });

        map.on("click", (e) => {
          const features = map.queryRenderedFeatures(
            [
              [e.point.x, e.point.y],
              [e.point.x, e.point.y],
            ],
            {
              layers: [
                "gunshot",
                "gunshot-circle",
                "listening_scouts",
                "scouts",
              ],
            }
          );
          if (!features.length) {
            this.props.setFocus(undefined, false);
          }
        });

        if (applyDemo(store.getState().authentication.userData)) {
          addDemoEvents(map)
        }

        if (process.env.REACT_APP_DEMO) {
          addSimulationEvents(map)
        }
        

        mapOnClick(map, "scouts", (id) =>
          this.props.setFocus({ type: FocusType.Scouts, id }, false)
        );
        mapOnClick(map, "geofence", (id) => {
          this.props.setSelectedGeofence(id !== undefined ? `${id}`: undefined)
        }
        );
        mapOnClick(map, "gunshot", (id) =>
          this.props.setFocus({ type: FocusType.Shots, id }, true)
        );
        mapOnClick(map, "gunshot-circle", (id) =>
          this.props.setFocus({ type: FocusType.Shots, id }, true)
        );
        mapOnClick(map, "listening_scouts", (id) => {
          this.props.setFocus({ type: FocusType.Scouts, id }, false);
        });
      });

      map.on("dragstart", () => {
        this.props.disableAutomaticFocus();
      });

      map.on("zoom", () => {
        if (this.props.autoFocus) {
          this.props.disableAutomaticFocus();
        }
      });

      map.on("rotatestart", () => {
        if (!this.state.movingEventInProgress) {
          this.props.resetMapAngleCallback(false);
        }
      });

      map.on("rotateend", () => {
        if (this.state.movingEventInProgress) {
          this.props.resetMapAngleCallback(true);
          this.setState((prevState) => ({
            ...prevState,
            movingEventInProgress: false,
          }));
        }
      });
    }
  }

  public render() {
    const width = window.innerWidth;
    const style = {
      bottom: "0",
      height:
        width > 600
          ? `calc(${window.innerHeight}px - 60px)`
          : `calc(${window.innerHeight}px - 60px)`,
      width: this.state.width,
    };

    const { autoFocus, mapType, events, clearEvents } = this.props;
    const { loaded, currentMapType } = this.state;

    if (map && loaded) {
      scoutOnPress(map, this.props.jwtToken, this.props.dragScoutsMode);
        
      
      
      if (events.length > 0) {
        events.forEach((e) => {
          if (
            e.type === EventType.ROTATE &&
            !this.state.movingEventInProgress
          ) {
            this.setState((prevState) => ({
              ...prevState,
              movingEventInProgress: true,
            }));
          }
          e.toExecute(map);
        });
        clearEvents();
      }

      if (mapType !== currentMapType) {
        // removeLayersAndSources(map);

        map.setStyle(getMapStyling(mapType));
        setTimeout(() => {
          this.setState((prevState) => ({
            ...prevState,
            currentMapType: mapType,
          }));
        }, 1000);
      }
    }

    return (
      <Mapbox
        map={map}
        styling={style}
        mapRef={this.myRef}
        focused={undefined}
        loaded={loaded}
        autoFocus={autoFocus}
        currentMapType={this.state.currentMapType}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    autoFocus: state.commander.mapInFocus,
    dragScoutsMode: state.commander.dragScoutsMode,
    events: state.map.events,
    jwtToken: state.commander.jwtToken,
    mapType: state.map.mapInitState
      ? state.map.mapInitState.mapType
      : state.commander.mapType,
  };
};

interface DispatchFromProps {
  disableAutomaticFocus: () => void;
  resetMapAngleCallback: (_: boolean) => void;
  setFocus: (_: Focus | undefined, clickedInMap: boolean) => void;
  setSelectedGeofence: (_: string | undefined) => void;
  clearEvents: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearEvents: clearEventsAction,
      disableAutomaticFocus: () => mapInFocus(false),
      resetMapAngleCallback: resetMapAngleAction,
      setFocus: setFocusAction,
      setSelectedGeofence: selectedGeofenceAction
    },
    dispatch
  );

export default connect<MainMapProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(MainMap);
