import * as React from "react";
import { connect } from "react-redux";
import BigLabel from "../../components/label/BigLabel";
import WideDropdownOverlay from "../../components/overlay/WideDropdownOverlay";
import { Focus, FocusType } from "../../models/map";
import { Status, StatusMessage } from "../../models/warning";
import { ApplicationState } from "../../reducers";
import { getColorForStatus, getFocusType } from "../../util/util";
import styles from "./style.module.scss";

const WarningComponent = ({ warning }: { warning: StatusMessage }) => (
  <div
    key="messageAndLabel"
    style={{ borderLeft: `5px solid ${getColorForStatus(warning.status)}` }}
    className={styles.warning}
  >
    <div className={styles.statusListItemStatus}><p style={{ color: getColorForStatus(warning.status) }}>{warning.status}</p></div>
    
    <p key={"message"} className={styles.message}>
      {warning.message}
    </p>
    <BigLabel key={"label"} message={warning.component} />
  </div>
);

const WarningsOverlay = ({ focused, warnings }: Props) => {
  const showList = getFocusType(focused) === FocusType.Status;

  const flattenWarnings = Object.keys(warnings).map((w) => warnings[w]);
  const Warnings = flattenWarnings.filter((t) => t.status !== Status.Ok && t.status !== Status.Up).map((warning, index) => (
    <WarningComponent key={"warningItem" + index} warning={warning} />
  ));

  return (
    <div className={[styles.warnings, styles.warningsoverlay].join(" ")}>
      <WideDropdownOverlay open={showList}>
        <div className={styles.warningList}>
          {Warnings}
        </div>
      </WideDropdownOverlay>
    </div>
  );
};

interface Props {
  focused: Focus | undefined;
  warnings: { [id: string]: StatusMessage };
}

const mapStateToProps = (state: ApplicationState) => {
  return {
  focused: state.commander.focused,
  warnings: state.status.list,
}};

export default connect<Props, {}, {}, ApplicationState>(mapStateToProps, {})(WarningsOverlay);
