import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { FocusType, TwinDisplayMode } from "../../../models/map";
import { MapViewType } from "../../../models/MapViewType";
import { Gunshot } from "../../../models/gunshots";
import { orderLayers } from "../layerUtils";
import { distancePoints } from "../mapUtil";
import { ApplicationState } from "../../../reducers";
import { Units } from "../../../models/organisation";

export interface MapboxProps {
  gunshot: Gunshot | undefined;
  bearingInMils: boolean;
  twinsDisplayMode: TwinDisplayMode;
  unit: Units;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

function addDistanceLayer(map: Map) {
  map.addLayer({
    id: "distance",
    interactive: false,
    layout: {
      "icon-allow-overlap": true,
      "text-field": ["get", "distance"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 12,
      
    },
    minzoom: 12,
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "distance",
    type: "symbol",
  });
}

function addDistanceSource(
  gunshot:Gunshot | undefined,
  map: Map,
  bearingInMils: boolean,
  twinsDisplayMode: TwinDisplayMode,
  unit: Units
) {
  map.addSource("distance", {
    data: distancePoints(gunshot, bearingInMils, twinsDisplayMode, unit),
    type: "geojson",
  });
}

function updateDistanceSource(
  gunshot: Gunshot | undefined,
  map: Map,
  bearingInMils: boolean,
  twinsDisplayMode: TwinDisplayMode,
  unit: Units
) {
  const d = distancePoints(gunshot, bearingInMils, twinsDisplayMode, unit);
  map
    .getSource("distance")
    // @ts-ignore
    .setData(d);
}

const DistanceLayer = ({
  gunshot,
  map,
  loaded,
  bearingInMils,
  twinsDisplayMode,
  unit
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("distance")) {
      addDistanceSource(gunshot, map, bearingInMils, twinsDisplayMode, unit);
      addDistanceLayer(map);
      orderLayers(map);
    } else {
      updateDistanceSource(gunshot, map, bearingInMils, twinsDisplayMode, unit);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const focused = state.commander.focused;

  if (!focused || focused.type !== FocusType.Shots || !focused.id) {
    return {
      bearingInMils: state.commander.bearingInMils,
      gunshot: undefined,
      twinsDisplayMode: state.triangulation.twinToDisplay,
      unit: state.authentication.userData?.organisation?.units || Units.Metric
    };
  }

  const focusedGunshot = state.triangulation.gunshots[focused.id];


  return {
    bearingInMils: state.commander.bearingInMils,
    gunshot: focusedGunshot,
    twinsDisplayMode: state.triangulation.twinToDisplay,
    unit: state.authentication.userData?.organisation?.units || Units.Metric
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(DistanceLayer);
