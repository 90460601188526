// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import { connect } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import { store } from "./ConfiguredApp";
import {
  setOrganisationAction,
  setUserAction,
  setUserDataAction,
} from "./containers/authentication/authenticationReducer";
import {
  setDemoModeAction,
} from "./containers/feature/featureReducer";
import { Organisation } from "./models/organisation";
import { UserData } from "./models/user";
import { StatusMessage, statusMessageIsCritical } from "./models/warning";
import { ApplicationState } from "./reducers";
import { listenForFeatureSwitchConfig } from "./topics/firebase/featureSwitch";
import { listenForDemoGunshots } from "./topics/firebase/gunshots";
import { listenForPlans } from "./topics/firebase/planning";
import { listenForDemoIncidents } from "./topics/firebase/potentialIncidents";
import { listenForDemoScouts } from "./topics/firebase/scouts";
import { listenForUserInfo } from "./topics/firebase/user";
import DemoPage from "./containers/demo/DemoPage";

const setupListeners = (userData: UserData | undefined) => {
  if (userData && userData.organisation) {
    const urlParams = new URLSearchParams(window.location.search);
    const planId = urlParams.get("id"); // TODO Handle case where no ID is created
    if (planId) {
      listenForDemoIncidents(userData, planId);
      // TODO Is this deprecated??
      //listenForDetections(userData);
      listenForPlans(userData);
      listenForDemoScouts(userData, planId);
      listenForDemoGunshots(userData, planId);
      listenForFeatureSwitchConfig(userData);
    }
  }
};

class DemoApp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      loadingUserData: true,
      unregisterAuthObserver: undefined,
    };
  }

  // Listen to the Firebase Auth state and set the local state.
  public componentDidMount() {
    store.dispatch(setDemoModeAction());
    this.setState({
      unregisterAuthObserver: firebase.auth().onAuthStateChanged((user) => {
        if (user != null) {
          this.props.setUser(user);
          listenForUserInfo(
            user,
            this.props.setUserData,
            this.props.setOrganisation,
            () =>
              this.setState((prevState) => ({
                ...prevState,
                loadingUserData: false,
              }))
          );
        }
        this.setState((prevState) => ({ ...prevState, loading: false }));
      }),
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  public componentWillUnmount() {
    if (this.state.unregisterAuthObserver) {
      this.state.unregisterAuthObserver();
    }
  }

  public render() {
    setupListeners(this.props.userData);
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/" component={DemoPage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

interface State {
  loading: boolean;
  loadingUserData: boolean;
  unregisterAuthObserver: firebase.Unsubscribe | undefined;
}

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
  notEnoughScouts: boolean;
  initialLoadingDone: boolean;
  minimalLoadingDone: boolean;
  criticalIssues: StatusMessage[];
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
  setOrganisation: (_: Organisation) => void;
}

type Props = StateToProps & DispatchFromProps;

const mapStateToProps = (state: ApplicationState) => {
  return {
    initialLoadingDone: state.commander.initialLoadingDone,
    jwtToken: state.commander.jwtToken,
    notEnoughScouts: state.scout.list.length < 3,
    minimalLoadingDone: Object.keys(state.triangulation.gunshots).length > 10,
    user: state.authentication.user,
    userData: state.authentication.userData,
    criticalIssues: Object.values(state.status.list).filter((x) =>
      statusMessageIsCritical(x as StatusMessage)
    ) as StatusMessage[],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOrganisation: setOrganisationAction,
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(DemoApp);
