import * as React from "react";
import styles from "../../containers/overview/style.module.scss";
import summaryStyles from "./style.module.scss";
import commonStyles from "../../containers/styles/style.module.scss";
import { Focus, FocusType } from "../../models/map";
import { Scout, ScoutState } from "../../models/scouts";
import SummaryItem from "./SummaryItem";

interface Props {
  inFocus: boolean;
  setFocus: (_: Focus | undefined) => void;
  scouts: Scout[];
}

const Scouts = ({ inFocus, setFocus, scouts }: Props) => {
  const setFocusOnClick = () => {
    setFocus({ type: FocusType.Scouts });
  };
  const activeScouts = scouts.filter(
    (scout) => scout.state === ScoutState.Listening
  );
  return (
    <SummaryItem
      focusType={FocusType.Scouts}
      inFocus={inFocus}
      setFocus={setFocusOnClick}
    >
      <div className={[styles.summaryText, commonStyles.centerText].join(" ")}>
        <p className={summaryStyles.summaryLabel}>Active scouts</p>
        <p id={"active_scouts"} className={summaryStyles.summaryValue}>
          {activeScouts.length}
        </p>
        <p className={styles.summaryDescription + " " + commonStyles.noMargin}>
          out of {scouts.length}
        </p>
      </div>
    </SummaryItem>
  );
};

export default Scouts;
