import { CoverageSettings } from "../models/coverage";
import { Focus, FocusType, MgrsMode } from "../models/map";
import { MinimalScout, Scout, ScoutState } from "../models/scouts";
import moment from "moment";
import { Status } from "../models/warning";

export const FOCUSED_BORDER_WIDTH = 3;

export interface MarkerDetails {
  text: string;
  background: string;
  borderWidth: number;
  borderColor: string;
  opacity: number;
}

export const cssColorFromBasedOnPercentage = (percent: number) => {
  const green = 120;
  const red = 0;
  const b = (green - red) * (percent / 100);
  const c = b + red;

  // Return a CSS HSL string
  return "hsl(" + c + ", 90%, 40%)";
};

export const upperFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const weaponTypeToPrettyText = (word: string) => {
  return (word.charAt(0).toUpperCase() + word.slice(1)).replace("_", " ");
}

export const squareKmToSquareMi = (squareKm: number): number => {
  return squareKm * 0.3861
}


export const getCircleArea = (radius: number): number => {
  const area = Math.PI * radius * radius;
  return area;
}

export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

// https://www.movable-type.co.uk/scripts/latlong-utm-mgrs.html
// https://en.wikipedia.org/wiki/Military_Grid_Reference_System
export const latLng2Mgrs = (lat: number, lng: number, mgrsMode: MgrsMode) => {
  if (lat < -80) {
    return "Too far South";
  }
  if (lat > 84) {
    return "Too far North";
  }
  const c = 1 + Math.floor((lng + 180) / 6);
  const e = c * 6 - 183;
  const k = (lat * Math.PI) / 180;
  const l = (lng * Math.PI) / 180;
  const m = (e * Math.PI) / 180;
  const n = Math.cos(k);
  const o = 0.006739496819936062 * Math.pow(n, 2);
  const p = 40680631590769 / (6356752.314 * Math.sqrt(1 + o));
  const q = Math.tan(k);
  const r = q * q;
  const t = l - m;
  const u = 1.0 - r + o;
  const v = 5.0 - r + 9 * o + 4.0 * (o * o);
  const w = 5.0 - 18.0 * r + r * r + 14.0 * o - 58.0 * r * o;
  const x = 61.0 - 58.0 * r + r * r + 270.0 * o - 330.0 * r * o;
  const y = 61.0 - 479.0 * r + 179.0 * (r * r) - r * r * r;
  const z = 1385.0 - 3111.0 * r + 543.0 * (r * r) - r * r * r;
  let aa: number | string =
    p * n * t +
    (p / 6.0) * Math.pow(n, 3) * u * Math.pow(t, 3) +
    (p / 120.0) * Math.pow(n, 5) * w * Math.pow(t, 5) +
    (p / 5040.0) * Math.pow(n, 7) * y * Math.pow(t, 7);
  let ab: number | string =
    6367449.14570093 *
      (k -
        0.00251882794504 * Math.sin(2 * k) +
        0.00000264354112 * Math.sin(4 * k) -
        0.00000000345262 * Math.sin(6 * k) +
        0.000000000004892 * Math.sin(8 * k)) +
    (q / 2.0) * p * Math.pow(n, 2) * Math.pow(t, 2) +
    (q / 24.0) * p * Math.pow(n, 4) * v * Math.pow(t, 4) +
    (q / 720.0) * p * Math.pow(n, 6) * x * Math.pow(t, 6) +
    (q / 40320.0) * p * Math.pow(n, 8) * z * Math.pow(t, 8);
  aa = aa * 0.9996 + 500000.0;
  ab = ab * 0.9996;

  if (ab < 0.0) {
    ab += 10000000.0;
  }

  const ad = "CDEFGHJKLMNPQRSTUVWXX".charAt(Math.floor(lat / 8 + 10));
  const ae = Math.floor(aa / 100000);
  const af = ["ABCDEFGH", "JKLMNPQR", "STUVWXYZ"][(c - 1) % 3].charAt(ae - 1);
  const ag = Math.floor(ab / 100000) % 20;
  const ah = ["ABCDEFGHJKLMNPQRSTUV", "FGHJKLMNPQRSTUVABCDE"][
    (c - 1) % 2
  ].charAt(ag);
  const pad = (val: number): string => {
    if (val < 10) {
      return "0000" + val;
    } else if (val < 100) {
      return "000" + val;
    } else if (val < 1000) {
      return "00" + val;
    } else if (val < 10000) {
      return "0" + val;
    } else {
      return val + "";
    }
  };
  aa = Math.floor(aa % 100000);
  const aaS = pad(aa);
  ab = Math.floor(ab % 100000);
  const abS = pad(ab);
  return (
    c +
    ad +
    " " +
    af +
    ah +
    " " +
    (mgrsMode === MgrsMode.ten_meter_accuracy
      ? aaS.substring(0, 4)
      : mgrsMode === MgrsMode.hundred_meter_accuracy
      ? aaS.substring(0, 3)
      : aaS) +
    " " +
    (mgrsMode === MgrsMode.ten_meter_accuracy
      ? abS.substring(0, 4)
      : mgrsMode === MgrsMode.hundred_meter_accuracy
      ? abS.substring(0, 3)
      : abS)
  );
};

export const getScoutStateColor = (state: ScoutState) => {
  switch (state) {
    case ScoutState.Offline || ScoutState.OfflineIgnore:
      return "#DADADA";
    case ScoutState.Initializing:
      return "#cc6618";
    case ScoutState.DownloadingDetector:
      return "#cc6618";
    case ScoutState.DetectionUnavailable:
      return "#cc6618";
    case ScoutState.InaccurateLocation:
      return "#cc6618";
      case ScoutState.Disabled:
      return "#0ea5e9";
    case ScoutState.Ready:
      return "rgb(0, 66, 255)"  ;
    case ScoutState.Listening:
      return "#26CD7A";
    default: 
      return "#DADADA";
  }
};

export const coverageSettingsMatrixMultiplier: number[] = [0.75, 1.5, 2];

export const getRangeFactor = (coverageSettings: CoverageSettings) => {
  return coverageSettingsMatrixMultiplier[coverageSettings.landscape - 1];
};

export const getPlanRangeFactor = (coverageSettings: CoverageSettings) => {
  return coverageSettingsMatrixMultiplier[coverageSettings.landscape - 1];
};

export const TUT_BIRTHDAY_MILLIS = 1500854400000;

export const tutToDate = (tut: number) =>
  new Date(tut / 1000 + TUT_BIRTHDAY_MILLIS);

export const nowToTut = () => {
  return (new Date().getTime() - TUT_BIRTHDAY_MILLIS) * 1000;
};

export const dateToTut = (date: Date) => {
  return (date.getTime() - TUT_BIRTHDAY_MILLIS) * 1000;
};


export const dateMillisToTut = (millis: number) => {
  return (millis - TUT_BIRTHDAY_MILLIS) * 1000;
};

export const prettyListDate = (date: Date) => {
  const diff = moment()
    .startOf("day")
    .diff(moment(date).startOf("day"), "days");
  if (diff === 0) {
    return "TODAY";
  } else if (diff === 1) {
    return "YESTERDAY";
  } else {
    return moment(date).format("LL");
  }
};

export const getDiffInMinutes = (date: Date, latest: Date) =>
  moment(latest)
    .startOf("minute")
    .diff(moment(date).startOf("minute"), "minute");

// Get a difference in milliseconds (at least one) of an earlier date and a later date
export const getPositiveDiffMs = (earlierMs: number) =>
  Math.max(1, Date.now() - earlierMs);

export const formatTime = (iso8601IncludingMicros: string) =>
  moment(iso8601IncludingMicros).format("HH:mm:ss");
export const formatTimeDate = (date: Date) => moment(date).format("HH:mm:ss");

export const prettyFormatTime = (iso8601IncludingMicros: string) =>
  moment(iso8601IncludingMicros)
    .startOf("second")
    .fromNow();

export const prettyTime = (date: Date) =>
  moment(date)
    .startOf("second")
    .fromNow();

export const tutToPrettyTimestamp = (tut: number) => {
  return moment(tutToDate(tut)).format(
    "YYYY-MM-DD HH:mm:ss"
  )
}

export const getDiffMsSinceLastSeen = (scout: MinimalScout) => {
  const tut = scout.status.receivedTut;
  const lastSeen = tut ? tutToDate(tut) : undefined;
  return lastSeen === undefined
    ? undefined
    : getPositiveDiffMs(lastSeen.getTime());
};

const getScoutBackgroundColor = (
  state: ScoutState,
  focused: boolean,
  unhandledGunshots: boolean
): string => {
  if (unhandledGunshots) {
    return "#f00"; // Flash red on gunshot
  }
  return getScoutStateColor(state);
};

export const okColor = "#28a745";
export const warnColor = "#F59E0B";
export const criticalColor = "#EF4444";

export const getColorForStatus = (status: Status): string => {
  return (status === Status.Critical || status === Status.Down) ?  criticalColor : status === Status.Warning ? warnColor : okColor;
}

export const getScoutStateDescription = (scout: Scout) => {
  switch (scout.state) {
    case ScoutState.Offline:
      return "Offline";
    case ScoutState.Ready:
      return "Muted";
    case ScoutState.Listening:
      return "Listening";
    case ScoutState.Initializing:
      return "Synchronizing";
    case ScoutState.InaccurateLocation:
      return "No location";
    case ScoutState.DownloadingDetector:
      return "Updating detector";
    case ScoutState.DetectionUnavailable:
        return "Detector unavailable";
    case ScoutState.Disabled:
        return `${scout.status.detector?.disabled?.metric} ${scout.status.detector?.disabled?.value}`;
    default:
      return "Unknown state";
  }
};

export const getChargingDescription = (scout: Scout) => {
  if (
    scout.status === undefined ||
    scout.status.usbCharge === undefined ||
    scout.status.acCharge === undefined
  ) {
    return "Charging unknown";
  }

  return scout.status.usbCharge || scout.status.acCharge
    ? "Charging"
    : "Not charging";
};

export const getFocusType = (
  focused: Focus | undefined
): FocusType | undefined => {
  return focused && focused.type;
};

export const getFocusElementId = (
  focused: Focus | undefined
): string | undefined => {
  return focused && focused.id;
};

export function getScoutMarkerDetails(
  scout: Scout,
  focused: boolean
): MarkerDetails {
  const unhandledPotentialGunshots = scout.unhandledPotentialGunshots > 0;
  return {
    background: getScoutBackgroundColor(
      scout.state,
      focused,
      unhandledPotentialGunshots
    ),
    borderColor: "#fff",
    borderWidth: focused ? FOCUSED_BORDER_WIDTH : 1,
    opacity: scout.state === ScoutState.Offline ? 0.5 : 1,
    text: "#fff",
  };
}

export const debugLog = (...params: any[]) => {
  process.env.NODE_ENV !== 'production' && console.log(...params, new Date().toISOString());
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};