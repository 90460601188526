import { PlanScout } from "../models/plan";
import { MinimalScoutStatus, Scout, ScoutLocationAndRange } from "../models/scouts";
import { coordLengthBearingToCoord } from "./mapUtil";

export type Meter = number;

export interface CoverPoint {
  latitude: number;
  longitude: number;
  score: number;
  rifleScore: number;
  coverCount: number;
  rifleCoverCount: number;
}

interface CoverBoundary {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
}

export interface CoverPointAndResolution {
  points: CoverPoint[];
  resolution: Resolution;
  area: number;
}

export interface CoverageData {
  points: CoverPoint[];
  area: number;
  boundary?: CoverBoundary;
  resolution?: Resolution;
}

const debugLog = (...params: any[]) => {
  process.env.NODE_ENV !== "production" && console.log("[Coverage]", ...params, new Date().toISOString());
};

export const generateCoverage = (scout: ScoutLocationAndRange[], rangeFactor: number): CoverageData => {
  const start = new Date();
  console.log("GenerateCoverage", "START", start, scout.length);
  const clusterDistance = Math.max(...scout.map((s) => s.range)) * rangeFactor + 500;
  debugLog("Cluster distance: ", scout.length, clusterDistance, new Date().getTime() - start.getTime() + "ms");
  const clusters = clusterScouts(scout, clusterDistance).filter((c) => c.length >= 3);
  if (clusters.length === 0) {
    return { points: [], area: 0 };
  }
  debugLog("After clustering: ", new Date().getTime() - start.getTime() + "ms");

  const boundary = createGridOfPoints(scout, 0.002, rangeFactor);
  const resolution = calculateResolution(scout[0], boundary);

  debugLog(
    "Clusters: ",
    clusters.length,
    "Resolution: ",
    resolution,
    "Boundary: ",
    boundary,
    new Date().getTime() - start.getTime() + "ms"
  );

  const coverageData = clusters.map((s) => createCoverageData2(s, rangeFactor, boundary));
  debugLog("Before points", new Date().getTime() - start.getTime() + "ms");
  const points = coverageData.flatMap((c) => c.points);
  debugLog("Before area", new Date().getTime() - start.getTime() + "ms");
  const area = coverageData.reduce((acc, v) => acc + v.area, 0);

  debugLog("Generate coverage", "END", new Date().getTime() - start.getTime() + "ms", scout.length);
  return {
    points,
    area: squareMeterToSquareKilometer(area),
    boundary,
    resolution,
  };
};

export interface Resolution {
  yResolution: Meter;
  xResolution: Meter;
  latitudeResolution: number;
  longitudeResolution: number;
}

export const createCoverageData2 = (
  scout: ScoutLocationAndRange[],
  rangeFactor: number,
  boundary: CoverBoundary
): CoverPointAndResolution => {
  const gridBoundary = createGridOfPoints(scout, 0.002, rangeFactor);
  const resolution2 = calculateResolution(scout[0], gridBoundary);
  const coveragePoints: CoverPoint[][] = createCoveragePoints(gridBoundary, resolution2);
  const minLat = coveragePoints[0][0].latitude;
  const minLon = coveragePoints[0][0].longitude;
  const maxLat = coveragePoints[coveragePoints.length - 1][coveragePoints[0].length - 1].latitude;
  const maxLon = coveragePoints[coveragePoints.length - 1][coveragePoints[0].length - 1].longitude;
  const start = new Date();
  scout.forEach((s) => {
    // calculate the CoverBoundary for scout based on location and range
    const scoutBoundary = findScoutBoundary(s, rangeFactor);
    // Calculate the index step based on boundary and array length
    const stepX = (maxLat - minLat) / coveragePoints.length;
    const stepY = (maxLon - minLon) / coveragePoints[0].length;

    // Calculate the starting index based on the scout's boundary
    const startIndexX = Math.floor((scoutBoundary.minLatitude - minLat) / stepX);
    const startIndexY = Math.floor((scoutBoundary.minLongitude - minLon) / stepY);

    // Calculate the ending index based on the scout's boundary
    const endIndexX = Math.ceil((scoutBoundary.maxLatitude - minLat) / stepX);
    const endIndexY = Math.ceil((scoutBoundary.maxLongitude - minLon) / stepY);

    // Loop over the relevant section of the grid
    for (let i = Math.max(0, startIndexX); i <= Math.min(endIndexX, coveragePoints.length - 1); i++) {
      for (let j = Math.max(0, startIndexY); j <= Math.min(endIndexY, coveragePoints[i].length - 1); j++) {
        const point = coveragePoints[i][j];
        const score = scoreScout(s, point, rangeFactor, resolution2);
        const rifleScore = scoreScout(s, point, rangeFactor * 1.75, resolution2);
        point.score += score;
        point.rifleScore += rifleScore;
        if (score > 0) {
          point.coverCount += 1;
        }
        if (rifleScore > 0) {
          point.rifleCoverCount += 1;
        }
      }
    }
  });

  coveragePoints.forEach((row) => {
    row.forEach((point) => {
      if (point.coverCount <= 2) {
        point.score = 0;
      }
      if (point.rifleCoverCount <= 2) {
        point.rifleScore = 0;
      }
      point.score = point.coverCount > 0 ? point.score / point.coverCount : 0;
      point.rifleScore = point.rifleCoverCount > 0 ? point.rifleScore / point.rifleCoverCount : 0;
    });
  });

  //   debugLog(
  //     "Combinations handled",
  //     new Date().getTime() - start.getTime() + "ms"
  //   );

  const pointsWithCoverage = coveragePoints.flatMap((c) => c).filter((c) => c.rifleScore > 0);

  //   debugLog(
  //     "Total points: ",
  //     pointsWithCoverage.length,
  //     "Rifle points: ",
  //     pointsWithCoverage.length
  //   );
  const coverageArea = calculateCoverageArea(scout, pointsWithCoverage, resolution2);

  return {
    points: pointsWithCoverage,
    resolution: resolution2,
    area: coverageArea,
  };
};

const calculateResolution = (scout: ScoutLocationAndRange, boundary: CoverBoundary): Resolution => {
  const mapResolutionY = (boundary.maxLatitude - boundary.minLatitude) / 20;
  const mapResolutionX = (boundary.maxLongitude - boundary.minLongitude) / 20;
  const longitudeResolutionInMeter = euclideanDistanceMeters(
    scout.longitude,
    scout.latitude,
    scout.longitude + mapResolutionX,
    scout.latitude
  );
  const latitudeResolutionInMeter = euclideanDistanceMeters(
    scout.longitude,
    scout.latitude,
    scout.longitude,
    scout.latitude + mapResolutionY
  );

  return {
    yResolution: latitudeResolutionInMeter,
    xResolution: longitudeResolutionInMeter,
    latitudeResolution: mapResolutionY,
    longitudeResolution: mapResolutionX,
  };
};

const calculateCoverageArea = (scout: ScoutLocationAndRange[], coverPoints: CoverPoint[], resolution: Resolution) => {
  const xInMeters = euclideanDistanceMeters(
    scout[0].longitude,
    scout[0].latitude,
    scout[0].longitude + resolution.longitudeResolution,
    scout[0].latitude
  );
  const yInMeters = euclideanDistanceMeters(
    scout[0].longitude,
    scout[0].latitude,
    scout[0].longitude,
    scout[0].latitude + resolution.latitudeResolution
  );

  return xInMeters * yInMeters * coverPoints.filter((c) => c.score > 0).length;
};

const createCoveragePoints = (boundary: CoverBoundary, resolution: Resolution): CoverPoint[][] => {
  const points: CoverPoint[][] = [];
  for (let y = boundary.minLatitude; y <= boundary.maxLatitude; y += resolution.latitudeResolution) {
    const row: CoverPoint[] = [];
    for (let x = boundary.minLongitude; x <= boundary.maxLongitude; x += resolution.longitudeResolution) {
      row.push({
        latitude: y,
        longitude: x,
        score: 0,
        rifleScore: 0,
        coverCount: 0,
        rifleCoverCount: 0,
      });
    }
    points.push(row);
  }
  return points;
};

//euclideanDistanceMeters
function euclideanDistanceMeters(aLat: number, aLon: number, bLat: number, bLon: number): number {
  // Approximate conversion factors (in meters)
  const latToMeters = 111000; // 1 degree latitude to meters
  const lonToMeters = 111000 * Math.cos(((aLat + bLat) / 2) * (Math.PI / 180)); // 1 degree longitude to meters

  // Calculate differences and convert to meters
  const dx = (aLon - bLon) * lonToMeters;
  const dy = (aLat - bLat) * latToMeters;

  // Calculate Euclidean distance in meters
  return Math.sqrt(dx * dx + dy * dy);
}

const getDistanceBetweenScoutAndPoint = (scout1: ScoutLocationAndRange, point: CoverPoint): number => {
  return euclideanDistanceMeters(scout1.latitude, scout1.longitude, point.latitude, point.longitude);
};

// Function that calculates lat/lon boundaries of all scouts and creates a grid of points
const createGridOfPoints = (
  combination: ScoutLocationAndRange[],
  resolution: number,
  rangeFactor: number
): CoverBoundary => {
  let maxLatitude = Math.max(
    ...combination.map((scout) => {
      return coordLengthBearingToCoord(scout, 0, (scout.range * 3) / 1000).lat;
    })
  );
  let maxLongitude = Math.max(
    ...combination.map((scout) => {
      return coordLengthBearingToCoord(scout, 90, (scout.range * 3) / 1000).lon;
    })
  );
  let minLatitude = Math.min(
    ...combination.map((scout) => {
      return coordLengthBearingToCoord(scout, 180, (scout.range * 3) / 1000).lat;
    })
  );
  let minLongitude = Math.min(
    ...combination.map((scout) => {
      return coordLengthBearingToCoord(scout, 270, (scout.range * 3) / 1000).lon;
    })
  );

  return {
    minLatitude: minLatitude,
    maxLatitude: maxLatitude,
    minLongitude: minLongitude,
    maxLongitude: maxLongitude,
  };
};

const findScoutBoundary = (scout: ScoutLocationAndRange, rangeFactor: number): CoverBoundary => {
  let maxLatitude = Math.max(coordLengthBearingToCoord(scout, 0, (scout.range * rangeFactor * 1.75) / 1_000).lat);
  let maxLongitude = Math.max(coordLengthBearingToCoord(scout, 90, (scout.range * rangeFactor * 1.75) / 1_000).lon);
  let minLatitude = Math.min(coordLengthBearingToCoord(scout, 180, (scout.range * rangeFactor * 1.75) / 1_000).lat);
  let minLongitude = Math.min(coordLengthBearingToCoord(scout, 270, (scout.range * rangeFactor * 1.75) / 1_000).lon);

  return {
    minLatitude: minLatitude,
    maxLatitude: maxLatitude,
    minLongitude: minLongitude,
    maxLongitude: maxLongitude,
  };
};

const squareMeterToSquareKilometer = (squareMeter: number): number => {
  return squareMeter / 1000000;
};

export const scoutToScoutLocationAndRange = (scout: Scout): ScoutLocationAndRange => ({
  latitude: scout.status.latitude,
  longitude: scout.status.longitude,
  range: scout.status.detector?.range ?? 0,
});

export const scoutStatusToScoutLocationAndRange = (scout: MinimalScoutStatus): ScoutLocationAndRange => ({
  latitude: scout.latitude,
  longitude: scout.longitude,
  range: scout.detector?.range ?? 0,
});

export const planScoutToScoutLocationAndRange = (scout: PlanScout): ScoutLocationAndRange => ({
  latitude: scout.latitude,
  longitude: scout.longitude,
  range: 200,
});

const scoreScout = (
  scout: ScoutLocationAndRange,
  point: CoverPoint,
  rangeFactor: number,
  resolution: Resolution
): number => {
  const distance =
    getDistanceBetweenScoutAndPoint(scout, point) - (resolution.xResolution + resolution.yResolution) / 4;
  const range = scout.range * rangeFactor;
  const probability = probabilityForRange(distance > 0 ? distance : 1, range);

  return probability > 1 ? 1 : probability;
};

const probabilityForRange = (distance: number, range: number): number => {
  return distance < range ? -Math.log2(distance / range) : 0;
};

function clusterScouts(scouts: ScoutLocationAndRange[], distance: number): ScoutLocationAndRange[][] {
  const start = new Date();
  var clusters: ScoutLocationAndRange[][] = [];
  for (const scout of scouts) {
    let foundCluster = false;

    for (const cluster of clusters) {
      for (const otherScout of cluster) {
        const d = euclideanDistanceMeters(scout.latitude, scout.longitude, otherScout.latitude, otherScout.longitude);

        if (d <= distance) {
          foundCluster = true;
          cluster.push(scout);
          break;
        }
      }

      if (foundCluster) {
        break;
      }
    }

    if (!foundCluster) {
      clusters.push([scout]);
    }
  }
  debugLog("Cluster time: ", new Date().getTime() - start.getTime() + "ms");
  return clusters;
}
