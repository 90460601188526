import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import DropdownItem from "../../components/overlay/DropdownItem";
import { Focus, FocusType, TwinDisplayMode } from "../../models/map";
import { Scout } from "../../models/scouts";
import { Gunshot } from "../../models/gunshots";
import { calculateHeatMapColor } from "../../util/mapUtil";
import { formatTimeDate, getDiffInMinutes, prettyTime } from "../../util/util";
import { setFocusAction } from "../commander/commanderReducer";
import DetectedBy from "./DetectedBy";
import GunshotOverlay from "./GunshotDetails";
import styles from "./style.module.scss";
import { ApplicationState } from "../../reducers";
import { RelativeRange } from "../../components/range/rangeUtil";
import LightBold from "../../components/text/LightBold";
import commonStyles from "../styles/style.module.scss";
import { familyProbability } from "./WeaponType";

const DateInformation = ({
  gunshot,
  isAdmin
}: {
  gunshot: Gunshot;
  isAdmin: boolean;
}) => {
  const familyProbabilty = familyProbability(gunshot);
  const formatted =
    moment().diff(moment(gunshot.timestamp), "minutes") < 60
      ? prettyTime(gunshot.timestamp)
      : formatTimeDate(gunshot.timestamp);
  return (
    <div className={commonStyles.row} style={{alignItems: "center"}}>

    
      {(isAdmin && familyProbabilty > 0) && (
                <div className={commonStyles.item}>
                Burst
              </div>
          
        )}
        <LightBold
      id={`pretty_date_${formatTimeDate(gunshot.timestamp)}`}
      text={formatted}
      />
      </div>
  );
};

const Dot = ({ color }: { color: string }) => {
  const style = {
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
    height: "8px",
    marginTop: "-5px",
    opacity: 0.7,
    width: "8px",
  };
  // @ts-ignore
  return <div style={style} />;
};

class GunshotItem extends React.Component<Props, {}> {
  public shouldComponentUpdate(nextProps: Props, nextState: {}) {
    return (
      nextProps.gunshot !== this.props.gunshot ||
      nextProps.focused !== this.props.focused
    );
  }

  public render() {
    const {
      gunshot,
      scouts,
      setFocus,
      focused,
      timeOfLatestGunshot,
      relativeRange,
      twinsDisplayMode,
      isAdmin
    } = this.props;
    
    const setFocusOnShot = () =>
      setFocus({ id: gunshot.id, type: FocusType.Shots });

    const provideTimeColorIndication =
      getDiffInMinutes(gunshot.timestamp, timeOfLatestGunshot) < 45;
    const heatMapColor = calculateHeatMapColor(
      gunshot.timestamp.getTime(),
      timeOfLatestGunshot.getTime(),
      relativeRange
    );
    return (
      <DropdownItem
        id={gunshot.id}
        focused={focused}
        setFocus={setFocusOnShot}
        focusStrokeColor={provideTimeColorIndication ? heatMapColor : undefined}
        classes={"gunshotItem"}
      >
        <div
          style={{ display: "flex", marginLeft: "-10px", alignItems: "center" }}
        >
          {!focused && (
            <div style={{ marginLeft: "4px", marginRight: "-6px" }}>
              <Dot
                color={provideTimeColorIndication ? heatMapColor : "white"}
              />
            </div>
          )}
          <div className={[styles.gunshotItem].join(" ")}>
            {twinsDisplayMode === TwinDisplayMode.All && gunshot.location.geoJson.features.length > 1 && (
              <p
                className={styles.minimalLabel}
              >{`${gunshot.location.geoJson.features.length} possible locations`}</p>
            )}
            <div className={styles.gunshotItemRow}>
              <div>
                <DetectedBy
                  gunshot={gunshot}
                  scouts={scouts}
                  suffix={""}
                />
              </div>
              
              <DateInformation gunshot={gunshot} isAdmin={isAdmin}/>
            </div>
            {focused && <GunshotOverlay id={gunshot.id} />}
          </div>
        </div>
      </DropdownItem>
    );
  }
}

interface Props extends OwnProps, StateToProps, DispatchToProps {}

interface OwnProps {
  gunshot: Gunshot;
}

interface StateToProps {
  focused: boolean;
  scouts: Scout[];
  timeOfLatestGunshot: Date;
  relativeRange: RelativeRange;
  twinsDisplayMode: TwinDisplayMode;
  isAdmin: boolean;
}

interface DispatchToProps {
  setFocus: (_: Focus | undefined) => void;
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  const focused = state.commander.focused;
  const focusedShot = focused && focused.id === ownProps.gunshot.id;

  return {
    focused: focusedShot!,
    scouts: state.scout.list,
    timeOfLatestGunshot: state.triangulation.timeOfLatestGunshot,
    relativeRange: state.triangulation.relativeRange,
    twinsDisplayMode: state.triangulation.twinToDisplay,
    isAdmin: state.commander.admin
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFocus: setFocusAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(GunshotItem);
