import firebase from "firebase/compat/app";
import { Action, Reducer } from "redux";
import { Organisation } from "../../models/organisation";
import { UserData } from "../../models/user";

const SET_USER = "SET_USER";
const SET_ORGANISATION = "SET_ORGANISATION";
const NO_ACCESS = "NO_ACCESS";
const SET_USER_DATA = "SET_USER_DATA";

export interface SetUserAction extends Action {
  type: "SET_USER";
  user: firebase.User | undefined;
}

export const setUserAction = (user: firebase.User | undefined): SetUserAction => {
  return { type: SET_USER, user };
};

export interface SetUserDataAction extends Action {
  type: "SET_USER_DATA";
  userData: UserData | undefined;
}

export const setUserDataAction = (
  userData: UserData | undefined
): SetUserDataAction => {
  return { type: SET_USER_DATA, userData };
};

export interface SetOrganisationAction extends Action {
  type: "SET_ORGANISATION";
  organisation: Organisation | undefined;
}

export const setOrganisationAction = (
  organisation: Organisation | undefined
): SetOrganisationAction => {
  return { type: SET_ORGANISATION, organisation };
};

export interface NoAccessAction extends Action {
  type: "NO_ACCESS";
}

export const setNoAccessAction = (): NoAccessAction => {
  return { type: NO_ACCESS };
};

export interface AuthenticationState {
  user: firebase.User | undefined;
  userData: UserData | undefined;
  noAccess: boolean;
}

export const authenticationInitialState: AuthenticationState = {
  user: undefined,
  userData: undefined,
  noAccess: false,
};

const reducer: Reducer<AuthenticationState> = (
  state: AuthenticationState = authenticationInitialState,
  action
) => {
  switch ((action as Action).type) {
    case SET_USER: {
      return { ...state, user: action.user };
    }
    case NO_ACCESS: {
      return { ...state, noAccess: true };
    }
    case SET_ORGANISATION: {
      return {
        ...state,
        userData: { ...state.userData, organisation: action.organisation },
      };
    }
    case SET_USER_DATA: {
      return { ...state, userData: action.userData };
    }
    default:
      return state;
  }
};

export default reducer;
