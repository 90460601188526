import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";

import Spinner from "../../components/common/Spinner";
import FeatureSwitch from "../../components/featureSwitch/FeatureSwitch";
import CheckedIcon from "../../components/icons/CheckedIcon";
import UnCheckedIcon from "../../components/icons/UnCheckedIcon";
import { MapViewType } from "../../models/MapViewType";
import { Feature } from "../../models/featureSwitch";
import {
  Focus,
  FocusType,
  GunshotLayerType,
  MgrsMode,
  TwinDisplayMode,
} from "../../models/map";
import { UserData } from "../../models/user";
import { ApplicationState } from "../../reducers";
import { deleteAllAreaFilters } from "../../services/areaFilterService";
import {
  changeOperatingMode,
  muteAllScouts,
  setScoutsToListening,
} from "../../services/scoutService";
import { setUserAction } from "../authentication/authenticationReducer";
import {
  bearingInMilsAction,
  changeGunshotLayerTypeAction,
  changeMapType,
  setFocusAction,
  setMgrsModeAction,
  setShowOnlyFocusedShotAction,
  showHyperbolasAction,
  dragScoutsModeAction,
  setShowScoutRangeAction,
  showScoutsAtScaleModeAction,
} from "../commander/commanderReducer";
import { DemoSettings } from "../demo/DemoSettings";
import { applyDemo } from "../demo/demoService";
import { showGeofenceAction } from "../geofence/geofenceReducer";
import { toggleLocalLocationAction } from "../location/locationReducer";
import { createAreaFilterAction } from "../map/areaFilterReducer";
import { hideScoutsActions } from "../scout/scoutReducer";
import common from "../styles/style.module.scss";
import { twinToDisplayAction } from "../triangulations/triangulationReducer";
import { Page } from "./MenuWithLogo";
import styles from "./style.module.scss";

const signOut = (setUser: (_: firebase.User | undefined) => void) =>
  firebase
    .auth()
    .signOut()
    .then(() => setUser(undefined));

interface GroupOptionProps {
  selected: boolean;
  text: string;
  onClick: () => void;
}

const GroupOption = ({ selected, text, onClick }: GroupOptionProps) => {
  return (
    <p
      className={[
        selected ? styles.selected : styles.notSelected,
        styles.selection,
      ].join(" ")}
      onClick={onClick}
    >
      {text}
    </p>
  );
};

const Group = ({ children }: { children: JSX.Element[] }) => (
  <div className={styles.sectionGroup}>{children}</div>
);

const Checkbox = ({
  checked,
  onClick,
}: {
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <div style={{ padding: "1px 7px" }} onClick={onClick}>
      {checked ? <CheckedIcon /> : <UnCheckedIcon />}
    </div>
  );
};

const SpaceBetween = ({ children }: { children: JSX.Element[] }) => (
  <div className={styles.spaceBetween}>{children}</div>
);

const BearingUnit = ({ bearingInMils, bearingInMilsHandler }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Bearing unit</p>
    <Group>
      <GroupOption
        text={"Degrees"}
        selected={!bearingInMils}
        onClick={() => bearingInMilsHandler(false)}
      />
      <GroupOption
        text={"Mils"}
        selected={bearingInMils}
        onClick={() => bearingInMilsHandler(true)}
      />
    </Group>
  </SpaceBetween>
);

const MapType = ({ mapType, changeMapTypeAction }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Map type</p>
    <Group>
      <GroupOption
        text={"Map"}
        selected={mapType === MapViewType.ROADMAP}
        onClick={() => changeMapTypeAction(MapViewType.ROADMAP)}
      />
      <GroupOption
        text={"Satellite"}
        selected={mapType === MapViewType.SATELLITE}
        onClick={() => changeMapTypeAction(MapViewType.SATELLITE)}
      />
    </Group>
  </SpaceBetween>
);

const TwinOptions = ({ twinDisplayMode, setTwinDisplayMode }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Twin type</p>
    <Group>
      <GroupOption
        text={"Both"}
        selected={twinDisplayMode === TwinDisplayMode.All}
        onClick={() => setTwinDisplayMode(TwinDisplayMode.All)}
      />
      <GroupOption
        text={"Inner"}
        selected={twinDisplayMode === TwinDisplayMode.Inner}
        onClick={() => setTwinDisplayMode(TwinDisplayMode.Inner)}
      />
      <GroupOption
        text={"Outer"}
        selected={twinDisplayMode === TwinDisplayMode.Outer}
        onClick={() => setTwinDisplayMode(TwinDisplayMode.Outer)}
      />
    </Group>
  </SpaceBetween>
);

const MgrsOptions = ({ mgrsMode, setMgrsMode }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>MGRS precision</p>
    <Group>
      <GroupOption
        text={"1m"}
        selected={mgrsMode === MgrsMode.one_meter_accuracy}
        onClick={() => setMgrsMode(MgrsMode.one_meter_accuracy)}
      />
      <GroupOption
        text={"10m"}
        selected={mgrsMode === MgrsMode.ten_meter_accuracy}
        onClick={() => setMgrsMode(MgrsMode.ten_meter_accuracy)}
      />
      <GroupOption
        text={"100m"}
        selected={mgrsMode === MgrsMode.hundred_meter_accuracy}
        onClick={() => setMgrsMode(MgrsMode.hundred_meter_accuracy)}
      />
      <GroupOption
        text={"Off"}
        selected={mgrsMode === MgrsMode.Off}
        onClick={() => setMgrsMode(MgrsMode.Off)}
      />
    </Group>
  </SpaceBetween>
);

const knownOperatingModes = ["DEFAULT", "LOW_SENSITIVITY", "HIGH_SENSITIVITY"];

const OperatingMode = ({ operatingMode, jwtToken }: Props) => {
  const [loading, setLoading] = useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (operatingMode === loading) {
      setLoading(undefined);
    }
  }, [operatingMode]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <SpaceBetween>
      <p className={styles.menuItem}>Operating mode</p>
      <Group>
        {knownOperatingModes.find((m) => m === operatingMode) ? (
          <></>
        ) : (
          <GroupOption
            text={capitalizeFirstLetter(operatingMode)}
            selected={true}
            onClick={() => {}}
          />
        )}
        <GroupOption
          text={"Default sensitivity"}
          selected={operatingMode === "DEFAULT"}
          onClick={() => {
            setLoading("DEFAULT");
            changeOperatingMode(jwtToken!, "DEFAULT");
          }}
        />
        <GroupOption
          text={"Low sensitivity"}
          selected={operatingMode === "LOW_SENSITIVITY"}
          onClick={() => {
            setLoading("LOW_SENSITIVITY");
            changeOperatingMode(jwtToken!, "LOW_SENSITIVITY");
          }}
        />
        <GroupOption
          text={"High sensitivity"}
          selected={operatingMode === "HIGH_SENSITIVITY"}
          onClick={() => {
            setLoading("HIGH_SENSITIVITY");
            changeOperatingMode(jwtToken!, "HIGH_SENSITIVITY");
          }}
        />
      </Group>
    </SpaceBetween>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const GunshotView = ({ gunshotLayer, changeGunshotLayer }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Gunshot view</p>
    <Group>
      <GroupOption
        text={"Points"}
        selected={gunshotLayer === GunshotLayerType.Circle}
        onClick={() => changeGunshotLayer(GunshotLayerType.Circle)}
      />
      <GroupOption
        text={"Heatmap"}
        selected={gunshotLayer === GunshotLayerType.Heatmap}
        onClick={() => changeGunshotLayer(GunshotLayerType.Heatmap)}
      />
    </Group>
  </SpaceBetween>
);

const OnlyFocused = ({
  showOnlyFocusedShot,
  setShowOnlyFocusedShot,
}: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Show only focused gunshot</p>
    <Checkbox
      checked={showOnlyFocusedShot}
      onClick={() => setShowOnlyFocusedShot(!showOnlyFocusedShot)}
    />
  </SpaceBetween>
);

const HideScouts = ({ hideScouts, setHideScouts }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Hide scouts</p>
    <Checkbox checked={hideScouts} onClick={() => setHideScouts(!hideScouts)} />
  </SpaceBetween>
);

const DraggableScouts = ({ dragScoutsMode, setDragScoutMode }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Drag-and-drop scouts</p>
    <Checkbox
      checked={dragScoutsMode}
      onClick={() => setDragScoutMode(!dragScoutsMode)}
    />
  </SpaceBetween>
);

const Documentation = () => (
  <section style={{ marginTop: "2rem" }}>
    <SpaceBetween>
      <a
        className={styles.menuLink}
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        href={"https://triangula.com/docs"}
      >
        Documentation
      </a>
      <div />
    </SpaceBetween>
  </section>
);

const Configuration = () => (
  <Link id={"setup_btn"} to={"/configuration"} className={styles.menuLink}>
    Configurations
  </Link>
);

const ShowHyperbolas = ({ showHyperbolas, setShowHyperbolas }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Show hyperbolas</p>
    <Checkbox
      checked={showHyperbolas}
      onClick={() => setShowHyperbolas(!showHyperbolas)}
    />
  </SpaceBetween>
);

const ShowScoutsAtScaleMode = ({ showScoutsAtScaleMode, setShowScoutsAtScaleMode }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Show scouts</p>
    <Checkbox
      checked={showScoutsAtScaleMode}
      onClick={() => setShowScoutsAtScaleMode(!showScoutsAtScaleMode)}
    />
  </SpaceBetween>
);

const ShowZones = ({ showGeofence, setShowGeofence }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Show Triangula Zones</p>
    <Checkbox
      checked={showGeofence}
      onClick={() => setShowGeofence(!showGeofence)}
    />
  </SpaceBetween>
);

const ShowScoutRange = ({ showScoutRange, setShowScoutRange }: Props) => (
  <SpaceBetween>
    <p className={styles.menuItem}>Show scout range</p>
    <Checkbox
      checked={showScoutRange}
      onClick={() => setShowScoutRange(!showScoutRange)}
    />
  </SpaceBetween>
);

const MapSettings = (props: Props) => {
  return (
    <section>
      <h4 className={styles.subsection}>Map settings</h4>
      <BearingUnit {...props} />
      <MgrsOptions {...props} />
      <MapType {...props} />
      <FeatureSwitch feature={Feature.AreaFilter}>
        <AreaFilter {...props} />
      </FeatureSwitch>
      {/* <ShowLocalLocation {...props} /> */}
    </section>
  );
};

const GunshotSettings = (props: Props) => {
  return (
    <section>
      <h4 className={styles.subsection}>Gunshot settings</h4>
      <TwinOptions {...props} />
      <GunshotView {...props} />
      <OnlyFocused {...props} />
    </section>
  );
};

const ScoutSettings = (props: Props) => {
  return (
    <section>
      <h4 className={styles.subsection}>Scout settings</h4>
      <FeatureSwitch feature={Feature.OperatingMode}>
        <OperatingMode {...props} />
      </FeatureSwitch>
      <HideScouts {...props} />
      <DraggableScouts {...props} />
      <FeatureSwitch feature={Feature.Geofence}>
        <ShowZones {...props} />
      </FeatureSwitch>
    </section>
  );
};

const BoxSettings = (props: Props) => {
  return (
    <section>
      <h4 className={styles.subsection}>Box settings</h4>
      <Configuration />
    </section>
  );
};

const MuteAllScouts = ({ jwtToken }: { jwtToken: string }) => (
  <p
    id="set_all_scouts_to_muted_btn"
    className={common.actionText}
    style={{ marginRight: "1rem" }}
    onClick={() => muteAllScouts(jwtToken!)}
  >
    Mute all
  </p>
);

const SpotlightArea = ({ callback }: { callback: () => void }) => (
  <p
    id="spotlight_area_btn"
    className={common.actionText}
    style={{ marginRight: "1rem" }}
    onClick={callback}
  >
    Spotlight area
  </p>
);

const ExcludeArea = ({ callback }: { callback: () => void }) => (
  <p
    id="exclude_area_btn"
    className={common.actionText}
    style={{ marginRight: "1rem" }}
    onClick={callback}
  >
    Exclude area
  </p>
);

const ResetAreaFilter = ({ jwtToken }: { jwtToken: string }) => (
  <p
    id="exclude_area_btn"
    className={common.actionText}
    style={{ marginRight: "1rem" }}
    onClick={() =>
      deleteAllAreaFilters(
        jwtToken!,
        () => {},
        () => {}
      )
    }
  >
    Reset
  </p>
);

const MakeAllScoutsListen = ({ jwtToken }: { jwtToken: string }) => (
  <p
    id={"set_all_scouts_to_listening_btn"}
    className={common.actionText}
    onClick={() => setScoutsToListening(jwtToken!)}
  >
    All listening
  </p>
);

const AllMutedOrListening = (props: Props) => {
  return (
    <SpaceBetween>
      <p className={styles.menuItem}>Change scout state</p>
      <div style={{ display: "flex" }}>
        <MuteAllScouts jwtToken={props.jwtToken!} />
        <MakeAllScoutsListen jwtToken={props.jwtToken!} />
      </div>
    </SpaceBetween>
  );
};

const AreaFilter = (props: Props) => {
  return (
    <SpaceBetween>
      <p className={styles.menuItem}>Area filters</p>
      <div style={{ display: "flex" }}>
        <SpotlightArea
          callback={() => {
            props.setFocus(undefined);
            props.createNewAreaFilter(false);
          }}
        />
        <ExcludeArea
          callback={() => {
            props.setFocus(undefined);
            props.createNewAreaFilter(true);
          }}
        />
        <ResetAreaFilter jwtToken={props.jwtToken!} />
      </div>
    </SpaceBetween>
  );
};

const AdminSettings = (props: Props) => {
  return (
    <section>
      <h4 className={styles.subsection}>Admin settings</h4>

      <ShowHyperbolas {...props} />
      <ShowScoutRange {...props} />
      <AllMutedOrListening {...props} />
      {props.user?.organisation?.scale && <ShowScoutsAtScaleMode {...props}/>}
    </section>
  );
};

const LogoutButton = (props: Props) => {
  const { setUser } = props;
  const signOutCallback = () => signOut(setUser);
  return (
    <p
      style={{
        color: "#203864",
        fontSize: "1em",
        fontWeight: "bold",
        marginTop: "14px",
      }}
      className={common.actionText}
      onClick={signOutCallback}
    >
      Logout
    </p>
  );
};

const MenuController = (props: Props) => {
  return (
    <div style={{ padding: "20px", paddingLeft: "50px", paddingRight: "50px" }}>
      <h2>Settings</h2>
      <MapSettings {...props} />
      <GunshotSettings {...props} />
      <ScoutSettings {...props} />
      {applyDemo(props.user) && <DemoSettings />}
      {process.env.REACT_APP_BOX && <BoxSettings {...props} />}
      {props.isAdmin && <AdminSettings {...props} />}
      <Documentation />
      {props.organisationName !== undefined && <LogoutButton {...props} />}
    </div>
  );
};

const MenuOverlay = (props: Props) => {
  const showMenu = props.focused
    ? props.focused.type === FocusType.Menu
    : false;
  if (showMenu) {
    return (
      <div
        className={
          props.page === Page.overview
            ? styles.menuOverlay
            : styles.planningMenu
        }
      >
        <MenuController {...props} />
      </div>
    );
  } else {
    return <></>;
  }
};

interface OwnProps {
  page: Page;
}

interface Props extends DispatchFromProps, StateToProps, OwnProps {}

interface StateToProps {
  organisationName: string | undefined;
  user: UserData | undefined;
  showOnlyFocusedShot: boolean;
  hideScouts: boolean;
  mapType: MapViewType;
  gunshotLayer: GunshotLayerType;
  focused: Focus | undefined;
  bearingInMils: boolean;
  showHyperbolas: boolean;
  twinDisplayMode: TwinDisplayMode;
  mgrsMode: MgrsMode;
  showLocalLocation: boolean;
  isAdmin: boolean;
  dragScoutsMode: boolean;
  jwtToken: string | undefined;
  showScoutRange: boolean;
  showGeofence: boolean;
  operatingMode: string;
  showScoutsAtScaleMode: boolean;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setHideScouts: (_: boolean) => void;
  setShowOnlyFocusedShot: (_: boolean) => void;
  changeMapTypeAction: (_: MapViewType) => void;
  setFocus: (_: Focus | undefined) => void;
  bearingInMilsHandler: (_: boolean) => void;
  setShowHyperbolas: (_: boolean) => void;
  setTwinDisplayMode: (_: TwinDisplayMode) => void;
  changeGunshotLayer: (_: GunshotLayerType) => void;
  setMgrsMode: (_: MgrsMode) => void;
  toggleLocalLocation: () => void;
  setDragScoutMode: (_: boolean) => void;
  setShowScoutRange: (_: boolean) => void;
  setShowGeofence: (_: boolean) => void;
  createNewAreaFilter: (_: boolean) => void;
  setShowScoutsAtScaleMode: (_: boolean) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  bearingInMils: state.commander.bearingInMils,
  focused: state.commander.focused,
  gunshotLayer: state.commander.gunshotLayerType,
  hideScouts: state.scout.hideScouts,
  mapType: state.commander.mapType,
  mgrsMode: state.commander.mgrsMode,
  user: state.authentication.userData,
  organisationName:
    state.authentication.userData &&
    state.authentication.userData.organisation &&
    state.authentication.userData.organisation.name,
  operatingMode:
    (state.authentication.userData &&
      state.authentication.userData.organisation &&
      state.authentication.userData.organisation.mode) ??
    "DEFAULT",
  showHyperbolas: state.commander.showHyperbolas,
  showLocalLocation: state.location.showLocalLocation,
  showOnlyFocusedShot: state.commander.showOnlyFocusedShot,
  twinDisplayMode: state.triangulation.twinToDisplay,
  showScoutsAtScaleMode: state.commander.showScoutsAtScaleMode,
  isAdmin: state.commander.admin,
  dragScoutsMode: state.commander.dragScoutsMode,
  jwtToken: state.commander.jwtToken,
  showScoutRange: state.commander.showScoutRange,
  showGeofence: state.geofence.show,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      bearingInMilsHandler: bearingInMilsAction,
      changeGunshotLayer: changeGunshotLayerTypeAction,
      changeMapTypeAction: changeMapType,
      setFocus: setFocusAction,
      setHideScouts: hideScoutsActions,
      setMgrsMode: setMgrsModeAction,
      setShowHyperbolas: showHyperbolasAction,
      setShowScoutsAtScaleMode: showScoutsAtScaleModeAction,
      setShowOnlyFocusedShot: setShowOnlyFocusedShotAction,
      setTwinDisplayMode: twinToDisplayAction,
      setUser: setUserAction,
      toggleLocalLocation: toggleLocalLocationAction,
      setDragScoutMode: dragScoutsModeAction,
      setShowScoutRange: setShowScoutRangeAction,
      setShowGeofence: showGeofenceAction,
      createNewAreaFilter: createAreaFilterAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(MenuOverlay);
