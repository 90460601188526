// @ts-ignore
// @ts-ignore
import { Map } from "mapbox-gl";
import * as mapboxgl from "mapbox-gl";

import { store } from "../../ConfiguredApp";
import {
  addPendingFixedLocationAction,
  movingFixedLocationAction,
} from "../../containers/scout/scoutReducer";
import { GunshotPositionFeature } from "../../models/gunshots";
import { Plan } from "../../models/plan";
import { updatePlan } from "../../services/planningService";
import { setFixedLocationScout } from "../../services/scoutService";
import { dragIconAndLineLayer } from "./layers/DragLayer";

let pressTimeout: any | undefined;
let originalLocation: any | undefined;
let pressed: boolean = false;
let mousedown: boolean = false;
let dragScoutsMode: boolean = false;

const movingGeoJson = {
  features: [],
  type: "FeatureCollection",
};

export const countCoordinates = (feature: GunshotPositionFeature) => {
  return feature.geometry
    ? feature.geometry.type === "MultiPolygon"
      ? feature.geometry.coordinates.reduce(
          (acc1, v1) => acc1 + v1.reduce((acc2, v2) => acc2 + v2.length, 0),
          0
        )
      : 1
    : 0;
};

const createSearchLocation = (coords: any, map: Map, description: string) => {
  const dragIcon = map.getSource("drag-icon");
  const dragIconFeature = {
    features: [
      {
        geometry: {
          coordinates: [coords.lng, coords.lat],
          type: "Point",
        },
        properties: {
          description,
        },
        type: "Feature",
      },
    ],
    type: "FeatureCollection",
  };
  if (!dragIcon) {
    //@ts-ignore
    map.addSource("drag-icon", {
      //@ts-ignore
      data: dragIconFeature,
      type: "geojson",
    });
  } else {
    //@ts-ignore
    dragIcon.setData(dragIconFeature);
  }
  //@ts-ignore

  const dragLine = map.getSource("drag-line");
  const dragLineFeature = {
    features: [
      {
        geometry: {
          coordinates: [originalLocation, [coords.lng, coords.lat]],
          type: "LineString",
        },
      },
    ],
    type: "FeatureCollection",
  };
  if (!dragLine) {
    //@ts-ignore
    map.addSource("drag-line", {
      //@ts-ignore
      data: dragLineFeature,
      type: "geojson",
    });
  } else {
    //@ts-ignore
    dragLine.setData(dragLineFeature);
  }

  if (!map.getLayer("drag-icon")) {
    dragIconAndLineLayer(map);
  }
};

const deleteSearchLocation = (map: Map) => {
  originalLocation = undefined;
  //@ts-ignore
  map.getSource("drag-icon")?.setData({
    features: [],
    type: "FeatureCollection",
  });

  //@ts-ignore
  map.getSource("drag-line")?.setData({
    features: [],
    type: "FeatureCollection",
  });
};

export function createGeoJson(coords: number[], text: string="") {
  return {
    features: [
      {
        geometry: {
          coordinates: coords,
          type: "Point",
        },
        properties: {
          text
        },
        type: "Feature",
      },
    ],
    type: "FeatureCollection",
  };
}

function onMove(map: Map, description: string) {
  return (e: any) => {
    if (pressed) {
      const coords = e.lngLat;
      e.preventDefault();
      const updatedGeoJson = createGeoJson(coords.toArray());
      createSearchLocation(coords, map, description);

      // @ts-ignore
      map.getSource("moving_scout").setData(updatedGeoJson);
      store.dispatch(
        movingFixedLocationAction({
          latitude: coords.lat,
          longitude: coords.lng,
        })
      );

      // Set a UI indicator for dragging.

      // Update the Point feature in `geojson` coordinates
      // and call setData to the source layer `point` on it.
      //     geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
      //     map.getSource('point').setData(geojson);
    }
  };
}

function onUp(
  map: Map,
  deviceId: string,
  callback: (deviceId: string, e: any) => void
) {
  return (e: any) => {
    pressed = false;

    // @ts-ignore
    map.getSource("moving_scout").setData(movingGeoJson);
    store.dispatch(movingFixedLocationAction(undefined));

    map.getCanvasContainer().style.cursor = "";

    // Unbind mouse/touch events
    map.off("mousemove", onMove(map, ""));
    map.off("touchmove", onMove(map, ""));
    deleteSearchLocation(map);
    callback(deviceId, e);
  };
}

function addTargetLayer(map: mapboxgl.Map) {
  map.addLayer({
    id: "moving_scout_front",
    paint: {
      "circle-color": "#3887be",
      "circle-radius": 5,
    },
    source: "moving_scout",
    type: "circle",
  });

  map.addLayer({
    id: "moving_scout_back",
    paint: {
      "circle-color": "#3887be",
      "circle-opacity": 0.4,
      "circle-radius": 30,
    },
    source: "moving_scout",
    type: "circle",
  });
}

function onRelease(map: Map) {
  map.getCanvasContainer().style.cursor = "pointer";
  pressed = false;
  mousedown = false;
  deleteSearchLocation(map);

  if (pressTimeout !== undefined) {
    clearTimeout(pressTimeout);
    pressTimeout = undefined;
  }
}

function getFeatures(map: mapboxgl.Map, layer: string, e: any) {
  return map.queryRenderedFeatures(
    [
      [e.point.x, e.point.y],
      [e.point.x, e.point.y],
    ],
    { layers: [layer] }
  );
}

function getPlannedScoutIfSelected(map: mapboxgl.Map, layer: string, e: any) {
  const selectedScout = store.getState().planning.selectedScout
    ? store.getState().planning.selectedScout
    : "";
  return map.queryRenderedFeatures(
    [
      [e.point.x, e.point.y],
      [e.point.x, e.point.y],
    ],
    { layers: [layer], filter: ["==", "id", selectedScout] }
  );
}

// @ts-ignore
function preparePressed(
  coords,
  map: mapboxgl.Map,
  features,
  inPlanMode: boolean
) {
  const canvas = map.getCanvasContainer();
  pressed = true;
  const updatedGeoJson = createGeoJson(coords.toArray());
  createSearchLocation(coords, map, getDragIconText(inPlanMode));
  addMovingScoutLayerIfNeeded(map);
  // @ts-ignore
  map.getSource("moving_scout").setData(updatedGeoJson);
  const deviceId = features[0].properties.id as string;

  store.dispatch(
    movingFixedLocationAction({
      latitude: coords.lat,
      longitude: coords.lng,
    })
  );

  canvas.style.cursor = "grabbing";
  canvas.draggable = false;
  // const canvas = map.getCanvasContainer();
  // Prevent the default map drag behavior.

  return deviceId;
}

const getDragIconText = (inPlanningMode: boolean) => {
  return inPlanningMode ? "Choose new location" : "Choose fixed location";
};

// @ts-ignore
function touchPressed(
  map: mapboxgl.Map,
  layer: string,
  callback: (deviceId: string, e: any) => void,
  inPlanMode: boolean = false
) {
  const canvas = map.getCanvasContainer();
  map.on("touchstart", layer, (e) => {
    const features = inPlanMode
      ? getPlannedScoutIfSelected(map, layer, e)
      : getFeatures(map, layer, e);

    if (features.length === 1 && dragScoutsMode) {
      // If multiple fingers touch the screen at the same time:
      // Cancel drag effect
      // if (e.originalEvent.touches.length > 1) {
      //   clearTimeout(pressTimeout);
      //   pressTimeout = undefined;
      //   return;
      // }

      mousedown = true;

      map.on("touchmove", onMove(map, getDragIconText(inPlanMode)));

      const coords = e.lngLat;
      e.preventDefault();

      canvas.style.cursor = "grab";
      if (!pressTimeout) {
        pressTimeout = setTimeout(() => {
          //@ts-ignore
          originalLocation = features[0].geometry.coordinates;
          if (mousedown) {
            const deviceId = preparePressed(coords, map, features, inPlanMode);
            map.on("touchmove", onMove(map, getDragIconText(inPlanMode)));
            map.once("touchend", onUp(map, deviceId, callback));
            // You are now in a `hold` state, you can do whatever you like!
          }
        }, 1000);
      }
    }
  });
}

function mousePressed(
  map: mapboxgl.Map,
  layer: string,
  callback: (id: string, e: any) => void,
  inPlanMode: boolean = false
) {
  const canvas = map.getCanvasContainer();

  map.on("mousedown", layer, (e) => {
    const features = inPlanMode
      ? getPlannedScoutIfSelected(map, layer, e)
      : getFeatures(map, layer, e);
    if (features.length >= 1 && dragScoutsMode) {
      mousedown = true;

      const coords = e.lngLat;
      e.preventDefault();

      canvas.style.cursor = "grab";
      if (!pressTimeout) {
        pressTimeout = setTimeout(() => {
          //@ts-ignore
          originalLocation = features[0].geometry.coordinates;
          if (mousedown) {
            const id = preparePressed(coords, map, features, inPlanMode);
            map.on("mousemove", onMove(map, getDragIconText(inPlanMode)));
            map.once("mouseup", onUp(map, id, callback));
            // You are now in a `hold` state, you can do whatever you like!
          }
        }, 1000);
      }
    }
  });
}





export const scoutOnPress = (
  map: Map,
  // onClick: (id: string) => void
  jwtToken: string | undefined,
  dragScouts: boolean
) => {
  dragScoutsMode = dragScouts;
  if (!map.getSource("moving_scout")) {
    // Add a single point to the map
    // @ts-ignore
    map.addSource("moving_scout", {
      // @ts-ignore
      data: movingGeoJson,
      type: "geojson",
    });
    addTargetLayer(map);
  }

  const canvas = map.getCanvasContainer();

  // Listening scouts
  map.on("mouseenter", "listening_scouts", () => {
    if (!pressed) {
      canvas.style.cursor = "grab";
    }
  });

  map.on("mouseleave", "listening_scouts", () => {
    if (!pressed) {
      canvas.style.cursor = "";
    }
  });

  const callback = (deviceId: string, e: any) => {
    if (jwtToken) {
      setFixedLocationScout(deviceId, e.lngLat.lat, e.lngLat.lng, jwtToken);
      store.dispatch(
        addPendingFixedLocationAction(deviceId, {
          latitude: e.lngLat.lat,
          longitude: e.lngLat.lng,
        })
      );
    }
  };

  
  mousePressed(map, "listening_scouts", callback);
  touchPressed(map, "listening_scouts", callback);
  

  map.on("mouseup", "listening_scouts", () => {
    onRelease(map);
  });

  map.on("touchend", "listening_scouts", () => {
    onRelease(map);
  });

  map.on("touchcancel", "listening_scouts", () => {
    onRelease(map);
  });

  // All other scout states
  map.on("mouseenter", "scouts", () => {
    if (!pressed) {
      canvas.style.cursor = "grab";
    }
  });

  map.on("mouseleave", "scouts", () => {
    if (!pressed) {
      canvas.style.cursor = "";
    }
  });

  mousePressed(map, "scouts", callback);
  touchPressed(map, "scouts", callback);

  map.on("mouseup", "scouts", () => {
    onRelease(map);
  });

  map.on("touchend", "scouts", () => {
    onRelease(map);
  });

  map.on("touchcancel", "scouts", () => {
    onRelease(map);
  });
};

function addMovingScoutLayerIfNeeded(map: mapboxgl.Map) {
  if (!map.getSource("moving_scout")) {
    // Add a single point to the map
    // @ts-ignore
    map.addSource("moving_scout", {
      // @ts-ignore
      data: movingGeoJson,
      type: "geojson",
    });
    addTargetLayer(map);
  }
}

export const planScoutOnPress = (
  map: Map,
  // onClick: (id: string) => void
  jwtToken: string | undefined,
  planMode: boolean = false,
  selectScoutCallback?: (_: string | undefined) => void
) => {
  dragScoutsMode = true;

  addMovingScoutLayerIfNeeded(map);

  const canvas = map.getCanvasContainer();

  // Listening scouts
  map.on("mouseenter", "plan_scout", () => {
    if (!pressed) {
      canvas.style.cursor = "grab";
    }
  });

  map.on("mouseleave", "plan_scout", () => {
    if (!pressed) {
      canvas.style.cursor = "";
    }
  });

  const callback = (deviceId: string, e: any) => {
    if (jwtToken) {
      const selectedPlan: Plan = store.getState().planning.selectedPlan!;
      updatePlan(
        {
          ...selectedPlan,
          scouts: selectedPlan.scouts.map((s) => {
            if (s.id !== deviceId) {
              return s;
            }
            return { ...s, latitude: e.lngLat.lat, longitude: e.lngLat.lng };
          }),
        },
        jwtToken
      );
    }
  };

  mousePressed(
    map,
    "plan_scout",
    (id, e) => {
      if (selectScoutCallback) {
        selectScoutCallback(id);
      }

      if (pressTimeout) {
        clearTimeout(pressTimeout);
        pressTimeout = undefined;
      }
      callback(id, e);
    },
    planMode
  );
  touchPressed(
    map,
    "plan_scout",
    (id, e) => {
      if (pressTimeout) {
        clearTimeout(pressTimeout);
        pressTimeout = undefined;
      }
      callback(id, e);
    },
    planMode
  );

  map.on("mouseup", "plan_scout", () => {
    onRelease(map);
  });

  map.on("touchend", "plan_scout", () => {
    onRelease(map);
  });

  map.on("touchcancel", "plan_scout", () => {
    onRelease(map);
  });
};
