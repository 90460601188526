import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setScrolledAction } from "../../containers/commander/commanderReducer";
import { ApplicationState } from "../../reducers";
import styles from "./style.module.scss";

interface State {
  shouldScroll: boolean;
}

class DropdownItem extends React.Component<Props, State> {
  private myRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = { shouldScroll: false };
  }

  public componentDidMount() {
    if (this.myRef.current && this.props.focused && !this.props.scrolled) {
      this.myRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      this.props.setScrolled(true);
    }
  }

  public render() {
    const { focused, children, setFocus, id, focusStrokeColor, classes } =
      this.props;

    const combinedClasses = [
      focused ? styles.focusedScout : "",
      classes ? classes : "",
    ];
    const style =
      focusStrokeColor && focused
        ? { borderLeft: `5px solid ${focusStrokeColor}` }
        : {};

    return (
      <div
        key={"drop_item_" + id}
        id={"drop_item_" + id}
        ref={this.myRef}
        className={combinedClasses.join(" ")}
        style={style}
        onClick={setFocus}
      >
        <div className={[styles.container].join(" ")}>{children}</div>
      </div>
    );
  }
}

interface Props extends StateToProps, DispatchToProps, OwnProps {}

interface OwnProps {
  focused: boolean;
  id: string;
  setFocus: () => void;
  children: JSX.Element;
  focusStrokeColor?: string;
  classes?: string;
}
interface StateToProps {
  scrolled: boolean;
}

interface DispatchToProps {
  setScrolled: (_: boolean) => void;
}

const mapStateToProps = (state: ApplicationState, own: OwnProps) => ({
  scrolled: state.commander.scrolled,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setScrolled: setScrolledAction,
    },
    dispatch
  );

export default connect<
  StateToProps,
  DispatchToProps,
  OwnProps,
  ApplicationState
>(
  mapStateToProps,
  mapDispatchToProps
)(DropdownItem);
