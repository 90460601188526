import * as React from "react";
import { connect } from "react-redux";
import FocusedItem from "../../components/overlay/FocusedItem";
import { Scout, AudioStatistics, ScoutState } from "../../models/scouts";
import {
  muteScout,
  setScoutToListening,
  useFixedLocationForScout as enableFixedLocationForScout,
  enableGpsLocationForScout,
} from "../../services/scoutService";

import { MgrsMode } from "../../models/map";
import styles from "./style.module.scss";
import common from "../styles/style.module.scss";
import LocationDetails from "../../components/location/LocationDetails";
import mute from "./mute.png";
import fixed from "./fixed.png";
import gps from "./gps.png";
import IconButton from "../../components/buttons/IconButton";
import AmbientNoiseWidget from "../../components/noise/AmbientNoiseWidget";
import { store } from "../../ConfiguredApp";
import { removePendingFixedLocationAction } from "./scoutReducer";
import { ApplicationState } from "../../reducers";
import { tutToDate } from "../../util/util";
import moment from "moment";

export const MuteAndLockDevice = ({
  scout,
  jwtToken,
}: {
  scout: Scout;
  jwtToken: string | undefined;
}) => (
  <div className={styles.controllers}>
    <div style={{ marginRight: "0.5rem", marginBottom: "0.5rem" }}>
      <LockedPositionSection scout={scout} jwtToken={jwtToken} />
    </div>
    <ListenOrMuteSection scout={scout} jwtToken={jwtToken} />
    {/* <AmbientNoiseWidget statistics={scout.status.audioStatistics} /> */}
  </div>
);

const ListenOrMuteSection = ({
  scout,
  jwtToken,
}: {
  scout: Scout;
  jwtToken: string | undefined;
}) => (
  <div>
    {(scout.state === ScoutState.Listening || scout.state === ScoutState.Disabled ) ? (
      <IconButton
        id={`mute_${scout.deviceId}`}
        icon={mute}
        text={"Mute"}
        onClick={() => muteScout(scout.deviceId, jwtToken!)}
      />
    ) : scout.state === ScoutState.Ready ? (
      <IconButton
        id={`mute_${scout.deviceId}`}
        icon={mute}
        text={"Unmute"}
        onClick={() => setScoutToListening(scout.deviceId, jwtToken!)}
      />
    ) : <></>}
  </div>
);

const LockedPositionSection = ({
  scout,
  jwtToken,
}: {
  scout: Scout;
  jwtToken: string | undefined;
}) => (
  <div>
    {scout.fixedLocation ? (
      <IconButton
        id={`mute_${scout.deviceId}`}
        icon={gps}
        text={"Change to GPS location"}
        onClick={() => {
          store.dispatch(removePendingFixedLocationAction(scout.deviceId))
          enableGpsLocationForScout(scout.deviceId, jwtToken!)}}
      />
    ) : scout.fixedLocation !== undefined && !scout.fixedLocation ? (
      <IconButton
        id={`mute_${scout.deviceId}`}
        icon={fixed}
        text={"Change to fixed location"}
        onClick={() => enableFixedLocationForScout(scout.deviceId, jwtToken!)}
      />
    ) : (
      <div />
    )}
  </div>
);

const NoiseLevel = ({ stats }: { stats: AudioStatistics }) => {
  return <AmbientNoiseWidget statistics={stats} />;
};

const ScoutDetails = ({ scout, jwtToken, mgrsMode }: Props) => {
  if (scout) {
    return (
      <FocusedItem>
        <div
          id={"focused_scout_" + scout.deviceId}
          className={styles.scoutInformation}
        >
          {(scout.state === ScoutState.Offline && scout.status.receivedTut) && (
            <>
            <div className={common.subHeader}>Last seen</div>
            <p className={common.infoText}>{`${moment(tutToDate(scout.status.receivedTut)).format(
                    "MMM DD, HH:mm"
                  )}`}</p>
            </>
            
          )}
          <LocationDetails
            latLon={{
              latitude: scout.status.latitude,
              longitude: scout.status.longitude,
            }}
            mgrsMode={mgrsMode}
          />
          {/* {scout.state !== ScoutState.Offline && ( */}
            <MuteAndLockDevice scout={scout} jwtToken={jwtToken} />
          {/* )} */}
          {scout.state !== ScoutState.Offline &&
            scout.status.audioStatistics && (
              <NoiseLevel stats={scout.status.audioStatistics} />
            )}
        </div>
      </FocusedItem>
    );
  } else {
    return <div>Unknown scout</div>;
  }
};

interface Props extends OwnProps, StateToProps {}

interface OwnProps {
  deviceId: string;
}

interface StateToProps {
  scout: Scout | undefined;
  jwtToken: string | undefined;
  mgrsMode: MgrsMode;
}

const mapStateToProps = (state: ApplicationState, props: any) => ({
  jwtToken: state.commander.jwtToken,
  
  mgrsMode: state.commander.mgrsMode,
  scout: state.scout.list.find((scout) => scout.deviceId === props.deviceId),
});

export default connect<StateToProps, {}, OwnProps, ApplicationState>(mapStateToProps)(
  ScoutDetails
);
