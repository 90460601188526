import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Focus, FocusType } from "../../models/map";
import { MapViewType } from "../../models/MapViewType";
import { ApplicationState } from "../../reducers";
import { setUserAction } from "../authentication/authenticationReducer";
import {
  changeMapType,
  setFocusAction,
  setShowOnlyFocusedShotAction,
} from "../commander/commanderReducer";
import MenuOverlay from "./MenuOverlay";
import { Page } from "./MenuWithLogo";

const TriangulaMenu = (props: Props) => {
  return <LogoWithUserDesktop {...props} />;
};

const LogoWithUserDesktop = ({
  setUser,
  setShowOnlyFocusedShot,
  showOnlyFocusedShot,
  mapType,
  changeMapTypeAction,
  focused,
  setFocus,
  page,
}: Props) => {
  const showMenu = focused && focused.type === FocusType.Menu;
  return (
    <>
      <button
        data-testid={"hamburger-menu"}
        className={
          "hamburger hamburger--elastic" + (showMenu ? " is-active" : "")
        }
        type="button"
        onClick={() =>
          setFocus(showMenu ? undefined : { type: FocusType.Menu })
        }
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <MenuOverlay page={page} />
    </>
  );
};

interface OwnProps {
  page: Page;
}

interface Props extends DispatchFromProps, StateToProps, OwnProps {}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setShowOnlyFocusedShot: (_: boolean) => void;
  changeMapTypeAction: (_: MapViewType) => void;
  setFocus: (_: Focus | undefined) => void;
}

interface StateToProps {
  organisationName: string | undefined;
  showOnlyFocusedShot: boolean;
  mapType: MapViewType;
  focused: Focus | undefined;
}

const mapStateToProps = (state: ApplicationState) => ({
  focused: state.commander.focused,
  mapType: state.commander.mapType,
  organisationName:
    state.authentication.userData &&
    state.authentication.userData.organisation &&
    state.authentication.userData.organisation.name,
  showOnlyFocusedShot: state.commander.showOnlyFocusedShot,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeMapTypeAction: changeMapType,
      setFocus: setFocusAction,
      setShowOnlyFocusedShot: setShowOnlyFocusedShotAction,
      setUser: setUserAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(TriangulaMenu);
