import firebase from "firebase/compat/app";
import * as React from "react";
import LoadingScreen from "../../components/loading/LoadingScreen";
import {addOrganisationForUser} from "../../topics/firebase/user";
import CreateOrganisationForm from "../../components/forms/CreateOrganisationForm";

const WaitForCreation = () => (
    <LoadingScreen message={"Wait while we create your organisation"}/>
);

class CreateOrganisation extends React.Component<CreateOrganisationProps, CreateOrganisationState> {

    public state: CreateOrganisationState = {
        error: false,
        loading: false,
    };

    constructor(props: CreateOrganisationProps) {
        super(props);
        this.createHandler = this.createHandler.bind(this);
    }

    public render() {
        return this.state.loading ? <WaitForCreation/>
            : <CreateOrganisationForm createHandler={this.createHandler} {...this.props}/>;
    }

    private createHandler(organisation: string) {
        addOrganisationForUser(organisation, this.props.user)
            .then(() => {
                this.setState(((prevState) => ({...prevState, loading: true})));
            })
            .catch(() => {
                // TODO handle issues when posting new organisation
            });
    }
}

interface CreateOrganisationProps {
    user: firebase.User;
}

interface CreateOrganisationState {
    error: boolean;
    loading: boolean;
}

export default CreateOrganisation;
