// import "firebase/compat/firestore";
import * as serviceWorker from "./serviceWorker";
import { renderAdmin } from "./admin-index";
import { renderPublic } from "./public-index";
import { renderBox } from "./box-index";
import { renderDemo } from "./demo-index";
if (process.env.REACT_APP_ADMIN && !process.env.REACT_APP_BOX) {
  renderAdmin();
} else if (process.env.REACT_APP_BOX) {
  renderBox();
} else if (process.env.REACT_APP_DEMO) {
  renderDemo();
} else {
  renderPublic();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
