import { LngLat } from "mapbox-gl";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { CoverageSettings } from "../../models/coverage";
import { Hyperbolas, MgrsMode, TwinDisplayMode } from "../../models/map";
import { Gunshot } from "../../models/gunshots";
import { getLocationFor } from "../../services/geoLocationService";
import commonStyles from "../styles/style.module.scss";
import styles from "./style.module.scss";
import { setHyperbolasAction } from "../triangulations/triangulationReducer";
import LocationDetails from "../../components/location/LocationDetails";
import { getRepresentativePoint } from "../../util/mapUtil";
import { ApplicationState } from "../../reducers";
import WeaponType from "./WeaponType";
import FeatureSwitch from "../../components/featureSwitch/FeatureSwitch";
import { Feature } from "../../models/featureSwitch";

interface LocationProps {
  gunshot: Gunshot;
  mgrsMode: MgrsMode;
  twinsDisplayMode: TwinDisplayMode;
}

const Locations = ({ gunshot, mgrsMode, twinsDisplayMode }: LocationProps) => {
  const [focusedId, setFocusedId] = useState("");
  const [location, setLocation] = useState("");
  const [secondLocation, setSecondLocation] = useState("");

  useEffect(() => {
    if (gunshot && (location === "" || focusedId !== gunshot.id)) {
      const gunshotLocation = getRepresentativePoint(
        gunshot.location.geoJson.features[0]
      );
      setLocation("");
      setSecondLocation("");
      setFocusedId(gunshot.id);
      getLocationFor(
        new LngLat(gunshotLocation.longitude, gunshotLocation.latitude),
        (l) => setLocation(l)
      );
      if (gunshot.location.geoJson.features.length === 2) {
        const twinLocation = getRepresentativePoint(
          gunshot.location.geoJson.features[1]
        );
        getLocationFor(
          new LngLat(twinLocation.longitude, twinLocation.latitude),
          (l) => setSecondLocation(l)
        );
      }
    }
  }, [gunshot, location, focusedId]);

  const gunshotLocations = gunshot.location.geoJson.features.map((r) => {
    return getRepresentativePoint(r);
  });

  return (
    <div className={styles.gunshotDetailRow}>
      <WeaponTypeSection gunshot={gunshot} />
      <div>
        <LocationDetails
          latLon={{
            latitude: gunshotLocations[0].latitude,
            longitude: gunshotLocations[0].longitude,
          }}
          mgrsMode={mgrsMode}
        />
        {twinsDisplayMode === TwinDisplayMode.All && secondLocation !== "" && gunshotLocations.length === 2 && (
          <>
            <p
              style={{ marginRight: "10px", marginTop: "5px" }}
              className={[
                commonStyles.noMargin,
                commonStyles.secondarySm,
              ].join(" ")}
            >
              or
            </p>
            <LocationDetails
              latLon={{
                latitude: gunshotLocations[1].latitude,
                longitude: gunshotLocations[1].longitude,
              }}
              mgrsMode={mgrsMode}
            />
          </>
        )}
      </div>
      
    </div>
  );
};

const WeaponTypeSection = ({ gunshot }: { gunshot: Gunshot }) => (
  <FeatureSwitch feature={Feature.WeaponType}>
    <WeaponType gunshot={gunshot} />
  </FeatureSwitch>
);

class GunshotDetails extends React.Component<Props, {}> {
  public render() {
    const { selected } = this.props;
    if (selected) {
      return (
        <div key="shot_details">
          {selected !== undefined && (
            <Locations mgrsMode={this.props.mgrsMode} gunshot={selected} twinsDisplayMode={this.props.twinsDisplayMode}/>
          )}
        </div>
      );
    } else {
      return <div>Unknown</div>;
    }
  }
}

const MemoizedGunshotDetails = React.memo(GunshotDetails, (prev, next) => {
  return prev.selected === next.selected;
});

interface Props extends OwnProps, StateToProps, DispatchFromProps {}

interface OwnProps {
  id: string;
}

interface StateToProps {
  selected: Gunshot | undefined;
  jwtToken: string | undefined;
  showHyperbolas: boolean;
  coverageSettings: CoverageSettings;
  mgrsMode: MgrsMode;
  twinsDisplayMode: TwinDisplayMode;
}

const mapStateToProps = (state: ApplicationState, props: OwnProps) => {
  const gunshot = state.triangulation.gunshots[props.id];
  return {
    coverageSettings: state.commander.coverageSettings,
    jwtToken: state.commander.jwtToken,
    mgrsMode: state.commander.mgrsMode,
    selected: gunshot,
    showHyperbolas: state.commander.showHyperbolas,
    twinsDisplayMode: state.triangulation.twinToDisplay,
  };
};

interface DispatchFromProps {
  setHyperbolas: (triangulationId: string, hyperbolas: Hyperbolas) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setHyperbolas: setHyperbolasAction,
    },
    dispatch
  );

export default connect<
  StateToProps,
  DispatchFromProps,
  OwnProps,
  ApplicationState
>(
  mapStateToProps,
  mapDispatchToProps
)(MemoizedGunshotDetails);
