import mask from "@turf/mask";
import { Map } from "mapbox-gl";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { MapViewType } from "../../../models/MapViewType";
import { Geofence } from "../../../models/geofence";
import { Focus, FocusType } from "../../../models/map";
import { ApplicationState } from "../../../reducers";
import { createGeoJson } from "../dragAndDropUtil";
import { areaFilterOnPress, geofenceOnPress } from "../geofenceMapUtil";
import { getCirclePolygon } from "../mapUtil";

export interface MapboxProps {
  areaFilters: Geofence[];
  createNew: boolean;
  selectedAreaFilter: string | undefined;
  focused: Focus | undefined;
  exclude: boolean;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

export function addDragLayers(map: Map) {
  map.addLayer({
    id: "creating_active_filter_moving_text",
    layout: {
      "text-anchor": "bottom",
      "text-field": ["get", "text"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 24,
      "text-offset": [0, -2],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "creating_active_filter_center",
    type: "symbol",
  });

  map.addLayer({
    id: "creating_active_filter_moving_circle",
    paint: {
      "fill-color": "rgba(76, 118, 246, 0.5)",
      "fill-opacity": 0.5,
    },
    source: "creating_active_filter_moving",
    type: "fill",
  });

  map.addLayer({
    id: "active-filter-label",
    layout: {
      "text-anchor": "center",
      "text-field": [
        "case",
        ["==", ["get", "state"], "muted"],
        "Excluded area",
        "",
      ],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 24,
      "text-offset": [0, 0],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "active_filter",
    type: "symbol",
  });
  map.addLayer({
    id: "active_filter",
    paint: {
      "fill-color": "black",
      "fill-opacity": ["case", ["==", ["get", "state"], "muted"], 0.3, 0.0],
    },
    source: "active_filter",
    type: "fill",
  });

  map.addLayer({
    id: "active-filter-exterior-label",
    layout: {
      "text-anchor": "bottom",
      "text-field": "Excluded area",
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 24,
      "text-offset": [0, -2],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "active-filter-exterior",
    type: "symbol",
  });
  map.addLayer({
    id: "active-filter-exterior",
    paint: {
      "fill-color": "black",
      "fill-opacity": 0.3,
    },
    source: "active-filter-exterior",
    type: "fill",
  });
  map.addLayer({
    id: "active-filter-exterior-selected",
    paint: {
      "line-color": "#fbbf24",
      "line-width": [
        "case",
        ["==", ["get", "selected"], true],
        5,
        0, //"red"
      ],
    },
    source: "active-filter-exterior",
    type: "line",
  });
  map.addLayer({
    id: "active_filter_selected",
    paint: {
      "line-color": "#fbbf24",
      "line-width": 5,
    },
    filter: ["==", "selected", "true"],
    source: "active_filter",
    type: "line",
  });
  map.moveLayer(
    "creating_active_filter_moving_circle",
    "creating_active_filter_moving_text"
  );
  map.moveLayer("active_filter", "creating_active_filter_moving_circle");
}

const geofenceToGeoJson = (
  areaFilters: Geofence[],
  selectedAreaFilter: string | undefined
): any => {
  return {
    features: areaFilters.map((geofence) => {
      const polygon = getCirclePolygon(
        geofence.center,
        geofence.radius / 1000
      ) as any;
      polygon.properties = {
        state: geofence.listening ? "listening" : "muted",
        selected: `${geofence.id === selectedAreaFilter}`,
        id: geofence.id,
      };
      polygon.id = geofence.id;

      return polygon;
    }),
    type: "FeatureCollection",
  };
};

const getExterior = (geoJson: any, focused: Focus | undefined): any => {
  const relevantFeatures = geoJson["features"].filter(
    (feature: any) => feature.properties.state === "listening"
  );
  if (relevantFeatures.length > 0) {
    let exterior = mask(geoJson);
    if (exterior !== null && focused && focused.type === FocusType.Coverage) {
      exterior.properties = { selected: true };
    }
    return {
      features: [exterior],
      type: "FeatureCollection",
    };
  }
  return {
    features: [],
    type: "FeatureCollection",
  };
};

function addSource(
  map: Map,
  areaFilters: Geofence[],
  selectedAreaFilter: string | undefined,
  focused: Focus | undefined
) {
  map.addSource("creating_active_filter_center", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  map.addSource("creating_active_filter_moving", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  const geoJson = geofenceToGeoJson(areaFilters, selectedAreaFilter);
  const exterior = getExterior(geoJson, focused);
  map.addSource("active_filter", {
    data: geoJson,
    type: "geojson",
  });

  map.addSource("active-filter-exterior", {
    data: exterior,
    type: "geojson",
  });
}

function updateSource(
  map: Map,
  areaFilters: Geofence[],
  selectedAreaFilter: string | undefined,
  focused: Focus | undefined
) {
  const geoJson = geofenceToGeoJson(areaFilters, selectedAreaFilter);
  const exterior = getExterior(geoJson, focused);
  // @ts-ignore
  map.getSource("active_filter").setData(geoJson);
  // @ts-ignore
  map.getSource("active-filter-exterior").setData(exterior);
}

function updateCircleWithStartDescription(map: Map, exclude: boolean) {
  map
  .getSource("creating_active_filter_center")
  // @ts-ignore
    .setData(
      createGeoJson(
        map.getCenter().toArray(),
        exclude
          ? "Press and drag in map to exclude an area"
          : "Press and drag in map to spotlight an area"
      )
    );
}

function removeStartDescription(map: Map) {
  // @ts-ignore
  map.getSource("creating_active_filter_center").setData({
    features: [],
    type: "FeatureCollection",
  });
}

const AreaFilterLayer = ({
  map,
  loaded,
  areaFilters,
  createNew,
  selectedAreaFilter,
  focused,
  exclude,
}: Props) => {
  useEffect(() => {
    areaFilterOnPress(map);
  }, []);
  useEffect(() => {
    if (map.getSource("creating_active_filter_center")) {
      if (createNew) {
        updateCircleWithStartDescription(map, exclude);
      } else {
        removeStartDescription(map);
      }
    }
  }, [createNew]);
  if (map && loaded) {
    if (!map.getSource("creating_active_filter_moving")) {
      addSource(map, areaFilters, selectedAreaFilter, focused);
      addDragLayers(map);
    } else {
      updateSource(map, areaFilters, selectedAreaFilter, focused);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    areaFilters: state.areaFilter.list,
    createNew: state.areaFilter.createNew,
    selectedAreaFilter: state.areaFilter.selected,
    focused: state.commander.focused,
    exclude: state.areaFilter.exclude,
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  AreaFilterLayer
);
