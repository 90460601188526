import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { MapViewType } from "../../../models/MapViewType";
import { ApplicationState } from "../../../reducers";
import { addMarkerIcons } from "../mapUtil";
import { getGunSvg } from "../svgUtils";

export interface MapboxProps {
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

function addLayer(map: Map) {
  addMarkerIcons([{ name: "gun_icon", imageWidth: 50, imageHeight: 42, svg: getGunSvg() }], map);
  map.addLayer({
    id: "demo-direction",
    paint: {
      "line-color": "yellow",
      "line-width": 5,
      "line-opacity": 1
      
    },
    source: "demo-direction",
    type: "line",
  });

  map.addLayer({
    id: "demo-shooter",
    layout: {
      "icon-allow-overlap": true,
      "icon-anchor": "center",
      "icon-image": "gun_icon",
      "text-allow-overlap": true,
      "text-anchor": "bottom",
      "text-field": "Shooter",
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 18,
      "text-offset": [0, 2],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "demo-shooter",
    type: "symbol",
  });
}

function addSource(map: Map) {
  map.addSource("demo-shooter", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  map.addSource("demo-direction", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
}

const GunshotDirectionlayer = ({ map, loaded }: Props) => {
  if (map && loaded) {
    if (!map.getSource("demo-shooter")) {
      addSource(map);
      addLayer(map);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {

  return {
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  GunshotDirectionlayer
);
