import React, { useEffect, useRef, useState } from "react";
import ActionButton from "../buttons/ActionButton";
import Hr from "../hr/Hr";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import style from "./style.module.scss";

interface Props<T> {
  items: T[];
  onSelect: (_: T) => void;
  selectedItem: T | undefined;
  icon?: JSX.Element;
  itemDescription: (_: T) => string;
  actionItem?: DropdownAction;
}

export interface DropdownAction {
  name: string;
  callback: () => void;
}

function Dropdown<T>({
  items,
  onSelect,
  selectedItem,
  itemDescription,
  actionItem,
  icon
}: Props<T>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div ref={ref} className={style.relativeContainer}>
      <div className={[style.selected, style.styleTwo].join(" ")} onClick={() => setOpen(!open)}>
        {icon !== undefined && icon}
        <p>{selectedItem ? itemDescription(selectedItem) : "Not selected"}</p>
        <ArrowDownIcon
          width={24}
          height={24}
          backgroundColor={"hsl(207, 12%, 43%)"}
        />
      </div>
      {open && (
        <div className={style.items}>
          {items.map((item, i) => (
            <div
              key={`range_${i}`}
              className={style.item}
              style={{
                fontWeight: selectedItem === item ? "bold" : "normal",
              }}
              onClick={() => {
                onSelect(item);
                setOpen(false);
              }}
            >
              {itemDescription(item)}
            </div>
          ))}
          {actionItem && (
            <>
            <Hr/>
            <ActionButton
            text={"NEW LABEL"}
            onClick={() => {
              actionItem.callback();
            }}
            style={{paddingLeft: "1rem"}}
          />
          </>
            // <div
            //   key={`range_action`}
            //   className={style.item}
            //   style={{
            //     fontWeight: "normal",
            //     color: "blue",
            //   }}
            //   onClick={() => {
            //     actionItem.callback();
            //   }}
            // >
            //   {actionItem.name}
            // </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
