import * as React from "react";
import  CommanderLogo from "../logo/CommanderLogo";
import CircleSpinner from "./CircleSpinner";
import styles from "./style.module.css";

class LoadingVanilla extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <div id={"loading_screen"} className={styles.centerContent}>
        <div className={styles.logoWrapper}>
          <div className={styles.vanillaLoaderContainer}>

        <CommanderLogo />
        <CircleSpinner />
        </div>
        </div>
      </div>
    );
  }
}

export default LoadingVanilla;
