import * as React from "react";

interface Props {
  backgroundColor: string;
  width: number;
  height: number;
}

const WarningIcon = ({ width, height, backgroundColor }: Props) => (
  <svg
    style={{ textDecoration: "none", pointerEvents: "none" }}
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Alert"
        transform="translate(-193.000000, -50.000000)"
        fill={backgroundColor}
      >
        <g id="ic_warning" transform="translate(192.000000, 48.000000)">
          <g id="Icon-24px">
            <path
              d="M1,17 L19,17 L10,2 L1,17 L1,17 Z M10.8181818,14.6315789 L9.18181818,14.6315789 L9.18181818,13.0526316 L10.8181818,13.0526316 L10.8181818,14.6315789 L10.8181818,14.6315789 Z M10.8181818,11.4736842 L9.18181818,11.4736842 L9.18181818,8.31578947 L10.8181818,8.31578947 L10.8181818,11.4736842 L10.8181818,11.4736842 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WarningIcon;