import * as React from "react";
import style from "./style.module.scss";

const RoundLabel = ({ letter, color }: { letter: string; color: string }) => {
  return (
    <div style={{ background: color }} className={style.circle}>
      {letter}
    </div>
  );
};

export default RoundLabel;
