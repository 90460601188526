import { MinimalScout, Scout, ScoutState, TimeState } from "../models/scouts";
import { getDiffMsSinceLastSeen } from "./util";

export const getMarkerLetter = (scout: Scout) => {
  if (scout.status.displayName) {
    return scout.status.displayName.charAt(0).toUpperCase();
  } else {
    return scout.status.deviceId.charAt(0).toUpperCase();
  }
};

export const SCOUT_OFFLINE_AFTER_MS = 60 * 1000 * 5;
// Temporary changed to one hour
export const SCOUT_OFFLINE_FOR_A_WEEK_MS = 60 * 1000 * 60 * 24 * 7;
// export const SCOUT_OFFLINE_FOR_A_WEEK_MS = 60 * 1000 * 60;

export const getStateFor = (scout: MinimalScout, modifiedMsg: boolean = false) => {
  const diffMs = getDiffMsSinceLastSeen(scout);
  const notHeardFromInAWeek =
    diffMs === undefined || diffMs >= SCOUT_OFFLINE_FOR_A_WEEK_MS;
  const notHeardFromInAWhile = diffMs !== undefined && diffMs >= SCOUT_OFFLINE_AFTER_MS;
  return (!modifiedMsg && (notHeardFromInAWeek || scout.config?.hidden)) ? ScoutState.OfflineIgnore: (!modifiedMsg && notHeardFromInAWhile)
    ? ScoutState.Offline
    : isInitializing(scout)
    ? ScoutState.Initializing
    : isDownloadingModels(scout) 
    ? ScoutState.DownloadingDetector
    : modelsAreUnavailable(scout)
    ? ScoutState.DetectionUnavailable
    : isInaccurateLocation(scout)
    ? ScoutState.InaccurateLocation
    : !scout.status.listening
    ? ScoutState.Ready
    : isDisabled(scout) 
    ? ScoutState.Disabled
    : ScoutState.Listening;
};

export const stateChangeToMessage = (
  diffInMinutes: number,
  state: ScoutState
) => {
  if (state === ScoutState.Offline && diffInMinutes < 1) {
    return "Offline seconds ago";
  } else if (state === ScoutState.Offline) {
    return `Offline ${diffInMinutes} minutes ago`;
  } else if (diffInMinutes < 1) {
    return "Online seconds ago";
  } else {
    return `Online ${diffInMinutes} minutes ago`;
  }
};

function isInaccurateLocation(scout: MinimalScout) {
  return scout.status.scoutLocation === undefined ||
    scout.status.scoutLocation === null;
}

function isDisabled(scout: MinimalScout): boolean {
  return process.env.REACT_APP_ADMIN !== undefined && scout.status.detector?.disabled !== undefined && scout.status.detector?.disabled !== null;
}

function isInitializing(scout: MinimalScout) {
  return scout.status.latitude === undefined ||
    scout.status.latitude === null ||
    scout.status.longitude === undefined ||
    scout.status.longitude === null ||
    scout.status.timeState !== TimeState.Ready ||
    scout.status.model === "None";
}

function isDownloadingModels(scout: MinimalScout) {
  return scout.status.downloading;
}

function modelsAreUnavailable(scout: MinimalScout) {
  return scout.status.modelsAvailable !== undefined && !scout.status.modelsAvailable && scout.status.model !== "None";
}

