import * as React from "react";
import {CSSProperties} from "react";
import styles from "./style.module.scss";

interface Props {
    children: JSX.Element;
    cssProperties: CSSProperties;
}

const Button = ({children, cssProperties}: Props) => {

    const className = [
        styles.normalButton,
        styles.shadow,
    ];

    return (
        <div style={cssProperties} className={className.join(" ")}>
            {children}
        </div>
    );
};

export default Button;
