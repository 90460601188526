import * as React from "react";
import { connect } from "react-redux";
import { Replay } from "../../models/gunshots";
import styles from "./style.module.scss";
import moment from "moment";

const ProgressBarInformation = ({ replay, rangeEnd }: Props) => {
  if (replay && rangeEnd) {
    const gunshotsLeft = replay.gunshots.filter(t => t > rangeEnd);
    return (
      <p className={styles.infoBox}>
        {moment(new Date(rangeEnd)).format("LLL")} — Replayed{" "}
        {replay.gunshots.length - gunshotsLeft.length} out of{" "}
        {replay.gunshots.length} gunshots
      </p>
    );
  } else {
    return <></>;
  }
};

interface Props {
  replay: Replay | undefined;
  rangeEnd: number | undefined;
}

const mapStateToPropsForOverlay = (state: any) => ({
  rangeEnd: state.commander.rangeEnd,
  replay: state.commander.replay,
});

export default connect<Props, {}, {}>(mapStateToPropsForOverlay)(
  ProgressBarInformation
);
