import * as React from "react";
import HeaderOnlyLogo from "../../components/header/HeaderOnlyLogo";
import { StatusMessage, statusMessageIsCritical } from "../../models/warning";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styles from "./style.module.scss";
import NotificationBox, {
  NotificationColors,
} from "../../components/notification/NotificationBox";
import { Link } from "react-router-dom";
import { ApplicationState } from "../../reducers";

const StatusElem = ({ statusMessage }: { statusMessage: StatusMessage }) => {
  return (
    <div className={styles.criticalItem}>
      <p className={styles.label}>{statusMessage.component}</p>
      <p>{statusMessage.message}</p>
    </div>
  );
};

const StatusLink = () => {
  return (
    <div>
      <Link id={"go_to_status"} to={"/status"}>
        <p className={styles.statusLink}>Go to status page</p>
      </Link>
    </div>
  );
};

const Content = ({ statusMessages }: { statusMessages: StatusMessage[] }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NotificationBox
          message={`${statusMessages.length} critical issue${
            statusMessages.length > 1 ? "s" : ""
          }`}
          color={NotificationColors.Critical}
        />
        {statusMessages.map((x) => (
          <StatusElem key={x.id} statusMessage={x} />
        ))}
        {process.env.REACT_APP_BOX && <StatusLink />}
      </div>
    </div>
  );
};

const CriticalPage = ({ criticalIssues }: Props) => {
  return (
    <main className={styles.criticalPage}>
      <HeaderOnlyLogo />
      <Content statusMessages={criticalIssues} />
    </main>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  criticalIssues: StatusMessage[];
}

interface DispatchToProps {}

const mapStateToProps = (state: ApplicationState) => ({
  criticalIssues: Object.values(state.status.list).filter((x) =>
    statusMessageIsCritical(x as StatusMessage)
  ) as StatusMessage[],
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(CriticalPage);
