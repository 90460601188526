import * as React from "react";
import { Suspense } from "react";
import * as ReactDOM from "react-dom";
import LoadingVanilla from "./components/loading/LoadingVanilla";

const ConnectedAdminApp = React.lazy(() => import("./connected/ConnectedAdminApp"));

export const renderAdmin = () => {

  ReactDOM.render(
    <Suspense fallback={<LoadingVanilla/>}>
      <ConnectedAdminApp />
    </Suspense>,
    document.getElementById("root")
  );
};
