import {store} from "./ConfiguredApp";
import {
    bearingInMilsAction,
    coverageOnAction,
    setCleanupTimestampAction,
    setCoverageSettingsAction,
    setMgrsModeAction,
    setShowOnlyFocusedShotAction,
    setShowScoutRangeAction,
    showHyperbolasAction,
} from "./containers/commander/commanderReducer";
import {twinToDisplayAction} from "./containers/triangulations/triangulationReducer";
import {CoverageSettings} from "./models/coverage";
import {MgrsMode, TwinDisplayMode} from "./models/map";
import { parseJwt } from "./util/util";

export interface TriangulaLocalStorage {
    bearingInMils: boolean;
    showHyperbolas: boolean;
    coverageOn: boolean;
    coverageSettings: CoverageSettings;
    cleanupTimestamp: number;
    showOnlyFocusedShot: boolean;
    twinToDisplay: TwinDisplayMode;
    mgrsMode: MgrsMode;
    showScoutRange: boolean;
}

export const subscribeForLocalStateChanges = () => {
    store.subscribe(() => {
        localStorage.setItem(
            "coverageSettings.landscape",
            `${store.getState().commander.coverageSettings.landscape}`
        );
        localStorage.setItem(
            "coverageSettings.weapon",
            `${store.getState().commander.coverageSettings.weapon}`
        );
        localStorage.setItem(
            "coverageSettings.listeningScouts",
            `${store.getState().commander.coverageSettings.listeningScouts}`
        );
        localStorage.setItem(
            "coverageOn",
            `${store.getState().commander.coverage}`
        );
        localStorage.setItem(
            "cleanupTimestamp",
            `${store.getState().commander.cleanupTimestamp}`
        );
        localStorage.setItem(
            "showOnlyFocusedShot",
            `${store.getState().commander.showOnlyFocusedShot}`
        );
        localStorage.setItem(
            "bearingInMils",
            `${store.getState().commander.bearingInMils}`
        );
        localStorage.setItem(
            "showHyperbolas",
            `${store.getState().commander.showHyperbolas}`
        );
        localStorage.setItem(
            "twinToDisplay",
            `${store.getState().triangulation.twinToDisplay}`
        );
        localStorage.setItem(
            "mgrsMode",
            `${store.getState().commander.mgrsMode}`
        );
        localStorage.setItem(
            "showScoutRange",
            `${store.getState().commander.showScoutRange}`
        );
    });
};

export const initialLocalState = () => {
    const localState = getLocalState();
    //@ts-ignore
    store.dispatch(coverageOnAction(localState.coverageOn));
    //@ts-ignore
    store.dispatch(setCoverageSettingsAction(localState.coverageSettings));
    //@ts-ignore
    store.dispatch(setCleanupTimestampAction(localState.cleanupTimestamp));
    //@ts-ignore
    store.dispatch(setShowOnlyFocusedShotAction(localState.showOnlyFocusedShot));
    //@ts-ignore
    store.dispatch(bearingInMilsAction(localState.bearingInMils));
    //@ts-ignore
    store.dispatch(showHyperbolasAction(localState.showHyperbolas));
    //@ts-ignore
    store.dispatch(twinToDisplayAction(localState.twinToDisplay));
    //@ts-ignore
    store.dispatch(setMgrsModeAction(localState.mgrsMode));
    //@ts-ignore
    store.dispatch(setShowScoutRangeAction(localState.showScoutRange));
};

const getLocalState = (): TriangulaLocalStorage => {
    try {
        const twinToDisplay = localStorage.getItem("twinToDisplay");
        const twinToDisplayOrDefault = (twinToDisplay !== "undefined" && twinToDisplay !== null && twinToDisplay !== undefined)  ? +(twinToDisplay) : 0;
        const mgrsMode = localStorage.getItem("mgrsMode");
        const mgrsModeOrDefault = (mgrsMode !== "undefined" && mgrsMode !== null && mgrsMode !== undefined && MgrsMode[+(mgrsMode)] !== undefined)  ? +(mgrsMode) : 0;
        return {
            bearingInMils:  localStorage.getItem("bearingInMils") === "true" || false,
            cleanupTimestamp: +(localStorage.getItem("cleanupTimestamp") || "0"),
            coverageOn: localStorage.getItem("coverageOn")
                ? localStorage.getItem("coverageOn") === "true"
                : false,
            coverageSettings: {
                landscape: +(localStorage.getItem("coverageSettings.landscape") || "2"),
                listeningScouts:
                    localStorage.getItem("coverageSettings.listeningScouts") === "true" ||
                    false,
                weapon: +(localStorage.getItem("coverageSettings.weapon") || "3"),
            },
            mgrsMode: mgrsModeOrDefault,
            showHyperbolas:  localStorage.getItem("showHyperbolas") === "true" || false,
            showOnlyFocusedShot: localStorage.getItem("showOnlyFocusedShot") === "true" || false,
            twinToDisplay: twinToDisplayOrDefault,
            showScoutRange:  localStorage.getItem("showScoutRange") === "true" || false,
        };
    } catch (e) {
        return {
            bearingInMils: false,
            cleanupTimestamp: 0,
            coverageOn: false,
            coverageSettings: {landscape: 2, weapon: 3, listeningScouts: false},
            mgrsMode: MgrsMode.one_meter_accuracy,
            showHyperbolas: false,
            showOnlyFocusedShot: false,
            twinToDisplay: TwinDisplayMode.All,
            showScoutRange: false
        };
    }
};

export const getBoxJwtFromLocalStorage = (): string | undefined => {
    try {
        const jwtInStorage = localStorage.getItem("box.token") ?? undefined;

        if (jwtInStorage) {
            console.log(Date.now(), parseJwt(jwtInStorage).exp * 1000, parseJwt(jwtInStorage).exp * 1000 - Date.now())
        }
        
        // If more than a minute left before exipred, return token
        // else force new login by returning undefined
        if (jwtInStorage && (parseJwt(jwtInStorage).exp * 1000 - Date.now()  > 60_000)) {
            return jwtInStorage;
        } else {
            return undefined
        }
    } catch (e) {
        return undefined;
    }
};

export const updateBoxJwtInLocalStorage = (jwtToken: string) => {
    localStorage.setItem(
        "box.token",
        jwtToken
    );
}
