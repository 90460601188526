import { Action, Reducer } from "redux";
import { LatLon } from "../../models/location";

const SHOOTER_LOCATION = "SHOOTER_LOCATION";

export interface ShooterLocation extends Action {
  type: "SHOOTER_LOCATION";
  shooterLocation: LatLon | undefined;
}

export const setShooterLocationAction = (
  shooterLocation: LatLon | undefined
): ShooterLocation => {
  return { type: SHOOTER_LOCATION, shooterLocation };
};

export interface DemoState {
  shooterLocation: LatLon | undefined;
}

export const commanderInitialState: DemoState = {
  shooterLocation: undefined,
};

const reducer: Reducer<DemoState> = (
  state: DemoState = commanderInitialState,
  action
) => {
  switch ((action as Action).type) {
    case SHOOTER_LOCATION: {
      return {
        ...state,
        shooterLocation: action.shooterLocation,
      };
    }
    default:
      return state;
  }
};

export default reducer;
