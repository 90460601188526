import * as React from "react";
import { CSSProperties } from "react";
import style from "./style.module.scss";

interface Props {
  id: string;
  cssProperties: CSSProperties;
  text: string;
  onClick: () => void;
}

export const BigRoundButton = ({ id, text, onClick, cssProperties }: Props) => (
  <div
    id={id}
    style={cssProperties}
    className={style.bigRoundButton}
    onClick={onClick}
  >
    {text}
  </div>
);

export const RoundButton = ({ id, text, onClick, cssProperties }: Props) => (
  <div id={id} style={cssProperties} className={style.roundButton} onClick={onClick}>
    {text}
  </div>
);

export default RoundButton;
