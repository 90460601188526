import "firebase/compat/firestore";
import * as React from "react";
import App from "../App";
import ConfiguredApp, {
  setupCommanderStore,
  setupFirestoreConnection,
  setupLocalState,
} from "../ConfiguredApp";
// @ts-ignore
import("../global_style/style.css");
// @ts-ignore
import("../fonts.css");
// @ts-ignore
import("../containers/styles/global-style.scss");



const ConnectedApp = () => {
  setupCommanderStore();
  setupFirestoreConnection();
  setupLocalState();
  
  return <ConfiguredApp><App /></ConfiguredApp>;
}

export default ConnectedApp
