import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import MainMap from "../../components/mapbox/MainMap";
import { IfMd } from "../../components/responsive/responsiveUtil";
import { ApplicationState } from "../../reducers";
import { setUserAction } from "../authentication/authenticationReducer";
import CoverageComponent from "../coverage/Coverage";
import GeofenceView from "../geofence/GeofenceView";
import AddGeofenceButton from "../map/AddGeofenceButton";
import ButtonGroup from "../map/ButtonGroup";
import InFocusButton from "../map/InFocusButton";
import ResetPitchAndBearingButton from "../map/ResetPitchAndBearingButton";
import SwitchMapButton from "../map/SwitchMapButton";
import LoadingContent from "../notification/LoadingContent";
import OverlaySlider from "../slider/OverlaySlider";
import CoverageOverlay from "../status/CoverageOverlay";
import NotificationOverlay from "../status/NotificationOverlay";
import MenuBar from "./MenuBar";
import Panel from "./Panel";
import styles from "./style.module.scss";

export class OverviewPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  public handleSignOut() {
    firebase
      .auth()
      .signOut()
      .then(() => this.props.setUser(undefined));
  }

  public render() {
    return (
      <div id="overview_page" className={styles.overviewPage}>
        <MenuBar />
        <NotificationOverlay />
        <ButtonGroup>
          <>
            <SwitchMapButton />
            <ResetPitchAndBearingButton />
            <InFocusButton />
            {this.props.geofenceMode && <AddGeofenceButton/>}
          </>
        </ButtonGroup>
        <Panel />
        <CoverageOverlay />
        <GeofenceView />
        <MainMap />
        <CoverageComponent />
        <LoadingContent />
        {process.env.REACT_APP_ADMIN && (
          <IfMd>
            <OverlaySlider />
          </IfMd>
        )}
      </div>
    );
  }
}

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  initialLoadingDone: boolean;
  jwtToken: string | undefined;
  geofenceMode: boolean;
}

interface DispatchToProps {
  setUser: (_: firebase.User | undefined) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  initialLoadingDone: state.commander.initialLoadingDone,
  jwtToken: state.commander.jwtToken,
  geofenceMode: state.geofence.show
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(OverviewPage);
