import { LngLat } from "mapbox-gl";
import { LatLon } from "./location";

export interface ScoutConfig {
  hidden: boolean;
  markerLetter: string;
}


export interface ScoutLocation {
  numberOfSatellites?: number;
  altitude: number;
  latitude: number;
  longitude: number;
  accuracy?: number;
  speedMeterPerSecond?: number;
  speedAccuracy?: number;

}

export interface SampleAverage {
  sampleAverage: number;
  sampleCount: number;
}

export interface AudioStatistics {
  sampleAverages: SampleAverage[];
}

export interface ScoutLocationAndRange extends LatLon {
  latitude: number;
  longitude: number;
  range: number;
}

export interface TimeService {
  details: string;
}

export interface ClockDetails {
  lastUpdate?: number
  lastSynchronized?: number;
  name: string;
  state: number;
  accuracy: number;
}

export interface MinimalScoutStatus {
  latitude: number;
  longitude: number;
  altitude: number;
  deviceId: string;
  dateTime: number;
  listening: boolean;
  detector?: Detection;
  batteryPercent: number;
  state: ScoutState;
}
export interface ScoutStatus extends MinimalScoutStatus {
  appVersion: string;
  clockAccuracy: number;
  created: string;
  detectorId?: string;
  gain?: number;
  timeServiceName?: string;
  audioLatencyMs?: number;
  deviceInfo?: DeviceInfo;
  id: string;
  referenceMode: boolean;
  searchMode: boolean;
  threshold: number;
  predictionThreshold?: number;
  model: string | undefined;
  timeOffset: number;
  timeState: number;
  displayName?: string;
  usbCharge?: boolean;
  acCharge?: boolean;
  receivedTut: number;
  time?: ClockDetails;
  scoutLocation?: ScoutLocation;
  audioStatistics?: AudioStatistics;
  moving?: boolean;
  fixedLocation?: boolean;
  downloading?: boolean;
  uptime?: number;
  modelsAvailable?: boolean;
  upload?: boolean;
  timeService?: TimeService;
}

export interface ScoutDisabled {
  metric: string;
  value: string;
}
export interface Detection {
  range: number;
  disabled?: ScoutDisabled;
}

export interface DeviceInfo {
  manufacturer: string;
  model: string;
  sdk: string;
}

export enum ScoutPositionState {
  // noinspection JSUnusedGlobalSymbols
  Unknown = 1,
  Rough = 2,
  Precise = 3,
}

export enum TimeState {
  // noinspection JSUnusedGlobalSymbols
  Unknown = 0,
  Initializing = 1,
  Ready = 2,
}

export enum ScoutState {
  Offline = 1, // Not heard from since a tut - ideally, no connections
  Initializing = 3, // Ge tting tut and position
  Ready = 4, // Ready to record or sync
  Listening = 6,
  InaccurateLocation = 7,
  OfflineIgnore = 8, // Not heard from in such a long time so it should be ignored
  DownloadingDetector = 9, // Downloading models used for detection
  DetectionUnavailable = 10, // Models are not available and therefore not listening for gunshots
  Disabled = 11, // Disabled because of bad audio quality
}

export interface Scout extends MinimalScout {
  deviceId: string;
  state: ScoutState;
}

export interface ScoutPosition {
  position: LngLat;
  time: number;
}

export interface MinimalScout {
  config: ScoutConfig;
  threshold: number | undefined;
  detectorId?: string;
  gain?: number;
  timeServiceName?: string;
  predictionThreshold: number | undefined;
  latitude?: number;
  longitude?: number;
  fixedLocation?: boolean;
  model: string | undefined;
  status: ScoutStatus;
  lastChangedStatus?: Date;
  connections: { [id: string]: boolean };
  unhandledPotentialGunshots: number;
}
