import { Map } from "mapbox-gl";
import { store } from "../../ConfiguredApp";
import { setShooterLocationAction } from "./demoReducer";
import { startSimulation } from "./demoService";
import { simulateGunshot } from "./simulationService";

export const addDemoEvents = (map: Map) => {
  map.on("contextmenu", (e) => {
    // If shooter is not set: Set shooter
    //@ts-ignore
    map.getSource("demo-shooter").setData({
      features: [
        {
          geometry: {
            coordinates: [e.lngLat.lng, e.lngLat.lat],
            type: "Point",
          },
          properties: {},
          type: "Feature",
        },
      ],
      type: "FeatureCollection",
    });
    store.dispatch(
      setShooterLocationAction({
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
      })
    );
  });

  map.on("click", (e) => {
    // If shooter is not set: Set shooter
    if (store.getState().demo.shooterLocation && !store.getState().featureSwitch.demo) {
      // Shooter is set, call simulation and reset shooter
      startSimulation(store.getState().demo.shooterLocation, {
        longitude: e.lngLat.lng,
        latitude: e.lngLat.lat,
      });

      cleanUpState(map);
    }
  });
  

  map.on("mousemove", (e) => {
    if (store.getState().demo.shooterLocation) {
      //@ts-ignore
      map.getSource("demo-direction").setData({
        features: [
          {
            geometry: {
              coordinates: [
                [
                  store.getState().demo.shooterLocation.longitude,
                  store.getState().demo.shooterLocation.latitude,
                ],
                [e.lngLat.lng, e.lngLat.lat],
              ],
              type: "LineString",
            },
          },
        ],
        type: "FeatureCollection",
      });
    }
  });
};


export const addSimulationEvents = (map: Map) => {
  map.on("contextmenu", (e) => {
    // If shooter is not set: Set shooter
    //@ts-ignore
    map.getSource("demo-shooter").setData({
      features: [
        {
          geometry: {
            coordinates: [e.lngLat.lng, e.lngLat.lat],
            type: "Point",
          },
          properties: {},
          type: "Feature",
        },
      ],
      type: "FeatureCollection",
    });
    store.dispatch(
      setShooterLocationAction({
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
      })
    );
  });

  map.on("click", (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const planId = urlParams.get("id")!; // TODO Handle case where no ID is created
    // If shooter is not set: Set shooter
    if (store.getState().demo.shooterLocation && store.getState().featureSwitch.demo) {
      // Shooter is set, call simulation and reset shooter
      
      simulateGunshot(planId, store.getState().demo.shooterLocation, {
        longitude: e.lngLat.lng,
        latitude: e.lngLat.lat,
      }, store.getState().scout.list, store.getState().commander.jwtToken);

      cleanUpState(map);
    }
  });
  

  map.on("mousemove", (e) => {
    if (store.getState().demo.shooterLocation) {
      //@ts-ignore
      map.getSource("demo-direction").setData({
        features: [
          {
            geometry: {
              coordinates: [
                [
                  store.getState().demo.shooterLocation.longitude,
                  store.getState().demo.shooterLocation.latitude,
                ],
                [e.lngLat.lng, e.lngLat.lat],
              ],
              type: "LineString",
            },
          },
        ],
        type: "FeatureCollection",
      });
    }
  });
};

function cleanUpState(map: Map) {
  store.dispatch(setShooterLocationAction(undefined));
  //@ts-ignore
  map.getSource("demo-shooter").setData({
    features: [],
    type: "FeatureCollection",
  });

  // Reset direction as well
  //@ts-ignore
  map.getSource("demo-direction").setData({
    features: [],
    type: "FeatureCollection",
  });
}
