import { LngLat, Map } from "mapbox-gl";
import * as React from "react";
import { CSSProperties } from "react";

import { store } from "../../ConfiguredApp";
import { MapViewType } from "../../models/MapViewType";
import { Feature } from "../../models/featureSwitch";
import FeatureSwitch from "../featureSwitch/FeatureSwitch";
import MapboxContainer from "./MapboxContainer";
import AlphaGunshotDirectionLayer from "./layers/AlphaGunshotDirectionLayer";
import AreaFilterLayer from "./layers/AreaFilterLayer";
import CoverageHeatmapLayer from "./layers/CoverageHeatmapLayer";
import DemoLayer from "./layers/DemoLayer";
import DistanceLayer from "./layers/DistanceLayer";
import DragLayer from "./layers/DragLayer";
import DynamicRangeLayer from "./layers/DynamicRangeLayer";
import FixedScoutLayer from "./layers/FixedScoutLayer";
import FocusStaticTriangulationLayer from "./layers/FocusStaticTriangulationLayer";
import GpsAccuracyLayer from "./layers/GpsAccuracyLayer";
import GunshotBlinkingLayer from "./layers/GunshotBlinkingLayer";
import GunshotDirectionLayer from "./layers/GunshotDirectionLayer";
import GunshotLayers from "./layers/GunshotLayer";
import HyperbolasLayer from "./layers/HyperbolaLayer";
import NewGunshotManager from "./layers/NewGunshotManager";
import PlayingScoutLayer from "./layers/PlayingScoutLayer";
import ScoutLayer from "./layers/ScoutLayer";
import ScoutPositionHistoryLayer from "./layers/ScoutPositionHistoryLayer";
import ScoutRangeLayer from "./layers/ScoutRangeLayer";
import TriangulationBlinkingLayer from "./layers/TraingulationBlinkingLayer";
import TriangulationLinesLayer from "./layers/TriangulationLinesLayer";
import TwinLinesLayer from "./layers/TwinLinesLayer";
import ZoneLayer from "./layers/ZoneLayer";

export interface Props {
  map: Map | undefined;
  mapRef: React.RefObject<HTMLDivElement>;
  styling: CSSProperties;
  focused: LngLat | undefined;
  loaded: boolean;
  autoFocus: boolean;
  currentMapType: MapViewType;
  mapType?: string | undefined;
}

const Mapbox = (props: Props) => {
  const map = props.map;
  return (
    <>
      {map !== undefined && (
        <>
          <ScoutLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          {/* <FocusedTriangulationLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          /> */}
          <FocusStaticTriangulationLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          <GunshotLayers
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
            mapType={props.mapType}
          />
          {!store.getState().commander.admin && (
            <>
            <TriangulationBlinkingLayer
              map={map}
              loaded={props.loaded}
              currentMapType={props.currentMapType}
            />
          <GunshotBlinkingLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          </>
          )}
          <NewGunshotManager
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />

          <TriangulationLinesLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          <TwinLinesLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
            mapType={props.mapType}
          />
          <ScoutPositionHistoryLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          <DistanceLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          <FeatureSwitch feature={Feature.Direction}>
            <GunshotDirectionLayer
              map={map}
              loaded={props.loaded}
              currentMapType={props.currentMapType}
            />
          </FeatureSwitch>
          <FixedScoutLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          <DemoLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />

          <DragLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
          <CoverageHeatmapLayer
            map={map}
            loaded={props.loaded}
            currentMapType={props.currentMapType}
          />
            <AreaFilterLayer
              map={map}
              loaded={props.loaded}
              currentMapType={props.currentMapType}
            />
          <ZoneLayer
                map={map}
                loaded={props.loaded}
                currentMapType={props.currentMapType}
              />
          {store.getState().commander.admin && (
            <>
              <ScoutRangeLayer
                map={map}
                loaded={props.loaded}
                currentMapType={props.currentMapType}
              />
              <HyperbolasLayer
                map={map}
                loaded={props.loaded}
                currentMapType={props.currentMapType}
              />
              <AlphaGunshotDirectionLayer
                map={map}
                loaded={props.loaded}
                currentMapType={props.currentMapType}
              />
              <GpsAccuracyLayer
                map={map}
                loaded={props.loaded}
                currentMapType={props.currentMapType}
              />
              <DynamicRangeLayer
                map={map}
                loaded={props.loaded}
                currentMapType={props.currentMapType}
              />
              <PlayingScoutLayer map={map} loaded={props.loaded} />
              
            </>
          )}
        </>
      )}
      <MapboxContainer {...props} />
    </>
  );
};

export default Mapbox;
