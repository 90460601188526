import axios from "axios";
import { Plan, PlanWithoutUpdate } from "../models/plan";
import { createHeaders, getHostname } from "./serviceUtil";


let updatePlanThrottleTimeout: NodeJS.Timer | undefined;

function plan2PlanRequest(plan: Plan) {
    return {
        coverageArea: plan.coverageArea,
        id: plan.id,
        labels: plan.labels,
        name: plan.name,
        scouts: plan.scouts,
    } as PlanWithoutUpdate;
}

export const updatePlan = (plan: Plan, jwtToken: string) => {
    if (updatePlanThrottleTimeout) {
        return;
    }
    updatePlanThrottleTimeout = setTimeout(() => {
        updatePlanThrottleTimeout = undefined;
    }, 500);
    axios
        .put(
            `${getHostname()}/alpha/plans/${plan.id}`,
            plan2PlanRequest(plan),
            createHeaders(jwtToken)
        )
        .then(response => {
            if (updatePlanThrottleTimeout) {
                clearTimeout(updatePlanThrottleTimeout);
                updatePlanThrottleTimeout = undefined;
            }
            if (response.status === 200) {
                // tslint:disable-next-line: no-empty
            } else {
            }
        });
};

export const createPlan = (plan: Plan, jwtToken: string, callback: (id: string) => void, onError: () => void) => {
    axios
        .post(
            `${getHostname()}/alpha/plans`,
            plan2PlanRequest(plan),
            createHeaders(jwtToken)
        )
        .then(response => {
            if (response.status === 200) {
                callback(response.data.id);
                // tslint:disable-next-line: no-empty
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        });
};

export const deletePlan = (plan: Plan, jwtToken: string) => {
    axios
        .delete(
            `${getHostname()}/alpha/plans/${plan.id}`,
            createHeaders(jwtToken)
        )
};

export const activatePlan = (plan: Plan, jwtToken: string) => {
    axios
        .put(
            `${getHostname()}/plans/${plan.id}/activate`,
            {},
            createHeaders(jwtToken)
        )
};


export const deactivatePlan = (plan: Plan, jwtToken: string) => {
    axios
        .put(
            `${getHostname()}/plans/${plan.id}/deactivate`,
            {},
            createHeaders(jwtToken)
        )
};