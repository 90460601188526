import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import SmallLeftArrow from "../../components/icons/SmallLeftArrowIcon";
import SmallRightArrow from "../../components/icons/SmallRightArrowIcon";
import { Replay } from "../../models/gunshots";
import { ApplicationState } from "../../reducers";
import { prettyTime } from "../../util/util";
import PlayTimeFrameButton from "../map/PlayTimeFrameButton";
import common from "../styles/style.module.scss";
import DualSlider from "./DualSlider";
import ProgressBar from "./ProgressBar";
import style from "./style.module.scss";

const SliderInfo = ({ rangeStart, rangeEnd, pressed, released }: Props) => {
  const styling = [
    style.sliderInfo,
    pressed || rangeEnd !== undefined || rangeStart !== undefined
      ? style.pressedAnimation
      : style.releasedAnimation,
  ].join(" ");

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{ display: "flex", alignItems: "center" }}
        className={styling}
      >
        {rangeStart !== undefined && <SmallLeftArrow />}
        <p
          style={{ marginLeft: "5px", marginRight: "5px" }}
          className={common.noMargin}
        >
          Timeline -{" "}
          {rangeStart ? prettyTime(new Date(rangeStart)) : "one week ago"} to{" "}
          {rangeEnd ? prettyTime(new Date(rangeEnd)) : "now"}
        </p>
        {rangeEnd !== undefined && <SmallRightArrow />}
      </div>
    </div>
  );
};

interface Props extends SliderInfoProps, OwnProps {}

interface SliderInfoProps {
  rangeEnd: number | undefined;
  rangeStart: number | undefined;
}

interface OwnProps {
  pressed: boolean;
  released: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
  };
};

const ConnectedSliderInfo = connect<SliderInfoProps, {}, OwnProps, ApplicationState>(
  mapStateToProps
)(SliderInfo);

const OverlaySlider = ({ replay }: OverlayProps) => {
  const [press, setPress] = useState(false);
  const [release, setRelease] = useState(false);
  return (
    <div>
      <div className={style.sliderOverlay}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <PlayTimeFrameButton />
          {!replay && (
            <div className={style.infoAndSlider}>
              <ConnectedSliderInfo pressed={press} released={release} />
              <DualSlider setPress={setPress} setRelease={setRelease} />
            </div>
          )}
          {replay && <ProgressBar replay={replay} />}
        </div>
      </div>
    </div>
  );
};

interface OverlayProps {
  replay: Replay | undefined;
}

const mapStateToPropsForOverlay = (state: any) => ({
  replay: state.commander.replay,
});

export default connect<OverlayProps, {}, {}>(mapStateToPropsForOverlay)(
  OverlaySlider
);
