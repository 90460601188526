import bezierSpline from "@turf/bezier-spline";
import { Map } from "mapbox-gl";
import { connect } from "react-redux";

import { DirectionAndLocation } from "../../../admin/container/adminReducer";
import { MapViewType } from "../../../models/MapViewType";
import { FocusType } from "../../../models/map";
import { ApplicationState } from "../../../reducers";
import { coordLengthBearingToCoord } from "../../../util/mapUtil";

export interface MapboxProps {
  direction: DirectionAndLocation | undefined;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

function addLayer(map: Map) {
  map.addLayer({
    id: "alpha-gunshot-direction",
    paint: {
      "line-color": ["get", "lineColor"],
      // "line-dasharray": [4, 1],
      "line-width": 5,
      "line-opacity": ["get", "opacity"],
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    source: "alpha-gunshot-direction-lines",
    type: "line",
  });

  map.addLayer({
    id: "alpha-gunshot-shadow",
    paint: {
      "line-color": "#000000",
      "line-width": 20,
      "line-blur": 20,
      "line-opacity": 0.2,
    },
    layout: {
      "line-join": "bevel",
      "line-cap": "round",
    },
    source: "alpha-gunshot-direction-lines",
    type: "line",
  });

  map.addLayer({
    id: "alpha-gunshot-fill",
    paint: {
      "fill-color": ["get", "color"],
      "fill-opacity": ["get", "fillopacity"],
    },
    layout: {},
    source: "alpha-gunshot-direction-lines",
    type: "fill",
  });
}

function addSource(map: Map, direction: DirectionAndLocation | undefined) {
  map.addSource("alpha-gunshot-direction-lines", {
    data: lines(direction),
    type: "geojson",
  });
}

export const lines = (
  direction: DirectionAndLocation | undefined
): GeoJSON.FeatureCollection => {
  return {
    features: direction ? createFeatures(direction) : [],
    type: "FeatureCollection",
  };
};

const createFeatures = (direction: DirectionAndLocation): GeoJSON.Feature[] => {
  return direction.direction.map((sector) => {
    const lengthOfThisSector = 0.1 * Math.pow(sector.score, 6);
    const coord1 = coordLengthBearingToCoord(
      direction.latLon,
      sector.center + sector.size / 2,
      lengthOfThisSector
    );
    const coord2 = coordLengthBearingToCoord(
      direction.latLon,
      sector.center - sector.size / 2,
      lengthOfThisSector
    );

    const coordMid = coordLengthBearingToCoord(
      direction.latLon,
      sector.center,
      lengthOfThisSector + (sector.size/(360 * 5)) * (lengthOfThisSector/3)
    );

    // Apply a bezier spline to get a smooth curve
    const curvedBase = bezierSpline(
      {
        geometry: {
          coordinates: [
            [coord1.lon, coord1.lat],
            [coordMid.lon, coordMid.lat],
            [coord2.lon, coord2.lat],
          ],
          type: "LineString",
        },
        properties: {},
        type: "Feature",
      },
      { resolution: sector.size * 100, sharpness: 0.99 }
    );


    const result = {
      properties: {
        color: "#1d4ed8",
        lineColor: "#1d4ed8",
        opacity: sector.score === 1 ? 0.8 : 0.6,
        fillopacity: sector.score === 1 ? 0.6 : 0.3,
      },
      geometry: {
        coordinates: [
          [
            [direction.latLon.longitude, direction.latLon.latitude],
            // [coord1.lon, coord1.lat],
            ...curvedBase.geometry.coordinates,
            // [coord2.lon, coord2.lat],
            [direction.latLon.longitude, direction.latLon.latitude],
          ],
        ],
        type: "Polygon",
      },
    } as any;
    return result
  });
};

function updateSource(map: Map, direction: DirectionAndLocation | undefined) {
  const geoJson = lines(direction);
  map
    .getSource("alpha-gunshot-direction-lines")
    // @ts-ignore
    .setData(geoJson);
}

const AlphaGunshotDirectionLayer = ({ map, loaded, direction }: Props) => {
  if (map && loaded) {
    if (!map.getSource("alpha-gunshot-direction-lines")) {
      addSource(map, direction);
      addLayer(map);
    } else {
      updateSource(map, direction);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const focused = state.commander.focused;

  if (!focused || focused.type !== FocusType.Shots || !focused.id) {
    return {
      gunshot: undefined,
      direction: undefined,
    };
  }

  const gunshot = state.triangulation.gunshots[focused.id]!;
  return {
    gunshot: gunshot,
    direction: state.admin.direction,
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  AlphaGunshotDirectionLayer
);
