import { degreesToRadians, radiansToDegrees } from "@turf/helpers";
import { LngLat, LngLatBounds } from "mapbox-gl";

import { store } from "../ConfiguredApp";
import { RelativeRange } from "../components/range/rangeUtil";
import { Gunshot, GunshotPositionFeature } from "../models/gunshots";
import { LatLon } from "../models/location";
import { TwinDisplayMode } from "../models/map";

export const createBoundsWithPadding = (original: LngLatBounds) => {
  const southWest = new LngLat(
    original.getSouthWest().lng - 0.0005,
    original.getSouthWest().lat - 0.0005
  );
  const northEast = new LngLat(
    original.getNorthEast().lng + 0.0005,
    original.getNorthEast().lat + 0.0005
  );

  return new LngLatBounds(southWest, northEast);
};

export const filterLocationsIfNeeded = (
  gunshot: Gunshot,
  twinDisplayMode: TwinDisplayMode
): GunshotPositionFeature[] => {
  if (
    twinDisplayMode === TwinDisplayMode.All ||
    gunshot.location.geoJson.features.length === 1
  ) {
    return gunshot.location.geoJson.features;
  }

  const sortedLocation = [...gunshot.location.geoJson.features].sort(
    (a, b) => b.properties.distance.meter - a.properties.distance.meter
  );

  return twinDisplayMode === TwinDisplayMode.Inner
    ? [sortedLocation[sortedLocation.length - 1]]
    : [sortedLocation[0]];
};

// https://stackoverflow.com/questions/12875486/what-is-the-algorithm-to-create-colors-for-a-heatmap
const getHeatMapColor = (diffInMinutes: number) => {
  if (diffInMinutes < 2.5) {
    return (diffInMinutes * 2 * 60) / 5;
  } else {
    return 40 + diffInMinutes * 4;
  }
};

export const getRepresentativePoint = (
  gunshotFeature: GunshotPositionFeature
): LatLon => {
  return gunshotFeature.properties.representativePoint;
};

/**
 * @deprecated This method goes to the store to get the relative range. Start using heatmapColor
 */
export const gunshotHeatMapColor = (millis: number, latestMillis: number) => {
  const relativeRange = store.getState().triangulation.relativeRange;
  return calculateHeatMapColor(millis, latestMillis, relativeRange);
};

export const calculateHeatMapColor = (
  millis: number,
  latestMillis: number,
  relativeRange?: RelativeRange
) => {
  const diffInSeconds = (latestMillis - millis) / 1000;
  const diffInMinutes = diffInSeconds / 60;

  if (diffInMinutes < 0) {
    return "hsl(" + 0 + ", 100%, 50%)";
  }
  switch (relativeRange) {
    case RelativeRange.ONE_MINUTE:
      return "hsl(" + getHeatMapColor(diffInSeconds) + ", 100%, 50%)";
    case RelativeRange.TEN_MINUTES:
      return "hsl(" + getHeatMapColor(diffInMinutes * 4.5) + ", 100%, 50%)";
    default:
      const h = diffInMinutes > 45 ? 240 : getHeatMapColor(diffInMinutes);
      return "hsl(" + h + ", 100%, 50%)";
  }
};

export interface BasePosition {
  lon: number;
  lat: number;
}

export const coordLengthBearingToCoord = (
  coord: LatLon,
  bearing: number,
  distance: number
): BasePosition => {
  const R = 6378.1; //Radius of the Earth
  const brng = degreesToRadians(bearing); // Bearing is converted to radians.
  const d = distance; //Distance in km

  const lat1 = degreesToRadians(coord.latitude); //Current lat point converted to radians
  const lon1 = degreesToRadians(coord.longitude);

  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(d / R) +
      Math.cos(lat1) * Math.sin(d / R) * Math.cos(brng)
  );

  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(d / R) * Math.cos(lat1),
      Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2)
    );

  return { lat: radiansToDegrees(lat2), lon: radiansToDegrees(lon2) };
};
