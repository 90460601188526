import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setRelativeRangeAction } from "../../containers/triangulations/triangulationReducer";
import { ApplicationState } from "../../reducers";
import Dropdown from "../dropdown/Dropdown";
import TimeIcon from "../icons/TimeIcon";
import { RelativeRange } from "./rangeUtil";
import styles from "./style.module.scss";
import { fetchOldTriangulations } from "../../topics/firebase/gunshots";
import { UserData } from "../../models/user";

const RangeDropdown = ({ relativeRange, setRelativeRange, userData }: Props) => (
  <div className={styles.rangeDropdown}>

  <Dropdown<RelativeRange>
    items={Object.keys(RelativeRange).map((r) => RelativeRange[r])}
    onSelect={(selected) => {
      if (userData) {fetchOldTriangulations(userData, selected)}
      setRelativeRange(selected);
    }}
    selectedItem={relativeRange}
    itemDescription={(range) =>
      range === RelativeRange.ALL
      ? "All gunshots"
      : `Gunshots ${range.toLowerCase()}`
    }
    icon={
      <TimeIcon width={16} height={16} backgroundColor={"hsl(207, 12%, 43%)"} />
    }
    />
    </div>
);

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  relativeRange: RelativeRange;
  userData: UserData | undefined;
}

interface DispatchToProps {
  setRelativeRange: (_: RelativeRange) => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    relativeRange: state.triangulation.relativeRange,
    userData: state.authentication.userData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setRelativeRange: setRelativeRangeAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(RangeDropdown);
