import { store } from "../../ConfiguredApp";
import { setTriangulationFocusAction } from "../../containers/map/mapReducer";
import {
  addTriangulation,
  removeTriangulationAction,
  updateGunshot,
} from "../../containers/triangulations/triangulationReducer";
import {
  Gunshot,
} from "../../models/gunshots";
import { dateMillisToTut } from "../../util/util";


const ONE_WEEK_IN_MILLIS = 604800000 * 2;
const ONE_MINUTES_AGO = 60000;

export const onGunshotAdded = (gunshot: Gunshot) => {
    
    if (newerThanAMinute(gunshot)) {
      debounceNewGunshot(gunshot, () => {
        store.dispatch(addTriangulation(gunshot));
        store.dispatch(setTriangulationFocusAction(gunshot));
      });
    } else {
      store.dispatch(addTriangulation(gunshot));
    }
  };
  
  export const onGunshotDelete = (gunshot: Gunshot) => {
    
    debounceNewGunshot(gunshot, () => {
      store.dispatch(removeTriangulationAction(gunshot));
      // store.dispatch(setTriangulationFocusAction(gunshot));
    },true);
};

interface GunshotAndTimer {
  timer: NodeJS.Timer;
  gunshot: Gunshot;
}
const debouncer: { [id: string]: GunshotAndTimer } = {};

function newerThanAMinute(gunshot: Gunshot) {
  return gunshot.timestamp.getTime() > Date.now() - ONE_MINUTES_AGO;
}

export const onGunshotModified = (gunshot:Gunshot) => {
    debounceNewGunshot(gunshot, () => {
      store.dispatch(updateGunshot(gunshot));
    });
    store.dispatch(updateGunshot(gunshot));
}

function debounceNewGunshot(
  gunshot: Gunshot,
  callback: () => void,
  deleteGunshot: boolean = false,
) {
  if (debouncer[gunshot.id] === undefined) {
    debouncer[gunshot.id] = {
      timer: setTimeout(callback, 300),
      gunshot: gunshot,
    };
  } else if (deleteGunshot) {
    clearTimeout(debouncer[gunshot.id].timer);
    debouncer[gunshot.id] = {
      timer: setTimeout(callback, 300),
      gunshot: gunshot,
    };
  }else if (
    debouncer[gunshot.id].gunshot.revision <
    gunshot.revision
    ) {
    clearTimeout(debouncer[gunshot.id].timer);
    debouncer[gunshot.id] = {
      timer: setTimeout(callback, 300),
      gunshot: gunshot,
    };
  }
}


export function removeGunshot(gunshot: Gunshot) {
  store.dispatch(removeTriangulationAction(gunshot));
}

export const ONE_WEEK_AGO_IN_TUT = dateMillisToTut(
  new Date().getTime() - ONE_WEEK_IN_MILLIS
);
