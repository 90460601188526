export enum Status {
  Ok = "Ok",
  Warning = "Warning",
  Critical = "Critical",
  Up = "UP",
  Down = "DOWN"
  
}

export interface StatusMessage {
  message: string;
  component: string;
  id: string;
  status: Status;
  selfDiagnose?: boolean;
  displayOnCommanderStatusPage?: boolean;
  useInPopup?: boolean;
}


export const statusMessageIsCritical = (status: StatusMessage) => {
  return status.status === Status.Critical || status.status === Status.Down
}

const wifi_mobile_net_key = "wifi_or_mobile_net"
export const createOnlineMessage = (): StatusMessage => {
  return (
    {
      component: "Internet",
      id: wifi_mobile_net_key,
      message: "Device is connected to internet",
      status: Status.Ok,
      selfDiagnose: true,
    }
  )
}

export const createDeviceOfflineMessage = (): StatusMessage => {
  return (
    {
      component: "Internet",
      id: wifi_mobile_net_key,
      message: "Device is not connected to internet",
      status: Status.Critical,
      selfDiagnose: true,
    }
  )
}
