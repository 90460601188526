import { Map } from "mapbox-gl";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Geofence } from "../../../models/geofence";
import { MapViewType } from "../../../models/MapViewType";
import { ApplicationState } from "../../../reducers";
import { createGeoJson } from "../dragAndDropUtil";
import { geofenceOnPress } from "../geofenceMapUtil";
import { getCirclePolygon } from "../mapUtil";
import mask from '@turf/mask';
import { Focus, FocusType } from "../../../models/map";

export interface MapboxProps {
  geofences: Geofence[];
  createNew: boolean;
  selectedGeofence: string | undefined;
  focused: Focus | undefined;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

export function addDragLayers(map: Map) {

  map.addLayer({
    id: "creating_geofence_moving_text",
    layout: {
      "text-anchor": "bottom",
      "text-field": ["get", "text"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 24,
      "text-offset": [0, -2],
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "creating_geofence_center",
    type: "symbol",
  });

  map.addLayer({
    id: "creating_geofence_moving_circle",
      paint: {
        "fill-color": "rgba(76, 118, 246, 0.5)",
        "fill-opacity": 0.5,
    },
    source: "creating_geofence_moving",
    type: "fill",
  })
    

    map.addLayer({
      id: "geofence",
        paint: {
          "fill-color": [
            'case',
            ['==', ['get', 'state'], 'listening'], "rgba(76, 246, 82, 0.0)",
            "#fcd34d"//"red"
          ],
          "fill-opacity": 0.2,
      },
      source: "geofence",
      type: "fill",
    })
    map.addLayer({
      id: "geofence-exterior-label",
      layout: {
        "text-anchor": "bottom",
        "text-field": "Outside active zones",
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-size": 24,
        "text-offset": [0, -2],
      },
      paint: {
        "text-color": "#FFFFFF",
        "text-halo-color": "#000",
        "text-halo-width": 1,
      },
      source: "geofence-exterior",
      type: "symbol",
    });
    map.addLayer({
      id: "geofence-exterior",
        paint: {
          "fill-color": "black",
          "fill-opacity": 0.3
      },
      source: "geofence-exterior",
      type: "fill",
    })
    map.addLayer({
      id: "geofence-exterior-selected",
      paint: {
        "line-color": "#fbbf24",
        "line-width":  [
          'case',
          ['==', ['get', 'selected'], true], 5,
          0//"red"
        ],
    },
      source: "geofence-exterior",
      type: "line",
    })
    map.addLayer({
      id: "geofence_selected",
        paint: {
          "line-color": "#fbbf24",
          "line-width": 5,
      },
      filter: ['==', 'selected', "true"],
      source: "geofence",
      type: "line",
    })
    map.moveLayer("creating_geofence_moving_circle", "creating_geofence_moving_text");
    map.moveLayer("geofence", "creating_geofence_moving_circle");
}

const geofenceToGeoJson = (geofences: Geofence[], selectedGeofence: string | undefined):any => {
  return {
    features: 
    geofences.map((geofence) => {
        const polygon = getCirclePolygon(geofence.center, geofence.radius/1000) as any
        polygon.properties = {
          state: geofence.listening ? "listening" : "muted",
          selected: `${geofence.id === selectedGeofence}`,
          id: geofence.id,
          text:  geofence.listening ? "" : "Muted zone",
        }
        polygon.id = geofence.id
        
        return polygon
      })
    ,
    type: "FeatureCollection",
  };
}

const getExterior = (geoJson:any, focused: Focus | undefined):any => {
  if (geoJson["features"].length > 0) {
    let exterior = mask(geoJson)
    if (exterior !== null && focused && focused.type === FocusType.Coverage) {
      exterior.properties = {selected: true}
    } 
    return {
      features: [exterior],
      type: "FeatureCollection",
    };
  }
  return {
    features: [],
    type: "FeatureCollection",
  };
}


function addSource(map: Map, geofences:Geofence[], selectedGeofence: string | undefined, focused: Focus | undefined) {
  map.addSource("creating_geofence_center", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  map.addSource("creating_geofence_moving", {
    data: {
      features: [],
      type: "FeatureCollection",
    },
    type: "geojson",
  });
  const geoJson = geofenceToGeoJson(geofences, selectedGeofence)
  const exterior = getExterior(geoJson, focused)
  map.addSource("geofence",  {
    data: geoJson,
    type: "geojson",
  });

  map.addSource("geofence-exterior",  {
    data: exterior,
    type: "geojson",
  });
}

function updateSource(map: Map, geofences:Geofence[], selectedGeofence: string | undefined, focused: Focus | undefined) {
  const geoJson = geofenceToGeoJson(geofences, selectedGeofence)
  const exterior = getExterior(geoJson, focused)
  // @ts-ignore
  map.getSource("geofence").setData(geoJson);
  // @ts-ignore
  map.getSource("geofence-exterior").setData(exterior);
}

function updateCircleWithStartDescription(map: Map) {
  
  // @ts-ignore
  map.getSource("creating_geofence_center").setData(createGeoJson(map.getCenter().toArray(), "Press and drag in map to define geofence"));
}

const ZoneLayer = ({ map, loaded, geofences, createNew, selectedGeofence, focused }: Props) => {
  const [shouldCreateGeofence, setShouldCreateGeofence] = useState(false)
  useEffect(() => {
    geofenceOnPress(map)
  }, [])
  useEffect(() => {
    if (!shouldCreateGeofence && createNew) {
      setShouldCreateGeofence(true)
      updateCircleWithStartDescription(map)
    } 
    if (!createNew) {
      setShouldCreateGeofence(false)
    }
  }, [createNew])
  if (map && loaded) {
    if (!map.getSource("creating_geofence_moving")) {
      addSource(map, geofences, selectedGeofence, focused);
      addDragLayers(map);
      
      
    } else {
      updateSource(map, geofences, selectedGeofence, focused)
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    geofences: state.geofence.list,
    createNew: state.geofence.createNew,
    selectedGeofence: state.geofence.selected,
    focused: state.commander.focused
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(ZoneLayer);
