import * as React from "react";

import DemoLogo from "../logo/DemoLogo";
import styles from "./style.module.css";

class LoadingDemo extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <div id={"loading_screen"} className={styles.centerContent}>
        <div className={styles.logoWrapper}>
          <DemoLogo />
        </div>
      </div>
    );
  }
}

export default LoadingDemo;
