


export const UNUSED_JWT_TOKEN = "UNUSED_JWT";

export const jwtIsValid = (jwtToken: string | undefined) => {
    return jwtToken && jwtToken !== UNUSED_JWT_TOKEN
}

export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
};
