import { LngLat } from "mapbox-gl";
import { Action, AnyAction, Reducer } from "redux";
import { tutToDate } from "../../components/mapbox/util";
import { LatLon } from "../../models/location";
import { PotentialIncident } from "../../models/potentialIncident";
import { Scout, ScoutPosition, ScoutState } from "../../models/scouts";
import { ThunkResult } from "../../reducers";
import { CoverageData } from "../../util/coverage";
import { getStateFor } from "../../util/scoutUtil";

const SET_SCOUT = "SET_SCOUT";
const REMOVE_SCOUT = "REMOVE_SCOUT";
const REFRESH_SCOUT_STATE = "REFRESH_SCOUT_STATE";
const ADD_UNHANDLED_POTENTIAL_GUNSHOT = "ADD_UNHANDLED_POTENTIAL_GUNSHOT";
const REMOVE_UNHANDLED_POTENTIAL_GUNSHOT = "REMOVE_UNHANDLED_POTENTIAL_GUNSHOT";
const HIDE_SCOUTS = "HIDE_SCOUTS";
const ADD_POTENTIAL_INCIDENT = "ADD_POTENTIAL_INCIDENT";
const REMOVE_POTENTIAL_INCIDENT = "REMOVE_POTENTIAL_INCIDENT";

export const setScoutActionCreator = (scout: Scout | undefined): AnyAction => {
  return { type: SET_SCOUT, scout };
};

export const setScout = (scout: Scout | undefined): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(setScoutActionCreator(scout));
  };
};

export const addPotentialIncidentActionCreator = (
  potentialIncident: PotentialIncident
): AnyAction => {
  return { type: ADD_POTENTIAL_INCIDENT, potentialIncident };
};

export const addPotentialIncident = (
  potentialIncident: PotentialIncident
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(addPotentialIncidentActionCreator(potentialIncident));
  };
};

export const removePotentialIncidentActionCreator = (
  potentialIncident: PotentialIncident
): AnyAction => {
  return { type: REMOVE_POTENTIAL_INCIDENT, potentialIncident };
};

export const removePotentialIncident = (
  potentialIncident: PotentialIncident
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(removePotentialIncidentActionCreator(potentialIncident));
  };
};

export const removeScoutActionCreator = (deviceId: string): AnyAction => {
  return { type: REMOVE_SCOUT, deviceId };
};

export const removeScout = (deviceId: string): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(removeScoutActionCreator(deviceId));
  };
};

export const refreshScoutState = (deviceId: string): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({
      deviceId,
      type: REFRESH_SCOUT_STATE,
    });
  };
};

export const hideScoutsActions = (hideScouts: boolean): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({
      hideScouts,
      type: HIDE_SCOUTS,
    });
  };
};

export interface AddUnhandledPotentialGunshotAction extends Action {
  type: "ADD_UNHANDLED_POTENTIAL_GUNSHOT";
  deviceId: string;
}

export const addUnhandledPotentialGunshot = (
  deviceId: string
): AddUnhandledPotentialGunshotAction => {
  return { type: ADD_UNHANDLED_POTENTIAL_GUNSHOT, deviceId };
};

export interface RemoveUnhandledPotentialGunshotAction extends Action {
  type: "REMOVE_UNHANDLED_POTENTIAL_GUNSHOT";
  deviceId: string;
}

export const removeUnhandledPotentialGunshot = (
  deviceId: string
): RemoveUnhandledPotentialGunshotAction => {
  return { type: REMOVE_UNHANDLED_POTENTIAL_GUNSHOT, deviceId };
};

const ADD_COVERAGE = "ADD_COVERAGE";

export const addCoveragesAction = (
  coverages: CoverageData | undefined
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: ADD_COVERAGE, coverages });
  };
};

const ADD_PENDING_FIXED_LOCATION = "ADD_PENDING_FIXED_LOCATION";

export const addPendingFixedLocationAction = (
  deviceId: string,
  latLon: LatLon
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: ADD_PENDING_FIXED_LOCATION, deviceId, latLon });
  };
};

const MOVING_FIXED_LOCATION_ACTION = "MOVING_FIXED_LOCATION_ACTION";
export const movingFixedLocationAction = (
  location: LatLon | undefined
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: MOVING_FIXED_LOCATION_ACTION, location });
  };
};

const REMOVE_PENDING_FIXED_LOCATION = "REMOVE_PENDING_FIXED_LOCATION";

export const removePendingFixedLocationAction = (
  deviceId: string
): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({ type: REMOVE_PENDING_FIXED_LOCATION, deviceId });
  };
};

export interface ScoutReducerState {
  history: { [deviceId: string]: ScoutPosition[] };
  list: Scout[];
  coverages: CoverageData | undefined;
  hideScouts: boolean;
  potentialIncidents: PotentialIncident[];
  pendingFixedLocation: { [deviceId: string]: LatLon | undefined };
  movingFixedScout: LatLon | undefined;
}

export const scoutInitialState: ScoutReducerState = {
  coverages: undefined,
  hideScouts: false,
  history: {},
  list: [],
  potentialIncidents: [],
  pendingFixedLocation: {},
  movingFixedScout: undefined
};

const updateHistory = (
  history: { [deviceId: string]: ScoutPosition[] },
  scout: Scout
) => {
  if (history[scout.deviceId]) {
    history[scout.deviceId] = [
      {
        position: new LngLat(scout.status.longitude, scout.status.latitude),
        time: tutToDate(scout.status.dateTime).getTime(),
      },
      ...history[scout.deviceId],
    ];
  } else {
    history[scout.deviceId] = [
      {
        position: new LngLat(scout.status.longitude, scout.status.latitude),
        time: tutToDate(scout.status.dateTime).getTime(),
      },
    ];
  }

  return history;
};

const reducer: Reducer<ScoutReducerState> = (
  state: ScoutReducerState = scoutInitialState,
  action
) => {
  switch ((action as Action).type) {
    case SET_SCOUT: {
      const scout: Scout = action.scout;
      if (state.list.find((s) => s.deviceId === scout.deviceId)) {
        return {
          ...state,
          history: updateHistory(state.history, action.scout),
          list: state.list.map((s) => {
            if (s.deviceId === action.scout.deviceId) {
              if (
                s.state !== action.scout.state &&
                (s.state === ScoutState.Offline ||
                  action.scout.state === ScoutState.Offline)
              ) {
                action.scout.lastChangedStatus = new Date();
              } else {
                action.scout.lastChangedStatus = s.lastChangedStatus;
              }
              return action.scout;
            } else {
              return s;
            }
          }),
          // Remove from pending fixed position when status message contains the same latLon as the fixedPosition set
          pendingFixedLocation:
            state.pendingFixedLocation[scout.deviceId] &&
            state.pendingFixedLocation[scout.deviceId]!.latitude ===
              scout.status.latitude &&
            state.pendingFixedLocation[scout.deviceId]!.longitude ===
              scout.status.longitude
              ? { ...state.pendingFixedLocation, [scout.deviceId]: undefined }
              : state.pendingFixedLocation,
        };
      } else {
        return { ...state, list: [...state.list, action.scout] };
      }
    }
    case ADD_PENDING_FIXED_LOCATION: {
      return {
        ...state,
        pendingFixedLocation: {
          ...state.pendingFixedLocation,
          [action.deviceId]: action.latLon,
        },
      };
    }
    case MOVING_FIXED_LOCATION_ACTION: {
      return {
        ...state,
        movingFixedScout: action.location
      }
    }
    case REMOVE_PENDING_FIXED_LOCATION: {
      return {
        ...state,
        pendingFixedLocation: {
          ...state.pendingFixedLocation,
          [action.deviceId]: undefined,
        },
      };
    }
    case REMOVE_SCOUT: {
      return {
        ...state,
        list: state.list.filter((s) => s.deviceId !== action.deviceId),
      };
    }
    case HIDE_SCOUTS: {
      return {
        ...state,
        hideScouts: action.hideScouts,
      };
    }
    case ADD_UNHANDLED_POTENTIAL_GUNSHOT: {
      const updatedList = state.list.map((s) =>
        s.deviceId === action.deviceId
          ? {
              ...s,
              unhandledPotentialGunshots: s.unhandledPotentialGunshots + 1,
            }
          : s
      );
      return { ...state, list: updatedList };
    }

    case REFRESH_SCOUT_STATE: {
      return {
        ...state,
        list: state.list.map((s) =>
          s.deviceId === action.deviceId ? { ...s, state: getStateFor(s) } : s
        ),
      };
    }
    case ADD_POTENTIAL_INCIDENT: {
      return {
        ...state,
        potentialIncidents: [
          ...state.potentialIncidents,
          action.potentialIncident,
        ],
      };
    }

    case REMOVE_POTENTIAL_INCIDENT: {
      const potentialIncident = action.potentialIncident;
      return {
        ...state,
        potentialIncidents: state.potentialIncidents.filter(
          (s) =>
            !(
              s.deviceId === potentialIncident.deviceId &&
              s.tut === potentialIncident.tut
            )
        ),
      };
    }

    case REMOVE_UNHANDLED_POTENTIAL_GUNSHOT: {
      const updatedList = state.list.map((s) =>
        s.deviceId === action.deviceId && s.unhandledPotentialGunshots > 0
          ? {
              ...s,
              unhandledPotentialGunshots: s.unhandledPotentialGunshots - 1,
            }
          : s
      );
      return { ...state, list: updatedList };
    }
    case ADD_COVERAGE: {
      return { ...state, coverages: action.coverages };
    }
    default:
      return state;
  }
};

export default reducer;
