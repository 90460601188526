import * as React from "react"
const SetupPage = React.lazy(() => import("./SetupPage"));

const LazySetupPage = () => {
    return (
        <React.Suspense fallback={<></>}>
            <SetupPage />
          </React.Suspense>
    )
}

export default LazySetupPage