import * as React from "react";
import { Gunshot } from "../../models/gunshots";
import { getHyperbola } from "../../services/adminService";
import { setHyperbolasAction } from "../triangulations/triangulationReducer";
import { Dispatch, bindActionCreators } from "redux";
import { Hyperbolas, FocusType } from "../../models/map";
import { connect } from "react-redux";
import { ApplicationState } from "../../reducers";

export const fetchHyperbolasIfNeeded = ({
    jwtToken,
    selected,
    showHyperbolas,
    setHyperbolas,
    isAdmin
  }: Props) => {
    if (
      isAdmin &&
      jwtToken &&
      selected &&
      selected.observations &&
      !selected.hyperbolas &&
      showHyperbolas
      ) {
      getHyperbola(selected, jwtToken).then(hyperbolas =>
        setHyperbolas(selected.id, hyperbolas)
      );
    }
  };

const HyperbolaFetcher = (props: Props) => {

    fetchHyperbolasIfNeeded(props);

    return (
        <></>
    )
}

const MemoizedHyperbolaFetcher = React.memo(HyperbolaFetcher, (prev, next) => {
    return prev.selected === next.selected;
  });
  
  type Props = StateToProps & DispatchFromProps;
  
  interface StateToProps {
    selected: Gunshot | undefined;
    jwtToken: string | undefined;
    showHyperbolas: boolean;
    isAdmin: boolean;
  }
  
  const mapStateToProps = (state: ApplicationState, props: any) => {
    const id = (state.commander.focused && state.commander.focused.type === FocusType.Shots) ? state.commander.focused.id : undefined;
    const triangulation = id ? state.triangulation.gunshots[id] : undefined;
    return {
      coverageSettings: state.commander.coverageSettings,
      jwtToken: state.commander.jwtToken,
      selected: triangulation,
      showHyperbolas: state.commander.showHyperbolas,
      isAdmin: state.commander.admin,
    };
  };
  
  interface DispatchFromProps {
    setHyperbolas: (triangulationId: string, hyperbolas: Hyperbolas) => void;
  }
  
  const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
      {
        setHyperbolas: setHyperbolasAction,
      },
      dispatch
    );
  
  export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
  )(MemoizedHyperbolaFetcher);