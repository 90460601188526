export const TUT_BIRTHDAY_MILLIS = 1500854400000;
export const tutToDate = (tut: number) =>
    new Date(tut / 1000 + TUT_BIRTHDAY_MILLIS);

export const getDiffInSeconds = (latestMillis: number, millis:number) => {
    return (latestMillis - millis) / 1000;
};

export const timeToOpacity = (secondsAgo: number) => {
    const opacity = ((100 - secondsAgo) * 0.01);
    return opacity;
};
