// @ts-ignore
import distance from "@turf/distance";
import { Map } from "mapbox-gl";
import * as mapboxgl from "mapbox-gl";

import { store } from "../../ConfiguredApp";
import { Geofence } from "../../models/geofence";
import { Units } from "../../models/organisation";
import { createZone } from "../../services/zoneService";
import { getCircleArea, nowToTut, squareKmToSquareMi } from "../../util/util";
import { createGeoJson } from "./dragAndDropUtil";
import { getCirclePolygon } from "./mapUtil";
import { createAreaFilter } from "../../services/areaFilterService";

let pressed: boolean = false;

const emptyGeoJson = {
  features: [],
  type: "FeatureCollection",
};

function mousePressedGeoFence(
  map: mapboxgl.Map,
  layer: string,
  shouldActivate: () => boolean,
  callback: (geofence: Geofence) => void
) {
  const canvas = map.getCanvasContainer();

  map.on("mousedown", (e) => {
    console.log("Mouse down")
    let metric =
      store.getState().authentication.userData?.organisation?.units ||
      Units.Metric;
    if (!shouldActivate()) {
      return;
    }
    console.log("Geofence", "Mouse down");
    console.log("Geofence", "Center", e.lngLat.toArray());
    canvas.style.cursor = "grabbing";
    pressed = true;
    let center = e.lngLat.toArray();
    map.dragPan.disable();
    const updatedGeoJson = createGeoJson(center, "Drag to increase geofence");
    // @ts-ignore
    map.getSource("creating_geofence_center").setData(updatedGeoJson);
    let onMoveListener = onMove(map, center, metric);
    let newCallback = (lngLat: number[], meter: number) => {
      let geofence = {
        center: lngLat,
        radius: meter,
        id: `${nowToTut()}`,
        listening: true,
      } as Geofence;
      callback(geofence);
      map.off("mousemove", onMoveListener);
    };
    map.on("mousemove", onMoveListener);
    map.once("mouseup", onUp(map, center, newCallback));
  });
}

function onUp(
  map: Map,
  center: number[],
  callback: (lngLat: number[], meter: number) => void
) {
  return (e: any) => {
    pressed = false;
    const canvas = map.getCanvasContainer();
    console.log("Geofence", "Release", e.lngLat.toArray());
    canvas.style.cursor = "";
    map.dragPan.enable();
    const coords = e.lngLat.toArray();
    e.preventDefault();
    const circlePolygon = createCirclePolygon(center, coords);
    // @ts-ignore
    map.getSource("creating_geofence_center").setData(emptyGeoJson);

    // @ts-ignore
    map.getSource("creating_geofence_moving").setData(emptyGeoJson);

    map.getCanvasContainer().style.cursor = "";

    // @ts-ignore
    map.getSource("creating_geofence_center").setData(emptyGeoJson);

    // Unbind mouse/touch events
    callback(center, Math.max(circlePolygon.radiusInMeter, 100));
  };
}

function onMove(map: Map, center: number[] | undefined, unit: Units) {
  var debouncer = 1;
  return (e: any) => {
    if (center === undefined) {
      return;
    }
    if (pressed) {
      const coords = e.lngLat.toArray();
      e.preventDefault();
      const circlePolygon = createCirclePolygon(center, coords);
      let areaText =
        unit === Units.Metric
          ? `${circlePolygon.areaInKm.toFixed(1)} km²`
          : `${squareKmToSquareMi(circlePolygon.areaInKm).toFixed(1)} mi²`;
      //createSearchLocation(coords, map, description);

      // @ts-ignore
      map.getSource("creating_geofence_moving").setData(circlePolygon.feature);
      if (debouncer % 10 === 0) {
        debouncer = 1;
        const updatedCenter = createGeoJson(
          center,
          `Area of geofence is ${areaText}`
        );
        // @ts-ignore
        map.getSource("creating_geofence_center").setData(updatedCenter);
      } else {
        debouncer += 1;
      }
    }
  };
}

interface GeofencePolygon {
  feature: GeoJSON.Feature;
  areaInKm: number;
  radiusInMeter: number;
}

export function createCirclePolygon(
  center: number[],
  border: number[]
): GeofencePolygon {
  // using turf to get distance between two points
  // @ts-ignore
  const calculatedDistance = distance(center, border, { units: "kilometers" });
  return {
    feature: getCirclePolygon(center, calculatedDistance),
    areaInKm: getCircleArea(calculatedDistance),
    radiusInMeter: calculatedDistance * 1000,
  };
}

var geoFenceDefined = false;

export const geofenceOnPress = (map: Map) => {
  if (geoFenceDefined) {
    return;
  }

  const callback = (geofence: Geofence) => {
    createZone(
      geofence,
      store.getState().commander.jwtToken,
      () => {},
      () => {}
    );
  };
  mousePressedGeoFence(map, "listening_scouts", () => store.getState().geofence.createNew, callback);
};

export const areaFilterOnPress = (map: Map) => {
  if (geoFenceDefined) {
    return;
  }

  const callback = (geofence: Geofence) => {
    geofence.listening = !store.getState().areaFilter.exclude;
    createAreaFilter(
      geofence,
      store.getState().commander.jwtToken,
      () => {},
      () => {}
    );
  };
  mousePressedGeoFence(map, "listening_scouts", () => {
    return store.getState().areaFilter.createNew}, callback);
};

