import * as React from "react";
import GunshotsView from "../gunshots/GunshotsView";
import ScoutsView from "../scout/ScoutsView";
import WarningsOverlay from "../status/WarningsOverlay";
import NewSummary from "./NewSummary";

const Panel = () => {
  return (
    <>
      <NewSummary />
      <ScoutsView />
      <GunshotsView />
      <WarningsOverlay />
    </>
  );
};

export default Panel;
