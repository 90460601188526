import * as noUiSlider from "nouislider";
import * as React from "react";
import {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import { ApplicationState } from "../../reducers";
import {setRangeAction} from "../commander/commanderReducer";
import styles from "./style.module.scss";

let slider: noUiSlider.noUiSlider | undefined;

const ONE_WEEK_IN_MILLIS = 604800000;
const ONE_DAY_IN_MILLIS = 86400000;
const ONE_HOUR_IN_MILLIS = 3600000;

const DualSlider = ({rangeStart, rangeEnd, setRange, setPress}: Props) => {
    useEffect(() => {
        if (slider === undefined) {

            const now = new Date().getTime();
            const oneWeekAgo = now - ONE_WEEK_IN_MILLIS;
            const oneDayAgo = now - ONE_DAY_IN_MILLIS;
            const oneHourAgo = now - ONE_HOUR_IN_MILLIS;
            slider = noUiSlider.create(ref.current!, {
                behaviour: 'drag',
                connect: true,
                range: {
                    '50%': oneDayAgo,
                    '80%': oneHourAgo,
                    'max': now,
                    'min': oneWeekAgo,
                },
                start: [rangeStart ? rangeStart : oneWeekAgo, rangeEnd ? rangeEnd : now],
            });
            slider.on("update", () => {
                if (slider) {
                    const updatedRangeStart = +slider.get()[0];
                    const updatedRangeEnd = +slider.get()[1];

                    setRange(
                        updatedRangeStart === oneWeekAgo ? undefined : updatedRangeStart,
                        updatedRangeEnd === now ? undefined : updatedRangeEnd);
                }
            });
            slider.on("start", () => {
                setPress(true)
            });
            slider.on("end", () => {
                setPress(false)
            })
        }
        return () => {
            if (slider !== undefined) {
                slider.destroy();
                slider = undefined;
            }
        };
    });
    const ref = React.createRef<HTMLDivElement>();
    return (
        <div className={styles.sliderElem}>
            <div className={styles.sliderElem} ref={ref}/>
        </div>
    );
};


const MemoDualSlider = React.memo(DualSlider, (np, pp) =>  np.minutes === pp.minutes);


interface Props extends StateToProps, DispatchToProps, OwnProps {
}

interface StateToProps {
    rangeStart: number | undefined;
    rangeEnd: number | undefined;
    minutes: number;
}

interface OwnProps {
    setPress: (_: boolean) => void
    setRelease: (_: boolean) => void
}

const mapStateToProps = (state: ApplicationState) => ({
    minutes: new Date().getMinutes(),
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
});

interface DispatchToProps {
    setRange: (rangeStart: number | undefined, rangeEnd: number | undefined) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setRange: setRangeAction,
        },
        dispatch
    );

export default connect<StateToProps, DispatchToProps, OwnProps, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
)(MemoDualSlider);
