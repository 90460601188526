import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import Button from "../../components/overlay/Button";
import {IfMobile, IfSm} from "../../components/responsive/responsiveUtil";
import {Focus, FocusType} from "../../models/map";
import {Gunshot, Replay} from "../../models/gunshots";
import {setRangeAction, setReplayAction} from "../commander/commanderReducer";
import styles from "./style.module.scss";
import { ApplicationState } from "../../reducers";


let player: any;
let isPaused = false;


const startOrPauseSimulation = (
    rangeStart: number,
    rangeEnd: number,
    setEndRange: (start: number, end: number) => void,
    gunshots: { [id: string]: Gunshot },
    setReplay: (_: Replay | undefined) => void,
) => {
    const sortedGunshots: Gunshot[] = Object.keys(gunshots)
        .map((t) => gunshots[t])
        .filter((t) => t.timestamp.getTime() < rangeEnd && t.timestamp.getTime() > rangeStart)
        .sort((t1, t2) => (t1.timestamp < t2.timestamp ? 1 : -2));
    const trimmedRangeEnd = sortedGunshots.length > 1 ? sortedGunshots[0].timestamp.getTime() : rangeEnd;
    const trimmedRangeStart = sortedGunshots.length > 1 ? sortedGunshots[sortedGunshots.length - 1].timestamp.getTime() : rangeStart;

    let localPauseFlag = false;

    setReplay({endTime: rangeEnd, startTime: rangeStart, gunshots: sortedGunshots.map((t) => t.timestamp.getTime()), duration: 20000, paused: false});

    let currentRangeEnd = trimmedRangeStart;
    let currentRangeStart = trimmedRangeStart;

    const step = ((trimmedRangeEnd - trimmedRangeStart) / 200);

    player = setInterval(() => {

        if (isPaused && !localPauseFlag) {
            setReplay({endTime: rangeEnd, startTime: rangeStart, gunshots: sortedGunshots.map((t) => t.timestamp.getTime()), duration: 20000, paused: true});
            localPauseFlag = true;
        }
        if (!isPaused && localPauseFlag) {
            setReplay({endTime: rangeEnd, startTime: rangeStart, gunshots: sortedGunshots.map((t) => t.timestamp.getTime()), duration: 20000, paused: false});
            localPauseFlag = false;
        }

        if (isPaused) {
            return;
        }

        if (currentRangeEnd >= trimmedRangeEnd) {
            setEndRange(rangeStart, rangeEnd);
            if (player) {
                clearInterval(player);
            }
            player = undefined;
            setReplay(undefined);
            return;
        }

        currentRangeEnd = currentRangeEnd + step;
        currentRangeStart = currentRangeEnd - (step*50) ;
        setEndRange(currentRangeStart, currentRangeEnd);
    }, 100);


};

const PlayTimeFrameButton = ({focused, rangeStart, rangeEnd, setRange, gunshots, setReplay, replay}: Props) => {
    const classes = [styles.darkButton, styles.buttonText].join(" ");
    const [, setPaused] = useState(false);

    if ((focused && focused.type === FocusType.Menu) || (!rangeStart || !rangeEnd)) {
        return <></>;
    }
    return (
        <>
            <IfSm>
                <Button cssProperties={{marginRight: "10px", marginBottom: "-7px"}}>
                    <div className={classes} onClick={() => {
                        if (!player) {
                            startOrPauseSimulation(rangeStart, rangeEnd, setRange, gunshots, setReplay);
                            setPaused(false)
                        } else {
                            isPaused = !isPaused;
                            setPaused(!isPaused);
                        }
                    }}>
                        {replay && !replay.paused ? "Pause" : "Play"}
                    </div>
                </Button>
            </IfSm>
            <IfMobile>
                <></>
            </IfMobile>
        </>
    );
};

interface StateToProps {
    rangeStart: number | undefined;
    rangeEnd: number | undefined;
    focused: Focus | undefined;
    gunshots: { [id: string]: Gunshot };
    replay: Replay | undefined;
}

interface Props extends StateToProps, DispatchToProps {
}

interface DispatchToProps {
    setRange: (start: number, end: number) => void;
    setReplay: (_: Replay | undefined) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
    focused: state.commander.focused,
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
    replay: state.commander.replay,
    gunshots: state.triangulation.gunshots,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setRange: setRangeAction,
            setReplay: setReplayAction,
        },
        dispatch
    );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
)(PlayTimeFrameButton);
