import "firebase/compat/firestore";
import * as React from "react";
import { Suspense } from "react";
import * as ReactDOM from "react-dom";
import LoadingVanilla from "./components/loading/LoadingVanilla";


const ConnectedBoxApp = React.lazy(() => import("./connected/ConnectedBoxApp"));

export const renderBox = () => {

  ReactDOM.render(
    <Suspense fallback={<LoadingVanilla/>}>
      <ConnectedBoxApp />
    </Suspense>,
    document.getElementById("root")
  );
};