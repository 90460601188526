import * as React from "react";
import styles from "../../containers/overview/style.module.scss";
import summaryStyles from "./style.module.scss";
import commonStyles from "../../containers/styles/style.module.scss";
import { Focus, FocusType } from "../../models/map";
import SummaryItem from "./SummaryItem";
import { CoverageData } from "../../util/coverage";
import { Units } from "../../models/organisation";
import { squareKmToSquareMi } from "../../util/util";

interface Props {
  inFocus: boolean;
  setFocus: (_: Focus | undefined) => void;
  coverages: CoverageData | undefined;
  toggleCoverage: () => void;
  unit: Units
}

const CoverageSummary = ({
  inFocus,
  setFocus,
  coverages,
  unit
}: Props) => {
  const setFocusOnClick = () => {
    setFocus({ type: FocusType.Coverage });
  };

  const classes = [styles.summaryText, commonStyles.centerText, styles.status];

  
  const area = coverages ? unit === Units.Metric ? coverages.area : squareKmToSquareMi(coverages.area) : undefined

  const message =area !== undefined ? area.toFixed(1) : "Off"

  const details = coverages
    ? coverages.area !== 0
      ? unit === Units.Metric ? "km2" : "sq mi" : "no coverage"
    : "Coverage disabled";

  return (
    <SummaryItem
      focusType={FocusType.Coverage}
      inFocus={inFocus}
      setFocus={setFocusOnClick}
    >
      <div className={classes.join(" ")}>
        <div className={[commonStyles.centerText].join(" ")}>
          <p className={summaryStyles.summaryLabel}>Coverage area</p>
          <p id="coverage_status" className={summaryStyles.summaryValue}>
            {message}
          </p>
          <p
            className={styles.summaryDescription + " " + commonStyles.noMargin}
          >
            {details}
          </p>
        </div>
      </div>
    </SummaryItem>
  );
};

export default CoverageSummary;
