import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import {
  Focus,
  FocusType,
  GunshotLayerType,
  TwinDisplayMode,
} from "../../../models/map";
import { MapViewType } from "../../../models/MapViewType";
import { Gunshot } from "../../../models/gunshots";
import { ApplicationState } from "../../../reducers";
import { orderLayers } from "../layerUtils";
import { gunshotShape, triangulationPoints } from "../mapUtil";

import { RelativeRange, relativeRangeToDate, shouldBeDisplayed } from "../../range/rangeUtil";
import { useEffect, useState } from "react";
import { Geofence } from "../../../models/geofence";
import { filterRelevantGunshot } from "../../../util/gunshotUtil";

export interface MapboxProps {
  gunshots: { [id: string]: Gunshot };
  timeOfLatestGunshot: Date;
  cleanupTimestamp: number;
  rangeEnd: number | undefined;
  rangeStart: number | undefined;
  relativeLatestTriangulation: number | undefined;
  showOnlyFocusedShot: boolean;
  focused: Focus | undefined;
  twinsDisplayMode: TwinDisplayMode;
  gunshotLayer: GunshotLayerType;
  relativeRange: RelativeRange;
  areaFilter: Geofence[]
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
  mapType?: string | undefined;
}

export interface Props extends OwnProps, MapboxProps {}

function addGunshotLayer(map: Map) {
  map.addLayer({
    id: "gunshot-line",
    minzoom: 15,
    paint: {
      "line-color": ["get", "lineColor"],
      "line-opacity": ["get", "lineOpacity"],
      "line-width": ["get", "lineWidth"],
    },
    layout: {
      //@ts-ignore
      "line-sort-key": ["get", "timestamp"],
      "line-join": "round",
      "line-cap": "round",
    },
    source: "gunshot",
    type: "line",
  });
  map.addLayer({
    id: "gunshot",
    minzoom: 15,
    paint: {
      "fill-color": ["get", "color"],
      "fill-opacity": ["get", "fillOpacity"],
    },
    layout: {
      //@ts-ignore
      "fill-sort-key": ["get", "timestamp"],
    },
    source: "gunshot",
    type: "fill",
  });
}

function addGunshotCircleLayer(map: Map) {
  map.addLayer({
    id: "gunshot-circle",
    maxzoom: 15,
    paint: {
      "circle-color": ["get", "color"],
      "circle-opacity": 0,
      "circle-radius": 8,
      "circle-stroke-color": ["get", "color"],
      "circle-stroke-opacity": 0.8,
      "circle-stroke-width": 5,
      
    },
    layout: {
      //@ts-ignore
      "circle-sort-key": ["get", "timestamp"],
    },
    source: "gunshot-circle",
    type: "circle",
  });
}

function addGunshotHeatmapLayer(map: Map) {
  map.addLayer({
    id: "triangulations-heat",
    maxzoom: 24,
    source: "gunshot-circle",
    type: "heatmap",
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      "heatmap-weight": 0.3,
      // [
      //     "interpolate",
      //     ["linear"],
      //     ["get", "mag"],
      //     0, 0,
      //     6, 1
      // ],
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      "heatmap-intensity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        1,
        10,
        1,
        13,
        1,
        14,
        1,
        15,
        1,
        20,
        1,
      ],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        "rgba(33,102,172,0)",
        0.3,
        "rgba(103,169,207,0.0)",
        0.4,
        "rgba(217, 216, 56, 1)", //rgb(209,229,240)
        0.6,
        "rgb(253,219,199)",
        0.8,
        "rgb(239,138,98)",
        1,
        "rgb(178,24,43)",
      ],
      // Adjust the heatmap radius by zoom level
      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        30,
        10,
        30,
        13,
        60,
        14,
        100,
        16,
        150,
        20,
        200
      ],
      //             "heatmap-radius": 30,
      // [
      //     "interpolate",
      //     ["linear"],
      //     ["zoom"],
      //     0, 2,
      //     9, 20
      // ],
      // Transition from heatmap to circle layer by zoom level
      "heatmap-opacity": 0.7,
      // [
      //     "interpolate",
      //     ["linear"],
      //     ["zoom"],
      //     7, 1,
      //     9, 0
      // ],
    },
  });
}

function addGunshotSource(
  mappedTriangulations: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined,
  map: Map,
  focused: Focus | undefined
) {
  map.addSource("gunshot", {
    data: gunshotShape(
      mappedTriangulations,
      timeOfLatestGunshot,
      rangeEnd,
      focused
    ),
    type: "geojson",
  });
}

function updateGunshotSource(
  gunshots: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined,
  map: Map,
  focused: Focus | undefined
) {
  map
    .getSource("gunshot")
    // @ts-ignore
    .setData(
      gunshotShape(
        gunshots,
        timeOfLatestGunshot,
        rangeEnd,
        focused
      )
    );
}

function addGunshotCircleSource(
  mappedTriangulations: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined,
  map: Map
) {
  map.addSource("gunshot-circle", {
    data: triangulationPoints(
      mappedTriangulations,
      timeOfLatestGunshot,
      rangeEnd
    ),
    type: "geojson",
  });
}

function updateTriangulationCircleSource(
  mappedTriangulations: Gunshot[],
  timeOfLatestGunshot: Date,
  rangeEnd: number | undefined,
  map: Map
) {
  map
    .getSource("gunshot-circle")
    // @ts-ignore
    .setData(
      triangulationPoints(
        mappedTriangulations,
        timeOfLatestGunshot,
        rangeEnd
      )
    );
}

const GunshotLayers = ({
  gunshots,
  timeOfLatestGunshot,
  map,
  loaded,
  rangeEnd,
  rangeStart,
  twinsDisplayMode,
  focused,
  relativeLatestTriangulation,
  gunshotLayer,
  relativeRange,
  areaFilter
}: Props) => {
  const [refresh, setRefresh] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (!refresh) {
      setRefresh(Date.now());
      const interval = setInterval(() => {
        setRefresh(Date.now())}, 60000);
      return () => {clearInterval(interval)}
    }
  }, [refresh]);
  const relevantGunshots = filterRelevantGunshot(gunshots, rangeEnd, rangeStart, relativeRange, twinsDisplayMode, areaFilter);
  
  if (map && loaded) {
    if (!map.getSource("gunshot")) {
      addGunshotSource(
        relevantGunshots,
        timeOfLatestGunshot,
        relativeLatestTriangulation,
        map,
        focused
      );
      addGunshotCircleSource(
        relevantGunshots,
        timeOfLatestGunshot,
        relativeLatestTriangulation,
        map
      );
      addGunshotLayer(map);
      addGunshotCircleLayer(map);
      addGunshotHeatmapLayer(map);
      orderLayers(map);
    } else {
      updateGunshotSource(
        relevantGunshots,
        timeOfLatestGunshot,
        relativeLatestTriangulation,
        map,
        focused
      );
      updateTriangulationCircleSource(
        relevantGunshots,
        timeOfLatestGunshot,
        relativeLatestTriangulation,
        map
      );
    }

    if (
      map.getLayer("triangulations-heat") &&
      (map.getLayoutProperty("triangulations-heat", "visibility") ===
        "visible" ||
        map.getLayoutProperty("triangulations-heat", "visibility") ===
          undefined) &&
      gunshotLayer === GunshotLayerType.Circle
    ) {
      map.setLayoutProperty("triangulations-heat", "visibility", "none");
      map.setLayoutProperty("gunshot", "visibility", "visible");
      map.setLayoutProperty("gunshot-line", "visibility", "visible");
      map.setLayoutProperty("gunshot-circle", "visibility", "visible");
    }
    if (
      map.getLayer("gunshot") &&
      (map.getLayoutProperty("gunshot", "visibility") === "visible" ||
        map.getLayoutProperty("gunshot", "visibility") === undefined) &&
      gunshotLayer === GunshotLayerType.Heatmap
    ) {
      map.setLayoutProperty("gunshot", "visibility", "none");
      map.setLayoutProperty("gunshot-circle", "visibility", "none");
      map.setLayoutProperty("gunshot-line", "visibility", "none");
      map.setLayoutProperty("triangulations-heat", "visibility", "visible");
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    cleanupTimestamp: state.commander.cleanupTimestamp,
    focused: state.commander.focused,
    gunshotLayer: state.commander.gunshotLayerType,
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
    relativeLatestTriangulation: state.triangulation.relativeEndDate,
    showOnlyFocusedShot: state.commander.showOnlyFocusedShot,
    timeOfLatestGunshot: state.triangulation.timeOfLatestGunshot,
    gunshots: state.triangulation.gunshots,
    twinsDisplayMode: state.triangulation.twinToDisplay,
    relativeRange: state.triangulation.relativeRange,
    areaFilter: state.areaFilter.list
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(GunshotLayers);
