import * as React from "react";
import styles from "./style.module.scss";

export enum NotificationColors {
  Ok = "#28a745",
  Warning = "#F59E0B",
  Critical = "#EF4444",
}

const NotificationBox = ({
  message,
  color,
}: {
  message: string;
  color: NotificationColors;
}) => {
  return <div style={{background: color}} className={styles.notificationBox}>{message}</div>;
};

export default NotificationBox;
