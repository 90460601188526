import * as React from "react";
import style from "./style.module.scss";

const LightInfo = ({ id, text }: { id?: string; text: string }) => {
  if (id) {
    return (
      <p id={id} className={style.lightInfo}>
        {text}
      </p>
    );
  } else {
    return <p className={style.lightInfo}>{text}</p>;
  }
};

export default LightInfo;
