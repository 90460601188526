import { features } from "process";

import {
  RelativeRange,
  relativeRangeToDate,
  shouldBeDisplayed,
} from "../components/range/rangeUtil";
import { Geofence } from "../models/geofence";
import { Gunshot } from "../models/gunshots";
import { TwinDisplayMode } from "../models/map";
import { isValidPointInAreaFilter } from "./areaFilterUtil";
import { filterLocationsIfNeeded } from "./mapUtil";

export const filterRelevantGunshot = (
  gunshots: { [id: string]: Gunshot } | Gunshot[],
  rangeEnd: number | undefined,
  rangeStart: number | undefined,
  relativeRange: RelativeRange,
  twinDisplayMode?: TwinDisplayMode,
  areaFilter: Geofence[] = []
): Gunshot[] => {
  const mappedTriangulations = Array.isArray(gunshots)
    ? gunshots
    : Object.keys(gunshots).map((key) => gunshots[key]);
  const relativeRangeDates = relativeRangeToDate(relativeRange);
  return mappedTriangulations
    .filter(
      (t) =>
        shouldBeDisplayed(t, relativeRangeDates) &&
        (rangeEnd ? t.timestamp.getTime() < rangeEnd : true) &&
        (rangeStart ? t.timestamp.getTime() > rangeStart : true)
    )
    .map((g) => {
      if (!twinDisplayMode && areaFilter.length === 0) {
        return g;
      }
      const filteredLocations = filterLocationsIfNeeded(
        g,
        twinDisplayMode || TwinDisplayMode.All
      ).filter((f) =>
        isValidPointInAreaFilter(
          [
            f.properties.representativePoint.longitude,
            f.properties.representativePoint.latitude,
          ],
          areaFilter
        )
      );

      return {
        ...g,
        location: {
          geoJson: { ...g.location.geoJson, features: filteredLocations },
        },
      };
    })
    .filter((g) => g.location.geoJson.features.length > 0);
};
