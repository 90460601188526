import distance from "@turf/distance";
import { Map } from "mapbox-gl";
import { connect } from "react-redux";

import { MapViewType } from "../../../models/MapViewType";
import { LatLon } from "../../../models/location";
import { FocusType } from "../../../models/map";
import { ScoutState } from "../../../models/scouts";
import { ApplicationState } from "../../../reducers";
import { orderLayers } from "../layerUtils";
import { Units } from "../../../models/organisation";

export interface ScoutRangeLayerProps {
  selectedScout: LatLon | undefined;
  scouts: LatLon[];
  unit: Units;
}

export interface ScoutRangeOwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends ScoutRangeOwnProps, ScoutRangeLayerProps {}

function addScoutRangeLayer(map: Map) {
  map.addLayer({
    id: "scout_range",
    minzoom: 12,
    paint: {
      "line-color": "#adadad",
      "line-width": 3,
    },
    source: "scout_range",
    type: "line",
  });
}

function addScoutRangeDistanceLayer(map: Map) {
  map.addLayer({
    id: "scout_range_text",
    interactive: false,
    layout: {
      "icon-allow-overlap": true,
      "text-field": ["get", "distance"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 12,
      "symbol-placement": "line-center",
    },
    minzoom: 12,
    paint: {
      "text-color": "#FFFFFF",
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "scout_range",
    type: "symbol",
  });
}

function addScoutRangeSource(
  map: Map,
  selectedScout: LatLon | undefined,
  scouts: LatLon[],
  unit: Units
) {
  map.addSource("scout_range", {
    data: createScoutRangeLines(selectedScout, scouts, unit),
    type: "geojson",
  });
}

function updateScoutRangeSource(
  map: Map,
  selectedScout: LatLon | undefined,
  scouts: LatLon[],
  unit: Units
) {
  map
    .getSource("scout_range")
    // @ts-ignore
    .setData(createScoutRangeLines(selectedScout, scouts, unit));
}

export const GeneralScoutRangeLayer = ({
  loaded,
  map,
  selectedScout,
  scouts,
  unit
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("scout_range")) {
      addScoutRangeSource(map, selectedScout, scouts, unit);
      addScoutRangeLayer(map);
      addScoutRangeDistanceLayer(map);
      orderLayers(map);
    } else {
      updateScoutRangeSource(map, selectedScout, scouts, unit);
    }

    if (
      (map.getLayer("scout_range") &&
        map.getLayer("scout_range") &&
        map.getLayoutProperty("scout_range", "visibility") === "visible" &&
        !selectedScout) ||
      (map.getLayer("scout_range") &&
        map.getLayoutProperty("scout_range", "visibility") !== "visible" &&
        selectedScout)
    ) {
      map.setLayoutProperty(
        "scout_range",
        "visibility",
        !selectedScout ? "none" : "visible"
      );
    }
  }

  return null;
};

const createScoutRangeLines = (
  selectedScout: LatLon | undefined,
  scouts: LatLon[],
  unit: Units
): any => {
  if (!selectedScout) {
    return {
      features: [],
      type: "FeatureCollection",
    };
  }
  return {
    features: scouts.map((scout) => {
      const calculatedDistance = distance(
        [selectedScout.longitude, selectedScout.latitude],
        [scout.longitude, scout.latitude],
        { units: unit === Units.Imperial ?"yards" : "meters" }
      ).toFixed(0)
      return {
        geometry: {
          coordinates: [
            [scout.longitude, scout.latitude],
            [selectedScout.longitude, selectedScout.latitude],
          ],
          type: "LineString",
        },
        id: `${scout.latitude}${scout.longitude}`,
        properties: {
          id: `${scout.latitude}${scout.longitude}`,
          distance: unit === Units.Imperial ? `${calculatedDistance}yd` : `${calculatedDistance}m`,
        },
        type: "Feature",
      };
    }),
    type: "FeatureCollection",
  };
};

const mapStateToProps = (state: ApplicationState) => {
  const focused = state.commander.focused;
  if (!focused || focused.type !== FocusType.Scouts || !focused.id) {
    return {
      selectedScout: undefined,
      scouts: [],
      unit: state.authentication.userData?.organisation?.units || Units.Metric
    };
  }

  const selectedScout: LatLon = state.scout.list
    .filter((s) => s.status.deviceId === focused?.id)
    .map((s) => ({
      latitude: s.status.latitude,
      longitude: s.status.longitude,
    }))[0];

  const moving: LatLon | undefined = state.scout.movingFixedScout;

  const scouts: LatLon[] = state.scout.list
    .filter(
      (s) =>
        s.status.deviceId !== focused.id && s.state !== ScoutState.OfflineIgnore
    )
    .map((s) => ({
      latitude: s.status.latitude,
      longitude: s.status.longitude,
    }));

  return { 
    selectedScout: moving ? moving : selectedScout, 
    scouts,
    unit: state.authentication.userData?.organisation?.units || Units.Metric
   };
};

export default connect<ScoutRangeLayerProps, {}, {}, ApplicationState>(
  mapStateToProps
)(GeneralScoutRangeLayer);


