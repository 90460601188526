import * as React from "react";
import styles from "./style.module.scss";
import CommanderLogoPng from "./triangula-commander-semi.png";

const CommanderLogo = () => {
  return (
      <img className={styles.logo} src={CommanderLogoPng} alt={"Triangula Commander"} />
  );
};

export default CommanderLogo;
