import { LngLat, Map } from "mapbox-gl";
import { connect } from "react-redux";
import { GunshotLayerType, TwinDisplayMode } from "../../../models/map";
import { MapViewType } from "../../../models/MapViewType";
import { ApplicationState } from "../../../reducers";
import { getRepresentativePoint } from "../../../util/mapUtil";
import { RelativeRange } from "../../range/rangeUtil";
import { orderLayers } from "../layerUtils";
import { twinLines } from "../mapUtil";
import { filterRelevantGunshot } from "../../../util/gunshotUtil";
import { Gunshot } from "../../../models/gunshots";
import { Geofence } from "../../../models/geofence";

export interface MapboxProps {
  gunshots: { [id: string]: Gunshot };
  rangeEnd: number | undefined;
  rangeStart: number | undefined;
  twinsDisplayMode: TwinDisplayMode;
  gunshotLayer: GunshotLayerType;
  relativeRange: RelativeRange;
  areaFilter: Geofence[]
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
  mapType?: string | undefined;
}

export interface Props extends OwnProps, MapboxProps {}

function addTwinLinesLayer(map: Map) {
  map.addLayer({
    id: "twin_lines",
    minzoom: 12,
    paint: {
      "line-color": "#fbfe00",
      "line-opacity": ["get", "opacity"],
      "line-width": 2,
    },
    source: "twin_lines",
    type: "line",
  });
}

function addTwinLinesSource(lines: LngLatLine[], map: Map) {
  map.addSource("twin_lines", {
    data: twinLines(lines),
    type: "geojson",
  });
}

function updateTwinLinesSource(lines: LngLatLine[], map: Map) {
  map
    .getSource("twin_lines")
    // @ts-ignore
    .setData(twinLines(lines));
}

const TriangulationLinesLayer = ({ loaded, map, areaFilter,currentMapType,gunshotLayer,gunshots ,rangeEnd,rangeStart,relativeRange,twinsDisplayMode }: Props) => {


  const gunshotsWithTwoLocations = Object.values(gunshots).filter(
    (g) => g.location.geoJson.features.length > 1
  );
  const lines = gunshotLayer === GunshotLayerType.Heatmap ? [] :filterRelevantGunshot(gunshotsWithTwoLocations, rangeEnd, rangeStart, relativeRange, twinsDisplayMode, areaFilter)
  .filter((g) => g.location.geoJson.features.length > 1)
    
    .map((t) => {
      const point1 = getRepresentativePoint(t.location.geoJson.features[0]);
      const point2 = getRepresentativePoint(t.location.geoJson.features[1]);
      return {
        point1: new LngLat(point1.longitude, point1.latitude),
        point2: new LngLat(point2.longitude, point2.latitude),
        focused: false
      } as LngLatLine;
    });

  if (map && loaded) {
    if (!map.getSource("twin_lines")) {
      addTwinLinesSource(lines, map);
      addTwinLinesLayer(map);
      orderLayers(map);
    } else {
      updateTwinLinesSource(lines, map);
    }
  }

  return null;
};

export interface LngLatLine {
  point1: LngLat;
  point2: LngLat;
  focused?: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
 
  return {
    gunshotLayer: state.commander.gunshotLayerType,
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
    gunshots: state.triangulation.gunshots,
    twinsDisplayMode: state.triangulation.twinToDisplay,
    relativeRange: state.triangulation.relativeRange,
    areaFilter: state.areaFilter.list
  };
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  TriangulationLinesLayer
);
