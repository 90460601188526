// https://github.com/moment/moment/issues/2608
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import RangeDropdown from "../../components/range/RangeDropdown";
import {
  RelativeRange,
  relativeRangeToDate,
  shouldBeDisplayed,
} from "../../components/range/rangeUtil";
import { Gunshot } from "../../models/gunshots";
import { FocusType } from "../../models/map";
import { ApplicationState } from "../../reducers";
import { filterRelevantGunshot } from "../../util/gunshotUtil";
import { prettyListDate } from "../../util/util";
import { setCleanupTimestampAction } from "../commander/commanderReducer";
import GunshotItem from "./GunshotItem";
import HyperbolaFetcher from "./HyperbolaFetcher";
import styles from "./style.module.scss";

const NoGunshots = () => (
  <div
    style={{
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      padding: "120px 20px",
    }}
  >
    <i id="no_gunshot" style={{ color: "grey" }}>
      No gunshots
    </i>
  </div>
);

const GunshotList = ({
  gunshots,
  rangeEnd,
  rangeStart,
  focusId,
  relativeRange,
}: Props) => {
  const mapToGunshotItem = (gunshot: Gunshot) => {
    return (
      <GunshotItem key={"triangulation_" + gunshot.id} gunshot={gunshot} />
    );
  };
  const relevantGunshots = filterRelevantGunshot(gunshots, rangeEnd, rangeStart, relativeRange)
    .sort((t1, t2) => (t1.timestamp < t2.timestamp ? 1 : -2))
    .slice(0, 100);

  const focusedGunshot: Gunshot | undefined =
    focusId !== undefined ? gunshots[focusId] : undefined;

  const Triangulations =
  relevantGunshots.find((s) => s.id === focusId) === undefined &&
    focusedGunshot !== undefined
      ? [...relevantGunshots, focusedGunshot]
      : relevantGunshots;

  const ListElements: JSX.Element[] = [];

  let previousDate = new Date(0);
  Triangulations.forEach((t) => {
    if (!moment(previousDate).isSame(t.timestamp, "day")) {
      ListElements.push(
        <p key={t.timestamp.getTime()} className={styles.dateHeader}>
          {prettyListDate(t.timestamp)}
        </p>
      );
      previousDate = t.timestamp;
    }
    ListElements.push(mapToGunshotItem(t));
  });

  return (
    <div id={"shot_list"} className={styles.list}>
      <RangeDropdown />
      <HyperbolaFetcher />
      {Triangulations.length ? ListElements : <NoGunshots />}
    </div>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  gunshots: { [id: string]: Gunshot };
  rangeStart: number | undefined;
  rangeEnd: number | undefined;
  cleanupTimestamp: number;
  focusedElement: boolean;
  focusType: FocusType | undefined;
  focusId: string | undefined;
  relativeRange: RelativeRange;
}

interface DispatchToProps {
  setCleanupTimestamp: (_: number) => void;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cleanupTimestamp: state.commander.cleanupTimestamp,
    focusType: state.commander.focused
      ? state.commander.focused.type
      : undefined,
    focusedElement: state.commander.focused
      ? state.commander.focused.id !== undefined
      : false,
    focusId:
      state.commander.focused &&
      state.commander.focused.type === FocusType.Shots
        ? state.commander.focused.id
        : undefined,
    rangeEnd: state.commander.rangeEnd,
    rangeStart: state.commander.rangeStart,
    gunshots: state.triangulation.gunshots,
    relativeRange: state.triangulation.relativeRange,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCleanupTimestamp: setCleanupTimestampAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(GunshotList);
