import {Map} from "mapbox-gl";
import {connect} from "react-redux";
import {FocusType} from "../../../models/map";
import {MapViewType} from "../../../models/MapViewType";
import {ScoutPosition} from "../../../models/scouts";
import { ApplicationState } from "../../../reducers";
import {orderLayers} from "../layerUtils";
import {focusedScoutLines} from "../mapUtil";

export interface MapboxProps {
  scoutPositions: ScoutPosition[];
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  currentMapType: MapViewType; // NEEDS THIS TO RELOAD THE LAYERS
}

export interface Props extends OwnProps, MapboxProps {}

function addScoutLinesLayer(map: Map) {
  map.addLayer({
    id: "scout_lines",
    minzoom: 13,
    paint: {
        "circle-color": ["get", "color"],
        "circle-opacity": 0.7,
        "circle-radius": 8,
        "circle-stroke-width": 0,
    },
    source: "scout_lines",
    type: "circle",
  });
}

function addScoutLinesSource(scoutPositions: ScoutPosition[], map: Map) {
  map.addSource("scout_lines", {
    data: focusedScoutLines(scoutPositions),
    lineMetrics: true,
    type: "geojson",
  });
}

function updateScoutLinesSource(scoutPositions: ScoutPosition[], map: Map) {
  map
    .getSource("scout_lines")
    // @ts-ignore
    .setData(focusedScoutLines(scoutPositions));
}

const ScoutPositionHistoryLayer = ({ loaded, map, scoutPositions }: Props) => {
  if (map && loaded) {
    if (!map.getSource("scout_lines")) {
      addScoutLinesSource(scoutPositions, map);
      addScoutLinesLayer(map);
      orderLayers(map);
    } else {
      updateScoutLinesSource(scoutPositions, map);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const focused = state.commander.focused;
  const scoutPositions = state.scout.history;

  if (
    !focused ||
    focused.type !== FocusType.Scouts ||
    !focused.id ||
    !scoutPositions[focused.id]
  ) {
    return {
      scoutPositions: [],
    };
  } else {
    return {
      scoutPositions: state.scout.history[focused.id],
    };
  }
};

export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(
  ScoutPositionHistoryLayer
);
