import * as React from "react";

import styles from "../../containers/overview/style.module.scss";
import commonStyles from "../../containers/styles/style.module.scss";
import { Geofence } from "../../models/geofence";
import { Focus, FocusType } from "../../models/map";
import { Units } from "../../models/organisation";
import { CoverageData } from "../../util/coverage";
import { getCircleArea, squareKmToSquareMi } from "../../util/util";
import SummaryItem from "./SummaryItem";
import summaryStyles from "./style.module.scss";

interface Props {
  geofences: Geofence[];
  inFocus: boolean;
  setFocus: (_: Focus | undefined) => void;
  coverages: CoverageData | undefined;
  toggleCoverage: () => void;
  unit: Units;
}

const ZoneSummary = ({
  inFocus,
  setFocus,
  geofences,
  unit,
}: Props) => {
  const setFocusOnClick = () => {
    setFocus({ type: FocusType.Coverage });
  };

  const classes = [styles.summaryText, commonStyles.centerText, styles.status];

  const activeGeofences = geofences.filter((geofence) => geofence.listening);
  const activeGeofenceAreaInKm = activeGeofences.reduce((acc, geofence) => {
    return acc + getCircleArea(geofence.radius / 1000);
  }, 0);
  let areaText =
    unit === Units.Metric
      ? `${activeGeofenceAreaInKm.toFixed(1)} km²`
      : `${squareKmToSquareMi(activeGeofenceAreaInKm).toFixed(1)} mi²`;

  const details =
    activeGeofences.length === 0
      ? "No active zones"
      : `${activeGeofences.length} active zone${
          activeGeofences.length > 1 ? "s" : ""
        }`;

  return (
    <SummaryItem
      focusType={FocusType.Coverage}
      inFocus={inFocus}
      setFocus={setFocusOnClick}
    >
      <div className={classes.join(" ")}>
        <div className={[commonStyles.centerText].join(" ")}>
          <p className={summaryStyles.summaryLabel}>Active zone{
          activeGeofences.length > 1 ? "s" : ""
        }</p>
          <p id="coverage_status" className={summaryStyles.summaryValue}>
            {areaText}
          </p>
          <p
            className={styles.summaryDescription + " " + commonStyles.noMargin}
          >
            {details}
          </p>
        </div>
      </div>
    </SummaryItem>
  );
};

export default ZoneSummary;
